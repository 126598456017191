import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Login, Inventory, LossessDamages, RecourceMapping, ReliefDistribution, Forms, MyTask, ImportSchools, ItemDetails, MyDraft } from './pages';
import { Provider } from 'react-redux';
import { store } from './reduxToolkit/store';
import { RootState } from './reduxToolkit/store';
import { useSelector } from 'react-redux';
import { PrivateRoute } from './privateRoute';
import { Banner, Header, Loading, Tabs } from './components';
import Footer from './components/footer';
import { useDispatch } from 'react-redux';
import { loadUserFromToken } from "./reduxToolkit/auth/authSlice";
import { fetchAllEvents, fetchDivisionData, fetchExternalWorkflows, fetchGenderData, fetchInternalWorkflows } from './reduxToolkit/entitiesSlice';
import { fetchHumanCasualtieTypeData } from './reduxToolkit/humanCasualtieSlice';
import { initializeSocket } from './config/socket';

const App: React.FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const dispatch = useDispatch<any>();
  const [spinner, setSpinner] = useState<any>();

  useEffect(() => {
    setCurrentUrl(window.location.pathname);
    const token: any = localStorage.getItem('token');
    if (token) {
      fetchUserData(token)
        .then(user => {
          if (user) {
            dispatch(loadUserFromToken({ user }));
            dispatch(fetchExternalWorkflows(user?.role?.name));
            dispatch(fetchInternalWorkflows(user?.role?.name));
            dispatch(fetchAllEvents(user));
          }
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    }
    dispatch(fetchDivisionData());
    dispatch(fetchGenderData());
    dispatch(fetchHumanCasualtieTypeData());
    dispatch(initializeSocket());
  }, []);

  // Function to fetch user data using token
  const fetchUserData = async (token: string) => {
    setSpinner(true);
    try {
      // Ensure backend URL is available
      if (!process.env.REACT_APP_LOGIN_DEV_URL) {
        setSpinner(false);
        throw new Error('Backend URL not provided in environment variables');
      }
      const backendDevUrl = process.env.REACT_APP_LOGIN_DEV_URL;
      const response = await fetch(`${backendDevUrl}users/me?populate=*`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSpinner(false);

      if (!response.ok) {
        setSpinner(true);
        const errorData = await response.json(); // Try to parse error details
        // Handle specific error messages from the server (if applicable)
        if (errorData.error) {
          setSpinner(false);
          throw new Error(errorData.error.message); // Relay specific error message
        } else {
          setSpinner(false);
          throw new Error(`HTTP error ${response.status}: ${response.statusText}`);
        }
      }
      const userData = await response.json();
      setSpinner(false);
      return userData;
    } catch (error) {
      setSpinner(false);
      console.error('Error fetching user data:', error);
      return Promise.reject(error); // Explicitly reject the promise
    }
  };

  return (
    <Provider store={store}>
      <Loading isLoading={false} />
      <Router>
        <Header />
        <div style={{ minHeight: '76vh' }}>
          <Banner imageUrl="/path/to/banner.jpg" />
          <Tabs />
          <Routes>
            <Route path="/" element={<Navigate to={`/resourcemapping`} />} />

            <Route path="/resourcemapping" element={<RecourceMapping />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/lossessdamages" element={<LossessDamages />} />
            <Route path="/details" element={<ItemDetails />} />
            <Route path="/login" element={isAuthenticated ? <Navigate to={`/resourcemapping`} /> : <Login />} />
            <Route path='/reliefdistribution' element={<PrivateRoute />}>
              <Route path='/reliefdistribution' element={<ReliefDistribution />} />
            </Route>
            <Route path="/form" element={<PrivateRoute />}>
              <Route path="/form" element={<Forms />} />
            </Route>
            <Route path="/mytask" element={<PrivateRoute />}>
              <Route path="/mytask" element={<MyTask />} />
            </Route>
            <Route path="/mydraft" element={<PrivateRoute />}>
              <Route path="/mydraft" element={<MyDraft />} />
            </Route>
            <Route path="/importschools" element={<ImportSchools />} />
            <Route path="*" element={<div>Not Found</div>} />
          </Routes>
        </div>
        <Footer imageUrl="/path/to/banner.jpg" />
      </Router>
    </Provider>
  );
};

export default App;
