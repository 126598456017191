// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-form {
  background-color: #f7f7f7;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 50px;

  .textArea-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 115px;
    grid-column: 1 / -1;
  }

  .textArea-cont span {
    font-size: 14px;
    font-weight: 500;
    color: #073042;
  }

  .form-bady-heading {
    margin: 0px auto;
  }

  .ant-tag {
    background-color: rgba(0, 0, 0, 0.04);
    color: #073042;
    padding: 8px;
    border-radius: 8px;
  }

  .input-cont {
    height: 100%;
    justify-content: flex-start;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Details/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,uCAAuC;EACvC,iBAAiB;;EAEjB;IACE,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,qCAAqC;IACrC,cAAc;IACd,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,2BAA2B;EAC7B;AACF","sourcesContent":[".event-form {\n  background-color: #f7f7f7;\n  padding: 20px;\n  border: 1px solid #ddd;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  margin: 20px 50px;\n\n  .textArea-cont {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    margin-bottom: 15px;\n    height: 115px;\n    grid-column: 1 / -1;\n  }\n\n  .textArea-cont span {\n    font-size: 14px;\n    font-weight: 500;\n    color: #073042;\n  }\n\n  .form-bady-heading {\n    margin: 0px auto;\n  }\n\n  .ant-tag {\n    background-color: rgba(0, 0, 0, 0.04);\n    color: #073042;\n    padding: 8px;\n    border-radius: 8px;\n  }\n\n  .input-cont {\n    height: 100%;\n    justify-content: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
