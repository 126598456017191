import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

interface CompensatonReliefState {
    compensatonReliefList: any[];
    compensatonReliefLoading: boolean;
    compensatonStatusList: any[];
    filteredCompensatonReliefList: any[];
    compensatonReliefMeta: any;
    filteredCompensatonReliefCount: number;
    compensatonReliefCount: number,
    compensatonReliefChartList: any[];
    selectedCompensatonRelief: any;
}

const initialState: CompensatonReliefState = {
    compensatonReliefList: [],
    compensatonReliefLoading: false,
    compensatonStatusList: [],
    filteredCompensatonReliefList: [],
    compensatonReliefMeta: {},
    filteredCompensatonReliefCount: 0,
    compensatonReliefCount: 0,
    compensatonReliefChartList: [],
    selectedCompensatonRelief: {}
};

export const fetchCompensatonReliefCount = createAsyncThunk('compensatonRelief/fetchCompensatonReliefCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedEvent, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                }
            }
        };
        if (selectedEvent) {
            variables.filter.event = {
                name: {
                    "eq": selectedEvent
                }
            };
        }
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiCompensatonReliefsCount : API.ApiCompensatonReliefsCountWithOutChartData,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.compensatonReliefs.meta.pagination.total, compensatonReliefChartList: response.data.compensatonReliefs.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchCompensatonReliefData = createAsyncThunk('compensatonRelief/fetchCompensatonReliefData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent } = pageData;
        const filter: any = {
            stage: {
                null: true
            }
        };
        if (selectedEvent) {
            filter.event = {
                name: {
                    "eq": selectedEvent
                }
            };
        }
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllCompensatonReliefs,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.compensatonReliefs.data, meta: response.data.compensatonReliefs.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFilterdCompensationStatusesData = createAsyncThunk('compensatonRelief/fetchFilterdCompensationStatusesData', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await client.query({ query: API.ApiCompensatonReliefs, variables, context: { headers }, fetchPolicy: 'no-cache' });
        return { data: response.data.compensatonReliefDrafts.data, filteredCompensatonReliefCount: response.data.compensatonReliefDrafts.meta.pagination.total };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchCompensationStatusesData = createAsyncThunk('compensatonRelief/fetchCompensationStatusesData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiCompensationStatuses, fetchPolicy: 'no-cache' });
        return response.data.compensationStatuses.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchCompensatonReliefDataById = createAsyncThunk('compensatonRelief/fetchCompensatonReliefDataById', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetCompensatonReliefById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.compensatonRelief.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createCompensatonReliefData = createAsyncThunk('compensatonRelief/createCompensatonReliefData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateCompensatonRelief,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createCompensatonReliefDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});


export const updateCompensatonReliefDraftData = createAsyncThunk('compensatonRelief/updateCompensatonReliefDraftData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingCompensatonReliefDraft,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateCompensatonReliefDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateCompensationStatusesStageData = createAsyncThunk('compensatonRelief/updateCompensationStatusesStageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { stage, id, record } = data;
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        if (stage) {
            await client.mutate({
                mutation: API.ApiUpdateCompensatonReliefStage,
                variables: {
                    id: id,
                    stage: stage
                },
                context: {
                    headers,
                },
            });
        } else {
            const obj = {
                "divisionId": record.divisionId,
                "districtId": record.districtId,
                "eventId": record.eventId,
                "genderId": record.genderId,
                "full_name": record.full_name,
                "cnic": record.cnic ? Number(record.cnic) : null,
                "date_of_birth": record.date_of_birth,
                "type": record.type,
                "reason": record.reason,
                "address": record.address,
                "mobile_number": record.mobile_number ? Number(record.mobile_number) : null,
                "stage": null,
                "tehsilId": record.tehsilId,
                "union_councilId": record.union_councilId,
                "compensatonReliefId": record.compensaton_relief
            };
            await client.mutate({
                mutation: obj.compensatonReliefId ? API.ApiUpdateExistingCompensatonRelief : API.ApiCreatePublisedCompensatonRelief,
                variables: obj,
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
            await client.mutate({
                mutation: API.ApiDeleteCompensatonReliefDraft,
                variables: { id },
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
        }
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteCompensationStatusesData = createAsyncThunk('compensatonRelief/deleteCompensationStatusesData', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteCompensatonRelief,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateCompensatonReliefData = createAsyncThunk('compensatonRelief/updateCompensatonReliefData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingCompensatonRelief, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateCompensatonRelief.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateCompensatonReliefRecordFromSocket = createAsyncThunk('compensatonRelief/addAndUpdateCompensatonReliefRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, internalWorkflowsList } = data;
        const response = await client.query({ query: API.ApiGetCompensatonReliefById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.compensatonRelief.data, internalWorkflowsList };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteCompensatonReliefRecordFromSocket = createAsyncThunk('compensatonRelief/deleteCompensatonReliefRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateCompensationStatusRecordFromSocket = createAsyncThunk('compensatonRelief/addAndUpdateCompensationStatusRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetCompensationStatusById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.compensationStatus.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteCompensationStatuseRecordFromSocket = createAsyncThunk('compensatonRelief/deleteCompensationStatuseRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const getCompensatonReliefDraftByUser = createAsyncThunk('compensatonRelief/getCompensatonReliefDraftByUser', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.APIGetCompensatonReliefDraftByUser, // Assuming you have an API constant for update mutation
            variables,
            context: {
                headers,
            },
        });
        if (response.data && response.data.compensatonReliefDrafts.data.length > 0) {
            return true;
        }
        return false;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const checkCompensatonReliefCnicExists = createAsyncThunk('compensatonRelief/checkCompensatonReliefCnicExists', async ({ cnic, excludeCompensatonReliefId, excludeCompensatonReliefDraftId }: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.query({
            query: API.APICheckCompensatonReliefCnic,
            variables: { cnic, excludeCompensatonReliefId, excludeCompensatonReliefDraftId },
            context: {
                headers,
            },
        });
        return {
            compensatonReliefs: response.data.compensatonReliefs.data,
            compensatonReliefDrafts: response.data.compensatonReliefDrafts.data,
        };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const compensatonReliefSlice = createSlice({
    name: 'compensatonRelief',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchCompensatonReliefCount.fulfilled, (state, action) => {
                state.compensatonReliefCount = action.payload.count;
                state.compensatonReliefChartList = action.payload.compensatonReliefChartList;
                state.compensatonReliefLoading = false;
            })
            .addCase(fetchCompensatonReliefData.fulfilled, (state, action) => {
                state.compensatonReliefList = action.payload.data;
                state.compensatonReliefMeta = action.payload.meta;
                state.compensatonReliefLoading = false;
            })
            .addCase(fetchFilterdCompensationStatusesData.fulfilled, (state, action) => {
                state.filteredCompensatonReliefList = action.payload.data;
                state.filteredCompensatonReliefCount = action.payload.filteredCompensatonReliefCount;
                state.compensatonReliefLoading = false;
            })
            .addCase(fetchCompensationStatusesData.fulfilled, (state, action) => {
                state.compensatonStatusList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchCompensatonReliefDataById.fulfilled, (state, action) => {
                state.selectedCompensatonRelief = action.payload;
                state.compensatonReliefLoading = false;
            })
            .addCase(createCompensatonReliefData.fulfilled, (state, action) => {
                // state.compensatonReliefList.push(action.payload);
                state.compensatonReliefLoading = false;
            })
            .addCase(updateCompensatonReliefDraftData.fulfilled, (state, action) => {
                state.compensatonReliefLoading = false;
            })
            .addCase(updateCompensationStatusesStageData.fulfilled, (state, action) => {
                const index = state.filteredCompensatonReliefList.findIndex(item => item.id === action.payload);
                state.filteredCompensatonReliefList.splice(index, 1);
                state.compensatonReliefLoading = false;
            })
            .addCase(deleteCompensationStatusesData.fulfilled, (state, action) => {
                state.compensatonReliefLoading = false;
            })
            .addCase(getCompensatonReliefDraftByUser.fulfilled, (state, action) => {
                state.compensatonReliefLoading = false;
            })
            .addCase(updateCompensatonReliefData.fulfilled, (state, action) => {
                const index = state.filteredCompensatonReliefList.findIndex(item => item.id === action.payload);
                state.filteredCompensatonReliefList.splice(index, 1);
                state.compensatonReliefLoading = false;
            })
            .addCase(addAndUpdateCompensatonReliefRecordFromSocket.fulfilled, (state, action) => {
                const internalWorkflowsList = action.payload.internalWorkflowsList;
                const { id, attributes } = action.payload.data;
                if (attributes.stage) {
                    const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === attributes.stage);
                    const index = state.filteredCompensatonReliefList.findIndex(item => item.id == id);
                    if (selectedWorkFlow) {
                        index !== -1 ? state.filteredCompensatonReliefList[index] = action.payload.data : state.filteredCompensatonReliefList.push(action.payload.data);
                    } else {
                        state.filteredCompensatonReliefList.splice(index, 1);
                    }
                } else {
                    const index = state.compensatonReliefList.findIndex(item => item.id == id);
                    index !== -1 ? state.compensatonReliefList[index] = action.payload.data : state.compensatonReliefList.push(action.payload.data);
                }
            })
            .addCase(deleteCompensatonReliefRecordFromSocket.fulfilled, (state, action) => {
                state.compensatonReliefList = state.compensatonReliefList.filter(item => item.id != action.payload);
                state.filteredCompensatonReliefList = state.filteredCompensatonReliefList.filter(item => item.id != action.payload);
            })
            .addCase(addAndUpdateCompensationStatusRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.compensatonStatusList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.compensatonStatusList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.compensatonStatusList = updatedArray;
            })
            .addCase(deleteCompensationStatuseRecordFromSocket.fulfilled, (state, action) => {
                state.compensatonStatusList = state.compensatonStatusList.filter(item => item.id != action.payload);
            });
        [
            fetchCompensatonReliefCount,
            fetchCompensatonReliefData,
            fetchFilterdCompensationStatusesData,
            updateCompensationStatusesStageData,
            createCompensatonReliefData,
            deleteCompensationStatusesData,
            updateCompensatonReliefData,
            fetchCompensatonReliefDataById,
            updateCompensatonReliefDraftData,
            getCompensatonReliefDraftByUser
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.compensatonReliefLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.compensatonReliefLoading = false;
                });
        });
    }
});


export default compensatonReliefSlice.reducer;
