import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Input, Space, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { fetchFilterdNGOData, updateNGOStageData } from '../../reduxToolkit/ngoSlice';
import ReusableTable from '../../components/table';
import SearchSelect from '../../components/selectDropdown';
import Filters from '../../components/top_filter';
import googleMaps from '../../assets/images/google-maps-2020-icon 1.png';
import { fetchFilterdManufacturerData, updateManufacturerStageData } from '../../reduxToolkit/manufacturerSlice';
import { fetchFilterdSuppliersData, updateSupplierStageData } from '../../reduxToolkit/supplierSlice';
import { fetchFilterdVolunteersData, updateVolunteerStageData } from '../../reduxToolkit/volunteerSlice';
import { EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { toast } from '../../components/Toast';
import { fetchFilterdInventoriesData, updateInventoryStageData } from '../../reduxToolkit/inventorySlice';
import { compensatonReliefColumns, financialReliefColumns, foodDamageColumns, humanCasualtieColumns, infrastructureDamageColumns, inventoryColumns, livestockDamageColumns, manufacturersColumns, ngoColumns, suppliersColumns, volunteersColumns } from '../../helpers/tableColumns';
import { alignCompensatonReliefsData, alignFinancialReliefData, alignFoodDamageData, alignHumanCasualtieData, alignInfrastructureDamageData, alignInventoryData, alignLivestockDamageData, alignManufacturersData, alignNgoData, alignsSuppliersData, alignVolunteersData } from '../../helpers/alignData';
import { fetchFilterdFinancialReliefData, updateFinancialReliefStageData } from '../../reduxToolkit/financialReliefSlice';
import { fetchFilterdCompensationStatusesData, updateCompensationStatusesStageData } from '../../reduxToolkit/compensatonReliefSlice';
import { fetchFilterdHumanCasualtieData, updateHumanCasualtieStageData } from '../../reduxToolkit/humanCasualtieSlice';
import { fetchFilterdInfrastructureDamageData, updateInfrastructureDamageStageData } from '../../reduxToolkit/infrastructureDamageSlice';
import { fetchFilterdFoodDamageData, updateFoodDamageStageData } from '../../reduxToolkit/foodDamageSlice';
import { fetchFilterdLivestockDamageData, updateLivestockDamageStageData } from '../../reduxToolkit/livestockDamageSlice';

const MyTask: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const { internalWorkflowsList, externalWorkflowsList } = useSelector((state: RootState) => state.entities);
    const { userData } = useSelector((state: RootState) => state.auth);
    const { filteredNgoList, filteredngoCount } = useSelector((state: RootState) => state.ngos);
    const { filteredManufacturerList, filteredManufacturerCount } = useSelector((state: RootState) => state.manufacturer);
    const { filteredSupplierList, filteredSuppliersCount } = useSelector((state: RootState) => state.supplier);
    const { filteredVolunteerList, filteredVolunteersCount } = useSelector((state: RootState) => state.volunteer);
    const { filteredInventoriesList, filteredInventoriesCount } = useSelector((state: RootState) => state.inventory);
    const { filteredFinancialReliefList, filteredFinancialCount } = useSelector((state: RootState) => state.financialRelief);
    const { filteredCompensatonReliefList, filteredCompensatonReliefCount } = useSelector((state: RootState) => state.compensatonRelief);
    const { filteredHumanCasualtieList, filteredHumanCasualtieCount } = useSelector((state: RootState) => state.humanCasualtie);
    const { filteredInfrastructureDamageList, filteredInfrastructureCount } = useSelector((state: RootState) => state.infrastructureDamage);
    const { filteredFoodDamagesList, filteredFoodDamagesCount } = useSelector((state: RootState) => state.foodDamage);
    const { filteredLivestockDamagesList, filteredLivestockDamagesCount } = useSelector((state: RootState) => state.livestockDamage);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedTehsil, setSelectedTehsil] = useState('');
    const [selectedUnionCouncil, setSelectedUnionCouncil] = useState('');
    const [selectedParameter, setSelectedParameter] = useState('');
    const [selectedKeyword, setSelectedKeyword] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedTab, setSelectedTab] = useState('1');
    const userExternalWorkflowsList = externalWorkflowsList.filter((item: any) => item?.role === userData?.role?.name);
    const userInternalWorkflowsList = internalWorkflowsList.filter((item: any) => item?.role === userData?.role?.name);

    useEffect(() => {
        if (userExternalWorkflowsList && userExternalWorkflowsList.length && (selectedTab === "1" || selectedTab === "5")) {
            setSelectedTab("1");
        } else if (userInternalWorkflowsList && userInternalWorkflowsList.length && (selectedTab === "1" || selectedTab === "5")) {
            setSelectedTab("5");
        }
    }, [externalWorkflowsList, internalWorkflowsList]);

    useEffect(() => {
        if (userExternalWorkflowsList && userExternalWorkflowsList.length) {
            const variables: any = externalWorkFilters();
            if (selectedTab === "1") {
                dispatch(fetchFilterdNGOData(variables));
            } else if (selectedTab === "2") {
                dispatch(fetchFilterdVolunteersData(variables));
            } else if (selectedTab === "3") {
                dispatch(fetchFilterdManufacturerData(variables));
            } else if (selectedTab === "4") {
                dispatch(fetchFilterdSuppliersData(variables));
            }
        }
        if (userInternalWorkflowsList && userInternalWorkflowsList.length) {
            const variables: any = internalWorkFilters();
            if (selectedTab === "5") {
                dispatch(fetchFilterdInventoriesData(variables));
            } else if (selectedTab === "6") {
                dispatch(fetchFilterdFinancialReliefData(variables));
            } else if (selectedTab === "7") {
                dispatch(fetchFilterdCompensationStatusesData(variables));
            } else if (selectedTab === "8") {
                dispatch(fetchFilterdHumanCasualtieData(variables));
            } else if (selectedTab === "9") {
                dispatch(fetchFilterdInfrastructureDamageData(variables));
            } else if (selectedTab === "10") {
                dispatch(fetchFilterdFoodDamageData(variables));
            } else if (selectedTab === "11") {
                dispatch(fetchFilterdLivestockDamageData(variables));
            }
        }
    }, [selectedTab, rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, externalWorkflowsList, internalWorkflowsList]);

    const naviagteViewOnly = (formHeading: String, data: any) => {
        navigate("/form", {
            state: {
                formHeading,
                recordId: data.id,
                data,
                isForAction: true,
                isViewOnly: true,
            }
        });
    };

    const externalActionButtons = (formHeading: String) => {
        return {
            title: 'Actions',
            render: (text: any, record: any) => {
                const selectedWorkFlow: any = externalWorkflowsList.find((item: any) => item.stage === record.stage);
                return <span>
                    <Button
                        type="primary"
                        title='View'
                        style={{ background: '#357A38' }}
                        className='m-v-5'
                        icon={<EyeOutlined />}
                        onClick={() => naviagteViewOnly(formHeading, record)}
                        shape='circle' />
                    <Button
                        type="primary"
                        title={selectedWorkFlow?.title}
                        className='m-v-5'
                        onClick={() => onExternalAction(formHeading, record)}
                        size="middle">
                        {selectedWorkFlow?.title}
                    </Button>
                </span>;
            }
        };
    };

    const onExternalAction = async (formHeading: String, data: any) => {
        const selectedWorkFlow: any = externalWorkflowsList.find((item: any) => item.stage === data.stage);
        let smallestStageNumber = externalWorkflowsList.reduce((min: any, record: any) => record.stage < min && record.stage > data.stage ? record.stage : min, Infinity);
        const obj = {
            id: data.id,
            stage: (!smallestStageNumber || smallestStageNumber == Infinity) ? null : smallestStageNumber,
            record: data
        };
        let res = { type: '/rejected' };
        if (formHeading === "NGO Mapping") {
            res = await dispatch(updateNGOStageData(obj));
        } else if (formHeading === 'Volunteers Mapping') {
            res = await dispatch(updateVolunteerStageData(obj));
        } else if (formHeading === 'Manufacturers Mapping') {
            res = await dispatch(updateManufacturerStageData(obj));
        } else if (formHeading === 'Suppliers Mapping') {
            res = await dispatch(updateSupplierStageData(obj));
        }
        if (!res.type.includes("rejected")) {
            toast("success", `${(!smallestStageNumber || smallestStageNumber == Infinity) ? "Publish" : selectedWorkFlow.title} successfully`);
        }
    };

    const internalActionButtons = (formHeading: String) => {
        return {
            title: 'Actions',
            render: (text: any, record: any) => {
                const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === record.stage);
                return <span>
                    <Button
                        type="primary"
                        title='View'
                        style={{ background: '#357A38' }}
                        className='m-v-5'
                        icon={<EyeOutlined />}
                        onClick={() => naviagteViewOnly(formHeading, record)}
                        shape='circle' />
                    <Button
                        type="primary"
                        title={selectedWorkFlow.title}
                        className='m-v-5'
                        onClick={() => onInternalAction(formHeading, record)}
                        size="middle">
                        {selectedWorkFlow.title}
                    </Button>
                </span>;
            }
        };
    };

    const onInternalAction = async (formHeading: String, data: any) => {
        const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === data.stage);
        let smallestStageNumber = internalWorkflowsList.reduce((min: any, record: any) => record.stage < min && record.stage > data.stage ? record.stage : min, Infinity);
        const obj = {
            id: data.id,
            stage: (!smallestStageNumber || smallestStageNumber == Infinity) ? null : smallestStageNumber,
            record: data
        };
        let res = { type: '/rejected' };
        if (formHeading === "Inventory") {
            res = await dispatch(updateInventoryStageData(obj));
        } else if (formHeading === "Contributors relief") {
            res = await dispatch(updateFinancialReliefStageData(obj));
        } else if (formHeading === "Compensation relief") {
            res = await dispatch(updateCompensationStatusesStageData(obj));
        } else if (formHeading === "Human Casualties") {
            res = await dispatch(updateHumanCasualtieStageData(obj));
        } else if (formHeading === "Infrastructure Damages") {
            res = await dispatch(updateInfrastructureDamageStageData(obj));
        } else if (formHeading === "Food Damages") {
            res = await dispatch(updateFoodDamageStageData(obj));
        } else if (formHeading === "Livestock Damages") {
            res = await dispatch(updateLivestockDamageStageData(obj));
        }
        if (!res.type.includes("rejected")) {
            toast("success", `${(!smallestStageNumber || smallestStageNumber == Infinity) ? "Publish" : selectedWorkFlow.title} successfully`);
        }
    };

    const externalWorkFilters = () => {
        const stages = userExternalWorkflowsList.map((item: any) => (item?.stage));
        const variables: any = {
            page: 1,
            pageSize: rowsPerPage,
            "filter": {
                "stage": {
                    "in": stages
                }
            }
        };
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        return variables;
    };

    const internalWorkFilters = () => {
        const stages = userInternalWorkflowsList.map((item: any) => (item?.stage));
        const variables: any = {
            page: 1,
            pageSize: rowsPerPage,
            "filter": {
                "stage": {
                    "in": stages
                }
            }
        };
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        return variables;
    };

    const ngoData = alignNgoData(filteredNgoList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const volunteersData = alignVolunteersData(filteredVolunteerList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const manufacturersData = alignManufacturersData(filteredManufacturerList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const suppliersData = alignsSuppliersData(filteredSupplierList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const inventoryData = alignInventoryData(filteredInventoriesList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const financialReliefListData = alignFinancialReliefData(filteredFinancialReliefList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const compensatonReliefListData = alignCompensatonReliefsData(filteredCompensatonReliefList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const humanCasualtieData = alignHumanCasualtieData(filteredHumanCasualtieList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const infrastructureDamageData = alignInfrastructureDamageData(filteredInfrastructureDamageList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const foodDamageData = alignFoodDamageData(filteredFoodDamagesList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const livestockDamageData = alignLivestockDamageData(filteredLivestockDamagesList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);

    const externalTabs = (userExternalWorkflowsList && userExternalWorkflowsList.length) ? [
        {
            label: 'NGO Mapping',
            key: '1',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '1') ? ngoData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : ngoData}
                columns={[
                    ...ngoColumns,
                    externalActionButtons("NGO Mapping")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredngoCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdNGOData({ ...externalWorkFilters(), page }))}
            />
        },
        {
            label: 'Volunteers Mapping',
            key: '2',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '2') ? volunteersData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : volunteersData}
                columns={[
                    ...volunteersColumns,
                    externalActionButtons("Volunteers Mapping")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredVolunteersCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdVolunteersData({ ...externalWorkFilters(), page }))}
            />
        },
        {
            label: 'Manufacturers Mapping',
            key: '3',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '3') ? manufacturersData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : manufacturersData}
                columns={[
                    ...manufacturersColumns,
                    externalActionButtons("Manufacturers Mapping")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredManufacturerCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdManufacturerData({ ...externalWorkFilters(), page }))}
            />
        },
        {
            label: 'Suppliers Mapping',
            key: '4',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? suppliersData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : suppliersData}
                columns={[
                    ...suppliersColumns,
                    externalActionButtons("Suppliers Mapping")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredSuppliersCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdSuppliersData({ ...externalWorkFilters(), page }))}
            />
        }
    ] : [];

    const internalTabs = (userInternalWorkflowsList && userInternalWorkflowsList.length) ? [
        {
            label: 'Inventory',
            key: '5',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? inventoryData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : inventoryData}
                columns={[
                    ...inventoryColumns,
                    internalActionButtons("Inventory")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredInventoriesCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdInventoriesData({ ...internalWorkFilters(), page }))}
            />
        },
        {
            label: 'Contributors relief',
            key: '6',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? financialReliefListData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : financialReliefListData}
                columns={[
                    ...financialReliefColumns,
                    internalActionButtons("Contributors relief")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredFinancialCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdFinancialReliefData({ ...internalWorkFilters(), page }))}
            />
        },
        {
            label: 'Compensation relief',
            key: '7',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? compensatonReliefListData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : compensatonReliefListData}
                columns={[
                    ...compensatonReliefColumns,
                    internalActionButtons("Compensation relief")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredCompensatonReliefCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdCompensationStatusesData({ ...internalWorkFilters(), page }))}
            />
        },
        {
            label: 'Human Casualties',
            key: '8',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? humanCasualtieData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : humanCasualtieData}
                columns={[
                    ...humanCasualtieColumns,
                    internalActionButtons("Human Casualties")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredHumanCasualtieCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdHumanCasualtieData({ ...internalWorkFilters(), page }))}
            />
        },
        {
            label: 'Infrastructure Damages',
            key: '9',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? infrastructureDamageData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : infrastructureDamageData}
                columns={[
                    ...infrastructureDamageColumns,
                    internalActionButtons("Infrastructure Damages")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredInfrastructureCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdInfrastructureDamageData({ ...internalWorkFilters(), page }))}
            />
        },
        {
            label: 'Food Damages',
            key: '10',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? foodDamageData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : foodDamageData}
                columns={[
                    ...foodDamageColumns,
                    internalActionButtons("Food Damages")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredFoodDamagesCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdFoodDamageData({ ...internalWorkFilters(), page }))}
            />
        },
        {
            label: 'Livestock Damages',
            key: '11',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? livestockDamageData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : livestockDamageData}
                columns={[
                    ...livestockDamageColumns,
                    internalActionButtons("Livestock Damages")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredLivestockDamagesCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdLivestockDamageData({ ...internalWorkFilters(), page }))}
            />
        },
    ] : [];

    return (
        <div className='Inventory-mainCont'>
            <Filters
                setSelectedDivision={setSelectedDivision}
                selectedDivision={selectedDivision}
                setSelectedDistrict={setSelectedDistrict}
                selectedDistrict={selectedDistrict}
                setSelectedTehsil={setSelectedTehsil}
                selectedTehsil={selectedTehsil}
                setSelectedUnionCouncil={setSelectedUnionCouncil}
                selectedUnionCouncil={selectedUnionCouncil}
            />
            <div className='ResourceMapping-table-cont'>
                <div className='ResourceMapping-table-filter-btn-cont'>
                    <div />
                    <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap', width: "500px" }}>
                        <SearchSelect
                            onChange={env => { setSelectedParameter(env); setSelectedKeyword(''); }}
                            placeholder="Select the Parameter"
                            className="filter-input select-param-input"
                            value={selectedParameter || undefined}
                            options={(selectedTab === '1' ? ngoColumns : selectedTab === '2' ? volunteersColumns : selectedTab === '3' ? manufacturersColumns : selectedTab === '4' ? suppliersColumns : selectedTab === '5' ? inventoryColumns : selectedTab === '6' ? financialReliefColumns : selectedTab === '7' ? compensatonReliefColumns : selectedTab === '8' ? humanCasualtieColumns : selectedTab === '9' ? infrastructureDamageColumns : selectedTab === '10' ? foodDamageColumns : selectedTab === '11' ? livestockDamageColumns : [])
                                // .filter((item:any) => item.title !== 'Google Map Location')
                                .map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))
                            }
                            allowClear={true}
                        />
                        <Input
                            placeholder="Keyword Search"
                            className=' filter-input select-param-input'
                            allowClear={true}
                            disabled={!selectedParameter}
                            onChange={({ target }) => setSelectedKeyword(target.value)}
                            value={selectedKeyword}
                        />
                        <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                            <span>Show:</span>
                            <SearchSelect
                                onChange={(value: String) => setRowsPerPage(+value)}
                                placeholder="Select the District"
                                className="w-100px"
                                value={rowsPerPage}
                                options={[
                                    { value: "2", label: "2 Entries" },
                                    { value: "5", label: "5 Entries" },
                                    { value: "8", label: "8 Entries" },
                                    { value: "10", label: "10 Entries" },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div id="BottomTabs">
                    <Tabs
                        defaultActiveKey="1"
                        onTabClick={(activeKey) => { setSelectedTab(activeKey); setSelectedParameter(''); setSelectedKeyword(''); }}
                        activeKey={selectedTab}
                        type='card'
                        tabBarStyle={{ color: "#8D949C" }}
                        style={{ width: "100%" }}
                        items={[
                            ...externalTabs,
                            ...internalTabs,
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default MyTask;