import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { fetchAllDistrictData, fetchAllTehsilData, fetchAllUnionCouncilData } from '../../reduxToolkit/entitiesSlice';
import { Button } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { toast } from '../../components/Toast';
import { createSchoolData } from '../../reduxToolkit/schoolSlice';

const ImportSchools: React.FC = () => {
    const dispatch = useDispatch<any>();
    const { divisionList, allDistrictList, alltehsilList, allUnionCouncilList } = useSelector((state: RootState) => state.entities);
    const [data, setData] = useState<any>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    useEffect(() => {
        dispatch(fetchAllDistrictData());
        dispatch(fetchAllTehsilData());
        dispatch(fetchAllUnionCouncilData());
    }, [dispatch]);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1).map((row: any) => {
                    const rowData: { [key: string]: any; } = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index];
                    });
                    return rowData;
                });

                const processedData = rows.map(row => ({
                    ...row,
                    division_id: divisionList.find((division: any) => division?.attributes?.division_name === row.Division)?.id || null,
                    district_id: allDistrictList.find((district: any) => district?.attributes?.district_name === row.District)?.id || null,
                    tehsil_id: alltehsilList.find((tehsil: any) => tehsil?.attributes?.tehsil_name === row.Tehsil)?.id || null,
                    union_council_id: allUnionCouncilList.find((union: any) => union?.attributes?.union_council_name === row["Union Council"])?.id || null,
                }));
                setData(processedData);
            } catch (error) {
                console.error("Error reading or processing file:", error);
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };

        reader.readAsArrayBuffer(file);
    };

    const handleClick = () => {
        document.getElementById('fileInput')?.click();
    };

    const onUpload = async () => {
        try {
            setIsUploading(true);
            for (let i = 0; i < data.length; i++) {
                try {
                    const element = data[i];
                    const obj: any = {
                        divisionId: element.division_id,
                        districtId: element.district_id,
                        tehsilId: element.tehsil_id,
                        union_councilId: element.union_council_id,
                        semis_code: element["SEMIS Code"],
                        name: element["School Name"],
                        address: element.Address,
                        latitude: element.Latitude,
                        longitude: element.Longitude,
                        area_type: element["Area Type"],
                        building_condition: element["Building Condition"],
                        number_of_rooms: element["Number of Rooms"],
                        electricity: element.Electricity === "Yes" ? true : false,
                        toilet: element.Toilet === "Yes" ? true : false,
                        drinking_water: element["Drinking Water"] === "Yes" ? true : false,
                        boundary_wall: element["Boundary Wall"] === "Yes" ? true : false,
                    };
                    await dispatch(createSchoolData(obj));
                } catch (error: any) {
                    console.log(error.message);
                }
            }
            setIsUploading(false);
            toast('success', "School created sucessfully");
        } catch (error: any) {
            setIsUploading(false);
            toast('error', error.message);
        }
    };

    return (
        <div className='container'>
            <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
            />
            <Button
                type="primary"
                style={{ backgroundColor: '#4CAF50', margin: 10 }}
                icon={<PlusOutlined />}
                onClick={handleClick}
                size={"large"}>
                Select XLSX
            </Button>
            {data.length ? <Button
                style={{ backgroundColor: '#4CAF50', margin: 10 }}
                type="primary"
                icon={<UploadOutlined />}
                onClick={onUpload}
                loading={isUploading}
                size={"large"}>
                Upload
            </Button> : null}
        </div>
    );
};

export default ImportSchools;
