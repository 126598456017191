import React, { useEffect } from 'react';
import './index.css'; // Import CSS for styling
import { useLocation } from 'react-router-dom';
import { useDispatch, } from 'react-redux';
import { fetchAllDistrictData, fetchAllPrimaryAreas, fetchAllSecondaryAreas } from '../../reduxToolkit/entitiesSlice';
import InventoryForms from './inventoryForm';
import NGOMapping from './NGOMapping';
import VolunteersMapping from './volunteersMapping';
import ManufacturersMapping from './manufacturersMapping';
import SuppliersMapping from './suppliersMapping';
import Contributors from './contributors';
import Compensation from './compensation';
import HumanCasualties from './humanCasualties';
import InfrastructureDamages from './infrastructureDamages';
import FoodDamages from './foodDamages';
import LivestockDamages from './livestockDamages';

const Forms = () => {
    const location = useLocation();
    const dispatch = useDispatch<any>();
    const { formHeading } = location.state || {};
  
    useEffect(() => {
        dispatch(fetchAllDistrictData());
        dispatch(fetchAllPrimaryAreas());
        dispatch(fetchAllSecondaryAreas());
    }, []);

    return (<div>
        {formHeading === 'NGO Mapping' ? <NGOMapping />
            : formHeading === "Volunteers Mapping" ? <VolunteersMapping />
                : formHeading === "Manufacturers Mapping" ? <ManufacturersMapping />
                    : formHeading === "Suppliers Mapping" ? <SuppliersMapping />
                        : formHeading === "Inventory" ? <InventoryForms />
                            : formHeading === "Contributors relief" ? <Contributors />
                                : formHeading === "Compensation relief" ? <Compensation />
                                    : formHeading === "Human Casualties" ? <HumanCasualties />
                                        : formHeading === "Infrastructure Damages" ? <InfrastructureDamages />
                                            : formHeading === "Food Damages" ? <FoodDamages />
                                                : formHeading === "Livestock Damages" ? <LivestockDamages />
                                                    : ""}
    </div>);
};

export default Forms;
