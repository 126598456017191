import React, { useEffect, useState } from 'react';
import './index.css'; // Import CSS for styling
import { FaArrowLeft } from "react-icons/fa6";
import FinancialIcon from '../../assets/images/FinancialIcon.png';
import formReliefDistributionLogo from '../../assets/images/formReliefDistributionLogo.png';
import { DatePicker, Input } from 'antd';
import { Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { toast } from '../../components/Toast';
import { checkCompensatonReliefCnicExists, createCompensatonReliefData, getCompensatonReliefDraftByUser, updateCompensationStatusesStageData, updateCompensatonReliefData, updateCompensatonReliefDraftData } from '../../reduxToolkit/compensatonReliefSlice';
import dayjs from 'dayjs';
import { fetchTehsilsData, fetchUnionCouncilData } from '../../reduxToolkit/entitiesSlice';
import { validateObject } from '../../helpers/common';

const Compensation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { genderList, allDistrictList, divisionList, tehsilList, unionCouncilList, eventList, internalWorkflowsList } = useSelector((state: RootState) => state.entities);
    const { userData } = useSelector((state: RootState) => state.auth);
    const [compensationDetails, setCompensationDetails] = useState<{ [key: string]: any; }>({});
    const [actionButtonText, setActionButtonText] = useState("");
    const { data, isForAction, isViewOnly, isForUpdate, is_draft } = location.state;
    const [isCreating, setIsCreating] = useState(false);
    const [isCheckRequired, setIsCheckRequired] = useState(false);
    const [hasDraft, setHasDraft] = useState(false);

    useEffect(() => {
        (async () => {
            if (userData && userData.id) {
                const userDraft = (await dispatch(getCompensatonReliefDraftByUser({
                    id: compensationDetails.draft_id,
                    created_by_user: userData.id
                }))).payload;
                setHasDraft(userDraft);
            }
        })();
    }, [userData, compensationDetails.draft_id]);

    useEffect(() => {
        if ((isForAction || isViewOnly) && internalWorkflowsList.length) {
            setCompensationDetails(data);
            const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === data.stage);
            setActionButtonText(selectedWorkFlow?.title);
        } else if (isForUpdate) {
            setCompensationDetails(data);
            setActionButtonText("Update Information");
        } else {
            const obj = {
                division: userData?.division?.division_name,
                divisionId: userData?.division?.id,
                district: userData?.district?.district_name,
                districtId: userData?.district?.id,
            };
            setCompensationDetails(prevState => ({ ...prevState, ...obj }));
        }
    }, [userData, internalWorkflowsList]);

    useEffect(() => {
        dispatch(fetchTehsilsData(compensationDetails?.district));
    }, [dispatch, compensationDetails?.district]);

    useEffect(() => {
        dispatch(fetchUnionCouncilData(compensationDetails?.tehsil));
    }, [dispatch, compensationDetails?.tehsil]);

    const divisionData = divisionList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.division_name,
        label: item.attributes.division_name,
    })) || [];

    const districtData = allDistrictList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.district_name,
        label: item.attributes.district_name,
    })) || [];

    const tehsilData = tehsilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.tehsil_name,
        label: item.attributes.tehsil_name,
    })) || [];

    const unionCouncilListData = unionCouncilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.union_council_name,
        label: item.attributes.union_council_name,
    })) || [];

    const genderListData = genderList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const eventListData = eventList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const onSave = async () => {
        try {
            if (hasDraft) {
                throw new Error("You already have a draft in progress or pending approval.");
            } else {
                const excludeCompensatonReliefId = compensationDetails.compensaton_relief || null;
                const excludeCompensatonReliefDraftId = compensationDetails.draft_id || null;

                // Check for duplicate cnic in both compensatonReliefs and compensatonReliefDrafts
                const { compensatonReliefs, compensatonReliefDrafts } = await dispatch(checkCompensatonReliefCnicExists({
                    cnic: compensationDetails.cnic,
                    excludeCompensatonReliefId,
                    excludeCompensatonReliefDraftId,
                })).unwrap();

                if (compensatonReliefs.length > 0 || compensatonReliefDrafts.length > 0) {
                    setIsCreating(false);
                    toast('error', "CNIC already exists.");
                    return;
                }
                const obj: any = {
                    "divisionId": compensationDetails.divisionId,
                    "districtId": compensationDetails.districtId,
                    "eventId": compensationDetails.eventId,
                    "genderId": compensationDetails.genderId,
                    "full_name": compensationDetails.full_name,
                    "cnic": compensationDetails.cnic ? Number(compensationDetails.cnic) : null,
                    "date_of_birth": compensationDetails.date_of_birth,
                    "type": compensationDetails.type,
                    "reason": compensationDetails.reason,
                    "address": compensationDetails.address,
                    "mobile_number": compensationDetails.mobile_number ? Number(compensationDetails.mobile_number) : null,
                    "stage": 1,
                    "is_draft": false,
                    "created_by_user": userData.id,
                };
                if (!validateObject(obj)) {
                    setIsCheckRequired(true);
                    throw new Error('Please fill the all highlighted fields');
                }
                obj.tehsilId = compensationDetails.tehsilId;
                obj.union_councilId = compensationDetails.union_councilId;
                obj.compensaton_relief = compensationDetails.compensaton_relief;
                if (compensationDetails.draft_id) {
                    obj.compensatonReliefId = compensationDetails.id;
                    const res = await dispatch(updateCompensatonReliefDraftData(obj));
                    if (res.type !== "compensatonRelief/updateCompensatonReliefDraftData/rejected") {
                        navigate(-1);
                        toast('success', "compensaton Relief draft update successfully");
                    } else {
                        setIsCreating(false);
                    }
                } else {
                    if (isForUpdate) {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createCompensatonReliefData(obj));
                        if (res.type !== "compensatonRelief/createCompensatonReliefData/rejected") {
                            navigate(-1);
                            toast('success', "Compensaton Relief update successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                        // const res = await dispatch(updateCompensatonReliefData(obj));
                        // if (res.type !== "compensatonRelief/updateCompensatonReliefData/rejected") {
                        //     navigate(-1);
                        //     toast('success', "Compensaton Relief update successfully");
                        // }
                    } else {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createCompensatonReliefData(obj));
                        if (res.type !== "compensatonRelief/createCompensatonReliefData/rejected") {
                            navigate(-1);
                            toast('success', "Compensaton Relief created successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                    }
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    const onAction = async () => {
        const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === compensationDetails.stage);
        let smallestStageNumber = internalWorkflowsList.reduce((min: any, record: any) => record.stage < min && record.stage > compensationDetails.stage ? record.stage : min, Infinity);
        const res = await dispatch(updateCompensationStatusesStageData({
            id: compensationDetails.id,
            stage: (!smallestStageNumber || smallestStageNumber == Infinity) ? null : smallestStageNumber,
            record: compensationDetails
        }));
        if (res.type !== "compensatonRelief/updateCompensationStatusesStageData/rejected") {
            navigate(-1);
            toast("success", `${(!smallestStageNumber || smallestStageNumber == Infinity) ? "Publish" : selectedWorkFlow.title} successfully`);
        }
    };

    const onSaveAsDraft = async () => {
        try {
            if (isCreating) return;
            setIsCreating(true);
            const excludeCompensatonReliefId = compensationDetails.compensaton_relief || null;
            const excludeCompensatonReliefDraftId = compensationDetails.draft_id || null;

            // Check for duplicate cnic in both compensatonReliefs and compensatonReliefDrafts
            // const { compensatonReliefs, compensatonReliefDrafts } = await dispatch(checkCompensatonReliefCnicExists({
            //     cnic: compensationDetails.cnic,
            //     excludeCompensatonReliefId,
            //     excludeCompensatonReliefDraftId,
            // })).unwrap();

            // if (compensatonReliefs.length > 0 || compensatonReliefDrafts.length > 0) {
            //     setIsCreating(false);
            //     toast('error', "CNIC already exists.");
            //     return;
            // }
            const obj: any = {
                "divisionId": compensationDetails.divisionId,
                "districtId": compensationDetails.districtId,
                "eventId": compensationDetails.eventId,
                "genderId": compensationDetails.genderId,
                "full_name": compensationDetails.full_name,
                "cnic": compensationDetails.cnic ? Number(compensationDetails.cnic) : null,
                "date_of_birth": compensationDetails.date_of_birth,
                "type": compensationDetails.type,
                "reason": compensationDetails.reason,
                "address": compensationDetails.address,
                "mobile_number": compensationDetails.mobile_number ? Number(compensationDetails.mobile_number) : null,
                "tehsilId": compensationDetails.tehsilId,
                "union_councilId": compensationDetails.union_councilId,
                "stage": null,
                "is_draft": true,
                "created_by_user": userData.id,
            };
            if (compensationDetails.draft_id) {
                obj.compensaton_relief = compensationDetails.compensaton_relief;
                obj.compensatonReliefId = data.draft_id;
                const res = await dispatch(updateCompensatonReliefDraftData(obj));
                if (res.type !== "compensatonRelief/updateCompensatonReliefDraftData/rejected") {
                    navigate(-1);
                    toast('success', "compensaton Relief draft update successfully");
                } else {
                    setIsCreating(false);
                }
            } else {
                obj.compensaton_relief = compensationDetails.compensaton_relief;
                const res = await dispatch(createCompensatonReliefData(obj));
                if (res.type !== "compensatonRelief/createCompensatonReliefData/rejected") {
                    navigate(-1);
                    toast('success', "compensaton Relief draft successfully");
                } else {
                    setIsCreating(false);
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    console.log("is_draft", is_draft);
    console.log("data", data);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formReliefDistributionLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Relief Distribution</span>
                        </div>
                    </div>
                    {isForAction ? <div className='form-send-btn' onClick={onAction}>
                        <span className='sand-btn-text'>{actionButtonText}</span>
                    </div>
                        : <div>
                            {!hasDraft ? <div className='form-send-btn' onClick={onSaveAsDraft}>
                                <span className='sand-btn-text'>Save as draft</span>
                            </div> : null}
                            <div className='form-send-btn' onClick={onSave}>
                                <span className='sand-btn-text'>Submit</span>
                            </div>
                        </div>}
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <img src={FinancialIcon} alt="" />
                    <span>Compensation relief</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Full Name</span>
                        <Input
                            onChange={({ target }) => setCompensationDetails(prevState => ({ ...prevState, full_name: target.value }))}
                            placeholder="Enter Full Name"
                            status={(isCheckRequired && !compensationDetails.full_name) ? 'error' : ""}
                            disabled={isViewOnly}
                            value={compensationDetails.full_name}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>CNIC</span>
                        <Input
                            onChange={({ target }) => setCompensationDetails(prevState => ({ ...prevState, cnic: target.value }))}
                            placeholder="Enter CNIC"
                            value={compensationDetails.cnic}
                            disabled={isViewOnly}
                            status={(isCheckRequired && !compensationDetails.cnic) ? 'error' : ""}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Date of Birth</span>
                        <DatePicker
                            disabled={isViewOnly}
                            onChange={(date: any, dateString) => setCompensationDetails(prevState => ({ ...prevState, date_of_birth: dateString }))}
                            status={(isCheckRequired && !compensationDetails.date_of_birth) ? 'error' : ""}
                            value={compensationDetails.date_of_birth ? dayjs(compensationDetails.date_of_birth) : undefined}
                            className="input"
                            placeholder="Enter Date of Birth"
                            format={"YYYY-MM-DD"}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Type</span>
                        <Input
                            onChange={({ target }) => setCompensationDetails(prevState => ({ ...prevState, type: target.value }))}
                            placeholder="Enter Type"
                            disabled={isViewOnly}
                            status={(isCheckRequired && !compensationDetails.type) ? 'error' : ""}
                            value={compensationDetails.type}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Reason</span>
                        <Input
                            onChange={({ target }) => setCompensationDetails(prevState => ({ ...prevState, reason: target.value }))}
                            disabled={isViewOnly}
                            status={(isCheckRequired && !compensationDetails.reason) ? 'error' : ""}
                            placeholder="Enter Reason"
                            value={compensationDetails.reason}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            onChange={({ target }) => setCompensationDetails(prevState => ({ ...prevState, address: target.value }))}
                            status={(isCheckRequired && !compensationDetails.address) ? 'error' : ""}
                            disabled={isViewOnly}
                            placeholder="Enter Address"
                            value={compensationDetails.address}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Mobile Number</span>
                        <Input
                            onChange={({ target }) => setCompensationDetails(prevState => ({ ...prevState, mobile_number: target.value }))}
                            disabled={isViewOnly}
                            status={(isCheckRequired && !compensationDetails.mobile_number) ? 'error' : ""}
                            placeholder="Enter Mobile Number"
                            value={compensationDetails.mobile_number}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Gender</span>
                        <Select
                            style={{ width: '100%' }}
                            disabled={isViewOnly}
                            onChange={(value: any, item: any) => setCompensationDetails(prevState => ({ ...prevState, genderId: item.id, gender: item.value }))}
                            options={genderListData}
                            status={(isCheckRequired && !compensationDetails.genderId) ? 'error' : ""}
                            placeholder="Select Gender"
                            className='input'
                            value={compensationDetails.gender}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Event</span>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setCompensationDetails(prevState => ({ ...prevState, eventId: item.id, event: item.value }))}
                            disabled={isViewOnly}
                            options={eventListData}
                            status={(isCheckRequired && !compensationDetails.eventId) ? 'error' : ""}
                            placeholder="Select Event"
                            className='input'
                            value={compensationDetails.event}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Select
                            value={compensationDetails.division || undefined}
                            placeholder="Select the Division"
                            disabled
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setCompensationDetails(prevState => ({ ...prevState, divisionId: item?.id, division: item?.value }))}
                            status={(isCheckRequired && !compensationDetails.divisionId) ? 'error' : ""}
                            options={divisionData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the District"
                            disabled
                            value={compensationDetails.district || undefined}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setCompensationDetails(prevState => ({ ...prevState, districtId: item?.id, district: item?.value }))}
                            options={districtData}
                            status={(isCheckRequired && !compensationDetails.districtId) ? 'error' : ""}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Tehsil"
                            style={{ width: '100%' }}
                            value={compensationDetails.tehsil || undefined}
                            disabled={isViewOnly}
                            onChange={(value: any, item: any) => setCompensationDetails(prevState => ({ ...prevState, tehsilId: item?.id, tehsil: item?.value }))}
                            options={tehsilData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Union Council"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setCompensationDetails(prevState => ({ ...prevState, union_councilId: item?.id, union_council: item?.value }))}
                            options={unionCouncilListData}
                            disabled={isViewOnly}
                            className='input'
                            value={compensationDetails.union_council || undefined}
                            allowClear
                        />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Compensation;
