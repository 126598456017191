import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import formResourceMappingLogo from '../../assets/images/formResourceMappingLogo.png';
import { Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { alignsSchoolsData } from '../../helpers/alignData';
import { fetchSchoolDataById } from '../../reduxToolkit/schoolSlice';

const SchoolMappingDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { recordId } = location.state || {};
    const [schoolMapping, setSchoolMapping] = useState<{ [key: string]: any; }>({});
    const { selectedSchool } = useSelector((state: RootState) => state.school);

    useEffect(() => {
        dispatch(fetchSchoolDataById(recordId));
    }, []);

    useEffect(() => {
        const schoolData = alignsSchoolsData([selectedSchool]);
        const selectedSchoolData = schoolData[0] ? schoolData[0] : {};
        setSchoolMapping(selectedSchoolData);
    }, [selectedSchool]);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formResourceMappingLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Resource Mapping</span>
                        </div>
                    </div>
                    <div />
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <span>NGO Mapping</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>SEMIS Code</span>
                        <Input
                            value={schoolMapping.semis_code}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>School Name</span>
                        <Input
                            value={schoolMapping.name}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            value={schoolMapping.address}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Building Condition</span>
                        <Input
                            value={schoolMapping.building_condition}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Number of Rooms</span>
                        <Input
                            value={schoolMapping.number_of_rooms}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Electricity</span>
                        <Input
                            value={schoolMapping.electricity ? "Yes" : "No"}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Toilet</span>
                        <Input
                            value={schoolMapping.toilet ? "Yes" : "No"}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Drinking Water</span>
                        <Input
                            value={schoolMapping.drinking_water ? "Yes" : "No"}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Boundary Wall</span>
                        <Input
                            value={schoolMapping.boundary_wall ? "Yes" : "No"}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Input
                            value={schoolMapping.district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Input
                            value={schoolMapping.division}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Input
                            value={schoolMapping.tehsil}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Input
                            value={schoolMapping.union_council}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Area type</span>
                        <Input
                            value={schoolMapping.area_type}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            value={schoolMapping.latitude}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            value={schoolMapping.longitude}
                            disabled
                            className='input'
                        />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SchoolMappingDetails;
