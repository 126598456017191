import React from 'react';
import { Pie } from '@ant-design/plots';

const PieChart = ({ data, angleField = 'Qty', colorField = 'Item', legend }: any) => {
  const config = {
    data: data || [],
    angleField: angleField,
    colorField: colorField,
    label: {
      style: {
        fontWeight: 'bold',
      },
      formatter: (data: any, mappingData: any) => {
        if (mappingData.Unit) {
          return `${mappingData[angleField]} ${mappingData.Unit}`;
        } else if (angleField) {
          return `${mappingData[angleField]}`;
        }
        return '';
      },
    },
    legend: legend || {
      color: {
        title: false,
        position: 'left',
        rowPadding: 5,
      },
    },
  };

  if (!Object.keys(data).length) {
    return null;
  }

  return <Pie {...config} />;
};

export default PieChart;
