import React, { useEffect, useState } from 'react';
import './index.css'; // Import CSS for styling
import { FaArrowLeft } from "react-icons/fa6";
import formResourceMappingLogo from '../../assets/images/formResourceMappingLogo.png';
import { Input } from 'antd';
import { Select, DatePicker } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { toast } from '../../components/Toast';
import { createManufacturerData, getManufacturerDraftByUser, updateManufacturerData, updateManufacturerDraftData, updateManufacturerStageData } from '../../reduxToolkit/manufacturerSlice';
import dayjs from 'dayjs';
import { fetchTehsilsData, fetchUnionCouncilData } from '../../reduxToolkit/entitiesSlice';
import { validateObject } from '../../helpers/common';

const ManufacturersMapping = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { allDistrictList, divisionList, tehsilList, unionCouncilList, externalWorkflowsList, primaryAreasList, secondaryAreasList } = useSelector((state: RootState) => state.entities);
    const { userData } = useSelector((state: RootState) => state.auth);
    const [manufacturersMapping, setManufacturersMapping] = useState<{ [key: string]: any; }>({});
    const [actionButtonText, setActionButtonText] = useState("");
    const { data, isForAction, isViewOnly, isForUpdate, is_draft } = location.state;
    const [isCreating, setIsCreating] = useState(false);
    const [isCheckRequired, setIsCheckRequired] = useState(false);
    const [hasDraft, setHasDraft] = useState(false);

    useEffect(() => {
        (async () => {
            if (userData && userData.id) {
                const userDraft = (await dispatch(getManufacturerDraftByUser({
                    id: manufacturersMapping.draft_id,
                    created_by_user: userData.id
                }))).payload;
                setHasDraft(userDraft);
            }
        })();
    }, [userData, manufacturersMapping.draft_id]);

    useEffect(() => {
        if ((isForAction || isViewOnly) && externalWorkflowsList.length) {
            setManufacturersMapping(data);
            const selectedWorkFlow: any = externalWorkflowsList.find((item: any) => item.stage === data.stage);
            setActionButtonText(selectedWorkFlow?.title);
        } else if (isForUpdate) {
            setManufacturersMapping(data);
            setActionButtonText("Update Information");
        } else {
            const obj = {
                division: userData?.division?.division_name,
                divisionId: userData?.division?.id,
                district: userData?.district?.district_name,
                districtId: userData?.district?.id,
            };
            setManufacturersMapping(prevState => ({ ...prevState, ...obj }));
        }
    }, [userData, externalWorkflowsList]);

    useEffect(() => {
        dispatch(fetchTehsilsData(manufacturersMapping?.district));
    }, [dispatch, manufacturersMapping?.district]);

    useEffect(() => {
        dispatch(fetchUnionCouncilData(manufacturersMapping?.tehsil));
    }, [dispatch, manufacturersMapping?.tehsil]);

    const divisionData = divisionList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.division_name,
        label: item.attributes.division_name,
    })) || [];

    const districtData = allDistrictList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.district_name,
        label: item.attributes.district_name,
    })) || [];

    const tehsilData = tehsilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.tehsil_name,
        label: item.attributes.tehsil_name,
    })) || [];

    const unionCouncilListData = unionCouncilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.union_council_name,
        label: item.attributes.union_council_name,
    })) || [];

    const primaryAreaData = primaryAreasList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const secondaryAreaData = secondaryAreasList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const onSave = async () => {
        try {
            if (hasDraft) {
                throw new Error("You already have a draft in progress or pending approval.");
            } else {
                const obj: any = {
                    "name": manufacturersMapping.name,
                    "divisionId": manufacturersMapping.divisionId,
                    "districtId": manufacturersMapping.districtId,
                    "postal_address": manufacturersMapping.postal_address,
                    "primary_areasId": manufacturersMapping.primary_areasId,
                    "secondary_areasId": manufacturersMapping.secondary_areasId,
                    "website": manufacturersMapping.website,
                    "registration_number": manufacturersMapping.registration_number,
                    "year_established": manufacturersMapping.year_established,
                    "organizational_head": manufacturersMapping.organizational_head,
                    "contact_person_in_district": manufacturersMapping.contact_person_in_district,
                    "contact_person_mobile_number_in_district": manufacturersMapping.contact_person_mobile_number_in_district ? Number(manufacturersMapping.contact_person_mobile_number_in_district) : null,
                    "contact_number": manufacturersMapping.contact_number ? Number(manufacturersMapping.contact_number) : null,
                    "latitude": manufacturersMapping.latitude ? Number(manufacturersMapping.latitude) : null,
                    "longitude": manufacturersMapping.longitude ? Number(manufacturersMapping.longitude) : null,
                    "stage": 1,
                    "is_draft": false,
                    "created_by_user": userData.id,
                };
                if (!validateObject(obj)) {
                    setIsCheckRequired(true);
                    throw new Error('Please fill the all highlighted fields');
                }
                obj.tehsilId = manufacturersMapping.tehsilId;
                obj.union_councilId = manufacturersMapping.union_councilId;
                obj.address = manufacturersMapping.address;
                obj.manufacturer = manufacturersMapping.manufacturer;
                if (manufacturersMapping.draft_id) {
                    obj.manufacturerId = manufacturersMapping.id;
                    const res = await dispatch(updateManufacturerDraftData(obj));
                    if (res.type !== "manufacturer/updateManufacturerDraftData/rejected") {
                        navigate(-1);
                        toast('success', "Manufacturer draft update successfully");
                    } else {
                        setIsCreating(false);
                    }
                } else {
                    if (isForUpdate) {
                        obj.manufacturer = manufacturersMapping.id;
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createManufacturerData(obj));
                        if (res.type !== "manufacturer/createManufacturerData/rejected") {
                            navigate(-1);
                            toast('success', "Manufacturer update successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                        // const res = await dispatch(updateManufacturerData(obj));
                        // if (res.type !== "manufacturer/updateManufacturerData/rejected") {
                        //     navigate(-1);
                        //     toast('success', "Manufacturer update successfully");
                        // }
                    } else {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createManufacturerData(obj));
                        if (res.type !== "manufacturer/createManufacturerData/rejected") {
                            navigate(-1);
                            toast('success', "Manufacturer created successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                    }
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    const onAction = async () => {
        const selectedWorkFlow: any = externalWorkflowsList.find((item: any) => item.stage === manufacturersMapping.stage);
        let smallestStageNumber = externalWorkflowsList.reduce((min: any, record: any) => record.stage < min && record.stage > manufacturersMapping.stage ? record.stage : min, Infinity);
        dispatch(updateManufacturerStageData({
            id: manufacturersMapping.id,
            stage: (!smallestStageNumber || smallestStageNumber == Infinity) ? null : smallestStageNumber,
            record: manufacturersMapping
        }));
        toast("success", `${(!smallestStageNumber || smallestStageNumber == Infinity) ? "Publish" : selectedWorkFlow.title} successfully`);
        navigate(-1);
    };

    const onSaveAsDraft = async () => {
        try {
            if (isCreating) return;
            setIsCreating(true);
            const obj: any = {
                "name": manufacturersMapping.name,
                "divisionId": manufacturersMapping.divisionId,
                "districtId": manufacturersMapping.districtId,
                "postal_address": manufacturersMapping.postal_address,
                "primary_areasId": manufacturersMapping.primary_areasId,
                "secondary_areasId": manufacturersMapping.secondary_areasId,
                "website": manufacturersMapping.website,
                "registration_number": manufacturersMapping.registration_number,
                "year_established": manufacturersMapping.year_established,
                "organizational_head": manufacturersMapping.organizational_head,
                "contact_person_in_district": manufacturersMapping.contact_person_in_district,
                "contact_person_mobile_number_in_district": manufacturersMapping.contact_person_mobile_number_in_district ? Number(manufacturersMapping.contact_person_mobile_number_in_district) : null,
                "contact_number": manufacturersMapping.contact_number ? Number(manufacturersMapping.contact_number) : null,
                "latitude": manufacturersMapping.latitude ? Number(manufacturersMapping.latitude) : null,
                "longitude": manufacturersMapping.longitude ? Number(manufacturersMapping.longitude) : null,
                "tehsilId": manufacturersMapping.tehsilId,
                "union_councilId": manufacturersMapping.union_councilId,
                "address": manufacturersMapping.address,
                "stage": null,
                "is_draft": true,
                "created_by_user": userData.id,
            };
            if (manufacturersMapping.draft_id) {
                obj.manufacturer = manufacturersMapping.manufacturer;
                obj.manufacturerId = data.draft_id;
                const res = await dispatch(updateManufacturerDraftData(obj));
                if (res.type !== "manufacturer/updateManufacturerDraftData/rejected") {
                    navigate(-1);
                    toast('success', "manufacturer draft update successfully");
                } else {
                    setIsCreating(false);
                }
            } else {
                obj.manufacturer = manufacturersMapping.manufacturer;
                const res = await dispatch(createManufacturerData(obj));
                if (res.type !== "manufacturer/createManufacturerData/rejected") {
                    navigate(-1);
                    toast('success', "manufacturer draft successfully");
                } else {
                    setIsCreating(false);
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    console.log("is_draft", is_draft);
    console.log("data", data);

    const disableFutureDates = (current: any) => current && current > dayjs().endOf('year');

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formResourceMappingLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Resource Mapping</span>
                        </div>
                    </div>
                    {isForAction ? <div className='form-send-btn' onClick={onAction}>
                        <span className='sand-btn-text'>{actionButtonText}</span>
                    </div>
                        : <div>
                            {!hasDraft ? <div className='form-send-btn' onClick={onSaveAsDraft}>
                                <span className='sand-btn-text'>Save as draft</span>
                            </div> : null}
                            <div className='form-send-btn' onClick={onSave}>
                                <span className='sand-btn-text'>Submit</span>
                            </div>
                        </div>}
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <span>Manufacturers Mapping</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Name</span>
                        <Input
                            onChange={({ target }) => setManufacturersMapping(prevState => ({ ...prevState, name: target.value }))}
                            placeholder="Name"
                            status={(isCheckRequired && !manufacturersMapping.name) ? 'error' : ""}
                            disabled={isViewOnly}
                            value={manufacturersMapping.name}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Select
                            value={manufacturersMapping.division || undefined}
                            disabled
                            placeholder="Select the Division"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setManufacturersMapping(prevState => ({ ...prevState, divisionId: item?.id, division: item?.value }))}
                            status={(isCheckRequired && !manufacturersMapping.divisionId) ? 'error' : ""}
                            options={divisionData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the District"
                            disabled
                            value={manufacturersMapping.district || undefined}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setManufacturersMapping(prevState => ({ ...prevState, districtId: item?.id, district: item?.value }))}
                            options={districtData}
                            status={(isCheckRequired && !manufacturersMapping.districtId) ? 'error' : ""}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Tehsil"
                            style={{ width: '100%' }}
                            disabled={isViewOnly}
                            value={manufacturersMapping.tehsil || undefined}
                            onChange={(value: any, item: any) => setManufacturersMapping(prevState => ({ ...prevState, tehsilId: item?.id, tehsil: item?.value }))}
                            // status={(isCheckRequired && !manufacturersMapping.tehsilId) ? 'error' : ""}
                            options={tehsilData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Union Council"
                            disabled={isViewOnly}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setManufacturersMapping(prevState => ({ ...prevState, union_councilId: item?.id, union_council: item?.value }))}
                            options={unionCouncilListData}
                            status={(isCheckRequired && !manufacturersMapping.union_councilId) ? 'error' : ""}
                            className='input'
                            value={manufacturersMapping.union_council || undefined}
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            onChange={({ target }) => setManufacturersMapping(prevState => ({ ...prevState, address: target.value }))}
                            placeholder="Enter Address"
                            value={manufacturersMapping.address || undefined}
                            status={(isCheckRequired && !manufacturersMapping.address) ? 'error' : ""}
                            disabled={isViewOnly}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Postal Address</span>
                        <Input
                            onChange={({ target }) => setManufacturersMapping(prevState => ({ ...prevState, postal_address: target.value }))}
                            placeholder="Enter Postal Address"
                            status={(isCheckRequired && !manufacturersMapping.postal_address) ? 'error' : ""}
                            disabled={isViewOnly}
                            value={manufacturersMapping.postal_address}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Primary Areas</span>
                        <Select
                            placeholder="Select Primary Area"
                            mode="multiple"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setManufacturersMapping(prevState => ({ ...prevState, primary_areasId: item?.map((select: any) => select?.id), primary_areas: item?.map((select: any) => select?.value) }))}
                            disabled={isViewOnly}
                            options={primaryAreaData}
                            status={(isCheckRequired && !manufacturersMapping.primary_areasId) ? 'error' : ""}
                            className='input'
                            value={manufacturersMapping.primary_areas || undefined}
                            allowClear
                            maxTagCount='responsive'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Secondary Areas</span>
                        <Select
                            placeholder="Select Primary Area"
                            mode="multiple"
                            style={{ width: '100%' }}
                            disabled={isViewOnly}
                            onChange={(value: any, item: any) => setManufacturersMapping(prevState => ({ ...prevState, secondary_areasId: item?.map((select: any) => select?.id), secondary_areas: item?.map((select: any) => select?.value) }))}
                            status={(isCheckRequired && !manufacturersMapping.secondary_areasId) ? 'error' : ""}
                            options={secondaryAreaData}
                            className='input'
                            value={manufacturersMapping.secondary_areas || undefined}
                            allowClear
                            maxTagCount='responsive'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Website</span>
                        <Input
                            onChange={({ target }) => setManufacturersMapping(prevState => ({ ...prevState, website: target.value }))}
                            placeholder="Website Link"
                            status={(isCheckRequired && !manufacturersMapping.website) ? 'error' : ""}
                            value={manufacturersMapping.website}
                            disabled={isViewOnly}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Registration Number</span>
                        <Input
                            onChange={({ target }) => setManufacturersMapping(prevState => ({ ...prevState, registration_number: target.value }))}
                            status={(isCheckRequired && !manufacturersMapping.registration_number) ? 'error' : ""}
                            placeholder="Enter Registration Number"
                            disabled={isViewOnly}
                            value={manufacturersMapping.registration_number}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Year established</span>
                        <DatePicker
                            onChange={(date: any, dateString) => setManufacturersMapping(prevState => ({ ...prevState, year_established: dateString }))}
                            status={(isCheckRequired && !manufacturersMapping.year_established) ? 'error' : ""}
                            value={manufacturersMapping.year_established ? dayjs(manufacturersMapping.year_established) : undefined}
                            className="input"
                            disabled={isViewOnly}
                            disabledDate={disableFutureDates}
                            placeholder="Select Year"
                            format={"YYYY"}
                            picker="year" />
                    </div>
                    <div className='input-cont'>
                        <span>Organizational Head</span>
                        <Input
                            onChange={({ target }) => setManufacturersMapping(prevState => ({ ...prevState, organizational_head: target.value }))}
                            disabled={isViewOnly}
                            status={(isCheckRequired && !manufacturersMapping.organizational_head) ? 'error' : ""}
                            placeholder="Enter Organizational Head"
                            value={manufacturersMapping.organizational_head || undefined}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Contact Person in District</span>
                        <Input
                            onChange={({ target }) => setManufacturersMapping(prevState => ({ ...prevState, contact_person_in_district: target.value }))}
                            status={(isCheckRequired && !manufacturersMapping.contact_person_in_district) ? 'error' : ""}
                            placeholder="Enter Name"
                            disabled={isViewOnly}
                            value={manufacturersMapping.contact_person_in_district || undefined}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Contact Person Mobile Number in District</span>
                        <Input
                            onChange={({ target }) => setManufacturersMapping(prevState => ({ ...prevState, contact_person_mobile_number_in_district: target.value }))}
                            status={(isCheckRequired && !manufacturersMapping.contact_person_mobile_number_in_district) ? 'error' : ""}
                            placeholder="Enter Mobile Number"
                            value={manufacturersMapping.contact_person_mobile_number_in_district || undefined}
                            disabled={isViewOnly}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Contact Number</span>
                        <Input
                            onChange={({ target }) => setManufacturersMapping(prevState => ({ ...prevState, contact_number: target.value }))}
                            placeholder="Enter Contact Number"
                            disabled={isViewOnly}
                            value={manufacturersMapping.contact_number || undefined}
                            status={(isCheckRequired && !manufacturersMapping.contact_number) ? 'error' : ""}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            onChange={({ target }) => setManufacturersMapping(prevState => ({ ...prevState, latitude: target.value }))}
                            disabled={isViewOnly}
                            placeholder="Enter Latitude"
                            status={(isCheckRequired && !manufacturersMapping.latitude) ? 'error' : ""}
                            value={manufacturersMapping.latitude || undefined}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            onChange={({ target }) => setManufacturersMapping(prevState => ({ ...prevState, longitude: target.value }))}
                            disabled={isViewOnly}
                            placeholder="Enter Longitude"
                            status={(isCheckRequired && !manufacturersMapping.longitude) ? 'error' : ""}
                            value={manufacturersMapping.longitude || undefined}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ManufacturersMapping;
