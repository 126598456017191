import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../reduxToolkit/store';
import { fetchAllInfrastructureOwnerDetails, fetchInfrastructureDamageDataById } from '../../reduxToolkit/infrastructureDamageSlice';
import { alignEventData, alignInfrastructureDamageData, alignInfrastructureOwnerDetailsData } from '../../helpers/alignData';
import { FaArrowLeft } from "react-icons/fa6";
import InfrastructureDamagesIcon from '../../assets/images/infrastructure_card.png';
import formLossesDamagesLogo from '../../assets/images/formLosses&DamagesLogo.png';
import { Flex, Input, Tag } from 'antd';
import EventsIcon from '../../assets/images/Events.png';
import DetailIcon from '../../assets/images/Detail.png';
import ReusableTable from '../../components/table';
import { infrastructureOwnerDetailColumn } from '../../helpers/tableColumns';

const InfrastructureDamageDetails = () => {
    const { TextArea } = Input;
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const navigate = useNavigate();
    const { recordId } = location.state || {};
    const { selectedInfrastructure, infrastructureOwnerDetails, infrastructureOwnerDetailsMeta } = useSelector((state: RootState) => state.infrastructureDamage);
    const [infrastructureDamageDetails, setInfrastructureDamageDetails] = useState<{ [key: string]: any; }>({});
    const [eventData, setEventData] = useState<{ [key: string]: any; }>({});

    useEffect(() => {
        dispatch(fetchInfrastructureDamageDataById(recordId));
    }, []);

    useEffect(() => {
        if (selectedInfrastructure) {
            const infrastructureDamageData = alignInfrastructureDamageData([selectedInfrastructure]);
            const selectedInfrastructureDamageData = infrastructureDamageData[0] ? infrastructureDamageData[0] : {};
            const eventData = alignEventData(selectedInfrastructureDamageData.eventData);
            const id = selectedInfrastructureDamageData ? selectedInfrastructureDamageData.id : null;
            if (id) {
                dispatch(fetchAllInfrastructureOwnerDetails({
                    page: 1,
                    pageSize: 10,
                    "filter": {
                        "infrastructure_damage": {
                            "id": {
                                "eq": id
                            }
                        }
                    }
                }));
            }
            setEventData(eventData);
            setInfrastructureDamageDetails(selectedInfrastructureDamageData);
        }
    }, [selectedInfrastructure]);

    const ownerData = alignInfrastructureOwnerDetailsData(infrastructureOwnerDetails);

    return (
        <div className="form-main-cont">
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <img src={formLossesDamagesLogo} alt="" style={{ height: "40px" }} />
                        <span>Losses & Damages</span>
                    </div>
                    <div />
                </div>
            </div>
            <div className='event-form'>
                <div className='form-bady-heading'>
                    <img src={EventsIcon} alt="" style={{ height: "35px" }} />
                    <span>Event</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Name</span>
                        <Input
                            value={eventData.name}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>From date</span>
                        <Input
                            value={eventData.from_date}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>To date</span>
                        <Input
                            value={eventData.to_date}
                            disabled
                            className='input' />
                    </div>
                    <div className='textArea-cont'>
                        <span>Description</span>
                        <TextArea
                            rows={4}
                            value={eventData.description}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Divisions</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.divisions?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Districts</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.districts?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Tehsils</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.tehsils?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Union Councils</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.union_councils?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <img src={InfrastructureDamagesIcon} alt="" style={{ height: "35px" }} />
                    <span>Infrastructure Damage</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Damaged</span>
                        <Input
                            value={infrastructureDamageDetails.damaged}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Restored</span>
                        <Input
                            value={infrastructureDamageDetails.restored}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Infrastructure Type</span>
                        <Input
                            value={infrastructureDamageDetails.infrastructure_type}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Event</span>
                        <Input
                            value={infrastructureDamageDetails.event}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Input
                            value={infrastructureDamageDetails.division}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Input
                            value={infrastructureDamageDetails.district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Input
                            value={infrastructureDamageDetails.tehsil}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Input
                            value={infrastructureDamageDetails.union_council}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            value={infrastructureDamageDetails.address}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            value={infrastructureDamageDetails.latitude}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            value={infrastructureDamageDetails.longitude}
                            disabled
                            className='input'
                        />
                    </div>
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading' style={{ marginTop: 0 }}>
                    <img src={DetailIcon} alt="" style={{ height: "35px" }} />
                    <span>Owner Details</span>
                </div>
                <div className='form-cont'>
                    <ReusableTable
                        data={ownerData}
                        columns={infrastructureOwnerDetailColumn}
                        rowsPerPage={10}
                        total={infrastructureOwnerDetailsMeta?.pagination?.total}
                        onChangePagination={(page: number) => dispatch(fetchAllInfrastructureOwnerDetails({
                            page,
                            pageSize: 10,
                            "filter": {
                                "infrastructure_damage": {
                                    "id": {
                                        "eq": infrastructureDamageDetails.id
                                    }
                                }
                            }
                        }))}
                    />
                </div>
            </div>
        </div>
    );
};

export default InfrastructureDamageDetails;