import React, { useState, useMemo } from "react";
import Map, { Source, Layer, Marker } from "react-map-gl";
import { FaMapMarkerAlt } from "react-icons/fa";
import "mapbox-gl/dist/mapbox-gl.css";
import supercluster from "supercluster";
import sindhGeoJSON from "./pk-all.topo.json";
import NGOMapping from '../../assets/images/ngo_pointer.png';
import VolunteersMapping from '../../assets/images/volunteers_pointer.png';
import ManufacturersMapping from '../../assets/images/manufacturers_pointer.png';
import SuppliersMapping from '../../assets/images/suppliers_pointer.png';
import SchoolMapping from '../../assets/images/school_pointer.png';
import human_casualties_pointer from '../../assets/images/human_casualties_pointer.png';
import infrastructure_pointer from '../../assets/images/infrastructure_pointer.png';
import agriculture_pointer from '../../assets/images/agriculture_pointer.png';
import livestock_pointer from '../../assets/images/livestock_pointer.png';
import './index.css';

const MAPBOX_TOKEN =
  // "sk.eyJ1IjoiY2ltYWM0ODQ3OSIsImEiOiJjbHdyczQ5czcwMzl0Mm5zYTB0ejIyMnk4In0.3uRLAl567elfLuSs62b6eg";
  "pk.eyJ1IjoiY2ltYWM0ODQ3OSIsImEiOiJjbHdycmR6cTQwMzg3MnRzOTczbHJxMnk3In0.-YXiB7OwsMyTd1msI0YEZg";

const sindhBounds = {
  north: 30.7,
  south: 20.5,
  east: 90,
  west: 50.5,
};

const isWithinSindh = (latitude, longitude) =>
  latitude >= sindhBounds.south &&
  latitude <= sindhBounds.north &&
  longitude >= sindhBounds.west &&
  longitude <= sindhBounds.east;

const MapComponent = (props) => {
  const { data } = props;
  const sortedData = data
    .filter((item) => isWithinSindh(item.latitude, item.longitude))
    .map((item) => ({
      name: item.district || "Unknown",
      longitude: item.longitude,
      latitude: item.latitude,
      type: item.type || "Unknown",
    }));

  const initialCoordinate = sortedData.length > 0
    ? [sortedData[0].longitude, sortedData[0].latitude]
    : [68.5, 26.5];

  const [viewport, setViewport] = useState({
    longitude: initialCoordinate[0],
    latitude: initialCoordinate[1],
    zoom: 6,
  });

  const clusterData = useMemo(() => {
    const index = new supercluster({
      radius: 40,
      maxZoom: 16,
    });

    index.load(
      sortedData.map((item) => ({
        type: "Feature",
        properties: { cluster: false, item },
        geometry: {
          type: "Point",
          coordinates: [item.longitude, item.latitude],
        },
      }))
    );

    return index;
  }, [sortedData]);

  const clusters = clusterData.getClusters(
    [-180, -85, 180, 85],
    Math.floor(viewport.zoom)
  );

  return (
    <div className="map-container">
      {/* <div className="legend">
        {legendItems}
      </div> */}
      {/* <div className="Sindh-map"> */}
      <Map
        {...viewport}
        onMove={(evt) => setViewport(evt.viewState)}
        style={{ width: "100%", height: "500px" }}
        // mapStyle={"https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={MAPBOX_TOKEN}
        maxBounds={[
          [sindhBounds.west, sindhBounds.south],
          [sindhBounds.east, sindhBounds.north],
        ]}
      >
        <Source id="sindhBoundary" type="geojson" data={sindhGeoJSON}>
          <Layer
            id="sindhFill"
            type="fill"
            paint={{
              "fill-color": "rgba(144, 238, 144, 0.1)",
              "fill-outline-color": "green",
            }}
          />
        </Source>
        {clusters.map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          if (isCluster) {
            return (
              <Marker key={`cluster-${index}`} longitude={longitude} latitude={latitude}>
                <div
                  style={{
                    width: `${35 + (pointCount / sortedData.length) * 20}px`,
                    height: `${35 + (pointCount / sortedData.length) * 20}px`,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }

          const { item } = cluster.properties;

          return (
            <Marker key={`marker-${index}`} longitude={longitude} latitude={latitude} anchor="bottom">
              <img
                src={icons[item.type]}
                alt="Mapping Icon"
                style={{ height: '40px' }}
              />
            </Marker>
          );
        })}
      </Map>
      {/* </div> */}
    </div>
  );
};

export default MapComponent;

const icons = {
  "NGO Mapping": NGOMapping,
  "Volunteers Mapping": VolunteersMapping,
  "Manufacturers Mapping": ManufacturersMapping,
  "Suppliers Mapping": SuppliersMapping,
  "School Mapping": SchoolMapping,
  "Human Casualties": human_casualties_pointer,
  "Infrastructure Damages": infrastructure_pointer,
  "Food Damages": agriculture_pointer,
  "Livestock Damages": livestock_pointer,
};
