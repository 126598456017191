import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import formInventoryLogo from '../../assets/images/formInventoryLogo.png';
import { Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { alignInventoryData } from '../../helpers/alignData';
import { fetchInventoryDataById } from '../../reduxToolkit/inventorySlice';

const InventoryDetails = () => {
    const { TextArea } = Input;
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { recordId } = location.state || {};
    const [inventory, setInventory] = useState<{ [key: string]: any; }>({});
    const { selectedInventory } = useSelector((state: RootState) => state.inventory);

    useEffect(() => {
        dispatch(fetchInventoryDataById(recordId));
    }, []);

    useEffect(() => {
        const financialReliefData = alignInventoryData([selectedInventory]);
        const selectedFinancialReliefData = financialReliefData[0] ? financialReliefData[0] : {};
        setInventory(selectedFinancialReliefData);
    }, [selectedInventory]);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formInventoryLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Inventory</span>
                        </div>
                    </div>
                    <div />
                </div>
            </div>
            <div className='form-body'>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Product type</span>
                        <Input
                            value={inventory.product_type}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Product Name</span>
                        <Input
                            value={inventory.product_name}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Product Category</span>
                        <Input
                            value={inventory.product_category}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Product Section</span>
                        <Input
                            value={inventory.product_section}
                            disabled
                            className='input'
                        />
                    </div>
                    {(inventory.product_type === "Food" || inventory.product_type === "Medicine") ? <div className='input-cont'>
                        <span>Class</span>
                        <Input
                            value={inventory.class}
                            disabled
                            className='input'
                        />
                    </div> : null}
                    <div className='input-cont'>
                        <span>Received Quantity</span>
                        <Input
                            value={inventory.received_quantity}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Consumed Quantity</span>
                        <Input
                            value={inventory.consumed_quantity}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>On-Hand Quantity</span>
                        <Input
                            value={inventory.on_hand_quantity}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Warehouse Address</span>
                        <Input
                            value={inventory.warehouse_address}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Description</span>
                        <Input
                            value={inventory.description}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Unit of Measure</span>
                        <Input
                            value={inventory.unit_of_measure}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Product Code</span>
                        <Input
                            value={inventory.product_code}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Product Barcode</span>
                        <Input
                            value={inventory.product_barcode}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Product QR Code</span>
                        <Input
                            value={inventory.product_qr_code}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Product Brand</span>
                        <Input
                            value={inventory.product_brand}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Product Company</span>
                        <Input
                            value={inventory.product_company}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Induction Date</span>
                        <Input
                            value={inventory.induction_date}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Warranty Year</span>
                        <Input
                            value={inventory.warranty_year}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Expiry Date</span>
                        <Input
                            value={inventory.expiry_date}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Input
                            value={inventory.division}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Input
                            value={inventory.district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Input
                            value={inventory.tehsil}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Input
                            value={inventory.union_council}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            value={inventory.latitude}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            value={inventory.longitude}
                            disabled
                            className='input'
                        />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default InventoryDetails;
