import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

interface SupplierState {
    supplierList: any[];
    suppliersLoading: boolean;
    filteredSupplierList: any[];
    suppliersMeta: any;
    supplierCount: number;
    supplierChartList: any[];
    filteredSuppliersCount: number;
    selectedSuppliers: any;
}

const initialState: SupplierState = {
    supplierList: [],
    suppliersLoading: false,
    filteredSupplierList: [],
    suppliersMeta: {},
    supplierCount: 0,
    supplierChartList: [],
    filteredSuppliersCount: 0,
    selectedSuppliers: {}
};

export const fetchSupplierCount = createAsyncThunk('supplier/fetchSupplierCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                }
            }
        };
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiGetSuppliersCount : API.ApiGetSuppliersCountWithOutLocation,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.suppliers.meta.pagination.total, supplierChartList: response.data.suppliers.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchSupplierData = createAsyncThunk('supplier/fetchSupplierData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil } = pageData;
        const filter: any = {
            stage: {
                null: true
            }
        };
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllSuppliers,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.suppliers.data, meta: response.data.suppliers.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFilterdSuppliersData = createAsyncThunk('supplier/fetchFilterdSuppliersData', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await client.query({ query: API.ApiSuppliers, variables, context: { headers }, fetchPolicy: 'no-cache' });
        return { data: response.data.supplierDrafts.data, filteredSuppliersCount: response.data.supplierDrafts.meta.pagination.total };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchSupplierDataById = createAsyncThunk('supplier/fetchSupplierDataById', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetSupplierById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.supplier.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createSupplierData = createAsyncThunk('supplier/createSupplierData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateSupplier,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createSupplierDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateSupplierDraftData = createAsyncThunk('supplier/updateSupplierDraftData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingSupplierDraft,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateSupplierDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateSupplierStageData = createAsyncThunk('supplier/updateSupplierStageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { stage, id, record } = data;
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        if (stage) {
            await client.mutate({
                mutation: API.ApiUpdateSupplier,
                variables: {
                    id: id,
                    stage: stage
                },
                context: {
                    headers,
                },
            });
        } else {
            const obj = {
                "name": record.name,
                "divisionId": record.divisionId,
                "districtId": record.districtId,
                "postal_address": record.postal_address,
                "primary_areasId": record.primary_areasId,
                "secondary_areasId": record.secondary_areasId,
                "website": record.website,
                "registration_number": record.registration_number,
                "year_established": record.year_established,
                "organizational_head": record.organizational_head,
                "contact_person_in_district": record.contact_person_in_district,
                "contact_person_mobile_number_in_district": record.contact_person_mobile_number_in_district ? Number(record.contact_person_mobile_number_in_district) : null,
                "contact_number": record.contact_number ? Number(record.contact_number) : null,
                "latitude": record.latitude ? Number(record.latitude) : null,
                "longitude": record.longitude ? Number(record.longitude) : null,
                "stage": null,
                "tehsilId": record.tehsilId,
                "union_councilId": record.union_councilId,
                "address": record.address,
                "supplierId": record.supplier
            };
            await client.mutate({
                mutation: obj.supplierId ? API.ApiUpdateExistingSupplier : API.ApiCreatePublishedSupplier,
                variables: obj,
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
            await client.mutate({
                mutation: API.ApiDeleteSupplierDraft,
                variables: { id },
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
        }
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteSupplierData = createAsyncThunk('supplier/deleteSupplierData', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteSupplier,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateSupplierData = createAsyncThunk('supplier/updateSupplierData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingSupplier, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateSupplier.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateSupplierRecordFromSocket = createAsyncThunk('supplier/addAndUpdateSupplierRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, externalWorkflowsList } = data;
        const response = await client.query({ query: API.ApiGetSupplierById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.supplier.data, externalWorkflowsList };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteSupplierRecordFromSocket = createAsyncThunk('supplier/deleteSupplierRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const getSupplierDraftByUser = createAsyncThunk('supplier/getSupplierDraftByUser', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.APIGetSupplierDraftByUser, // Assuming you have an API constant for update mutation
            variables,
            context: {
                headers,
            },
        });
        if (response.data && response.data.supplierDrafts.data.length > 0) {
            return true;
        }
        return false;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const supplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchSupplierCount.fulfilled, (state, action) => {
                state.supplierCount = action.payload.count;
                state.supplierChartList = action.payload.supplierChartList;
                state.suppliersLoading = false;
            })
            .addCase(fetchSupplierData.fulfilled, (state, action) => {
                state.supplierList = action.payload.data;
                state.suppliersMeta = action.payload.meta;
                state.suppliersLoading = false;
            })
            .addCase(fetchFilterdSuppliersData.fulfilled, (state, action) => {
                state.filteredSupplierList = action.payload.data;
                state.filteredSuppliersCount = action.payload.filteredSuppliersCount;
                state.suppliersLoading = false;
            })
            .addCase(fetchSupplierDataById.fulfilled, (state, action) => {
                state.selectedSuppliers = action.payload;
                state.suppliersLoading = false;
            })
            .addCase(createSupplierData.fulfilled, (state, action) => {
                // state.supplierList.push(action.payload);
                state.suppliersLoading = false;
            })
            .addCase(updateSupplierDraftData.fulfilled, (state, action) => {
                state.suppliersLoading = false;
            })
            .addCase(updateSupplierData.fulfilled, (state, action) => {
                state.suppliersLoading = false;
            })
            .addCase(getSupplierDraftByUser.fulfilled, (state, action) => {
                state.suppliersLoading = false;
            })
            .addCase(updateSupplierStageData.fulfilled, (state, action) => {
                const index = state.filteredSupplierList.findIndex(item => item.id === action.payload);
                state.filteredSupplierList.splice(index, 1);
                state.suppliersLoading = false;
            })
            .addCase(deleteSupplierData.fulfilled, (state, action) => {
                state.suppliersLoading = false;
            })
            .addCase(addAndUpdateSupplierRecordFromSocket.fulfilled, (state, action) => {
                const externalWorkflowsList = action.payload.externalWorkflowsList;
                const { id, attributes } = action.payload.data;
                if (attributes.stage) {
                    const selectedWorkFlow: any = externalWorkflowsList.find((item: any) => item.stage === attributes.stage);
                    const index = state.filteredSupplierList.findIndex(item => item.id == id);
                    if (selectedWorkFlow) {
                        index !== -1 ? state.filteredSupplierList[index] = action.payload.data : state.filteredSupplierList.push(action.payload.data);
                    } else {
                        state.filteredSupplierList.splice(index, 1);
                    }
                } else {
                    const index = state.supplierList.findIndex(item => item.id == id);
                    index !== -1 ? state.supplierList[index] = action.payload.data : state.supplierList.push(action.payload.data);
                }
            })
            .addCase(deleteSupplierRecordFromSocket.fulfilled, (state, action) => {
                state.supplierList = state.supplierList.filter(item => item.id != action.payload);
                state.filteredSupplierList = state.filteredSupplierList.filter(item => item.id != action.payload);
            });
        [
            fetchSupplierCount,
            fetchSupplierData,
            fetchFilterdSuppliersData,
            updateSupplierStageData,
            deleteSupplierData,
            createSupplierData,
            updateSupplierData,
            fetchSupplierDataById,
            updateSupplierDraftData,
            getSupplierDraftByUser
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.suppliersLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.suppliersLoading = false;
                });
        });
    }
});


export default supplierSlice.reducer;
