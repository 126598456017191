import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import formReliefDistributionLogo from '../../assets/images/formReliefDistributionLogo.png';
import EventsIcon from '../../assets/images/Events.png';
import { Flex, Input, Tag } from 'antd';
import FinancialIcon from '../../assets/images/FinancialIcon.png';
import { fetchCompensatonReliefDataById } from '../../reduxToolkit/compensatonReliefSlice';
import { RootState } from '../../reduxToolkit/store';
import { alignCompensatonReliefsData, alignEventData } from '../../helpers/alignData';

const CompensationDetails = () => {
    const { TextArea } = Input;
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { recordId } = location.state || {};
    const [compensationDetails, setCompensationDetails] = useState<{ [key: string]: any; }>({});
    const [eventData, setEventData] = useState<{ [key: string]: any; }>({});
    const { selectedCompensatonRelief } = useSelector((state: RootState) => state.compensatonRelief);

    useEffect(() => {
        dispatch(fetchCompensatonReliefDataById(recordId));
    }, []);

    useEffect(() => {
        const compensatonReliefData = alignCompensatonReliefsData([selectedCompensatonRelief]);
        const selectedCompensatonReliefData = compensatonReliefData[0] ? compensatonReliefData[0] : {};
        const eventData = alignEventData(selectedCompensatonReliefData.eventData);
        setEventData(eventData);
        setCompensationDetails(selectedCompensatonReliefData);
    }, [selectedCompensatonRelief]);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formReliefDistributionLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Relief Distribution</span>
                        </div>
                    </div>
                    <div />
                </div>
            </div>
            <div className='event-form'>
                <div className='form-bady-heading'>
                    <img src={EventsIcon} alt="" style={{ height: "35px" }} />
                    <span>Event</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Name</span>
                        <Input
                            value={eventData.name}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>From date</span>
                        <Input
                            value={eventData.from_date}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>To date</span>
                        <Input
                            value={eventData.to_date}
                            disabled
                            className='input' />
                    </div>
                    <div className='textArea-cont'>
                        <span>Description</span>
                        <TextArea
                            rows={4}
                            value={eventData.description}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Divisions</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.divisions?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Districts</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.districts?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Tehsils</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.tehsils?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Union Councils</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.union_councils?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <img src={FinancialIcon} alt="" />
                    <span>Compensation relief</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Full Name</span>
                        <Input
                            value={compensationDetails.full_name}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>CNIC</span>
                        <Input
                            value={compensationDetails.cnic}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Date of Birth</span>
                        <Input
                            value={compensationDetails.date_of_birth}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Type</span>
                        <Input
                            value={compensationDetails.type}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Reason</span>
                        <Input
                            value={compensationDetails.reason}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            value={compensationDetails.address}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Mobile Number</span>
                        <Input
                            value={compensationDetails.mobile_number}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Gender</span>
                        <Input
                            value={compensationDetails.gender}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Event</span>
                        <Input
                            value={compensationDetails.event}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Input
                            value={compensationDetails.division}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Input
                            value={compensationDetails.district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Input
                            value={compensationDetails.tehsil}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Input
                            value={compensationDetails.union_council}
                            disabled
                            className='input'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompensationDetails;