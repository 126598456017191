import React, { useEffect, useState } from 'react';
import './index.css'; // Import CSS for styling
import { FaArrowLeft } from "react-icons/fa6";
import personeIcon from '../../assets/images/personeIcon.png';
import formLossesDamagesLogo from '../../assets/images/formLosses&DamagesLogo.png';
import { Button, DatePicker, Input } from 'antd';
import { Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { toast } from '../../components/Toast';
import { createCasualtiesDetail, createHumanCasualtieData, deleteCasualtiesDetail, fetchAllCasualtiesDetails, getHumanCasualtieDraftByUser, updateCasualtiesDetail, updateHumanCasualtieData, updateHumanCasualtieDraftData, updateHumanCasualtieStageData } from '../../reduxToolkit/humanCasualtieSlice';
import dayjs from 'dayjs';
import { fetchTehsilsData, fetchUnionCouncilData } from '../../reduxToolkit/entitiesSlice';
import { validateObject } from '../../helpers/common';
import { alignCasualtiesDetailsData } from '../../helpers/alignData';
import ReusableTable from '../../components/table';
import DetailIcon from '../../assets/images/Detail.png';
import addIcon from '../../assets/images/Add.png';
import { DeleteOutlined } from '@ant-design/icons';

const HumanCasualties = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { genderList, allDistrictList, divisionList, tehsilList, unionCouncilList, internalWorkflowsList, eventList } = useSelector((state: RootState) => state.entities);
    const { humanCasualtieType, casualtiesDetails } = useSelector((state: RootState) => state.humanCasualtie);
    const { userData } = useSelector((state: RootState) => state.auth);
    const [humanCasualtiesData, setHumanCasualtiesData] = useState<{ [key: string]: any; }>({});
    const [actionButtonText, setActionButtonText] = useState("");
    const { data, isForAction, isViewOnly, isForUpdate, is_draft } = location.state;
    const [isCreating, setIsCreating] = useState(false);
    const [isCheckRequired, setIsCheckRequired] = useState(false);
    const [casualtiesDetailsData, setCasualtiesDetailsData] = useState<any>([{}]);
    const [hasDraft, setHasDraft] = useState(false);

    useEffect(() => {
        (async () => {
            if (userData && userData.id) {
                const userDraft = (await dispatch(getHumanCasualtieDraftByUser({
                    id: humanCasualtiesData.draft_id,
                    created_by_user: userData.id
                }))).payload;
                setHasDraft(userDraft);
            }
        })();
    }, [userData, humanCasualtiesData.draft_id]);

    useEffect(() => {
        if ((isForAction || isViewOnly) && internalWorkflowsList.length) {
            setHumanCasualtiesData(data);
            const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === data.stage);
            setActionButtonText(selectedWorkFlow?.title);
        } else if (isForUpdate) {
            setHumanCasualtiesData(data);
            setActionButtonText("Update Information");
        } else {
            const obj = {
                division: userData?.division?.division_name,
                divisionId: userData?.division?.id,
                district: userData?.district?.district_name,
                districtId: userData?.district?.id,
            };
            setHumanCasualtiesData(prevState => ({ ...prevState, ...obj }));
        }
        if (data?.human_casualtie) {
            dispatch(fetchAllCasualtiesDetails({
                page: 1,
                pageSize: 1000000,
                "filter": {
                    "human_casualtie": {
                        "id": {
                            "eq": data.human_casualtie
                        }
                    }
                }
            }));
        }
    }, [userData, internalWorkflowsList]);

    useEffect(() => {
        const casualtiesDetailsData = alignCasualtiesDetailsData(casualtiesDetails);
        console.log("casualtiesDetails", casualtiesDetails);

        setCasualtiesDetailsData(casualtiesDetailsData);
    }, [casualtiesDetails]);

    useEffect(() => {
        dispatch(fetchTehsilsData(humanCasualtiesData?.district));
    }, [dispatch, humanCasualtiesData?.district]);

    useEffect(() => {
        dispatch(fetchUnionCouncilData(humanCasualtiesData?.tehsil));
    }, [dispatch, humanCasualtiesData?.tehsil]);

    const divisionData = divisionList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.division_name,
        label: item.attributes.division_name,
    })) || [];

    const districtData = allDistrictList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.district_name,
        label: item.attributes.district_name,
    })) || [];

    const tehsilData = tehsilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.tehsil_name,
        label: item.attributes.tehsil_name,
    })) || [];

    const unionCouncilListData = unionCouncilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.union_council_name,
        label: item.attributes.union_council_name,
    })) || [];

    const genderListData = genderList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const humanCasualtieTypeData = humanCasualtieType.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const eventListData = eventList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const onSave = async () => {
        try {
            if (hasDraft) {
                throw new Error("You already have a draft in progress or pending approval.");
            } else {
                const obj: any = {
                    "divisionId": humanCasualtiesData.divisionId,
                    "districtId": humanCasualtiesData.districtId,
                    "casualty_typeId": humanCasualtiesData.casualty_typeId,
                    "eventId": humanCasualtiesData.eventId,
                    "latitude": humanCasualtiesData.latitude ? Number(humanCasualtiesData.latitude) : null,
                    "longitude": humanCasualtiesData.longitude ? Number(humanCasualtiesData.longitude) : null,
                    "quantity": humanCasualtiesData.quantity ? Number(humanCasualtiesData.quantity) : null,
                    "stage": 1,
                    "is_draft": false,
                    "created_by_user": userData.id,
                };
                console.log('humanCasualtiesData', humanCasualtiesData);
                console.log('obj', obj);
                if (!validateObject(obj)) {
                    setIsCheckRequired(true);
                    throw new Error('Please fill the all highlighted fields');
                }
                obj.tehsilId = humanCasualtiesData.tehsilId;
                obj.union_councilId = humanCasualtiesData.union_councilId;
                obj.human_casualtie = humanCasualtiesData.human_casualtie;
                if (humanCasualtiesData.draft_id) {
                    obj.humanCasualtieId = humanCasualtiesData.id;
                    await updateChild();
                    const res = await dispatch(updateHumanCasualtieDraftData(obj));
                    if (res.type !== "humanCasualtie/updateHumanCasualtieDraftData/rejected") {
                        navigate(-1);
                        toast('success', "human Casualtie draft update successfully");
                    } else {
                        setIsCreating(false);
                    }
                } else {
                    if (isForUpdate) {
                        await updateChild();
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createHumanCasualtieData(obj));
                        if (res.type !== "humanCasualtie/createHumanCasualtieData/rejected") {
                            navigate(-1);
                            toast('success', "Human Casualtie update successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                        // const res = await dispatch(updateHumanCasualtieData(obj));
                        // if (res.type !== "humanCasualtie/updateHumanCasualtieData/rejected") {
                        //     navigate(-1);
                        //     toast('success', "Human Casualtie update successfully");
                        // }
                    } else {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createHumanCasualtieData(obj));
                        if (res.type !== "humanCasualtie/createHumanCasualtieData/rejected") {
                            navigate(-1);
                            toast('success', "Human Casualtie created successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                    }
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    const updateChild = async () => {
        for (let i = 0; i < casualtiesDetailsData.length; i++) {
            const element = casualtiesDetailsData[i];
            const growerObj = {
                human_casualtieId: humanCasualtiesData.id,
                full_name: element.full_name,
                "cnic": element.cnic ? Number(element.cnic) : null,
                genderId: element.genderId,
                date_of_birth: element.date_of_birth,
                "mobile_number": element.mobile_number ? Number(element.mobile_number) : null,
                address: element.address,
            };
            if (Object.values(growerObj).some(item => item)) {
                if (element.id) {
                    await dispatch(updateCasualtiesDetail({ ...growerObj, casualties_detail_id: element.id }));
                } else {
                    await dispatch(createCasualtiesDetail(growerObj));
                }
            }
        }
    };

    const onAction = async () => {
        const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === humanCasualtiesData.stage);
        let smallestStageNumber = internalWorkflowsList.reduce((min: any, record: any) => record.stage < min && record.stage > humanCasualtiesData.stage ? record.stage : min, Infinity);
        const res = await dispatch(updateHumanCasualtieStageData({
            id: humanCasualtiesData.id,
            stage: (!smallestStageNumber || smallestStageNumber == Infinity) ? null : smallestStageNumber,
            record: humanCasualtiesData
        }));
        if (res.type !== "humanCasualtie/updateHumanCasualtieStageData/rejected") {
            navigate(-1);
            toast("success", `${(!smallestStageNumber || smallestStageNumber == Infinity) ? "Publish" : selectedWorkFlow.title} successfully`);
        }
    };

    const growerDetailsColumn = [
        {
            title: 'Full Name',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Full Name'
                disabled={isViewOnly}
                onChange={({ target }) => setCasualtiesDetailsData((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].full_name = target.value;
                    return newState;
                })}
                value={record.full_name}
                className='input'
            />,
        },
        {
            title: 'CNIC',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter CNIC No.'
                disabled={isViewOnly}
                onChange={({ target }) => setCasualtiesDetailsData((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].cnic = target.value;
                    return newState;
                })}
                value={record.cnic}
                className='input'
            />,
        },
        {
            title: 'Address',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Address Here'
                disabled={isViewOnly}
                onChange={({ target }) => setCasualtiesDetailsData((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].address = target.value;
                    return newState;
                })}
                value={record.address}
                className='input'
            />,
        },
        {
            title: 'Mobile Number',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Mobile no.'
                disabled={isViewOnly}
                onChange={({ target }) => setCasualtiesDetailsData((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].mobile_number = target.value;
                    return newState;
                })}
                value={record.mobile_number}
                className='input'
            />,
        },
        {
            title: 'Gender',
            render: (record: any, text: any, index: number) => <Select
                style={{ width: '100%' }}
                onChange={(value: any, item: any) => setCasualtiesDetailsData((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].genderId = item.id;
                    newState[index].gender = item.value;
                    return newState;
                })}
                options={genderListData}
                disabled={isViewOnly}
                placeholder="Select Gender"
                className='input'
                value={record.gender}
            />,
        },
        {
            title: 'Date of Birth',
            render: (record: any, text: any, index: number) => <DatePicker
                disabled={isViewOnly}
                onChange={(date: any, dateString) => setCasualtiesDetailsData((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].date_of_birth = dateString;
                    return newState;
                })}
                value={record.date_of_birth ? dayjs(record.date_of_birth) : undefined}
                className="input"
                placeholder="Enter Date of Birth"
                format={"YYYY-MM-DD"}
            />,
        },
        {
            title: "Delete",
            render: (a: any, b: any, index: number) => (
                <Button
                    type="primary"
                    title='Delete'
                    disabled={isViewOnly}
                    style={{ background: '#357A38' }}
                    className='m-v-5'
                    icon={<DeleteOutlined />}
                    onClick={() => deleteData(index, a.id)}
                    shape='circle'
                />
            ),
        }
    ];

    const deleteData = (index: number, id: String) => {
        if (id) {
            dispatch(deleteCasualtiesDetail(id));
        } else {
            setCasualtiesDetailsData((prevState: any) => {
                const newState = [...prevState];
                newState.splice(index, 1);
                return newState;
            });
        }
    };

    const onSaveAsDraft = async () => {
        try {
            if (isCreating) return;
            setIsCreating(true);
            await updateChild();
            const obj: any = {
                "divisionId": humanCasualtiesData.divisionId,
                "districtId": humanCasualtiesData.districtId,
                "casualty_typeId": humanCasualtiesData.casualty_typeId,
                "eventId": humanCasualtiesData.eventId,
                "latitude": humanCasualtiesData.latitude ? Number(humanCasualtiesData.latitude) : null,
                "longitude": humanCasualtiesData.longitude ? Number(humanCasualtiesData.longitude) : null,
                "quantity": humanCasualtiesData.quantity ? Number(humanCasualtiesData.quantity) : null,
                "tehsilId": humanCasualtiesData.tehsilId,
                "union_councilId": humanCasualtiesData.union_councilId,
                "address": humanCasualtiesData.address,
                "stage": null,
                "is_draft": true,
                "created_by_user": userData.id,
            };
            if (humanCasualtiesData.draft_id) {
                obj.human_casualtie = humanCasualtiesData.human_casualtie;
                obj.humanCasualtieId = data.draft_id;
                const res = await dispatch(updateHumanCasualtieDraftData(obj));
                if (res.type !== "humanCasualtie/updateHumanCasualtieDraftData/rejected") {
                    navigate(-1);
                    toast('success', "human Casualtie draft update successfully");
                } else {
                    setIsCreating(false);
                }
            } else {
                obj.human_casualtie = humanCasualtiesData.human_casualtie;
                const res = await dispatch(createHumanCasualtieData(obj));
                if (res.type !== "humanCasualtie/createHumanCasualtieData/rejected") {
                    navigate(-1);
                    toast('success', "human Casualtie draft successfully");
                } else {
                    setIsCreating(false);
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    const handleAddMoreData = () => {
        if (casualtiesDetailsData.length >= humanCasualtiesData.quantity) {
            toast('error', "Cannot add more records. The number of records cannot exceed the specified quantity.");
            return;
        }
        setCasualtiesDetailsData((prevState: any) => ([...prevState, {}]));
    };
    console.log("is_draft", is_draft);
    console.log("data", data);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formLossesDamagesLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Losses & Damages</span>
                        </div>
                    </div>
                    {isForAction ? <div className='form-send-btn' onClick={onAction}>
                        <span className='sand-btn-text'>{actionButtonText}</span>
                    </div>
                        : <div>
                            {!hasDraft ? <div className='form-send-btn' onClick={onSaveAsDraft}>
                                <span className='sand-btn-text'>Save as draft</span>
                            </div> : null}
                            <div className='form-send-btn' onClick={onSave}>
                                <span className='sand-btn-text'>Submit</span>
                            </div>
                        </div>}
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <img src={personeIcon} alt="" />
                    <span>Human Casualties</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Event</span>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setHumanCasualtiesData(prevState => ({ ...prevState, eventId: item.id, event: item.value }))}
                            status={(isCheckRequired && !humanCasualtiesData.eventId) ? 'error' : ""}
                            options={eventListData}
                            disabled={isViewOnly}
                            placeholder="Select Event"
                            className='input'
                            value={humanCasualtiesData.event}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Casualty Type</span>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setHumanCasualtiesData(prevState => ({ ...prevState, casualty_typeId: item.id, casualty_type: item.value }))}
                            disabled={isViewOnly}
                            options={humanCasualtieTypeData}
                            placeholder="Select Casualty Type"
                            status={(isCheckRequired && !humanCasualtiesData.casualty_typeId) ? 'error' : ""}
                            className='input'
                            value={humanCasualtiesData.casualty_type}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Quantity</span>
                        <Input
                            onChange={({ target }) => setHumanCasualtiesData(prevState => ({ ...prevState, quantity: target.value }))}
                            placeholder="Enter Quantity"
                            value={humanCasualtiesData.quantity}
                            className='input'
                            status={(isCheckRequired && !humanCasualtiesData.quantity) ? 'error' : ""}
                            disabled={isViewOnly}
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Select
                            value={humanCasualtiesData.division || undefined}
                            placeholder="Select the Division"
                            disabled
                            status={(isCheckRequired && !humanCasualtiesData.divisionId) ? 'error' : ""}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setHumanCasualtiesData(prevState => ({ ...prevState, divisionId: item?.id, division: item?.value }))}
                            options={divisionData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the District"
                            disabled
                            value={humanCasualtiesData.district || undefined}
                            status={(isCheckRequired && !humanCasualtiesData.districtId) ? 'error' : ""}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setHumanCasualtiesData(prevState => ({ ...prevState, districtId: item?.id, district: item?.value }))}
                            options={districtData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Tehsil"
                            style={{ width: '100%' }}
                            value={humanCasualtiesData.tehsil || undefined}
                            disabled={isViewOnly}
                            onChange={(value: any, item: any) => setHumanCasualtiesData(prevState => ({ ...prevState, tehsilId: item?.id, tehsil: item?.value }))}
                            options={tehsilData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Union Council"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setHumanCasualtiesData(prevState => ({ ...prevState, union_councilId: item?.id, union_council: item?.value }))}
                            options={unionCouncilListData}
                            disabled={isViewOnly}
                            className='input'
                            value={humanCasualtiesData.union_council || undefined}
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            onChange={({ target }) => setHumanCasualtiesData(prevState => ({ ...prevState, latitude: target.value }))}
                            placeholder="Enter Latitude"
                            value={humanCasualtiesData.latitude}
                            className='input'
                            status={(isCheckRequired && !humanCasualtiesData.latitude) ? 'error' : ""}
                            disabled={isViewOnly}
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            status={(isCheckRequired && !humanCasualtiesData.longitude) ? 'error' : ""}
                            onChange={({ target }) => setHumanCasualtiesData(prevState => ({ ...prevState, longitude: target.value }))}
                            placeholder="Enter Longitude"
                            disabled={isViewOnly}
                            value={humanCasualtiesData.longitude}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                </div>
            </div>
            {(isForUpdate || isViewOnly) ? <div className='form-body '>
                <div className='form-bady-heading grower-heading'>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <img src={DetailIcon} alt="" style={{ height: "35px" }} />
                        <span>Casualties Details</span>
                    </div>
                    {isForUpdate ? <div className='add-more-button' onClick={handleAddMoreData}>
                        <img style={{ height: "20px" }} src={addIcon} />
                        <p style={{ fontSize: "14px", color: 'white' }}>Add more Data</p>
                    </div> : null}
                </div>
                <div className='form-cont'>
                    <ReusableTable
                        data={casualtiesDetailsData}
                        columns={growerDetailsColumn}
                        rowsPerPage={10}
                        total={casualtiesDetailsData.length}
                    />
                </div>
            </div> : null}
        </div >
    );
};

export default HumanCasualties;
