import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import formResourceMappingLogo from '../../assets/images/formResourceMappingLogo.png';
import { Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { fetchNGODataById } from '../../reduxToolkit/ngoSlice';
import { alignNgoData } from '../../helpers/alignData';

const NGOMappingDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { recordId } = location.state || {};
    const [nGOMapping, setNGOMapping] = useState<{ [key: string]: any; }>({});
    const { selectedNgo } = useSelector((state: RootState) => state.ngos);

    useEffect(() => {
        dispatch(fetchNGODataById(recordId));
    }, []);

    useEffect(() => {
        const financialReliefData = alignNgoData([selectedNgo]);
        const selectedFinancialReliefData = financialReliefData[0] ? financialReliefData[0] : {};
        setNGOMapping(selectedFinancialReliefData);
    }, [selectedNgo]);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formResourceMappingLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Resource Mapping</span>
                        </div>
                    </div>
                    <div />
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <span>NGO Mapping</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>NGO Name</span>
                        <Input
                            value={nGOMapping.ngo_name}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Input
                            value={nGOMapping.division}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Input
                            value={nGOMapping.district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Input
                            value={nGOMapping.tehsil}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Input
                            value={nGOMapping.union_council}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            value={nGOMapping.address}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Postal Address</span>
                        <Input
                            value={nGOMapping.postal_address}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Primary Area</span>
                        <Input
                            value={nGOMapping.primary_areas?.map((uc: any) => uc).join(', ') ?? 'N/A'}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Secondary Area</span>
                        <Input
                            value={nGOMapping.secondary_areas?.map((uc: any) => uc).join(', ') ?? 'N/A'}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Website</span>
                        <Input
                            value={nGOMapping.website}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>License Registration Number</span>
                        <Input
                            value={nGOMapping.license_registration_number}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Year established</span>
                        <Input
                            value={nGOMapping.year_established}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Organizational Head</span>
                        <Input
                            value={nGOMapping.organizational_head}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>NGO Contact Person in District</span>
                        <Input
                            value={nGOMapping.ngo_contact_person_in_district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>NGO Contact Person Mobile Number in District</span>
                        <Input
                            value={nGOMapping.ngo_contact_person_mobile_number_in_district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>NGO Contact Number</span>
                        <Input
                            value={nGOMapping.ngo_contact_number}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            value={nGOMapping.latitude}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            value={nGOMapping.longitude}
                            disabled
                            className='input'
                        />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default NGOMappingDetails;
