import { gql } from "@apollo/client";

// NGO 

export const getNgoCount = gql`
query getNgoCount($filter: NgoFiltersInput) {
  ngos(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      attributes {
        latitude
        longitude
      }
    }
  }
}
`;

export const getNgoCountWithOutLocation = gql`
query getNgoCountWithOutLocation($filter: NgoFiltersInput) {
  ngos(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
`;

export const getAllNGOS = gql`
query getAllNGOS($filter: NgoFiltersInput, $page: Int!, $pageSize: Int!) {
  ngos(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        ngo_name
        address
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        primary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        secondary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        postal_address
        website
        license_registration_number
        year_established
        organizational_head
        ngo_contact_person_in_district
        ngo_contact_person_mobile_number_in_district
        ngo_contact_number
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}

`;

export const getNGOS = gql`
query getNGOS($filter: NgoDraftFiltersInput, $page: Int!, $pageSize: Int!) {
  ngoDrafts(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        ngo_name
        address
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
          ngo {
          data {
            id
            attributes {
              ngo_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        primary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        secondary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        postal_address
        website
        license_registration_number
        year_established
        organizational_head
        ngo_contact_person_in_district
        ngo_contact_person_mobile_number_in_district
        ngo_contact_number
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getNGOById = gql`
query getNGOById($id: ID) {
  ngo(id: $id) {
    data {
      id
      attributes {
        ngo_name
        address
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        primary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        secondary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        postal_address
        website
        license_registration_number
        year_established
        organizational_head
        ngo_contact_person_in_district
        ngo_contact_person_mobile_number_in_district
        ngo_contact_number
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

// Volunteers

export const getVolunteersCount = gql`
query getVolunteersCount($filter: VolunteerFiltersInput) {
  volunteers(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      attributes {
        latitude
        longitude
      }
    }
  }
}
`;

export const getVolunteersCountWithOutLocation = gql`
query getVolunteersCountWithOutLocation($filter: VolunteerFiltersInput) {
  volunteers(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
`;

export const getAllVolunteers = gql`
query getAllVolunteers($filter: VolunteerFiltersInput, $page: Int!, $pageSize: Int!) {
  volunteers(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        name
        address
        cnic_number
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        postal_address
        website
        contact_person
        contact_person_mobile
        latitude
        longitude
        createdAt
        updatedAt
        stage
      }
    }
  }
}
`;

export const getVolunteers = gql`
query getAllVolunteers(
  $filter: VolunteerDraftFiltersInput
  $page: Int!
  $pageSize: Int!
) {
  volunteerDrafts(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        name
        address
        cnic_number
        volunteer {
          data {
            id
            attributes {
              name
            }
          }
        }
          division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        postal_address
        website
        contact_person
        contact_person_mobile
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getVolunteerById = gql`
query getVolunteerById($id: ID)  {
  volunteer(id: $id) {
    data {
      id
      attributes {
        name
        cnic_number
        address
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        postal_address
        website
        contact_person
        contact_person_mobile
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

// Manufacturer

export const getManufacturersCount = gql`
query getManufacturersCount($filter: ManufacturerFiltersInput) {
  manufacturers(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      attributes {
        latitude
        longitude
      }
    }
  }
}
`;

export const getManufacturersCountWithOutLocation = gql`
query getManufacturersCountWithOutLocation($filter: ManufacturerFiltersInput) {
  manufacturers(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
`;

export const getAllManufacturer = gql`
query getAllManufacturer($filter: ManufacturerFiltersInput, $page: Int!, $pageSize: Int!) {
  manufacturers(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        name
        address
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        primary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        secondary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        postal_address
        website
        registration_number
        year_established
        organizational_head
        contact_person_in_district
        contact_person_mobile_number_in_district
        contact_number
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getManufacturer = gql`
query getManufacturer(
  $filter: ManufacturerDraftFiltersInput
  $page: Int!
  $pageSize: Int!
) {
  manufacturerDrafts(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        name
        address
        manufacturer {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        primary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        secondary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        postal_address
        website
        registration_number
        year_established
        organizational_head
        contact_person_in_district
        contact_person_mobile_number_in_district
        contact_number
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getManufacturerById = gql`
query getManufacturerById($id: ID)  {
  manufacturer(id: $id) {
    data {
      id
      attributes {
        name
        address
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        primary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        secondary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        postal_address
        website
        registration_number
        year_established
        organizational_head
        contact_person_in_district
        contact_person_mobile_number_in_district
        contact_number
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

// Suppliers

export const getSuppliersCount = gql`
query getSuppliersCount($filter: SupplierFiltersInput) {
  suppliers(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      attributes {
        latitude
        longitude
      }
    }
  }
}
`;

export const getSuppliersCountWithOutLocation = gql`
query getSuppliersCountWithOutLocation($filter: SupplierFiltersInput) {
  suppliers(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
`;

export const getAllSuppliers = gql`
query getAllSuppliers($filter: SupplierFiltersInput, $page: Int!, $pageSize: Int!) {
  suppliers(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        name
        address
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        primary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        secondary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        postal_address
        website
        registration_number
        year_established
        organizational_head
        contact_person_in_district
        contact_person_mobile_number_in_district
        contact_number
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getSuppliers = gql`
query getSuppliers(
  $filter: SupplierDraftFiltersInput
  $page: Int!
  $pageSize: Int!
) {
  supplierDrafts(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        name
        address
        supplier {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        primary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        secondary_areas {
          data {
            id
            attributes {
              name
            }
          }
        }
        postal_address
        website
        registration_number
        year_established
        organizational_head
        contact_person_in_district
        contact_person_mobile_number_in_district
        contact_number
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getSupplierById = gql`
query getSupplierById($id: ID)  {
  supplier(id: $id) {
    data {
      id
      attributes {
        address
        name
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        primary_areas{
          data {
            id
            attributes {
              name
            }
          }
        }
        secondary_areas{
          data {
            id
            attributes {
              name
            }
          }
        }
        postal_address
        website
        registration_number
        year_established
        organizational_head
        contact_person_in_district
        contact_person_mobile_number_in_district
        contact_number
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

// School

export const getSchoolsCount = gql`
query getSchoolsCount($filter: SchoolFiltersInput) {
  schools(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      attributes {
        latitude
        longitude
      }
    }
  }
}
`;

export const getSchoolsCountWithOutLocation = gql`
query getSchoolsCountWithOutLocation($filter: SchoolFiltersInput) {
  schools(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
`;

export const getAllSchool = gql`
query getAllSchools($filter: SchoolFiltersInput, $page: Int!, $pageSize: Int!) {
  schools(filters: $filter, pagination: { page: $page, pageSize: $pageSize }, sort: "id:desc") {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        semis_code
        name
        address
        number_of_rooms
        electricity
        toilet
        drinking_water
        area_type
        boundary_wall
        latitude
        longitude
        building_condition
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
      }
    }
  }
}
`;

export const getAllSchoolById = gql`
query getAllSchoolById($id: ID)  {
  school(id: $id) {
    data {
      id
      attributes {
        semis_code
        name
        address
        number_of_rooms
        electricity
        toilet
        drinking_water
        boundary_wall
        latitude
        area_type
        longitude
        building_condition
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
      }
    }
  }
}

`;
// Inventories

export const getInventoriesCount = gql`
query getInventoriesCount($filter: InventoryFiltersInput) {
  inventories(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      attributes {
        on_hand_quantity
        product_name {
          data {
            attributes {
              name
            }
          }
        }
        product_type {
          data {
            attributes {
              name
            }
          }
        }
      }
    }
  }
}

`;

export const ApiInventoriesCountWithOutChartData = gql`
query getInventoriesCount($filter: InventoryFiltersInput) {
  inventories(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}

`;

export const getAllInventories = gql`
query getAllInventories($filter: InventoryFiltersInput, $page: Int!, $pageSize: Int!) {
  inventories(
    filters: $filter,
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
          class{
          data{
            id
            attributes {
              name
            }
          }
        }
        product_name {
          data {
            id
            attributes {
              name
            }
          }
        }
        product_category {
          data {
            id
            attributes {
              name
            }
          }
        }
        product_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        description
        product_section
        received_quantity
        consumed_quantity
        on_hand_quantity
        unit_of_measure
        product_code
        product_barcode
        product_qr_code
        product_brand
        product_company
        induction_date
        warranty_year
        expiry_date
        warehouse_address
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}`;

export const getInventories = gql`
query getInventories(
  $filter: InventoryDraftFiltersInput
  $page: Int!
  $pageSize: Int!
) {
  inventoryDrafts(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        inventory {
          data {
            id
            attributes {
              product_barcode
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
          class{
          data{
            id
            attributes {
              name
            }
          }
        }
        product_name {
          data {
            id
            attributes {
              name
            }
          }
        }
        product_category {
          data {
            id
            attributes {
              name
            }
          }
        }
        product_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        description
        product_section
        received_quantity
        consumed_quantity
        on_hand_quantity
        unit_of_measure
        product_code
        product_barcode
        product_qr_code
        product_brand
        product_company
        induction_date
        warranty_year
        expiry_date
        warehouse_address
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getInventoriesById = gql`
query getInventoriesById($id: ID) {
  inventory(id: $id) {
    data {
      id
      attributes {
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
          class{
          data{
            id
            attributes {
              name
            }
          }
        }
        product_name {
          data {
            id
            attributes {
              name
            }
          }
        }
        product_category {
          data {
            id
            attributes {
              name
            }
          }
        }
        product_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        description
        product_section
        received_quantity
        consumed_quantity
        on_hand_quantity
        unit_of_measure
        product_code
        product_barcode
        product_qr_code
        product_brand
        product_company
        induction_date
        warranty_year
        expiry_date
        warehouse_address
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getProductTypes = gql`
query getAllProductTypes {
  productTypes(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getProductTypeById = gql`
query getProductTypeById($id: ID) {
  productType(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getProductNames = gql`
query getAllProductNames {
  productNames(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getProductNameById = gql`
query getProductNameById($id: ID) {
  productName(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getProductCategories = gql`
query getAllProductCategories {
  productCategories(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getProductCategoryById = gql`
query getProductCategoryById($id: ID) {
  productCategory(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getAllClasses = gql`
query getAllClasses {
  classes(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}`;

// FinancialRelief 

export const getFinancialReliefsCount = gql`
query getFinancialReliefsCount($filter: FinancialReliefFiltersInput) {
  financialReliefs(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      attributes {
        amount_received_in_pkr
        amount_consumed_in_pkr
        amount_on_hand_in_pkr
      }
    }
  }
}
`;

export const getFinancialReliefsCountWithOutChartData = gql`
query getFinancialReliefsCountWithOutChartData($filter: FinancialReliefFiltersInput) {
  financialReliefs(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
`;

export const getAllFinancialReliefs = gql`
query getAllFinancialReliefs($filter: FinancialReliefFiltersInput, $page: Int!, $pageSize: Int!) {
  financialReliefs(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        contributor
        amount_received_in_pkr
        amount_consumed_in_pkr
        amount_on_hand_in_pkr
        event {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        stage
      }
    }
  }
}
`;

export const getFinancialReliefs = gql`
query getFinancialReliefs(
  $filter: FinancialReliefDraftFiltersInput
  $page: Int!
  $pageSize: Int!
) {
  financialReliefDrafts(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        contributor
        amount_received_in_pkr
        amount_consumed_in_pkr
        amount_on_hand_in_pkr
        financial_relief {
          data {
            id
          }
        }
        event {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        stage
      }
    }
  }
}
`;

export const getFinancialReliefById = gql`
query getFinancialReliefById($id: ID) {
  financialRelief(id: $id) {
    data {
      id
      attributes {
        contributor
        amount_received_in_pkr
        amount_consumed_in_pkr
        amount_on_hand_in_pkr
        event {
          data {
            id
            attributes {
              name
              description
              from_date
              to_date
              divisions {
                data {
                  id
                  attributes {
                    division_name
                  }
                }
              }
              districts {
                data {
                  id
                  attributes {
                    district_name
                  }
                }
              }
              tehsils {
                data {
                  id
                  attributes {
                    tehsil_name
                  }
                }
              }
              union_councils {
                data {
                  id
                  attributes {
                    union_council_name
                  }
                }
              }
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        stage
      }
    }
  }
}

`;

// CompensatonReliefs

export const getCompensatonReliefsCount = gql`
query getCompensatonReliefsCount($filter: CompensatonReliefFiltersInput) {
  compensatonReliefs(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      attributes {
        compensation_status {
          data {
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
`;

export const getCompensatonReliefsCountWithOutChartData = gql`
query getCompensatonReliefsCountWithOutChartData($filter: CompensatonReliefFiltersInput) {
  compensatonReliefs(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
`;

export const getAllCompensatonReliefs = gql`
query getAllCompensatonReliefs($filter: CompensatonReliefFiltersInput, $page: Int!, $pageSize: Int!) {
  compensatonReliefs(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        full_name
        cnic
        date_of_birth
        type
        reason
        address
        mobile_number
        event {
          data {
            id
            attributes {
              name
            }
          }
        }
        compensation_status {
          data {
            id
            attributes {
              name
            }
          }
        }
        gender {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        stage
      }
    }
  }
}
`;

export const getCompensatonReliefs = gql`
query getCompensatonReliefs(
  $filter: CompensatonReliefDraftFiltersInput
  $page: Int!
  $pageSize: Int!
) {
  compensatonReliefDrafts(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        full_name
        cnic
        date_of_birth
        type
        reason
        address
        mobile_number
        compensaton_relief{
          data{
            id
          }
        }
        event {
          data {
            id
            attributes {
              name
            }
          }
        }
        compensation_status {
          data {
            id
            attributes {
              name
            }
          }
        }
        gender {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        stage
      }
    }
  }
}
`;

export const getCompensatonReliefById = gql`
query getCompensatonReliefById($id: ID) {
  compensatonRelief(id: $id) {
    data {
      id
      attributes {
        full_name
        cnic
        date_of_birth
        type
        reason
        address
        mobile_number
        event {
          data {
            id
            attributes {
              name
              description
              from_date
              to_date
              divisions {
                data {
                  id
                  attributes {
                    division_name
                  }
                }
              }
              districts {
                data {
                  id
                  attributes {
                    district_name
                  }
                }
              }
              tehsils {
                data {
                  id
                  attributes {
                    tehsil_name
                  }
                }
              }
              union_councils {
                data {
                  id
                  attributes {
                    union_council_name
                  }
                }
              }
            }
          }
        }
        compensation_status {
          data {
            id
            attributes {
              name
            }
          }
        }
        gender {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        stage
      }
    }
  }
}
`;

export const getCompensationStatuses = gql`
query getAllCompensationStatuses {
  compensationStatuses(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getCompensationStatusById = gql`
query getCompensationStatusById($id: ID) {
  compensationStatus(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

// HumanCasualties
export const getHumanCasualtiesCount = gql`
query getHumanCasualtiesCount($filter: HumanCasualtieFiltersInput) {
  humanCasualties(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      attributes {
        casualty_type {
          data {
            attributes {
              name
            }
          }
        }
        quantity
        casualties_details{
          data{
            attributes{
              gender{
                data{
                  id
                   attributes{
                    name
                  }
                }
              }
            }
          }
        }
        latitude
        longitude
      }
    }
  }
}
`;

export const getHumanCasualtiesCountWithOutChartData = gql`
query getHumanCasualtiesCountWithOutChartData($filter: HumanCasualtieFiltersInput) {
  humanCasualties(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}

`;

export const getAllHumanCasualties = gql`
query getAllHumanCasualties($filter: HumanCasualtieFiltersInput, $page: Int!, $pageSize: Int!) {
  humanCasualties(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
      quantity
        event{
          data{
            id
            attributes{
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        casualty_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getHumanCasualties = gql`
query getHumanCasualtiesDrafts($filter: HumanCasualtieDraftFiltersInput,  $page: Int!,
  $pageSize: Int!) {
  humanCasualtieDrafts(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
      quantity
        human_casualtie{
          data{
            id
          }
        }
        event{
          data{
            id
            attributes{
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        casualty_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getHumanCasualtieById = gql`
query getHumanCasualtieById($id: ID) {
  humanCasualtie(id: $id) {
    data {
      id
      attributes {
            quantity
        event {
          data {
            id
            attributes {
              name
              description
              from_date
              to_date
              divisions {
                data {
                  id
                  attributes {
                    division_name
                  }
                }
              }
              districts {
                data {
                  id
                  attributes {
                    district_name
                  }
                }
              }
              tehsils {
                data {
                  id
                  attributes {
                    tehsil_name
                  }
                }
              }
              union_councils {
                data {
                  id
                  attributes {
                    union_council_name
                  }
                }
              }
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        casualty_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getCasualtyTypes = gql`
query getCasualtyTypes {
  casualtyTypes(pagination: { limit: 100000 }, sort: "id:ASC") {
    data {
      id
      attributes {
        name
        chart_color
        createdAt    		
        updatedAt
      }
    }
  }
}`;

export const getCasualtyTypeById = gql`
query getCasualtyTypeById($id: ID) {
  casualtyType(id: $id) {
    data {
      id
      attributes {
        name
        chart_color
        createdAt    		
        updatedAt
      }
    }
  }
}`;

export const getAllCasualtiesDetails = gql`
query getAllCasualtiesDetails(
  $page: Int!
  $pageSize: Int!
  $filter: CasualtiesDetailFiltersInput
) {
  casualtiesDetails(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
       full_name
        cnic
        date_of_birth
        mobile_number
        address
        gender {
          data {
            id
            attributes {
              name
            }
          }
        }
        human_casualtie{
          data{
            id
          }
        }
      }
    }
  }
}

`;

// InfrastructureDamages
export const getInfrastructureDamagesCount = gql`
query getInfrastructureDamagesCount($filter: InfrastructureDamageFiltersInput) {
  infrastructureDamages(
    filters: $filter
    pagination: { pageSize: 1000000000 }
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      attributes {
        infrastructure_type {
          data {
            attributes {
              name
            }
          }
        }
        latitude
        longitude
      }
    }
  }
}
`;

export const getInfrastructureDamagesCountWithOutChartData = gql`
query getInfrastructureDamagesCountWithOutChartData($filter: InfrastructureDamageFiltersInput) {
  infrastructureDamages(
    filters: $filter
    pagination: { pageSize: 1000000000 }
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
`;

export const getAllInfrastructureDamages = gql`
query getAllInfrastructureDamages($filter: InfrastructureDamageFiltersInput, $page: Int!, $pageSize: Int!) {
  infrastructureDamages(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        damaged
        address
        restored
        infrastructure_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        event {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getInfrastructureDamages = gql`
query getInfrastructureDamages($filter: InfrastructureDamageDraftFiltersInput,  $page: Int!,
  $pageSize: Int!) {
  infrastructureDamageDrafts(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        damaged
        address
        restored
        infrastructure_damage {
          data {
            id
          }
        }
        infrastructure_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        event {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}`;

export const getInfrastructureDamageById = gql`
query getInfrastructureDamageById($id: ID) {
  infrastructureDamage(id: $id) {
    data {
      id
      attributes {
        damaged
        address
        restored
        infrastructure_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        event {
          data {
            id
            attributes {
              name
              description
              from_date
              to_date
              divisions {
                data {
                  id
                  attributes {
                    division_name
                  }
                }
              }
              districts {
                data {
                  id
                  attributes {
                    district_name
                  }
                }
              }
              tehsils {
                data {
                  id
                  attributes {
                    tehsil_name
                  }
                }
              }
              union_councils {
                data {
                  id
                  attributes {
                    union_council_name
                  }
                }
              }
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        latitude
        longitude
        stage
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getInfrastructureTypes = gql`
query getAllInfrastructureTypes {
  infrastructureTypes(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getInfrastructureTypeById = gql`
query getInfrastructureTypeById($id: ID) {
  infrastructureType(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getAllInfrastructureOwnerDetails = gql`
query getAllInfrastructureOwnerDetails(
  $page: Int!
  $pageSize: Int!
  $filter: InfrastructureOwnerDetailFiltersInput
) {
  infrastructureOwnerDetails(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        name
        cnic
        address
        mobile_number
        damaged
        restored
        infrastructure_damage {
          data {
            id
            attributes {
              damaged
              restored
            }
          }
        }
      }
    }
  }
}

`;
// FoodDamages

export const getFoodDamagesCount = gql`
query getFoodDamagesCount($filter: FoodDamageFiltersInput) {
  foodDamages(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      attributes {
        agriculture_type {
          data {
            attributes {
              name
            }
          }
        }
        acre
        latitude
        longitude
      }
    }
  }
}
`;

export const getFoodDamagesCountWithOutChartData = gql`
query getFoodDamagesCountWithOutChartData($filter: FoodDamageFiltersInput) {
  foodDamages(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
`;

export const getAllfoodDamages = gql`
query getAllfoodDamages($filter: FoodDamageFiltersInput, $page: Int!, $pageSize: Int!) {
  foodDamages(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        address
        acre
        cost
        agriculture_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        event {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        stage
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getFoodDamages = gql`
query getFoodDamages(
  $filter: FoodDamageDraftFiltersInput
  $page: Int!
  $pageSize: Int!
) {
  foodDamageDrafts(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        address
        acre
        cost
        food_damage {
          data {
            id
          }
        }
        agriculture_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        event {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        stage
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getFoodDamageById = gql`
query getFoodDamageById($id: ID) {
  foodDamage(id: $id) {
    data {
      id
      attributes {
        acre
        address
        cost
        agriculture_type{
          data {
            id
            attributes {
              name
            }
          }
        }
        event {
          data {
            id
            attributes {
              name
              description
              from_date
              to_date
              divisions {
                data {
                  id
                  attributes {
                    division_name
                  }
                }
              }
              districts {
                data {
                  id
                  attributes {
                    district_name
                  }
                }
              }
              tehsils {
                data {
                  id
                  attributes {
                    tehsil_name
                  }
                }
              }
              union_councils {
                data {
                  id
                  attributes {
                    union_council_name
                  }
                }
              }
            }
          }
        }
         division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        stage
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getAgricultureTypes = gql`
query getAllAgricultureTypes {
  agricultureTypes(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getAllGrowerDetails = gql`
query getAllGrowerDetails(
  $page: Int!
  $pageSize: Int!
$filter: GrowerDetailFiltersInput
) {
  growerDetails
  (
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  )
  {
  meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        name
        cnic
        address
        mobile_number
        acre
        food_damage {
          data {
            id
            attributes {
              acre
              cost
            }
          }
        }
      }
    }
  }
}
`;

export const getAgricultureTypeById = gql`
query getAgricultureTypeById($id: ID) {
  agricultureType(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getLivestockTypes = gql`
query getAllLivestockTypes {
  livestockTypes(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getLivestockTypeById = gql`
query getLivestockTypeById($id: ID) {
  livestockType(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

// LivestockDamages

export const getLivestockDamagesCount = gql`
query getLivestockDamagesCount($filter: LivestockDamageFiltersInput) {
  livestockDamages(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      attributes {
        livestock_type {
          data {
            attributes {
              name
            }
          }
        }
        latitude
        longitude
      }
    }
  }
}
`;

export const getLivestockDamagesCountWithOutChartData = gql`
query getLivestockDamagesCountWithOutChartData($filter: LivestockDamageFiltersInput) {
  livestockDamages(filters: $filter, pagination: { pageSize: 1000000000 }) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
`;

export const getAllLivestockDamages = gql`
query getAllLivestockDamages($filter: LivestockDamageFiltersInput, $page: Int!, $pageSize: Int!) {
  livestockDamages(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        address
        total_quantity
        cost
        casualty_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        livestock_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        event {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        stage
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getLivestockDamages = gql`
query getLivestockDamages(
  $filter: LivestockDamageDraftFiltersInput
  $page: Int!
  $pageSize: Int!
) {
  livestockDamageDrafts(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        address
        total_quantity
        cost
        livestock_damage {
          data {
            id
          }
        }
        casualty_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        livestock_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        event {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        stage
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getLivestockDamageById = gql`
query getLivestockDamageById($id: ID) {
  livestockDamage(id: $id) {
    data {
      id
      attributes {
        total_quantity
        cost
        address
        casualty_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        livestock_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        event {
          data {
            id
            attributes {
              name
              description
              from_date
              to_date
              divisions {
                data {
                  id
                  attributes {
                    division_name
                  }
                }
              }
              districts {
                data {
                  id
                  attributes {
                    district_name
                  }
                }
              }
              tehsils {
                data {
                  id
                  attributes {
                    tehsil_name
                  }
                }
              }
              union_councils {
                data {
                  id
                  attributes {
                    union_council_name
                  }
                }
              }
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
        stage
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getEvents = gql`
query getAllEvents($filter: EventFiltersInput) {
  events(filters: $filter, pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}`;

export const getEventById = gql`
query getEventById($id: ID) {
  event(id: $id) {
    data {
      id
      attributes {
        name
        districts {
          data {
            id
            attributes {
              district_name
            }
          }
        }
      }
    }
  }
}`;

export const getAllLivestockOwnerDetails = gql`
query getAllLivestockOwnerDetails(
  $page: Int!
  $pageSize: Int!
  $filter: LivestockOwnerDetailFiltersInput
) {
  livestockOwnerDetails(
    filters: $filter
    pagination: { page: $page, pageSize: $pageSize }
    sort: "id:desc"
  ) {
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
    data {
      id
      attributes {
        name
        cnic
        address
        mobile_number
        quantity
        livestock_damage {
          data {
            id
            attributes {
              cost
            }
          }
        }
      }
    }
  }
}
`;

// update stage

export const updateNgoStage = gql`
mutation updateNgoDraft($id: ID!, $stage: Int) {
  updateNgoDraft(id: $id, data: { stage: $stage }) {
    data {
      id
      attributes {
        ngo_name
      }
    }
  }
}`;

export const updateVolunteer = gql`
mutation updateVolunteer($id: ID!, $stage: Int) {
  updateVolunteerDraft(id: $id, data: { stage: $stage }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}`;

export const updateManufacturer = gql`
mutation updateManufacturer($id: ID!, $stage: Int) {
  updateManufacturerDraft(id: $id, data: { stage: $stage }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}`;

export const updateSupplier = gql`
mutation updateSupplierDraft($id: ID!, $stage: Int) {
  updateSupplierDraft(id: $id, data: { stage: $stage }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}`;

export const updateInventoryStage = gql`
mutation updateInventoryDraft($id: ID!, $stage: Int) {
  updateInventoryDraft(id: $id, data: { stage: $stage }) {
    data {
      id
      attributes {
        product_code
      }
    }
  }
}
`;

export const updateFinancialReliefStage = gql`
mutation updateFinancialReliefDraft($id: ID!, $stage: Int) {
  updateFinancialReliefDraft(id: $id, data: { stage: $stage }) {
    data {
      id
      attributes {
        contributor
      }
    }
  }
}
`;

export const updateCompensatonReliefStage = gql`
mutation updateCompensatonReliefDraft($id: ID!, $stage: Int) {
  updateCompensatonReliefDraft(id: $id, data: { stage: $stage }) {
    data {
      id
      attributes {
        full_name
      }
    }
  }
}
`;

export const updateHumanCasualtieStage = gql`
mutation updateHumanCasualtieDraft($id: ID!, $stage: Int) {
  updateHumanCasualtieDraft(id: $id, data: { stage: $stage }) {
    data {
      id
    }
  }
}
`;

export const updateInfrastructureDamageStage = gql`
mutation updateInfrastructureDamageDraft($id: ID!, $stage: Int) {
  updateInfrastructureDamageDraft(id: $id, data: { stage: $stage }) {
    data {
      id
      attributes {
        damaged
      }
    }
  }
}
`;

export const updateFoodDamageStage = gql`
mutation updateFoodDamageDraft($id: ID!, $stage: Int) {
  updateFoodDamageDraft(id: $id, data: { stage: $stage }) {
    data {
      id
      attributes {
        acre
      }
    }
  }
}
`;

export const updateLivestockDamageStage = gql`
mutation updateLivestockDamageDraft($id: ID!, $stage: Int) {
  updateLivestockDamageDraft(id: $id, data: { stage: $stage }) {
    data {
      id
      attributes {
        total_quantity
      }
    }
  }
}
`;

// update Existing record

export const updateExistingNgo = gql`
mutation updateExistingNgo(
    $ngoId: ID!
    $ngo_name: String
    $divisionId: ID
    $districtId: ID
    $tehsilId: ID
    $union_councilId: ID
    $postal_address: String
    $primary_areasId: [ID]
    $secondary_areasId: [ID]
    $website: String
    $address: String
    $license_registration_number: String
    $year_established: String
    $organizational_head: String
    $ngo_contact_person_in_district: String
    $ngo_contact_person_mobile_number_in_district: Long
    $ngo_contact_number: Long
    $latitude: Float
    $longitude: Float
  ) {
    updateNgo(
      id: $ngoId
      data: {
        ngo_name: $ngo_name
        division: $divisionId
        district: $districtId
        tehsil: $tehsilId
        union_council: $union_councilId
        postal_address: $postal_address
        primary_areas: $primary_areasId
        secondary_areas: $secondary_areasId
        website: $website
        address: $address
        license_registration_number: $license_registration_number
        year_established: $year_established
        organizational_head: $organizational_head
        ngo_contact_person_in_district: $ngo_contact_person_in_district
        ngo_contact_person_mobile_number_in_district: $ngo_contact_person_mobile_number_in_district
        ngo_contact_number: $ngo_contact_number
        latitude: $latitude
        longitude: $longitude
      }
    ) {
      data {
        id
        attributes {
          ngo_name
        }
      }
    }
  }
`;

export const updateExistingNgoDraft = gql`
mutation updateExistingNgoDraft(
    $ngoId: ID!
    $ngo_name: String
    $divisionId: ID
    $districtId: ID
    $tehsilId: ID
    $union_councilId: ID
    $ngo: ID
    $postal_address: String
    $primary_areasId: [ID]
    $secondary_areasId: [ID]
    $website: String
    $address: String
    $is_draft: Boolean
    $license_registration_number: String
    $year_established: String
    $organizational_head: String
    $ngo_contact_person_in_district: String
    $ngo_contact_person_mobile_number_in_district: Long
    $ngo_contact_number: Long
    $stage: Int
    $latitude: Float
    $longitude: Float
  ) {
    updateNgoDraft(
      id: $ngoId
      data: {
        ngo_name: $ngo_name
        division: $divisionId
        district: $districtId
        tehsil: $tehsilId
        union_council: $union_councilId
        postal_address: $postal_address
        primary_areas: $primary_areasId
        ngo: $ngo
        secondary_areas: $secondary_areasId
        website: $website
        address: $address
        is_draft: $is_draft
        stage: $stage
        license_registration_number: $license_registration_number
        year_established: $year_established
        organizational_head: $organizational_head
        ngo_contact_person_in_district: $ngo_contact_person_in_district
        ngo_contact_person_mobile_number_in_district: $ngo_contact_person_mobile_number_in_district
        ngo_contact_number: $ngo_contact_number
        latitude: $latitude
        longitude: $longitude
      }
    ) {
      data {
        id
        attributes {
          ngo_name
        }
      }
    }
  }
`;

export const updateExistingVolunteer = gql`
mutation updateExistingVolunteer(
  $volunteerId: ID!
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $cnic_number: Long
  $address: String
  $postal_address: String
  $website: String
  $contact_person: String
  $contact_person_mobile: Long
  $latitude: Float
  $longitude: Float
) {
  updateVolunteer(
    id: $volunteerId
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      cnic_number: $cnic_number
      postal_address: $postal_address
      address: $address
      website: $website
      contact_person: $contact_person
      contact_person_mobile: $contact_person_mobile
      latitude: $latitude
      longitude: $longitude
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;
export const updateExistingVolunteerDraft = gql`
mutation updateExistingVolunteerDraft(
  $volunteerId: ID!
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $volunteer: ID 
  $cnic_number: Long
  $address: String
  $postal_address: String
  $website: String
  $contact_person: String
  $contact_person_mobile: Long
  $latitude: Float
  $longitude: Float
  $stage: Int
  $is_draft: Boolean
) {
  updateVolunteerDraft(
    id: $volunteerId
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      cnic_number: $cnic_number
      volunteer: $volunteer
      postal_address: $postal_address
      address: $address
      website: $website
      contact_person: $contact_person
      contact_person_mobile: $contact_person_mobile
      latitude: $latitude
      longitude: $longitude
       is_draft: $is_draft
        stage: $stage
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const updateExistingManufacturer = gql`
mutation updateExistingManufacturer(
  $manufacturerId: ID!
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $postal_address: String
  $primary_areasId: [ID]
  $secondary_areasId: [ID]
  $website: String
  $address: String
  $registration_number: String
  $year_established: String
  $organizational_head: String
  $contact_person_in_district: String
  $contact_person_mobile_number_in_district: Long
  $contact_number: Long
  $latitude: Float
  $longitude: Float
) {
  updateManufacturer(
    id: $manufacturerId
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      postal_address: $postal_address
      primary_areas: $primary_areasId
      secondary_areas: $secondary_areasId
      website: $website
      address: $address
      registration_number: $registration_number
      year_established: $year_established
      organizational_head: $organizational_head
      contact_person_in_district: $contact_person_in_district
      contact_person_mobile_number_in_district: $contact_person_mobile_number_in_district
      contact_number: $contact_number
      latitude: $latitude
      longitude: $longitude
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;
export const updateExistingManufacturerDraft = gql`
mutation updateExistingManufacturerDraft(
  $manufacturerId: ID!
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $postal_address: String
  $primary_areasId: [ID]
  $secondary_areasId: [ID]
  $website: String
  $manufacturer: ID
  $address: String
  $registration_number: String
  $year_established: String
  $organizational_head: String
  $contact_person_in_district: String
  $contact_person_mobile_number_in_district: Long
  $contact_number: Long
  $latitude: Float
  $longitude: Float
   $stage: Int
  $is_draft: Boolean
) {
  updateManufacturerDraft(
    id: $manufacturerId
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      postal_address: $postal_address
      manufacturer: $manufacturer
      primary_areas: $primary_areasId
      secondary_areas: $secondary_areasId
      website: $website
      address: $address
      registration_number: $registration_number
      year_established: $year_established
      organizational_head: $organizational_head
      contact_person_in_district: $contact_person_in_district
      contact_person_mobile_number_in_district: $contact_person_mobile_number_in_district
      contact_number: $contact_number
      latitude: $latitude
      longitude: $longitude
      is_draft: $is_draft
        stage: $stage
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const updateExistingSupplier = gql`
mutation updateExistingSupplier(
  $supplierId: ID!
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $postal_address: String
  $primary_areasId: [ID]
  $secondary_areasId: [ID]
  $website: String
  $address: String
  $registration_number: String
  $year_established: String
  $organizational_head: String
  $contact_person_in_district: String
  $contact_person_mobile_number_in_district: Long
  $contact_number: Long
  $latitude: Float
  $longitude: Float
) {
  updateSupplier(
    id: $supplierId
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      postal_address: $postal_address
      primary_areas: $primary_areasId
      secondary_areas: $secondary_areasId
      website: $website
      address: $address
      registration_number: $registration_number
      year_established: $year_established
      organizational_head: $organizational_head
      contact_person_in_district: $contact_person_in_district
      contact_person_mobile_number_in_district: $contact_person_mobile_number_in_district
      contact_number: $contact_number
      latitude: $latitude
      longitude: $longitude
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;
export const updateExistingSupplierDraft = gql`
mutation updateExistingSupplierDraft(
  $supplierId: ID!
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $postal_address: String
  $primary_areasId: [ID]
  $secondary_areasId: [ID]
  $website: String
  $address: String
  $registration_number: String
  $supplier: ID
  $year_established: String
  $organizational_head: String
  $contact_person_in_district: String
  $contact_person_mobile_number_in_district: Long
  $contact_number: Long
  $latitude: Float
  $longitude: Float
    $stage: Int
  $is_draft: Boolean
) {
  updateSupplierDraft(
    id: $supplierId
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      postal_address: $postal_address
      primary_areas: $primary_areasId
      secondary_areas: $secondary_areasId
      supplier: $supplier
      website: $website
      address: $address
      registration_number: $registration_number
      year_established: $year_established
      organizational_head: $organizational_head
      contact_person_in_district: $contact_person_in_district
      contact_person_mobile_number_in_district: $contact_person_mobile_number_in_district
      contact_number: $contact_number
      latitude: $latitude
      longitude: $longitude
       is_draft: $is_draft
        stage: $stage
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const updateExistingInventory = gql`
mutation updateExistingInventory(
  $inventoryId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $product_typeId: ID
  $product_nameId: ID
  $classId: ID
  $product_categoryId: ID
  $product_section: String
  $received_quantity: Long
  $consumed_quantity: Long
  $warehouse_address: String
  $description: String
  $unit_of_measure: String
  $product_code: String
  $product_barcode: String
  $product_qr_code: String
  $product_brand: String
  $product_company: String
  $induction_date: Date
  $warranty_year: String
  $expiry_date: Date
  $latitude: Float
  $longitude: Float
) {
  updateInventory(
    id: $inventoryId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      product_type: $product_typeId
      product_name: $product_nameId
      class: $classId
      product_category: $product_categoryId
      product_section: $product_section
      received_quantity: $received_quantity
      consumed_quantity: $consumed_quantity
      warehouse_address: $warehouse_address
      description: $description
      unit_of_measure: $unit_of_measure
      product_code: $product_code
      product_barcode: $product_barcode
      product_qr_code: $product_qr_code
      product_brand: $product_brand
      product_company: $product_company
      induction_date: $induction_date
      warranty_year: $warranty_year
      expiry_date: $expiry_date
      latitude: $latitude
      longitude: $longitude
    }
  ) {
    data {
      id
      attributes {
        product_code
      }
    }
  }
}
`;
export const updateExistingInventoryDraft = gql`
mutation updateExistingInventoryDraft(
  $inventoryId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $product_typeId: ID
  $product_nameId: ID
  $classId: ID
  $product_categoryId: ID
  $inventory: ID
  $product_section: String
  $received_quantity: Long
  $consumed_quantity: Long
  $warehouse_address: String
  $description: String
  $unit_of_measure: String
  $product_code: String
  $product_barcode: String
  $product_qr_code: String
  $product_brand: String
  $product_company: String
  $induction_date: Date
  $warranty_year: String
  $expiry_date: Date
  $latitude: Float
  $longitude: Float
    $stage: Int
  $is_draft: Boolean
) {
  updateInventoryDraft(
    id: $inventoryId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      product_type: $product_typeId
      product_name: $product_nameId
      class: $classId
      inventory: $inventory
      product_category: $product_categoryId
      product_section: $product_section
      received_quantity: $received_quantity
      consumed_quantity: $consumed_quantity
      warehouse_address: $warehouse_address
      description: $description
      unit_of_measure: $unit_of_measure
      product_code: $product_code
      product_barcode: $product_barcode
      product_qr_code: $product_qr_code
      product_brand: $product_brand
      product_company: $product_company
      induction_date: $induction_date
      warranty_year: $warranty_year
      expiry_date: $expiry_date
      latitude: $latitude
      longitude: $longitude
      is_draft: $is_draft
        stage: $stage
    }
  ) {
    data {
      id
      attributes {
        product_code
      }
    }
  }
}
`;

export const updateExistingFinancialRelief = gql`
mutation updateExistingFinancialRelief(
  $financialReliefId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $eventId: ID
  $contributor: String
  $amount_received_in_pkr: Long
  $amount_consumed_in_pkr: Long
  $amount_on_hand_in_pkr: Long
  $stage: Int
) {
  updateFinancialRelief(
    id: $financialReliefId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      event: $eventId
      contributor: $contributor
      amount_received_in_pkr: $amount_received_in_pkr
      amount_consumed_in_pkr: $amount_consumed_in_pkr
      amount_on_hand_in_pkr: $amount_on_hand_in_pkr
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        contributor
      }
    }
  }
}

`;
export const updateExistingFinancialReliefDraft = gql`
mutation updateExistingFinancialReliefDraft(
  $financialReliefId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $eventId: ID
  $contributor: String
  $amount_received_in_pkr: Long
  $financial_relief: ID
  $amount_consumed_in_pkr: Long
  $amount_on_hand_in_pkr: Long
  $stage: Int
  $is_draft: Boolean
) {
  updateFinancialReliefDraft(
    id: $financialReliefId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      financial_relief: $financial_relief
      event: $eventId
      contributor: $contributor
      amount_received_in_pkr: $amount_received_in_pkr
      amount_consumed_in_pkr: $amount_consumed_in_pkr
      amount_on_hand_in_pkr: $amount_on_hand_in_pkr
      is_draft: $is_draft
        stage: $stage
    }
  ) {
    data {
      id
      attributes {
        contributor
      }
    }
  }
}

`;

export const updateExistingCompensatonRelief = gql`
mutation updateExistingCompensatonRelief(
  $compensatonReliefId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $full_name: String
  $cnic: Long
  $date_of_birth: Date
  $type: String
  $reason: String
  $address: String
  $mobile_number: Long
  $eventId: ID
  $genderId: ID
  $stage: Int
) {
  updateCompensatonRelief(
    id: $compensatonReliefId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      full_name: $full_name
      cnic: $cnic
      date_of_birth: $date_of_birth
      type: $type
      reason: $reason
      address: $address
      mobile_number: $mobile_number
      event: $eventId
      gender: $genderId
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        full_name
      }
    }
  }
}

`;
export const updateExistingCompensatonReliefDraft = gql`
mutation updateExistingCompensatonReliefDraft(
  $compensatonReliefId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $full_name: String
  $cnic: Long
  $date_of_birth: Date
  $type: String
  $reason: String
  $compensaton_relief: ID
  $address: String
  $mobile_number: Long
  $eventId: ID
  $genderId: ID
  $stage: Int
  $is_draft: Boolean
) {
  updateCompensatonReliefDraft(
    id: $compensatonReliefId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      full_name: $full_name
      cnic: $cnic
      date_of_birth: $date_of_birth
      type: $type
      compensaton_relief: $compensaton_relief
      reason: $reason
      address: $address
      mobile_number: $mobile_number
      event: $eventId
      gender: $genderId
      stage: $stage
      is_draft: $is_draft
    }
  ) {
    data {
      id
      attributes {
        full_name
      }
    }
  }
}

`;

export const updateExistingHumanCasualtie = gql`
mutation updateExistingHumanCasualtie(
  $humanCasualtieId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $eventId: ID
  $quantity: Long
  $casualty_typeId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  updateHumanCasualtie(
    id: $humanCasualtieId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      event: $eventId
      quantity: $quantity
      casualty_type: $casualty_typeId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
    }
  }
}

`;
export const updateExistingHumanCasualtieDraft = gql`
mutation updateExistingHumanCasualtieDraft(
  $humanCasualtieId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $eventId: ID
  $human_casualtie: ID
  $quantity: Long
  $casualty_typeId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
  $is_draft: Boolean
) {
  updateHumanCasualtieDraft(
    id: $humanCasualtieId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      event: $eventId
      human_casualtie: $human_casualtie
      quantity: $quantity
      casualty_type: $casualty_typeId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
      is_draft: $is_draft
    }
  ) {
    data {
      id
    }
  }
}
`;

export const updateExistingInfrastructureDamage = gql`
mutation updateExistingInfrastructureDamage(
  $infrastructureDamageId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $address: String
  $damaged: String
  $restored: String
  $infrastructure_typeId: ID
  $eventId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  updateInfrastructureDamage(
    id: $infrastructureDamageId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      address: $address
      damaged: $damaged
      restored: $restored
      infrastructure_type: $infrastructure_typeId
      event: $eventId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        damaged
      }
    }
  }
}

`;
export const updateExistingInfrastructureDamageDraft = gql`
mutation updateExistingInfrastructureDamageDraft(
  $infrastructureDamageId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $address: String
  $infrastructure_damage: ID
  $damaged: String
  $restored: String
  $infrastructure_typeId: ID
  $eventId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
  $is_draft: Boolean
) {
  updateInfrastructureDamageDraft(
    id: $infrastructureDamageId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      address: $address
      infrastructure_damage: $infrastructure_damage
      damaged: $damaged
      restored: $restored
      infrastructure_type: $infrastructure_typeId
      event: $eventId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
      is_draft: $is_draft
    }
  ) {
    data {
      id
      attributes {
        damaged
      }
    }
  }
}

`;

export const updateExistingFoodDamage = gql`
mutation updateExistingFoodDamage(
  $foodDamageId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $address: String
  $acre: String
  $cost: Long
  $agriculture_typeId: ID
  $eventId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  updateFoodDamage(
    id: $foodDamageId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      address: $address
      acre: $acre
      cost: $cost
      agriculture_type: $agriculture_typeId
      event: $eventId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        acre
      }
    }
  }
}

`;
export const updateExistingFoodDamageDraft = gql`
mutation updateExistingFoodDamageDraft(
  $foodDamageId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $address: String
  $food_damage: ID
  $acre: String
  $cost: Long
  $agriculture_typeId: ID
  $eventId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
  $is_draft: Boolean
) {
  updateFoodDamageDraft(
    id: $foodDamageId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      address: $address
      food_damage: $food_damage
      acre: $acre
      cost: $cost
      agriculture_type: $agriculture_typeId
      event: $eventId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
      is_draft: $is_draft
    }
  ) {
    data {
      id
      attributes {
        acre
      }
    }
  }
}

`;

export const updateExistingLivestockDamage = gql`
mutation updateExistingLivestockDamage(
  $livestockDamageId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $total_quantity: Long
  $address: String
  $cost: Long
  $livestock_typeId: ID
  $casualty_typeId: ID
  $eventId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  updateLivestockDamage(
    id: $livestockDamageId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      total_quantity: $total_quantity
      address: $address
      cost: $cost
      livestock_type: $livestock_typeId
      event: $eventId
      casualty_type: $casualty_typeId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        total_quantity
      }
    }
  }
}
`;
export const updateExistingLivestockDamageDraft = gql`
mutation updateExistingLivestockDamageDraft(
  $livestockDamageId: ID!
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $total_quantity: Long
  $address: String
  $cost: Long
  $livestock_typeId: ID
  $casualty_typeId: ID
  $eventId: ID
  $livestock_damage: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
  $is_draft: Boolean
) {
  updateLivestockDamageDraft(
    id: $livestockDamageId
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      total_quantity: $total_quantity
      address: $address
      cost: $cost
      livestock_type: $livestock_typeId
      event: $eventId
      casualty_type: $casualty_typeId
      latitude: $latitude
      livestock_damage: $livestock_damage
      longitude: $longitude
      stage: $stage
      is_draft: $is_draft
    }
  ) {
    data {
      id
      attributes {
        total_quantity
      }
    }
  }
}
`;

export const updateExistingGrowerDetail = gql`
mutation updateGrowerDetail(
  $grower_detail_id: ID!
  $name: String
  $cnic: Long
  $address: String
  $mobile_number: Long
  $acre: Long
) {
  updateGrowerDetail(
    id: $grower_detail_id
    data: {
      name: $name
      cnic: $cnic
      address: $address
      mobile_number: $mobile_number
      acre: $acre
    }
  ) {
    data {
      id
      attributes {
        name
        cnic
        address
        mobile_number
        acre
        food_damage {
          data {
            id
            attributes {
              acre
              cost
            }
          }
        }
      }
    }
  }
}
`;

export const updateLivestockOwnerDetail = gql`
mutation updateLivestockOwnerDetail(
  $livestock_owner_detail_id: ID!
  $name: String
  $cnic: Long
  $address: String
  $mobile_number: Long
  $quantity: Long
) {
  updateLivestockOwnerDetail(
    id: $livestock_owner_detail_id
    data: {
      name: $name
      cnic: $cnic
      address: $address
      mobile_number: $mobile_number
      quantity: $quantity
    }
  ) {
    data {
      id
      attributes {
        name
        cnic
        address
        mobile_number
        quantity
        livestock_damage {
          data {
            id
            attributes {
              cost
            }
          }
        }
      }
    }
  }
}
`;

export const updateInfrastructureOwnerDetail = gql`
mutation updateInfrastructureOwnerDetail(
  $infrastructure_owner_detail_id: ID!
  $name: String
  $cnic: Long
  $address: String
  $mobile_number: Long
  $damaged: String
  $restored: String
) {
  updateInfrastructureOwnerDetail(
    id: $infrastructure_owner_detail_id
    data: {
      name: $name
      cnic: $cnic
      address: $address
      mobile_number: $mobile_number
      damaged: $damaged
      restored: $restored
    }
  ) {
    data {
      id
      attributes {
        name
        cnic
        address
        mobile_number
        damaged
              restored
        infrastructure_damage {
          data {
            id
            attributes {
              damaged
              restored
            }
          }
        }
      }
    }
  }
}
`;

export const updateCasualtiesDetail = gql`
mutation updateCasualtiesDetail(
  $casualties_detail_id: ID!
  $full_name: String
  $cnic: Long
  $address: String
  $mobile_number: Long
  $date_of_birth: Date
  $genderId: ID
) {
  updateCasualtiesDetail(
    id: $casualties_detail_id
    data: {
      full_name: $full_name
      cnic: $cnic
      mobile_number: $mobile_number
      gender: $genderId
      address: $address
      date_of_birth: $date_of_birth
    }
  ) {
    data {
      id
      attributes {
      full_name
        cnic
        date_of_birth
        mobile_number
        address
        human_casualtie{
          data{
            id
          }
        }
      }
    }
  }
}
`;

// commmon entites 

export const getDivision = gql`
query getAllDivisions {
  divisions(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        division_name
      }
    }
  }
}
`;

export const getDivisionById = gql`
query getDivisionById($id: ID) {
  division(id: $id) {
    data {
      id
      attributes {
        division_name
      }
    }
  }
}
`;

export const getDistrict = gql`
query getAllDistrict($filter: DistrictFiltersInput) {
  districts(filters: $filter, pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        district_name
        division{
          data {
            id
          }
        }
      }
    }
  }
}
`;

export const getDistrictById = gql`
query getDistrictById($id: ID) {
  district(id: $id) {
    data {
      id
      attributes {
        district_name
        division{
          data {
            id
          }
        }
      }
    }
  }
}
`;

export const getAllDistrict = gql`
query getAllDistrict {
  districts(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        district_name
        division{
          data {
            id
          }
        }
      }
    }
  }
}
`;

export const getTehsilById = gql`
query getTehsilById($id: ID) {
  tehsil(id: $id) {
    data {
      id
      attributes {
        tehsil_name
        district{
          data{
            id
          }
        }
      }
    }
  }
}
`;

export const getTehsils = gql`
query getAllTehsils($filter: TehsilFiltersInput) {
  tehsils(filters: $filter, pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        tehsil_name
        district{
          data{
            id
          }
        }
      }
    }
  }
}
`;

export const getAllTehsils = gql`
query getAllTehsils{
  tehsils(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        tehsil_name
        district{
          data{
            id
          }
        }
      }
    }
  }
}
`;

export const getUnionCouncils = gql`
query getAllUnionCouncils($filter: UnionCouncilFiltersInput) {
  unionCouncils(filters: $filter, pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        union_council_name
        tehsil{
          data{
            id
          }
        }
      }
    }
  }
}
`;

export const getUnionCouncilById = gql`
query getUnionCouncilById($id: ID) {
  unionCouncil(id: $id) {
    data {
      id
      attributes {
        union_council_name
        tehsil{
          data{
            id
          }
        }
      }
    }
  }
}
`;

export const getAllUnionCouncils = gql`
query getAllUnionCouncils{
  unionCouncils(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        union_council_name
        tehsil{
          data{
            id
          }
        }
      }
    }
  }
}
`;

export const getGenders = gql`
query getAllGenders {
  genders(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getGenderById = gql`
query getGenderById($id: ID) {
  gender(id: $id) {
    data {
      id
      attributes {
        name
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getExternalWorkflows = gql`
query getExternalWorkflows($filter: ExternalWorkflowFiltersInput) {
  externalWorkflows(filters: $filter, pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        title
        stage
        role {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
`;

export const getInternalWorkflows = gql`
query getInternalWorkflows($filter: InternalWorkflowFiltersInput){
  internalWorkflows(filters: $filter, pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        title
        stage
        role {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}

`;

export const getAllPrimaryAreas = gql`
query getAllPrimaryAreas {
  primaryAreas(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getAllPrimaryAreaById = gql`
query getAllPrimaryAreaById($id: ID) {
  primaryArea(id: $id) {
    data {
      id
      attributes {
        name
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getAllSecondaryAreas = gql`
query getAllSecondaryAreas {
  secondaryAreas(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
        createdAt
        updatedAt
      }
    }
  }
}

`;

export const getAllSecondaryAreaById = gql`
query getAllSecondaryAreaById($id: ID) {
  secondaryArea(id: $id) {
    data {
      id
      attributes {
        name
        createdAt
        updatedAt
      }
    }
  }
}

`;

export const getNgoDraftByUser = gql`
  query getNgoDraftByUser($id: ID, $created_by_user: ID!) {
    ngoDrafts(filters: { created_by_user: { id: { eq: $created_by_user } }, id:{ne :$id} }) {
      data {
        id
      }
    }
  }
`;

export const getVolunteerDraftByUser = gql`
  query getVolunteerDraftByUser($id: ID, $created_by_user: ID!) {
    volunteerDrafts(filters: { created_by_user: { id: { eq: $created_by_user } }, id:{ne :$id} }) {
      data {
        id
      }
    }
  }
`;

export const getManufacturerDraftByUser = gql`
  query getManufacturerDraftByUser($id: ID, $created_by_user: ID!) {
    manufacturerDrafts(filters: { created_by_user: { id: { eq: $created_by_user } }, id:{ne :$id} }) {
      data {
        id
      }
    }
  }
`;

export const getSupplierDraftByUser = gql`
  query getSupplierDraftByUser($id: ID, $created_by_user: ID!) {
    supplierDrafts(filters: { created_by_user: { id: { eq: $created_by_user } }, id:{ne :$id} }) {
      data {
        id
      }
    }
  }
`;

export const getInventoryDraftByUser = gql`
  query getInventoryDraftByUser($id: ID, $created_by_user: ID!) {
    inventoryDrafts(filters: { created_by_user: { id: { eq: $created_by_user } }, id:{ne :$id} }) {
      data {
        id
      }
    }
  }
`;

export const getFinancialReliefDraftByUser = gql`
  query getFinancialReliefDraftByUser($id: ID, $created_by_user: ID!) {
    financialReliefDrafts(filters: { created_by_user: { id: { eq: $created_by_user } }, id:{ne :$id} }) {
      data {
        id
      }
    }
  }
`;

export const getCompensatonReliefDraftByUser = gql`
  query getCompensatonReliefDraftByUser($id: ID, $created_by_user: ID!) {
    compensatonReliefDrafts(filters: { created_by_user: { id: { eq: $created_by_user } }, id:{ne :$id} }) {
      data {
        id
      }
    }
  }
`;

export const getHumanCasualtieDraftByUser = gql`
  query getHumanCasualtieDraftByUser($id: ID, $created_by_user: ID!) {
    humanCasualtieDrafts(filters: { created_by_user: { id: { eq: $created_by_user } }, id:{ne :$id} }) {
      data {
        id
      }
    }
  }
`;

export const getInfrastructureDamageDraftByUser = gql`
  query getInfrastructureDamageDraftByUser($id: ID, $created_by_user: ID!) {
    infrastructureDamageDrafts(filters: { created_by_user: { id: { eq: $created_by_user } }, id:{ne :$id} }) {
      data {
        id
      }
    }
  }
`;

export const getFoodDamageDraftByUser = gql`
  query getFoodDamageDraftByUser($id: ID, $created_by_user: ID!) {
    foodDamageDrafts(filters: { created_by_user: { id: { eq: $created_by_user } }, id:{ne :$id} }) {
      data {
        id
      }
    }
  }
`;

export const getLivestockDamageDraftByUser = gql`
  query getLivestockDamageDraftByUser($id: ID, $created_by_user: ID!) {
    livestockDamageDrafts(filters: { created_by_user: { id: { eq: $created_by_user } }, id:{ne :$id} }) {
      data {
        id
      }
    }
  }
`;

export const checkLicenseRegistrationNumber = gql`
query checkLicenseRegistrationNumber($license_registration_number: String!, $excludeNgoDraftId: ID, $excludeNgoId: ID) {
  ngos: ngos(filters: { license_registration_number: { eq: $license_registration_number }, id: { ne: $excludeNgoId } }) {
    data {
      id
    }
  }
  ngoDrafts: ngoDrafts(filters: { license_registration_number: { eq: $license_registration_number }, id: { ne: $excludeNgoDraftId } }) {
    data {
      id
    }
  }
}
`;

export const checkVolunteerCnic = gql`
query checkVolunteerCnic($cnic_number: Long!, $excludeVolunteerDraftId: ID, $excludeVolunteerId: ID) {
  volunteers: volunteers(filters: { cnic_number: { eq: $cnic_number }, id: { ne: $excludeVolunteerId } }) {
    data {
      id
    }
  }
  volunteerDrafts: volunteerDrafts(filters: { cnic_number: { eq: $cnic_number }, id: { ne: $excludeVolunteerDraftId } }) {
    data {
      id
    }
  }
}
`;

export const checkCompensatonReliefCnic = gql`
query checkCompensatonReliefCnic($cnic: Long!, $excludeCompensatonReliefId: ID, $excludeCompensatonReliefDraftId: ID) {
  compensatonReliefs: compensatonReliefs(
    filters: { cnic: { eq: $cnic }, id: { ne: $excludeCompensatonReliefId } }
  ) {
    data {
      id
    }
  }
  compensatonReliefDrafts: compensatonReliefDrafts(
    filters: { cnic: { eq: $cnic }, id: { ne: $excludeCompensatonReliefDraftId } }
  ) {
    data {
      id
    }
  }
}
`;