import React, { useState, useEffect } from "react";
import "./index.css";
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import reloadIcon from "../../assets/images/filtersReloadIcon.png";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reduxToolkit/store";
import { useLocation, useNavigate } from 'react-router-dom';
import SearchSelect from "../selectDropdown";
import { fetchDistrictData, fetchTehsilsData, fetchUnionCouncilData } from "../../reduxToolkit/entitiesSlice";

interface FiltersProps {
  setSelectedDivision: (value: string) => void;
  selectedDivision: string;
  setSelectedDistrict: (value: string) => void;
  selectedDistrict: string;
  setSelectedTehsil: (value: string) => void;
  selectedTehsil: string;
  setSelectedUnionCouncil: (value: string) => void;
  selectedUnionCouncil: string;
  setSelectedEvent?: (value: string) => void;
  selectedEvent?: string;
}

const Filters: React.FC<FiltersProps> = ({
  setSelectedDivision,
  selectedDivision,
  setSelectedDistrict,
  selectedDistrict,
  setSelectedTehsil,
  selectedTehsil,
  setSelectedUnionCouncil,
  selectedUnionCouncil,
  setSelectedEvent,
  selectedEvent
}: FiltersProps) => {
  const { districtList, divisionList, tehsilList, unionCouncilList, eventList } = useSelector((state: RootState) => state.entities);
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const navigate = useNavigate();
  const substrings = ["/form", "/login"];
  const containsSubstring = substrings.some(substring => location.pathname.includes(substring));

  useEffect(() => {
    if (!containsSubstring) {
      dispatch(fetchDistrictData(selectedDivision));
    }
  }, [dispatch, containsSubstring, selectedDivision]);

  useEffect(() => {
    if (!containsSubstring) {
      dispatch(fetchTehsilsData(selectedDistrict));
    }
  }, [dispatch, containsSubstring, selectedDistrict]);

  useEffect(() => {
    if (!containsSubstring) {
      dispatch(fetchUnionCouncilData(selectedTehsil));
    }
  }, [dispatch, containsSubstring, selectedTehsil]);

  const filterOption = (input: string, option?: { label: string; value: string; }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const divisionData = divisionList.map((item: any, index) => ({
    value: item.attributes.division_name,
    label: item.attributes.division_name,
  })) || [];

  const districtData = districtList.map((item: any, index) => ({
    value: item.attributes.district_name,
    label: item.attributes.district_name,
  })) || [];

  const tehsilData = tehsilList.map((item: any, index) => ({
    value: item.attributes.tehsil_name,
    label: item.attributes.tehsil_name,
  })) || [];

  const unionCouncilListData = unionCouncilList.map((item: any, index) => ({
    value: item.attributes.union_council_name,
    label: item.attributes.union_council_name,
  })) || [];

  const eventData = eventList.map((item: any, index) => ({
    value: item.attributes.name,
    label: item.attributes.name,
  })) || [];

  const handleDivisionChange = (value: string) => {
    setSelectedDivision(value);
    setSelectedDistrict('');
    setSelectedTehsil('');
    setSelectedUnionCouncil('');
  };

  const handleDistrictChange = (value: string) => {
    setSelectedDistrict(value);
    setSelectedTehsil('');
    setSelectedUnionCouncil('');
  };

  const handleTehsilChange = (value: string) => {
    setSelectedTehsil(value);
    setSelectedUnionCouncil('');
  };

  const handleUnionCouncilChange = (value: string) => {
    setSelectedUnionCouncil(value);
  };

  const handleEventChange = (value: string) => {
    if (setSelectedEvent) {
      setSelectedEvent(value);
    }
  };

  const eventSubstrings = ["/reliefdistribution", "/lossessdamages"];
  const containsEventSubstring = eventSubstrings.some(substring => location.pathname.includes(substring));


  return (
    <div className="filtersCont">
      <div>
        {containsEventSubstring ? <SearchSelect
          onChange={handleEventChange}
          onSearch={setSelectedEvent}
          allowClear
          filterOption={filterOption}
          placeholder="Select the Event"
          value={selectedEvent || undefined}
          className="selectInput"
          options={eventData}
        /> : null}
        <SearchSelect
          onChange={handleDivisionChange}
          onSearch={setSelectedDivision}
          allowClear
          filterOption={filterOption}
          placeholder="Select the Division"
          value={selectedDivision || undefined}
          className="selectInput"
          options={divisionData}
        />
        <SearchSelect
          onChange={handleDistrictChange}
          onSearch={setSelectedDistrict}
          filterOption={filterOption}
          allowClear
          placeholder="Select the District"
          value={selectedDistrict || undefined}
          className="selectInput"
          options={districtData}
        />
        <SearchSelect
          onChange={handleTehsilChange}
          onSearch={setSelectedTehsil}
          allowClear
          filterOption={filterOption}
          placeholder="Select the Tehsil"
          value={selectedTehsil || undefined}
          className="selectInput"
          options={tehsilData}
        />
        <SearchSelect
          onChange={handleUnionCouncilChange}
          onSearch={setSelectedUnionCouncil}
          filterOption={filterOption}
          allowClear
          placeholder="Select the Union Council"
          value={selectedUnionCouncil || undefined}
          className="selectInput"
          options={unionCouncilListData}
        />
      </div>
      <img src={reloadIcon} alt="reload_Icon" className="filterContImg" onClick={() => {
        if (setSelectedEvent) {
          setSelectedEvent("");
        }
        setSelectedDivision('');
        setSelectedDistrict('');
        setSelectedTehsil('');
        setSelectedUnionCouncil('');
      }} />
    </div>
  );
};

export default Filters;
