import React, { useEffect, useState } from 'react';
import './index.css'; // Import CSS for styling
import { FaArrowLeft } from "react-icons/fa6";
import FoodDamagesIcon from '../../assets/images/agriculture_card.png';
import formLossesDamagesLogo from '../../assets/images/formLosses&DamagesLogo.png';
import { Button, Input } from 'antd';
import { Select } from 'antd';
import type { SelectProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { toast } from '../../components/Toast';
import { fetchAllAgricultureTypes, fetchTehsilsData, fetchUnionCouncilData } from '../../reduxToolkit/entitiesSlice';
import { createFoodDamageData, createGrowerData, deleteGrowerData, fetchAllGrowerDetails, getFoodDamageDraftByUser, updateFoodDamageData, updateFoodDamageDraftData, updateFoodDamageStageData, updateGrowerData } from '../../reduxToolkit/foodDamageSlice';
import { validateObject } from '../../helpers/common';
import ReusableTable from '../../components/table';
import DetailIcon from '../../assets/images/Detail.png';
import addIcon from '../../assets/images/Add.png';
import { DeleteOutlined } from '@ant-design/icons';
import { alignGrowerDetailData } from '../../helpers/alignData';

const FoodDamages = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { allDistrictList, divisionList, tehsilList, unionCouncilList, eventList, agricultureTypeList, internalWorkflowsList } = useSelector((state: RootState) => state.entities);
    const { userData } = useSelector((state: RootState) => state.auth);
    const { growerDetails } = useSelector((state: RootState) => state.foodDamage);
    const [foodDamageDetails, setFoodDamageDetails] = useState<{ [key: string]: any; }>({});
    const [actionButtonText, setActionButtonText] = useState("");
    const { data, isForAction, isViewOnly, isForUpdate, is_draft } = location.state;
    const [isCreating, setIsCreating] = useState(false);
    const [isCheckRequired, setIsCheckRequired] = useState(false);
    const [growerData, setGrowerDetails] = useState<any>([{}]);
    const [hasDraft, setHasDraft] = useState(false);

    useEffect(() => {
        (async () => {
            if (userData && userData.id) {
                const userDraft = (await dispatch(getFoodDamageDraftByUser({
                    id: foodDamageDetails.draft_id,
                    created_by_user: userData.id
                }))).payload;
                setHasDraft(userDraft);
            }
        })();
    }, [userData, foodDamageDetails.draft_id]);

    useEffect(() => {
        if ((isForAction || isViewOnly) && internalWorkflowsList.length) {
            setFoodDamageDetails(data);
            const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === data.stage);
            setActionButtonText(selectedWorkFlow?.title);
        } else if (isForUpdate) {
            setFoodDamageDetails(data);
            setActionButtonText("Update Information");
        } else {
            const obj = {
                division: userData?.division?.division_name,
                divisionId: userData?.division?.id,
                district: userData?.district?.district_name,
                districtId: userData?.district?.id,
            };
            setFoodDamageDetails(prevState => ({ ...prevState, ...obj }));
        }
        dispatch(fetchAllAgricultureTypes());
        if (data?.food_damage) {
            dispatch(fetchAllGrowerDetails({
                page: 1,
                pageSize: 1000000,
                "filter": {
                    "food_damage": {
                        "id": {
                            "eq": data.food_damage
                        }
                    }
                }
            }));
        }
    }, [userData, internalWorkflowsList]);

    useEffect(() => {
        const growerData = alignGrowerDetailData(growerDetails);
        console.log("growerDetails", growerDetails);

        setGrowerDetails(growerData);
    }, [growerDetails]);

    useEffect(() => {
        dispatch(fetchTehsilsData(foodDamageDetails?.district));
    }, [dispatch, foodDamageDetails?.district]);

    useEffect(() => {
        dispatch(fetchUnionCouncilData(foodDamageDetails?.tehsil));
    }, [dispatch, foodDamageDetails?.tehsil]);

    const divisionData = divisionList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.division_name,
        label: item.attributes.division_name,
    })) || [];

    const districtData = allDistrictList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.district_name,
        label: item.attributes.district_name,
    })) || [];

    const tehsilData = tehsilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.tehsil_name,
        label: item.attributes.tehsil_name,
    })) || [];

    const unionCouncilListData = unionCouncilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.union_council_name,
        label: item.attributes.union_council_name,
    })) || [];

    const eventListData = eventList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const agricultureTypeListData = agricultureTypeList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const onSave = async () => {
        try {
            if (hasDraft) {
                throw new Error("You already have a draft in progress or pending approval.");
            } else {
                const obj: any = {
                    "divisionId": foodDamageDetails.divisionId,
                    "districtId": foodDamageDetails.districtId,
                    "agriculture_typeId": foodDamageDetails.agriculture_typeId,
                    "eventId": foodDamageDetails.eventId,
                    "acre": foodDamageDetails.acre,
                    "cost": foodDamageDetails.cost ? Number(foodDamageDetails.cost) : null,
                    "latitude": foodDamageDetails.latitude ? Number(foodDamageDetails.latitude) : null,
                    "longitude": foodDamageDetails.longitude ? Number(foodDamageDetails.longitude) : null,
                    "stage": 1,
                    "is_draft": false,
                    "created_by_user": userData.id,
                };
                if (!validateObject(obj)) {
                    setIsCheckRequired(true);
                    throw new Error('Please fill the all highlighted fields');
                }
                obj.tehsilId = foodDamageDetails.tehsilId;
                obj.union_councilId = foodDamageDetails.union_councilId;
                obj.address = foodDamageDetails.address;
                obj.food_damage = foodDamageDetails.food_damage;
                if (foodDamageDetails.draft_id) {
                    obj.foodDamageId = foodDamageDetails.id;
                    await updateChild();
                    const res = await dispatch(updateFoodDamageDraftData(obj));
                    if (res.type !== "foodDamage/updateFoodDamageDraftData/rejected") {
                        navigate(-1);
                        toast('success', "food Damage draft update successfully");
                    } else {
                        setIsCreating(false);
                    }
                } else {
                    if (isForUpdate) {
                        await updateChild();
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createFoodDamageData(obj));
                        if (res.type !== "foodDamage/createFoodDamageData/rejected") {
                            navigate(-1);
                            toast('success', "Food Damage update successfully submitted for approval. It will be published after approval.");
                        }
                        // const res = await dispatch(updateFoodDamageData(obj));
                        // if (res.type !== "foodDamage/updateFoodDamageData/rejected") {
                        //     navigate(-1);
                        //     toast('success', "Food Damage update successfully");
                        // }
                    } else {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createFoodDamageData(obj));
                        if (res.type !== "foodDamage/createFoodDamageData/rejected") {
                            navigate(-1);
                            toast('success', "Food Damage created successfully submitted for approval. It will be published after approval.");
                        }
                    }
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    const updateChild = async () => {
        for (let i = 0; i < growerData.length; i++) {
            const element = growerData[i];
            const growerObj = {
                food_damageId: foodDamageDetails.id,
                name: element.name,
                address: element.address,
                "cnic": element.cnic ? Number(element.cnic) : null,
                "mobile_number": element.mobile_number ? Number(element.mobile_number) : null,
                "acre": element.acre ? Number(element.acre) : null,
            };
            if (Object.values(growerObj).some(item => item)) {
                if (element.id) {
                    await dispatch(updateGrowerData({ ...growerObj, grower_detail_id: element.id }));
                } else {
                    await dispatch(createGrowerData(growerObj));
                }
            }
        }
    };

    const onAction = async () => {
        const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === foodDamageDetails.stage);
        let smallestStageNumber = internalWorkflowsList.reduce((min: any, record: any) => record.stage < min && record.stage > foodDamageDetails.stage ? record.stage : min, Infinity);
        const res = await dispatch(updateFoodDamageStageData({
            id: foodDamageDetails.id,
            stage: (!smallestStageNumber || smallestStageNumber == Infinity) ? null : smallestStageNumber,
            record: foodDamageDetails
        }));
        if (res.type !== "foodDamage/updateFoodDamageStageData/rejected") {
            navigate(-1);
            toast("success", `${(!smallestStageNumber || smallestStageNumber == Infinity) ? "Publish" : selectedWorkFlow.title} successfully`);
        }
    };

    const growerDetailsColumn = [
        {
            title: 'Grower Name',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Grower Name'
                disabled={isViewOnly}
                onChange={({ target }) => setGrowerDetails((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].name = target.value;
                    return newState;
                })}
                value={record.name}
                className='input'
            />,
        },
        {
            title: 'Grower CNIC',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter CNIC No.'
                disabled={isViewOnly}
                onChange={({ target }) => setGrowerDetails((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].cnic = target.value;
                    return newState;
                })}
                value={record.cnic}
                className='input'
            />,
        },
        {
            title: 'Grower Address',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Address Here'
                disabled={isViewOnly}
                onChange={({ target }) => setGrowerDetails((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].address = target.value;
                    return newState;
                })}
                value={record.address}
                className='input'
            />,
        },
        {
            title: 'Grower Mobile Number',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Mobile no.'
                disabled={isViewOnly}
                onChange={({ target }) => setGrowerDetails((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].mobile_number = target.value;
                    return newState;
                })}
                value={record.mobile_number}
                className='input'
            />,
        },
        {
            title: 'Acre',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Acre'
                disabled={isViewOnly}
                onChange={({ target }) => setGrowerDetails((prevState: any) => {
                    const newState = [...prevState];
                    const newTotalAcre = prevState.reduce((sum: any, item: any, idx: any) =>
                        sum + (idx === index ? parseFloat(target.value) : parseFloat(item.acre) || 0)
                        , 0);

                    if (newTotalAcre > Number(foodDamageDetails.acre)) {
                        toast("error", `Cannot exceed the acre limit of ${Number(foodDamageDetails.acre)}.`);
                        return prevState;
                    }

                    newState[index].acre = target.value;
                    return newState;
                })}
                value={record.acre}
                className='input'
            />,
        },
        {
            title: "Delete",
            render: (a: any, b: any, index: number) => (
                <Button
                    type="primary"
                    title='Delete'
                    style={{ background: '#357A38' }}
                    className='m-v-5'
                    disabled={isViewOnly}
                    icon={<DeleteOutlined />}
                    onClick={() => deleteData(index, a.id)}
                    shape='circle'
                />
            ),
        }
    ];

    const deleteData = (index: number, id: String) => {
        if (id) {
            dispatch(deleteGrowerData(id));
        } else {
            setGrowerDetails((prevState: any) => {
                const newState = [...prevState];
                newState.splice(index, 1);
                return newState;
            });
        }
    };

    const onSaveAsDraft = async () => {
        try {
            if (isCreating) return;
            setIsCreating(true);
            await updateChild();
            const obj: any = {
                "divisionId": foodDamageDetails.divisionId,
                "districtId": foodDamageDetails.districtId,
                "agriculture_typeId": foodDamageDetails.agriculture_typeId,
                "eventId": foodDamageDetails.eventId,
                "acre": foodDamageDetails.acre,
                "cost": foodDamageDetails.cost ? Number(foodDamageDetails.cost) : null,
                "latitude": foodDamageDetails.latitude ? Number(foodDamageDetails.latitude) : null,
                "longitude": foodDamageDetails.longitude ? Number(foodDamageDetails.longitude) : null,
                "tehsilId": foodDamageDetails.tehsilId,
                "union_councilId": foodDamageDetails.union_councilId,
                "address": foodDamageDetails.address,
                "stage": null,
                "is_draft": true,
                "created_by_user": userData.id,
            };
            if (foodDamageDetails.draft_id) {
                obj.food_damage = foodDamageDetails.food_damage;
                obj.foodDamageId = data.draft_id;
                const res = await dispatch(updateFoodDamageDraftData(obj));
                if (res.type !== "foodDamage/updateFoodDamageDraftData/rejected") {
                    navigate(-1);
                    toast('success', "food damage draft update successfully");
                } else {
                    setIsCreating(false);
                }
            } else {
                obj.food_damage = foodDamageDetails.food_damage;
                const res = await dispatch(createFoodDamageData(obj));
                if (res.type !== "foodDamage/createFoodDamageData/rejected") {
                    navigate(-1);
                    toast('success', "food damage draft successfully");
                } else {
                    setIsCreating(false);
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    const handleAddMoreData = () => {
        // Calculate total restored and damaged values
        const totalAcre = growerData.reduce((sum: any, item: any) => sum + (parseFloat(item.acre) || 0), 0);

        // Check if adding another record would exceed the limit
        if (totalAcre >= Number(foodDamageDetails.acre)) {
            toast('error', `Cannot add more records. The total 'Acre' value cannot exceed ${Number(foodDamageDetails.acre)}.`);
            return;
        }

        setGrowerDetails((prevState: any) => ([...prevState, {}]));
    };

    console.log("is_draft", is_draft);
    console.log("data", data);

    console.log('foodDamageDetails', foodDamageDetails);
    console.log('growerData', growerData);


    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formLossesDamagesLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Losses & Damages</span>
                        </div>
                    </div>
                    {isForAction ? <div className='form-send-btn' onClick={onAction}>
                        <span className='sand-btn-text'>{actionButtonText}</span>
                    </div>
                        : <div>
                            {!hasDraft ? <div className='form-send-btn' onClick={onSaveAsDraft}>
                                <span className='sand-btn-text'>Save as draft</span>
                            </div> : null}
                            <div className='form-send-btn' onClick={onSave}>
                                <span className='sand-btn-text'>Submit</span>
                            </div>
                        </div>}
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <img src={FoodDamagesIcon} alt="" />
                    <span>Food Damage</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Acre</span>
                        <Input
                            onChange={({ target }) => setFoodDamageDetails(prevState => ({ ...prevState, acre: target.value }))}
                            disabled={isViewOnly}
                            placeholder="Enter Acre"
                            status={(isCheckRequired && !foodDamageDetails.acre) ? 'error' : ""}
                            value={foodDamageDetails.acre}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Cost</span>
                        <Input
                            onChange={({ target }) => setFoodDamageDetails(prevState => ({ ...prevState, cost: target.value }))}
                            placeholder="Enter Cost of Damage"
                            value={foodDamageDetails.cost}
                            status={(isCheckRequired && !foodDamageDetails.cost) ? 'error' : ""}
                            disabled={isViewOnly}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Agriculture Type</span>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setFoodDamageDetails(prevState => ({ ...prevState, agriculture_typeId: item.id, agriculture_type: item.value }))}
                            options={agricultureTypeListData}
                            status={(isCheckRequired && !foodDamageDetails.agriculture_typeId) ? 'error' : ""}
                            disabled={isViewOnly}
                            placeholder="Select Agriculture Type"
                            className='input'
                            value={foodDamageDetails.agriculture_type}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Event</span>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setFoodDamageDetails(prevState => ({ ...prevState, eventId: item.id, event: item.value }))}
                            status={(isCheckRequired && !foodDamageDetails.eventId) ? 'error' : ""}
                            options={eventListData}
                            disabled={isViewOnly}
                            placeholder="Select Event"
                            className='input'
                            value={foodDamageDetails.event}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Select
                            value={foodDamageDetails.division || undefined}
                            placeholder="Select the Division"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setFoodDamageDetails(prevState => ({ ...prevState, divisionId: item?.id, division: item?.value }))}
                            disabled
                            status={(isCheckRequired && !foodDamageDetails.divisionId) ? 'error' : ""}
                            options={divisionData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the District"
                            value={foodDamageDetails.district || undefined}
                            disabled
                            status={(isCheckRequired && !foodDamageDetails.districtId) ? 'error' : ""}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setFoodDamageDetails(prevState => ({ ...prevState, districtId: item?.id, district: item?.value }))}
                            options={districtData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Tehsil"
                            style={{ width: '100%' }}
                            disabled={isViewOnly}
                            value={foodDamageDetails.tehsil || undefined}
                            onChange={(value: any, item: any) => setFoodDamageDetails(prevState => ({ ...prevState, tehsilId: item?.id, tehsil: item?.value }))}
                            options={tehsilData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Union Council"
                            disabled={isViewOnly}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setFoodDamageDetails(prevState => ({ ...prevState, union_councilId: item?.id, union_council: item?.value }))}
                            options={unionCouncilListData}
                            className='input'
                            value={foodDamageDetails.union_council || undefined}
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            onChange={({ target }) => setFoodDamageDetails(prevState => ({ ...prevState, address: target.value }))}
                            placeholder="Enter Address"
                            value={foodDamageDetails.address || undefined}
                            status={(isCheckRequired && !foodDamageDetails.address) ? 'error' : ""}
                            disabled={isViewOnly}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            onChange={({ target }) => setFoodDamageDetails(prevState => ({ ...prevState, latitude: target.value }))}
                            placeholder="Enter Latitude"
                            value={foodDamageDetails.latitude}
                            status={(isCheckRequired && !foodDamageDetails.latitude) ? 'error' : ""}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            disabled={isViewOnly}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            onChange={({ target }) => setFoodDamageDetails(prevState => ({ ...prevState, longitude: target.value }))}
                            placeholder="Enter Longitude"
                            status={(isCheckRequired && !foodDamageDetails.longitude) ? 'error' : ""}
                            value={foodDamageDetails.longitude}
                            disabled={isViewOnly}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                </div>
            </div>
            {(isForUpdate || isViewOnly) ? <div className='form-body '>
                <div className='form-bady-heading grower-heading'>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <img src={DetailIcon} alt="" style={{ height: "35px" }} />
                        <span>Grower Details</span>
                    </div>
                    {isForUpdate ? <div className='add-more-button' onClick={handleAddMoreData}>
                        <img style={{ height: "20px" }} src={addIcon} />
                        <p style={{ fontSize: "14px", color: 'white' }}>Add more Data</p>
                    </div> : null}
                </div>
                <div className='form-cont'>
                    <ReusableTable
                        data={growerData}
                        columns={growerDetailsColumn}
                        rowsPerPage={10}
                        total={growerData.length}
                    />
                </div>
            </div> : null}
        </div >
    );
};

export default FoodDamages;


