import React, { useState } from 'react';
import "./index.css";
import { useDispatch } from 'react-redux';
import { login } from "../../reduxToolkit/auth/authSlice";
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components';
import HeadingIcon from '../../assets/images/loginHeadingIcon.png';
import { IoEye, IoEyeOff, IoPerson } from "react-icons/io5";
import { toast } from '../../components/Toast';
import { Button } from 'antd';
import { fetchAllEvents, fetchExternalWorkflows, fetchInternalWorkflows } from '../../reduxToolkit/entitiesSlice';

const Login: React.FC = () => {
    const [values, setValues] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState("");
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const handleChange = (name: any, value: any) => {
        setValues(prevState => ({ ...prevState, [name]: value }));
        if (name === "email") {
            validateEmail(value);
        }
    };

    const validateEmail = (email: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(email)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    // Function to fetch user data using token
    const fetchUserData = async (token: string) => {
        try {
            // Ensure backend URL is available
            if (!process.env.REACT_APP_LOGIN_DEV_URL) {
                throw new Error('Backend URL not provided in environment variables');
            }
            const backendDevUrl = process.env.REACT_APP_LOGIN_DEV_URL;
            const response = await fetch(`${backendDevUrl}users/me?populate=*`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                const errorData = await response.json(); // Try to parse error details
                // Handle specific error messages from the server (if applicable)
                if (errorData.error) {
                    throw new Error(errorData.error.message); // Relay specific error message
                } else {
                    throw new Error(`HTTP error ${response.status}: ${response.statusText}`);
                }
            }
            const userData = await response.json();
            return userData;
        } catch (error) {
            console.error('Error fetching user data:', error);
            return Promise.reject(error); // Explicitly reject the promise
        }
    };

    const handleLogin = async () => {
        const { email, password } = values;
        if (!emailError && email && password) {
            setLoading(true);
            if (!process.env.REACT_APP_LOGIN_DEV_URL) {
                throw new Error('Backend URL not provided in environment variables');
            }
            const backendDevUrl = process.env.REACT_APP_LOGIN_DEV_URL;
            try {
                const response = await fetch(`${backendDevUrl}auth/local`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ identifier: email, password })
                });
                if (!response.ok) {
                    throw new Error("Invalid Email or Password");
                }
                const data = await response.json();
                const user = await fetchUserData(data.jwt);
                dispatch(login({ jwt: data.jwt, user }));
                dispatch(fetchExternalWorkflows(user?.role?.name));
                dispatch(fetchInternalWorkflows(user?.role?.name));
                dispatch(fetchAllEvents(user));
                setLoading(false);
            } catch (error: any) {
                toast('error', error.message);
                setLoading(false);
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    return (
        <div className='loginContainer'>
            <div className='headingCont'>
                <img src={HeadingIcon} alt="Logo2" />
                <span>Kindly Input Credentials to access the Portal.</span>
            </div>
            <div className='labelCont'>
                <span>This is for the Members only!</span>
            </div>
            <div className='inputCont'>
                <input
                    type="email"
                    placeholder='Email*'
                    value={values.email}
                    onChange={({ target }) => handleChange('email', target.value)}
                    name=""
                    autoComplete="new-password"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                />
                {emailError && <span className="error">{emailError}</span>}
                <input
                    type={showPassword ? "text" : "password"}
                    placeholder='Password'
                    value={values.password}
                    onChange={({ target }) => handleChange('password', target.value)}
                    name=""
                    autoComplete="new-password"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                />
                {showPassword ? (
                    <IoEyeOff className='eyeIcon' onClick={togglePasswordVisibility} />
                ) : (
                    <IoEye className='eyeIcon' onClick={togglePasswordVisibility} />
                )}
            </div>
            <div className='loginBtnCont'>
                <Button
                    type="primary"
                    icon={<IoPerson className='personIcon' />}
                    loading={Loading}
                    onClick={handleLogin}
                >
                    Login
                </Button>
            </div>
        </div>
    );
};

export default Login;
