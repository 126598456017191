import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

interface HumanCasualtieState {
    humanCasualtieList: any[];
    humanCasualtieLoading: boolean;
    humanCasualtieType: any[];
    filteredHumanCasualtieList: any[];
    humanCasualtieMeta: any;
    filteredHumanCasualtieCount: number;
    humanCasualtieChartList: any[];
    humanCasualtieCount: number;
    selectedHumanCasualtie: any;
    casualtiesDetails: any[];
    casualtiesDetailsMeta: any;
}

const initialState: HumanCasualtieState = {
    humanCasualtieList: [],
    humanCasualtieLoading: false,
    humanCasualtieType: [],
    filteredHumanCasualtieList: [],
    humanCasualtieMeta: {},
    filteredHumanCasualtieCount: 0,
    humanCasualtieChartList: [],
    humanCasualtieCount: 0,
    selectedHumanCasualtie: {},
    casualtiesDetails: [],
    casualtiesDetailsMeta: {},
};

export const fetchHumanCasualtieCount = createAsyncThunk('humanCasualtie/fetchHumanCasualtieCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedEvent, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                }
            }
        };
        if (selectedEvent) {
            variables.filter.event = {
                name: {
                    "eq": selectedEvent
                }
            };
        }
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiHumanCasualtiesCount : API.ApiHumanCasualtiesCountWithOutChartData,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.humanCasualties.meta.pagination.total, humanCasualtieChartList: response.data.humanCasualties.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchHumanCasualtieData = createAsyncThunk('humanCasualtie/fetchHumanCasualtieData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { selectedEvent, page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil } = pageData;
        const filter: any = {
            stage: {
                null: true
            }
        };
        if (selectedEvent) {
            filter.event = {
                name: {
                    "eq": selectedEvent
                }
            };
        }
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllHumanCasualties,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.humanCasualties.data, meta: response.data.humanCasualties.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFilterdHumanCasualtieData = createAsyncThunk('humanCasualtie/fetchFilterdHumanCasualtieData', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await client.query({ query: API.ApiHumanCasualties, variables, context: { headers }, fetchPolicy: 'no-cache' });
        return { data: response.data.humanCasualtieDrafts.data, filteredHumanCasualtieCount: response.data.humanCasualtieDrafts.meta.pagination.total };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchHumanCasualtieDataById = createAsyncThunk('humanCasualtie/fetchHumanCasualtieDataById', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetHumanCasualtieById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.humanCasualtie.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchHumanCasualtieTypeData = createAsyncThunk('humanCasualtie/fetchHumanCasualtieTypeData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiCasualtyTypes, fetchPolicy: 'no-cache' });
        return response.data.casualtyTypes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllCasualtiesDetails = createAsyncThunk('humanCasualtie/fetchAllCasualtiesDetails', async (variables: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetAllCasualtiesDetails, variables, fetchPolicy: 'no-cache' });
        return { data: response.data.casualtiesDetails.data, meta: response.data.casualtiesDetails.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createHumanCasualtieData = createAsyncThunk('humanCasualtie/createHumanCasualtieData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateHumanCasualtie,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createHumanCasualtieDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createCasualtiesDetail = createAsyncThunk('humanCasualtie/createCasualtiesDetail', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateCasualtiesDetail,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createCasualtiesDetail.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateHumanCasualtieDraftData = createAsyncThunk('humanCasualtie/updateHumanCasualtieDraftData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingHumanCasualtieDraft,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateHumanCasualtieDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateHumanCasualtieStageData = createAsyncThunk('humanCasualtie/updateHumanCasualtieStageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { stage, id, record } = data;
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        if (stage) {
            await client.mutate({
                mutation: API.ApiUpdateHumanCasualtieStage,
                variables: {
                    id: id,
                    stage: stage
                },
                context: {
                    headers,
                },
            });
        } else {
            const obj = {
                "divisionId": record.divisionId,
                "districtId": record.districtId,
                "casualty_typeId": record.casualty_typeId,
                "eventId": record.eventId,
                "latitude": record.latitude ? Number(record.latitude) : null,
                "longitude": record.longitude ? Number(record.longitude) : null,
                "quantity": record.quantity ? Number(record.quantity) : null,
                "stage": null,
                "tehsilId": record.tehsilId,
                "union_councilId": record.union_councilId,
                "address": record.address,
                "humanCasualtieId": record.human_casualtie
            };
            await client.mutate({
                mutation: obj.humanCasualtieId ? API.ApiUpdateExistingHumanCasualtie : API.ApiCreatePublishedHumanCasualtie,
                variables: obj,
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
            await client.mutate({
                mutation: API.ApiDeleteHumanCasualtieDraft,
                variables: { id },
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
        }
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteHumanCasualtieData = createAsyncThunk('humanCasualtie/deleteHumanCasualtieData', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteHumanCasualtie,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateHumanCasualtieData = createAsyncThunk('humanCasualtie/updateHumanCasualtieData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingHumanCasualtie, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateHumanCasualtie.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateCasualtiesDetail = createAsyncThunk('humanCasualtie/updateCasualtiesDetail', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingCasualtiesDetail, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateCasualtiesDetail.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateHumanCasualtieRecordFromSocket = createAsyncThunk('humanCasualtie/addAndUpdateHumanCasualtieRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, internalWorkflowsList } = data;
        const response = await client.query({ query: API.ApiGetHumanCasualtieById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.humanCasualtie.data, internalWorkflowsList };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteHumanCasualtieRecordFromSocket = createAsyncThunk('humanCasualtie/deleteHumanCasualtieRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteHumanCasualtieTypeRecordFromSocket = createAsyncThunk('humanCasualtie/deleteHumanCasualtieTypeRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateHumanCasualtieTypeRecordFromSocket = createAsyncThunk('humanCasualtie/addAndUpdateHumanCasualtieTypeRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetCasualtyTypeById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.casualtyType.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteCasualtiesDetail = createAsyncThunk('humanCasualtie/deleteCasualtiesDetail', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteCasualtiesDetail,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const getHumanCasualtieDraftByUser = createAsyncThunk('humanCasualtie/getHumanCasualtieDraftByUser', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.APIGetHumanCasualtieDraftByUser, // Assuming you have an API constant for update mutation
            variables,
            context: {
                headers,
            },
        });
        if (response.data && response.data.humanCasualtieDrafts.data.length > 0) {
            return true;
        }
        return false;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const humanCasualtieSlice = createSlice({
    name: 'humanCasualtie',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchHumanCasualtieCount.fulfilled, (state, action) => {
                state.humanCasualtieCount = action.payload.count;
                state.humanCasualtieChartList = action.payload.humanCasualtieChartList;
                state.humanCasualtieLoading = false;
            })
            .addCase(fetchHumanCasualtieData.fulfilled, (state, action) => {
                state.humanCasualtieList = action.payload.data;
                state.humanCasualtieMeta = action.payload.meta;
                state.humanCasualtieLoading = false;
            })
            .addCase(fetchFilterdHumanCasualtieData.fulfilled, (state, action) => {
                state.filteredHumanCasualtieList = action.payload.data;
                state.filteredHumanCasualtieCount = action.payload.filteredHumanCasualtieCount;
                state.humanCasualtieLoading = false;
            })
            .addCase(fetchHumanCasualtieDataById.fulfilled, (state, action) => {
                state.selectedHumanCasualtie = action.payload;
                state.humanCasualtieLoading = false;
            })
            .addCase(fetchHumanCasualtieTypeData.fulfilled, (state, action) => {
                state.humanCasualtieType = action.payload;
            })
            .addCase(fetchAllCasualtiesDetails.fulfilled, (state, action) => {
                state.casualtiesDetails = action.payload.data; // Since action.payload is already an array of District
                state.casualtiesDetailsMeta = action.payload.meta; // Since action.payload is already an array of District
                state.humanCasualtieLoading = false;
            })
            .addCase(createHumanCasualtieData.fulfilled, (state, action) => {
                // state.humanCasualtieList.push(action.payload);
                state.humanCasualtieLoading = false;
            })
            .addCase(updateHumanCasualtieDraftData.fulfilled, (state, action) => {
                state.humanCasualtieLoading = false;
            })
            .addCase(createCasualtiesDetail.fulfilled, (state, action) => {
                state.casualtiesDetails.push(action.payload);
                state.humanCasualtieLoading = false;
            })
            .addCase(updateHumanCasualtieStageData.fulfilled, (state, action) => {
                const index = state.filteredHumanCasualtieList.findIndex(item => item.id === action.payload);
                state.filteredHumanCasualtieList.splice(index, 1);
                state.humanCasualtieLoading = false;
            })
            .addCase(deleteHumanCasualtieData.fulfilled, (state, action) => {
                state.humanCasualtieLoading = false;
            })
            .addCase(getHumanCasualtieDraftByUser.fulfilled, (state, action) => {
                state.humanCasualtieLoading = false;
            })
            .addCase(deleteCasualtiesDetail.fulfilled, (state, action) => {
                const index = state.casualtiesDetails.findIndex(item => item.id === action.payload);
                state.casualtiesDetails.splice(index, 1);
                state.humanCasualtieLoading = false;
            })
            .addCase(updateHumanCasualtieData.fulfilled, (state, action) => {
                const index = state.filteredHumanCasualtieList.findIndex(item => item.id === action.payload);
                state.filteredHumanCasualtieList.splice(index, 1);
                state.humanCasualtieLoading = false;
            })
            .addCase(updateCasualtiesDetail.fulfilled, (state, action) => {
                const index = state.casualtiesDetails.findIndex(item => item.id === action.payload.id);
                index !== -1 ? state.casualtiesDetails[index] = action.payload : state.casualtiesDetails.push(action.payload);
                state.humanCasualtieLoading = false;
            })
            .addCase(addAndUpdateHumanCasualtieRecordFromSocket.fulfilled, (state, action) => {
                const internalWorkflowsList = action.payload.internalWorkflowsList;
                const { id, attributes } = action.payload.data;
                if (attributes.stage) {
                    const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === attributes.stage);
                    const index = state.filteredHumanCasualtieList.findIndex(item => item.id == id);
                    if (selectedWorkFlow) {
                        index !== -1 ? state.filteredHumanCasualtieList[index] = action.payload.data : state.filteredHumanCasualtieList.push(action.payload.data);
                    } else {
                        state.filteredHumanCasualtieList.splice(index, 1);
                    }
                } else {
                    const index = state.humanCasualtieList.findIndex(item => item.id == id);
                    index !== -1 ? state.humanCasualtieList[index] = action.payload.data : state.humanCasualtieList.push(action.payload.data);
                }
                if (state.selectedHumanCasualtie.id === id) {
                    state.selectedHumanCasualtie = action.payload.data;
                }
            })
            .addCase(deleteHumanCasualtieRecordFromSocket.fulfilled, (state, action) => {
                state.humanCasualtieList = state.humanCasualtieList.filter(item => item.id != action.payload);
                state.filteredHumanCasualtieList = state.filteredHumanCasualtieList.filter(item => item.id != action.payload);
            })
            .addCase(addAndUpdateHumanCasualtieTypeRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.humanCasualtieType.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.humanCasualtieType];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.humanCasualtieType = updatedArray;
            })
            .addCase(deleteHumanCasualtieTypeRecordFromSocket.fulfilled, (state, action) => {
                state.humanCasualtieType = state.humanCasualtieType.filter(item => item.id != action.payload);
            });
        [
            fetchHumanCasualtieCount,
            fetchHumanCasualtieData,
            fetchFilterdHumanCasualtieData,
            updateHumanCasualtieStageData,
            createHumanCasualtieData,
            deleteHumanCasualtieData,
            updateHumanCasualtieData,
            fetchHumanCasualtieDataById,
            fetchAllCasualtiesDetails,
            createCasualtiesDetail,
            updateCasualtiesDetail,
            deleteCasualtiesDetail,
            updateHumanCasualtieDraftData,
            getHumanCasualtieDraftByUser
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.humanCasualtieLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.humanCasualtieLoading = false;
                });
        });
    }
});


export default humanCasualtieSlice.reducer;
