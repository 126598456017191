import React, { useEffect, useState } from 'react';
import './index.css'; // Import CSS for styling
import { FaArrowLeft } from "react-icons/fa6";
import FinancialIcon from '../../assets/images/FinancialIcon.png';
import formReliefDistributionLogo from '../../assets/images/formReliefDistributionLogo.png';
import { Input } from 'antd';
import { Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { toast } from '../../components/Toast';
import { createFinancialReliefData, getFinancialReliefDraftByUser, updateFinancialReliefData, updateFinancialReliefDraftData, updateFinancialReliefStageData } from '../../reduxToolkit/financialReliefSlice';
import { fetchTehsilsData, fetchUnionCouncilData } from '../../reduxToolkit/entitiesSlice';
import { validateObject } from '../../helpers/common';

const Contributors = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { allDistrictList, divisionList, tehsilList, unionCouncilList, eventList, internalWorkflowsList } = useSelector((state: RootState) => state.entities);
    const { userData } = useSelector((state: RootState) => state.auth);
    const [contributorsDetails, setContributorsDetails] = useState<{ [key: string]: any; }>({});
    const [actionButtonText, setActionButtonText] = useState("");
    const { data, isForAction, isViewOnly, isForUpdate, is_draft } = location.state;
    const [isCreating, setIsCreating] = useState(false);
    const [isCheckRequired, setIsCheckRequired] = useState(false);
    const [hasDraft, setHasDraft] = useState(false);

    useEffect(() => {
        (async () => {
            if (userData && userData.id) {
                const userDraft = (await dispatch(getFinancialReliefDraftByUser({
                    id: contributorsDetails.draft_id,
                    created_by_user: userData.id
                }))).payload;
                setHasDraft(userDraft);
            }
        })();
    }, [userData, contributorsDetails.draft_id]);

    useEffect(() => {
        if ((isForAction || isViewOnly) && internalWorkflowsList.length) {
            setContributorsDetails(data);
            const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === data.stage);
            setActionButtonText(selectedWorkFlow?.title);
        } else if (isForUpdate) {
            setContributorsDetails(data);
            setActionButtonText("Update Information");
        } else {
            const obj = {
                division: userData?.division?.division_name,
                divisionId: userData?.division?.id,
                district: userData?.district?.district_name,
                districtId: userData?.district?.id,
            };
            setContributorsDetails(prevState => ({ ...prevState, ...obj }));
        }
    }, [userData, internalWorkflowsList]);

    useEffect(() => {
        dispatch(fetchTehsilsData(contributorsDetails?.district));
    }, [dispatch, contributorsDetails?.district]);

    useEffect(() => {
        dispatch(fetchUnionCouncilData(contributorsDetails?.tehsil));
    }, [dispatch, contributorsDetails?.tehsil]);

    const divisionData = divisionList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.division_name,
        label: item.attributes.division_name,
    })) || [];

    const districtData = allDistrictList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.district_name,
        label: item.attributes.district_name,
    })) || [];

    const tehsilData = tehsilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.tehsil_name,
        label: item.attributes.tehsil_name,
    })) || [];

    const unionCouncilListData = unionCouncilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.union_council_name,
        label: item.attributes.union_council_name,
    })) || [];

    const eventListData = eventList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    useEffect(() => {
        const received = parseFloat(contributorsDetails.amount_received_in_pkr) || 0;
        const consumed = parseFloat(contributorsDetails.amount_consumed_in_pkr) || 0;
        const newConsumed = consumed > received ? received : consumed;
        const newOnHand = received - newConsumed;

        setContributorsDetails(prevState => ({
            ...prevState,
            amount_on_hand_in_pkr: newOnHand.toString()
        }));
    }, [contributorsDetails.amount_received_in_pkr, contributorsDetails.amount_consumed_in_pkr]);

    const onSave = async () => {
        try {
            if (hasDraft) {
                throw new Error("You already have a draft in progress or pending approval.");
            } else {
                const obj: any = {
                    "divisionId": contributorsDetails.divisionId,
                    "districtId": contributorsDetails.districtId,
                    "eventId": contributorsDetails.eventId,
                    "contributor": contributorsDetails.contributor,
                    "amount_received_in_pkr": (contributorsDetails.amount_received_in_pkr && contributorsDetails.amount_received_in_pkr !== "0") ? Number(contributorsDetails.amount_received_in_pkr) : null,
                    "amount_consumed_in_pkr": (contributorsDetails.amount_consumed_in_pkr && contributorsDetails.amount_consumed_in_pkr !== "0") ? Number(contributorsDetails.amount_consumed_in_pkr) : null,
                    "stage": 1,
                    "is_draft": false,
                    "created_by_user": userData.id,
                };
                if (!validateObject(obj)) {
                    setIsCheckRequired(true);
                    throw new Error('Please fill the all highlighted fields');
                }
                obj.amount_on_hand_in_pkr = (contributorsDetails.amount_on_hand_in_pkr && contributorsDetails.amount_on_hand_in_pkr !== "0") ? Number(contributorsDetails.amount_on_hand_in_pkr) : null
                obj.tehsilId = contributorsDetails.tehsilId;
                obj.union_councilId = contributorsDetails.union_councilId;
                obj.financial_relief = contributorsDetails.financial_relief;
                if (contributorsDetails.draft_id) {
                    obj.financialReliefId = contributorsDetails.id;
                    const res = await dispatch(updateFinancialReliefDraftData(obj));
                    if (res.type !== "financialRelief/updateFinancialReliefDraftData/rejected") {
                        navigate(-1);
                        toast('success', "Financial Relief draft update successfully");
                    } else {
                        setIsCreating(false);
                    }
                } else {
                    if (isForUpdate) {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createFinancialReliefData(obj));
                        if (res.type !== "financialRelief/createFinancialReliefData/rejected") {
                            navigate(-1);
                            toast('success', "Contributor update successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                        // const res = await dispatch(updateFinancialReliefData(obj));
                        // if (res.type !== "financialRelief/updateFinancialReliefData/rejected") {
                        //     navigate(-1);
                        //     toast('success', "Contributor update successfully");
                        // }
                    } else {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createFinancialReliefData(obj));
                        if (res.type !== "financialRelief/createFinancialReliefData/rejected") {
                            navigate(-1);
                            toast('success', "Contributor created successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                    }
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    const onAction = async () => {
        const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === contributorsDetails.stage);
        let smallestStageNumber = internalWorkflowsList.reduce((min: any, record: any) => record.stage < min && record.stage > contributorsDetails.stage ? record.stage : min, Infinity);
        const res = await dispatch(updateFinancialReliefStageData({
            id: contributorsDetails.id,
            stage: (!smallestStageNumber || smallestStageNumber == Infinity) ? null : smallestStageNumber,
            record: contributorsDetails
        }));
        if (res.type !== "financialRelief/updateFinancialReliefStageData/rejected") {
            navigate(-1);
            toast("success", `${(!smallestStageNumber || smallestStageNumber == Infinity) ? "Publish" : selectedWorkFlow.title} successfully`);
        }
    };

    const onSaveAsDraft = async () => {
        try {
            if (isCreating) return;
            setIsCreating(true);
            const obj: any = {
                "divisionId": contributorsDetails.divisionId,
                "districtId": contributorsDetails.districtId,
                "eventId": contributorsDetails.eventId,
                "contributor": contributorsDetails.contributor,
                "amount_received_in_pkr": (contributorsDetails.amount_received_in_pkr && contributorsDetails.amount_received_in_pkr !== "0") ? Number(contributorsDetails.amount_received_in_pkr) : null,
                "amount_consumed_in_pkr": (contributorsDetails.amount_consumed_in_pkr && contributorsDetails.amount_consumed_in_pkr !== "0") ? Number(contributorsDetails.amount_consumed_in_pkr) : null,
                "amount_on_hand_in_pkr": (contributorsDetails.amount_on_hand_in_pkr && contributorsDetails.amount_on_hand_in_pkr !== "0") ? Number(contributorsDetails.amount_on_hand_in_pkr) : null,
                "tehsilId": contributorsDetails.tehsilId,
                "union_councilId": contributorsDetails.union_councilId,
                "address": contributorsDetails.address,
                "stage": null,
                "is_draft": true,
                "created_by_user": userData.id,
            };
            if (contributorsDetails.draft_id) {
                obj.financial_relief = contributorsDetails.financial_relief;
                obj.financialReliefId = data.draft_id;
                const res = await dispatch(updateFinancialReliefDraftData(obj));
                if (res.type !== "financialRelief/updateFinancialReliefDraftData/rejected") {
                    navigate(-1);
                    toast('success', "financial Relief draft update successfully");
                } else {
                    setIsCreating(false);
                }
            } else {
                obj.financial_relief = contributorsDetails.financial_relief;
                const res = await dispatch(createFinancialReliefData(obj));
                if (res.type !== "financialRelief/createFinancialReliefData/rejected") {
                    navigate(-1);
                    toast('success', "financial Relief draft successfully");
                } else {
                    setIsCreating(false);
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    console.log("is_draft", is_draft);
    console.log("data", data);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formReliefDistributionLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Relief Distribution</span>
                        </div>
                    </div>
                    {isForAction ? <div className='form-send-btn' onClick={onAction}>
                        <span className='sand-btn-text'>{actionButtonText}</span>
                    </div>
                        : <div>
                            {!hasDraft ? <div className='form-send-btn' onClick={onSaveAsDraft}>
                                <span className='sand-btn-text'>Save as draft</span>
                            </div> : null}
                            <div className='form-send-btn' onClick={onSave}>
                                <span className='sand-btn-text'>Submit</span>
                            </div>
                        </div>}
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <img src={FinancialIcon} alt="" />
                    <span>Contributors relief</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Contributor</span>
                        <Input
                            onChange={({ target }) => setContributorsDetails(prevState => ({ ...prevState, contributor: target.value }))}
                            placeholder="Contributor Name"
                            status={(isCheckRequired && !contributorsDetails.contributor) ? 'error' : ""}
                            disabled={isViewOnly}
                            value={contributorsDetails.contributor}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Amount Received in PKR</span>
                        <Input
                            status={(isCheckRequired && (!contributorsDetails.amount_received_in_pkr || contributorsDetails.amount_received_in_pkr == "0")) ? 'error' : ""}
                            onChange={({ target }) => setContributorsDetails(prevState => ({ ...prevState, amount_received_in_pkr: target.value }))}
                            placeholder="Enter Amount Received"
                            value={contributorsDetails.amount_received_in_pkr}
                            className='input'
                            disabled={isViewOnly}
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Amount Consumed in PKR</span>
                        <Input
                            status={(isCheckRequired && (!contributorsDetails.amount_consumed_in_pkr || contributorsDetails.amount_consumed_in_pkr == "0")) ? 'error' : ""}
                            onChange={({ target }) => setContributorsDetails(prevState => ({ ...prevState, amount_consumed_in_pkr: target.value }))}
                            placeholder="Enter Amount Consumed"
                            disabled={isViewOnly}
                            value={contributorsDetails.amount_consumed_in_pkr}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Amount in Hand in PKR</span>
                        <Input
                            disabled
                            placeholder="Enter Amount in Hand"
                            value={contributorsDetails.amount_on_hand_in_pkr}
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Event</span>
                        <Select
                            style={{ width: '100%' }}
                            disabled={isViewOnly}
                            status={(isCheckRequired && !contributorsDetails.eventId) ? 'error' : ""}
                            onChange={(value: any, item: any) => setContributorsDetails(prevState => ({ ...prevState, eventId: item.id, event: item.value }))}
                            options={eventListData}
                            placeholder="Select Event"
                            className='input'
                            value={contributorsDetails.event}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Select
                            value={contributorsDetails.division || undefined}
                            placeholder="Select the Division"
                            disabled
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setContributorsDetails(prevState => ({ ...prevState, divisionId: item?.id, division: item?.value }))}
                            options={divisionData}
                            status={(isCheckRequired && !contributorsDetails.divisionId) ? 'error' : ""}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the District"
                            disabled
                            value={contributorsDetails.district || undefined}
                            status={(isCheckRequired && !contributorsDetails.districtId) ? 'error' : ""}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setContributorsDetails(prevState => ({ ...prevState, districtId: item?.id, district: item?.value }))}
                            options={districtData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Tehsil"
                            style={{ width: '100%' }}
                            disabled={isViewOnly}
                            value={contributorsDetails.tehsil || undefined}
                            onChange={(value: any, item: any) => setContributorsDetails(prevState => ({ ...prevState, tehsilId: item?.id, tehsil: item?.value }))}
                            options={tehsilData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Union Council"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setContributorsDetails(prevState => ({ ...prevState, union_councilId: item?.id, union_council: item?.value }))}
                            options={unionCouncilListData}
                            className='input'
                            disabled={isViewOnly}
                            value={contributorsDetails.union_council || undefined}
                            allowClear
                        />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Contributors;
