import React from 'react';
import { numberFormater } from '../../helpers/common';
import './style.css';

const CardComponent = (props: any) => {
    const {
        title,
        icon,
        count,
        selectedCard,
        cardNumber,
        onChangeCard
    } = props;
    return (
        <div className={`card ${selectedCard === cardNumber ? "selected-card" : ""}`} onClick={onChangeCard}>
            <img src={icon} alt={cardNumber} />
            <div style={{ fontSize: 'xx-small' }}>
                <h2>{title}</h2>
                <span>{numberFormater(count)}</span>
            </div>
        </div>
    );
};

export default CardComponent;