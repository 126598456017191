import { gql } from "@apollo/client";

export const createNgo = gql`
mutation createNgoDraft(
    $ngo_name: String
    $divisionId: ID
    $districtId: ID
    $tehsilId: ID
    $union_councilId: ID
    $ngo: ID
    $created_by_user: ID
    $postal_address: String
    $primary_areasId: [ID]
    $secondary_areasId: [ID]
    $website: String
    $address: String
    $license_registration_number: String
    $year_established: String
    $organizational_head: String
    $ngo_contact_person_in_district: String
    $ngo_contact_person_mobile_number_in_district: Long
    $ngo_contact_number: Long
    $latitude: Float
    $is_draft: Boolean
    $longitude: Float
    $stage: Int
  ) {
    createNgoDraft(
      data: {
        ngo_name: $ngo_name
        division: $divisionId
        district: $districtId
        tehsil: $tehsilId
        union_council: $union_councilId
        postal_address: $postal_address
        ngo: $ngo
        created_by_user: $created_by_user
        primary_areas: $primary_areasId
        secondary_areas: $secondary_areasId
        website: $website
        address: $address
        is_draft: $is_draft
        license_registration_number: $license_registration_number
        year_established: $year_established
        organizational_head: $organizational_head
        ngo_contact_person_in_district: $ngo_contact_person_in_district
        ngo_contact_person_mobile_number_in_district: $ngo_contact_person_mobile_number_in_district
        ngo_contact_number: $ngo_contact_number
        latitude: $latitude
        longitude: $longitude
        stage: $stage
      }
    ) {
      data {
        id
        attributes {
          ngo_name
        }
      }
    }
  }
`;

export const createPublisedNgo = gql`
mutation createNewNgo(
    $ngo_name: String
    $divisionId: ID
    $districtId: ID
    $tehsilId: ID
    $union_councilId: ID
    $postal_address: String
    $primary_areasId: [ID]
    $secondary_areasId: [ID]
    $website: String
    $address: String
    $license_registration_number: String
    $year_established: String
    $organizational_head: String
    $ngo_contact_person_in_district: String
    $ngo_contact_person_mobile_number_in_district: Long
    $ngo_contact_number: Long
    $latitude: Float
    $longitude: Float
    $stage: Int
  ) {
    createNgo(
      data: {
        ngo_name: $ngo_name
        division: $divisionId
        district: $districtId
        tehsil: $tehsilId
        union_council: $union_councilId
        postal_address: $postal_address
        primary_areas: $primary_areasId
        secondary_areas: $secondary_areasId
        website: $website
        address: $address
        license_registration_number: $license_registration_number
        year_established: $year_established
        organizational_head: $organizational_head
        ngo_contact_person_in_district: $ngo_contact_person_in_district
        ngo_contact_person_mobile_number_in_district: $ngo_contact_person_mobile_number_in_district
        ngo_contact_number: $ngo_contact_number
        latitude: $latitude
        longitude: $longitude
        stage: $stage
      }
    ) {
      data {
        id
        attributes {
          ngo_name
        }
      }
    }
  }
`;

export const createVolunteer = gql`
mutation createVolunteerDraft(
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $volunteer: ID 
  $created_by_user: ID 
  $cnic_number: Long
  $address: String
  $postal_address: String
  $website: String
  $contact_person: String
  $contact_person_mobile: Long
  $latitude: Float
  $longitude: Float
    $is_draft: Boolean
  $stage: Int
) {
  createVolunteerDraft(
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      volunteer: $volunteer
      created_by_user: $created_by_user
      cnic_number: $cnic_number
        is_draft: $is_draft
      address: $address
      postal_address: $postal_address
      website: $website
      contact_person: $contact_person
      contact_person_mobile: $contact_person_mobile
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;
export const createPublishedVolunteer = gql`
mutation createNewVolunteer(
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $cnic_number: Long
  $address: String
  $postal_address: String
  $website: String
  $contact_person: String
  $contact_person_mobile: Long
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createVolunteer(
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      cnic_number: $cnic_number
      address: $address
      postal_address: $postal_address
      website: $website
      contact_person: $contact_person
      contact_person_mobile: $contact_person_mobile
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const createManufacturer = gql`
mutation createManufacturerDraft(
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $manufacturer: ID
  $created_by_user: ID
  $postal_address: String
  $primary_areasId: [ID]
  $secondary_areasId: [ID]
  $website: String
  $address: String
    $is_draft: Boolean
  $registration_number: String
  $year_established: String
  $organizational_head: String
  $contact_person_in_district: String
  $contact_person_mobile_number_in_district: Long
  $contact_number: Long
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createManufacturerDraft(
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      manufacturer: $manufacturer
      created_by_user: $created_by_user
      postal_address: $postal_address
        is_draft: $is_draft
      primary_areas: $primary_areasId
      secondary_areas: $secondary_areasId
      website: $website
      address: $address
      registration_number: $registration_number
      year_established: $year_established
      organizational_head: $organizational_head
      contact_person_in_district: $contact_person_in_district
      contact_person_mobile_number_in_district: $contact_person_mobile_number_in_district
      contact_number: $contact_number
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const createPublishedManufacturer = gql`
mutation createNewManufacturer(
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $postal_address: String
  $primary_areasId: [ID]
  $secondary_areasId: [ID]
  $website: String
  $address: String
  $registration_number: String
  $year_established: String
  $organizational_head: String
  $contact_person_in_district: String
  $contact_person_mobile_number_in_district: Long
  $contact_number: Long
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createManufacturer(
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      postal_address: $postal_address
      primary_areas: $primary_areasId
      secondary_areas: $secondary_areasId
      website: $website
      address: $address
      registration_number: $registration_number
      year_established: $year_established
      organizational_head: $organizational_head
      contact_person_in_district: $contact_person_in_district
      contact_person_mobile_number_in_district: $contact_person_mobile_number_in_district
      contact_number: $contact_number
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const createSupplier = gql`
mutation createSupplierDraft(
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $supplier: ID
  $created_by_user: ID
  $postal_address: String
  $primary_areasId: [ID]
  $secondary_areasId: [ID]
    $is_draft: Boolean
  $website: String
  $address: String
  $registration_number: String
  $year_established: String
  $organizational_head: String
  $contact_person_in_district: String
  $contact_person_mobile_number_in_district: Long
  $contact_number: Long
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createSupplierDraft(
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      supplier: $supplier
      created_by_user: $created_by_user
      postal_address: $postal_address
      primary_areas: $primary_areasId
      secondary_areas: $secondary_areasId
      website: $website
        is_draft: $is_draft
      address: $address
      registration_number: $registration_number
      year_established: $year_established
      organizational_head: $organizational_head
      contact_person_in_district: $contact_person_in_district
      contact_person_mobile_number_in_district: $contact_person_mobile_number_in_district
      contact_number: $contact_number
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const createPublishedSupplier = gql`
mutation createNewSupplier(
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $postal_address: String
  $primary_areasId: [ID]
  $secondary_areasId: [ID]
  $website: String
  $address: String
  $registration_number: String
  $year_established: String
  $organizational_head: String
  $contact_person_in_district: String
  $contact_person_mobile_number_in_district: Long
  $contact_number: Long
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createSupplier(
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      postal_address: $postal_address
      primary_areas: $primary_areasId
      secondary_areas: $secondary_areasId
      website: $website
      address: $address
      registration_number: $registration_number
      year_established: $year_established
      organizational_head: $organizational_head
      contact_person_in_district: $contact_person_in_district
      contact_person_mobile_number_in_district: $contact_person_mobile_number_in_district
      contact_number: $contact_number
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const createInventory = gql`
mutation createInventoryDraft(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $product_typeId: ID
  $product_nameId: ID
  $classId: ID
  $inventory: ID
  $created_by_user: ID
  $product_categoryId: ID
  $product_section: String
  $received_quantity: Long
  $consumed_quantity: Long
  $warehouse_address: String
  $description: String
  $unit_of_measure: String
  $product_code: String
  $product_barcode: String
    $is_draft: Boolean
  $product_qr_code: String
  $product_brand: String
  $product_company: String
  $induction_date: Date
  $warranty_year: String
  $expiry_date: Date
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createInventoryDraft(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      product_type: $product_typeId
      product_name: $product_nameId
        is_draft: $is_draft
      class: $classId
      inventory: $inventory
      created_by_user: $created_by_user
      product_category: $product_categoryId
      product_section: $product_section
      received_quantity: $received_quantity
      consumed_quantity: $consumed_quantity
      warehouse_address: $warehouse_address
      description: $description
      unit_of_measure: $unit_of_measure
      product_code: $product_code
      product_barcode: $product_barcode
      product_qr_code: $product_qr_code
      product_brand: $product_brand
      product_company: $product_company
      induction_date: $induction_date
      warranty_year: $warranty_year
      expiry_date: $expiry_date
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        product_code
      }
    }
  }
}

`;

export const createPublishedInventory = gql`
mutation createNewInventory(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $product_typeId: ID
  $product_nameId: ID
  $classId: ID
  $product_categoryId: ID
  $product_section: String
  $received_quantity: Long
  $consumed_quantity: Long
  $warehouse_address: String
  $description: String
  $unit_of_measure: String
  $product_code: String
  $product_barcode: String
  $product_qr_code: String
  $product_brand: String
  $product_company: String
  $induction_date: Date
  $warranty_year: String
  $expiry_date: Date
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createInventory(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      product_type: $product_typeId
      product_name: $product_nameId
      class: $classId
      product_category: $product_categoryId
      product_section: $product_section
      received_quantity: $received_quantity
      consumed_quantity: $consumed_quantity
      warehouse_address: $warehouse_address
      description: $description
      unit_of_measure: $unit_of_measure
      product_code: $product_code
      product_barcode: $product_barcode
      product_qr_code: $product_qr_code
      product_brand: $product_brand
      product_company: $product_company
      induction_date: $induction_date
      warranty_year: $warranty_year
      expiry_date: $expiry_date
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        product_code
      }
    }
  }
}

`;

export const createFinancialRelief = gql`
mutation createFinancialReliefDraft(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $eventId: ID
  $financial_relief: ID
  $created_by_user: ID
  $contributor: String
  $amount_received_in_pkr: Long
  $amount_consumed_in_pkr: Long
    $is_draft: Boolean
  $amount_on_hand_in_pkr: Long
  $stage: Int
) {
  createFinancialReliefDraft(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      event: $eventId
      financial_relief: $financial_relief
      created_by_user: $created_by_user
        is_draft: $is_draft
      contributor: $contributor
      amount_received_in_pkr: $amount_received_in_pkr
      amount_consumed_in_pkr: $amount_consumed_in_pkr
      amount_on_hand_in_pkr: $amount_on_hand_in_pkr
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        contributor
      }
    }
  }
}
`;

export const createPublishedFinancialRelief = gql`
mutation createNewFinancialRelief(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $eventId: ID
  $contributor: String
  $amount_received_in_pkr: Long
  $amount_consumed_in_pkr: Long
  $amount_on_hand_in_pkr: Long
  $stage: Int
) {
  createFinancialRelief(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      event: $eventId
      contributor: $contributor
      amount_received_in_pkr: $amount_received_in_pkr
      amount_consumed_in_pkr: $amount_consumed_in_pkr
      amount_on_hand_in_pkr: $amount_on_hand_in_pkr
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        contributor
      }
    }
  }
}
`;

export const createCompensatonRelief = gql`
mutation createCompensatonReliefDraft(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
    $is_draft: Boolean
  $compensaton_relief: ID
  $created_by_user: ID
  $full_name: String
  $cnic: Long
  $date_of_birth: Date
  $type: String
  $reason: String
  $address: String
  $mobile_number: Long
  $eventId: ID
  $genderId: ID
  $stage: Int
) {
  createCompensatonReliefDraft(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      full_name: $full_name
      cnic: $cnic
      compensaton_relief: $compensaton_relief
      created_by_user: $created_by_user
      date_of_birth: $date_of_birth
        is_draft: $is_draft
      type: $type
      reason: $reason
      address: $address
      mobile_number: $mobile_number
      event: $eventId
      gender: $genderId
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        full_name
      }
    }
  }
}
`;

export const createPublisedCompensatonRelief = gql`
mutation createNewCompensatonRelief(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $full_name: String
  $cnic: Long
  $date_of_birth: Date
  $type: String
  $reason: String
  $address: String
  $mobile_number: Long
  $eventId: ID
  $genderId: ID
  $stage: Int
) {
  createCompensatonRelief(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      full_name: $full_name
      cnic: $cnic
      date_of_birth: $date_of_birth
      type: $type
      reason: $reason
      address: $address
      mobile_number: $mobile_number
      event: $eventId
      gender: $genderId
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        full_name
      }
    }
  }
}
`;

export const createHumanCasualtie = gql`
mutation createHumanCasualtieDraft(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
    $is_draft: Boolean
  $union_councilId: ID
  $eventId: ID
  $human_casualtie: ID
  $created_by_user: ID
  $casualty_typeId: ID
  $quantity: Long
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createHumanCasualtieDraft(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      event: $eventId
      human_casualtie: $human_casualtie
      created_by_user: $created_by_user
      casualty_type: $casualty_typeId
      quantity: $quantity
        is_draft: $is_draft
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
    }
  }
}

`;
export const createPublishedHumanCasualtie = gql`
mutation createNewHumanCasualtie(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $eventId: ID
  $casualty_typeId: ID
  $quantity: Long
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createHumanCasualtie(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      event: $eventId
      casualty_type: $casualty_typeId
      quantity: $quantity
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
    }
  }
}

`;

export const createInfrastructureDamage = gql`
mutation createInfrastructureDamageDraft(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $infrastructure_damage: ID
  $created_by_user: ID
    $is_draft: Boolean
  $address: String
  $damaged: String
  $restored: String
  $infrastructure_typeId: ID
  $eventId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createInfrastructureDamageDraft(
    data: {
      division: $divisionId
        is_draft: $is_draft
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      infrastructure_damage: $infrastructure_damage
      created_by_user: $created_by_user
      address: $address
      damaged: $damaged
      restored: $restored
      infrastructure_type: $infrastructure_typeId
      event: $eventId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        damaged
      }
    }
  }
}

`;

export const createPublisedInfrastructureDamage = gql`
mutation createNewInfrastructureDamage(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $address: String
  $damaged: String
  $restored: String
  $infrastructure_typeId: ID
  $eventId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createInfrastructureDamage(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      address: $address
      damaged: $damaged
      restored: $restored
      infrastructure_type: $infrastructure_typeId
      event: $eventId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        damaged
      }
    }
  }
}

`;

export const createFoodDamage = gql`
mutation createFoodDamageDraft(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $address: String
  $acre: String
  $cost: Long
  $agriculture_typeId: ID
  $eventId: ID
    $is_draft: Boolean
  $food_damage: ID
  $created_by_user: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createFoodDamageDraft(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      address: $address
      acre: $acre
        is_draft: $is_draft
      cost: $cost
      agriculture_type: $agriculture_typeId
      event: $eventId
      food_damage: $food_damage
      created_by_user: $created_by_user
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        acre
      }
    }
  }
}

`;
export const createPublishedFoodDamage = gql`
mutation createNewFoodDamage(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $address: String
  $acre: String
  $cost: Long
  $agriculture_typeId: ID
  $eventId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createFoodDamage(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      address: $address
      acre: $acre
      cost: $cost
      agriculture_type: $agriculture_typeId
      event: $eventId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        acre
      }
    }
  }
}

`;

export const createLivestockDamage = gql`
mutation createLivestockDamageDraft(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $total_quantity: Long
  $address: String
  $cost: Long
  $livestock_typeId: ID
    $is_draft: Boolean
  $eventId: ID
  $casualty_typeId: ID
  $livestock_damage: ID
  $created_by_user: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createLivestockDamageDraft(
    data: {
      division: $divisionId
      district: $districtId
        is_draft: $is_draft
      tehsil: $tehsilId
      union_council: $union_councilId
      total_quantity: $total_quantity
      address: $address
      cost: $cost
      livestock_type: $livestock_typeId
      event: $eventId
      casualty_type: $casualty_typeId
      livestock_damage: $livestock_damage
      created_by_user: $created_by_user
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        total_quantity
      }
    }
  }
}
`;
export const createPublisedLivestockDamage = gql`
mutation createNewLivestockDamage(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $total_quantity: Long
  $address: String
  $cost: Long
  $livestock_typeId: ID
  $eventId: ID
  $casualty_typeId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createLivestockDamage(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      total_quantity: $total_quantity
      address: $address
      cost: $cost
      livestock_type: $livestock_typeId
      event: $eventId
      casualty_type: $casualty_typeId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        total_quantity
      }
    }
  }
}
`;

export const createGrowerDetail = gql`
mutation createGrowerDetail(
  $food_damageId: ID!
  $name: String
  $cnic: Long
  $address: String
  $mobile_number: Long
  $acre: Long
) {
  createGrowerDetail(
    data: {
      food_damage: $food_damageId
      name: $name
      cnic: $cnic
      address: $address
      mobile_number: $mobile_number
      acre: $acre
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const createLivestockOwnerDetail = gql`
mutation createLivestockOwnerDetail(
  $livestock_damageId: ID!
  $name: String
  $cnic: Long
  $address: String
  $mobile_number: Long
  $quantity: Long
) {
  createLivestockOwnerDetail(
    data: {
      livestock_damage: $livestock_damageId
      name: $name
      cnic: $cnic
      address: $address
      mobile_number: $mobile_number
      quantity: $quantity
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const createInfrastructureOwnerDetail = gql`
mutation createInfrastructureOwnerDetail(
  $infrastructure_damageId: ID!
  $name: String
  $cnic: Long
  $address: String
  $mobile_number: Long
  $damaged: String
  $restored: String
) {
  createInfrastructureOwnerDetail(
    data: {
      infrastructure_damage: $infrastructure_damageId
      name: $name
      cnic: $cnic
      address: $address
      mobile_number: $mobile_number
      damaged: $damaged
      restored: $restored
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const createCasualtiesDetail = gql`
mutation createCasualtiesDetail(
  $human_casualtieId: ID!
  $full_name: String
  $cnic: Long
  $address: String
  $genderId: ID
  $mobile_number: Long
  $date_of_birth: Date
) {
  createCasualtiesDetail(
    data: {
      human_casualtie: $human_casualtieId
      full_name: $full_name
      gender: $genderId
      cnic: $cnic
      address: $address
      mobile_number: $mobile_number
      date_of_birth: $date_of_birth
    }
  ) {
    data {
      id
      attributes {
        full_name
      }
    }
  }
}
`;

export const deleteNgo = gql`
mutation deleteNgo($id: ID!) {
  deleteNgo(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteNgoDraft = gql`
mutation deleteNgoDraft($id: ID!) {
  deleteNgoDraft(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteManufacturer = gql`
mutation deleteManufacturer($id: ID!) {
  deleteManufacturer(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteManufacturerDraft = gql`
mutation deleteManufacturerDraft($id: ID!) {
  deleteManufacturerDraft(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteVolunteer = gql`
mutation deleteVolunteer($id: ID!) {
  deleteVolunteer(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteVolunteerDraft = gql`
mutation deleteVolunteerDraft($id: ID!) {
  deleteVolunteerDraft(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteSupplier = gql`
mutation deleteSupplier($id: ID!) {
  deleteSupplier(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteSupplierDraft = gql`
mutation deleteSupplierDraft($id: ID!) {
  deleteSupplierDraft(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteInventory = gql`
mutation deleteInventory($id: ID!) {
  deleteInventory(id: $id) {
    data {
      id
    }
  }
}
`;
export const deleteInventoryDraft = gql`
mutation deleteInventoryDraft($id: ID!) {
  deleteInventoryDraft(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteFinancialRelief = gql`
mutation deleteFinancialRelief($id: ID!) {
  deleteFinancialRelief(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteFinancialReliefDraft = gql`
mutation deleteFinancialReliefDraft($id: ID!) {
  deleteFinancialReliefDraft(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteCompensatonRelief = gql`
mutation deleteCompensatonRelief($id: ID!) {
  deleteCompensatonRelief(id: $id) {
    data {
      id
    }
  }
}
`;
export const deleteCompensatonReliefDraft = gql`
mutation deleteCompensatonReliefDraft($id: ID!) {
  deleteCompensatonReliefDraft(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteHumanCasualtie = gql`
mutation deleteHumanCasualtie($id: ID!) {
  deleteHumanCasualtie(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteHumanCasualtieDraft = gql`
mutation deleteHumanCasualtieDraft($id: ID!) {
  deleteHumanCasualtieDraft(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteInfrastructureDamage = gql`
mutation deleteInfrastructureDamage($id: ID!) {
  deleteInfrastructureDamage(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteInfrastructureDamageDraft = gql`
mutation deleteInfrastructureDamageDraft($id: ID!) {
  deleteInfrastructureDamageDraft(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteFoodDamage = gql`
mutation deleteFoodDamage($id: ID!) {
  deleteFoodDamage(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteFoodDamageDraft = gql`
mutation deleteFoodDamageDraft($id: ID!) {
  deleteFoodDamageDraft(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteGrowerDetail = gql`
mutation deleteGrowerDetail($id: ID!) {
  deleteGrowerDetail(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteLivestockDamage = gql`
mutation deleteLivestockDamage($id: ID!) {
  deleteLivestockDamage(id: $id) {
    data {
      id
    }
  }
}
`;
export const deleteLivestockDamageDraft = gql`
mutation deleteLivestockDamageDraft($id: ID!) {
  deleteLivestockDamageDraft(id: $id) {
    data {
      id
    }
  }
}
`;

export const createSchool = gql`
mutation createNewSchool(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $semis_code: Long
  $name: String
  $address: String
  $latitude: Float
  $longitude: Float
  $building_condition: String
  $area_type: String
  $number_of_rooms: Int
  $electricity: Boolean
  $toilet: Boolean
  $drinking_water: Boolean
  $boundary_wall: Boolean
) {
  createSchool(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      semis_code: $semis_code
      name: $name
      address: $address
      latitude: $latitude
      longitude: $longitude
      building_condition: $building_condition
      area_type: $area_type
      number_of_rooms: $number_of_rooms
      electricity: $electricity
      toilet: $toilet
      drinking_water: $drinking_water
      boundary_wall: $boundary_wall
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const deleteLivestockOwnerDetail = gql`
mutation deleteLivestockOwnerDetail($id: ID!) {
  deleteLivestockOwnerDetail(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteInfrastructureOwnerDetail = gql`
mutation deleteInfrastructureOwnerDetail($id: ID!) {
  deleteInfrastructureOwnerDetail(id: $id) {
    data {
      id
    }
  }
}
`;

export const deleteCasualtiesDetail = gql`
mutation deleteCasualtiesDetail($id: ID!) {
  deleteCasualtiesDetail(id: $id) {
    data {
      id
    }
  }
}
`;
