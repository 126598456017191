import React, { useEffect, useState } from 'react';
import './index.css'; // Import CSS for styling
import { FaArrowLeft } from "react-icons/fa6";
import formResourceMappingLogo from '../../assets/images/formResourceMappingLogo.png';
import { Input } from 'antd';
import { Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { toast } from '../../components/Toast';
import { checkVolunteerCnicExists, createVolunteerData, getVolunteerDraftByUser, updateVolunteerData, updateVolunteerDraftData, updateVolunteerStageData } from '../../reduxToolkit/volunteerSlice';
import { fetchTehsilsData, fetchUnionCouncilData } from '../../reduxToolkit/entitiesSlice';
import { validateObject } from '../../helpers/common';
import InputMask from 'react-input-mask';

const Forms = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { allDistrictList, divisionList, tehsilList, unionCouncilList, externalWorkflowsList } = useSelector((state: RootState) => state.entities);
    const { userData } = useSelector((state: RootState) => state.auth);
    const [volunteersMapping, setVolunteersMapping] = useState<{ [key: string]: any; }>({});
    const [actionButtonText, setActionButtonText] = useState("");
    const { data, isForAction, isViewOnly, isForUpdate, is_draft } = location.state;
    const [isCreating, setIsCreating] = useState(false);
    const [isCheckRequired, setIsCheckRequired] = useState(false);
    const [hasDraft, setHasDraft] = useState(false);

    useEffect(() => {
        (async () => {
            if (userData && userData.id) {
                const userDraft = (await dispatch(getVolunteerDraftByUser({
                    id: volunteersMapping.draft_id,
                    created_by_user: userData.id
                }))).payload;
                setHasDraft(userDraft);
            }
        })();
    }, [userData, volunteersMapping.draft_id]);

    useEffect(() => {
        if ((isForAction || isViewOnly) && externalWorkflowsList.length) {
            setVolunteersMapping(data);
            const selectedWorkFlow: any = externalWorkflowsList.find((item: any) => item.stage === data.stage);
            setActionButtonText(selectedWorkFlow?.title);
        } else if (isForUpdate) {
            setVolunteersMapping(data);
            setActionButtonText("Update Information");
        } else {
            const obj = {
                division: userData?.division?.division_name,
                divisionId: userData?.division?.id,
                district: userData?.district?.district_name,
                districtId: userData?.district?.id,
            };
            setVolunteersMapping(prevState => ({ ...prevState, ...obj }));
        }
    }, [userData, externalWorkflowsList]);

    useEffect(() => {
        dispatch(fetchTehsilsData(volunteersMapping?.district));
    }, [dispatch, volunteersMapping?.district]);

    useEffect(() => {
        dispatch(fetchUnionCouncilData(volunteersMapping?.tehsil));
    }, [dispatch, volunteersMapping?.tehsil]);

    const divisionData = divisionList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.division_name,
        label: item.attributes.division_name,
    })) || [];

    const districtData = allDistrictList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.district_name,
        label: item.attributes.district_name,
    })) || [];

    const tehsilData = tehsilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.tehsil_name,
        label: item.attributes.tehsil_name,
    })) || [];

    const unionCouncilListData = unionCouncilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.union_council_name,
        label: item.attributes.union_council_name,
    })) || [];

    const onSaveVolunteerMapping = async () => {
        try {
            if (hasDraft) {
                throw new Error("You already have a draft in progress or pending approval.");
            } else {
                const excludeVolunteerDraftId = volunteersMapping.draft_id || null;
                const excludeVolunteerId = volunteersMapping.volunteer || null;

                // Check for duplicate cnic_number in both volunteers and volunteerDrafts
                const { volunteers, volunteerDrafts } = await dispatch(checkVolunteerCnicExists({
                    cnic_number: volunteersMapping.cnic_number,
                    excludeVolunteerDraftId,
                    excludeVolunteerId,
                })).unwrap();

                if (volunteers.length > 0 || volunteerDrafts.length > 0) {
                    setIsCreating(false);
                    toast('error', "CNIC number already exists.");
                    return;
                }
                const obj: any = {
                    "name": volunteersMapping.name,
                    "divisionId": volunteersMapping.divisionId,
                    "districtId": volunteersMapping.districtId,
                    "cnic_number": volunteersMapping.cnic_number ? Number(volunteersMapping.cnic_number) : null,
                    "postal_address": volunteersMapping.postal_address,
                    "website": volunteersMapping.website,
                    "contact_person": volunteersMapping.contact_person,
                    "contact_person_mobile": volunteersMapping.contact_person_mobile ? Number(volunteersMapping.contact_person_mobile) : null,
                    "latitude": volunteersMapping.latitude ? Number(volunteersMapping.latitude) : null,
                    "longitude": volunteersMapping.longitude ? Number(volunteersMapping.longitude) : null,
                    "stage": 1,
                    "is_draft": false,
                    "created_by_user": userData.id,
                };
                if (!validateObject(obj)) {
                    setIsCheckRequired(true);
                    throw new Error('Please fill the all highlighted fields');
                }
                obj.tehsilId = volunteersMapping.tehsilId;
                obj.union_councilId = volunteersMapping.union_councilId;
                obj.address = volunteersMapping.address;
                obj.volunteer = volunteersMapping.volunteer;
                if (volunteersMapping.draft_id) {
                    obj.volunteerId = volunteersMapping.id;
                    const res = await dispatch(updateVolunteerDraftData(obj));
                    if (res.type !== "volunteer/updateVolunteerDraftData/rejected") {
                        navigate(-1);
                        toast('success', "Volunteer draft update successfully");
                    } else {
                        setIsCreating(false);
                    }
                } else {
                    if (isForUpdate) {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createVolunteerData(obj));
                        if (res.type !== "volunteer/createVolunteerData/rejected") {
                            navigate(-1);
                            toast('success', "Volunteer update successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                        // const res = await dispatch(updateVolunteerData(obj));
                        // if (res.type !== "volunteer/updateVolunteerData/rejected") {
                        //     navigate(-1);
                        //     toast('success', "Volunteer update successfully submitted for approval. It will be published after approval.");
                        // }
                    } else {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createVolunteerData(obj));
                        if (res.type !== "volunteer/createVolunteerData/rejected") {
                            navigate(-1);
                            toast('success', "Volunteer created successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                    }
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };


    const onAction = async () => {
        const selectedWorkFlow: any = externalWorkflowsList.find((item: any) => item.stage === volunteersMapping.stage);
        let smallestStageNumber = externalWorkflowsList.reduce((min: any, record: any) => record.stage < min && record.stage > volunteersMapping.stage ? record.stage : min, Infinity);
        dispatch(updateVolunteerStageData({
            id: volunteersMapping.id,
            stage: (!smallestStageNumber || smallestStageNumber == Infinity) ? null : smallestStageNumber,
            record: volunteersMapping
        }));
        toast("success", `${(!smallestStageNumber || smallestStageNumber == Infinity) ? "Publish" : selectedWorkFlow.title} successfully`);
        navigate(-1);
    };

    const onSaveAsDraft = async () => {
        try {
            if (isCreating) return;
            setIsCreating(true);
            const excludeVolunteerDraftId = volunteersMapping.draft_id || null;
            const excludeVolunteerId = volunteersMapping.volunteer || null;

            // Check for duplicate cnic_number in both volunteers and volunteerDrafts
            // const { volunteers, volunteerDrafts } = await dispatch(checkVolunteerCnicExists({
            //     cnic_number: volunteersMapping.cnic_number,
            //     excludeVolunteerDraftId,
            //     excludeVolunteerId,
            // })).unwrap();

            // if (volunteers.length > 0 || volunteerDrafts.length > 0) {
            //     setIsCreating(false);
            //     toast('error', "CNIC number already exists.");
            //     return;
            // }
            const obj: any = {
                "name": volunteersMapping.name,
                "divisionId": volunteersMapping.divisionId,
                "districtId": volunteersMapping.districtId,
                "cnic_number": volunteersMapping.cnic_number ? Number(volunteersMapping.cnic_number) : null,
                "postal_address": volunteersMapping.postal_address,
                "website": volunteersMapping.website,
                "contact_person": volunteersMapping.contact_person,
                "contact_person_mobile": volunteersMapping.contact_person_mobile ? Number(volunteersMapping.contact_person_mobile) : null,
                "latitude": volunteersMapping.latitude ? Number(volunteersMapping.latitude) : null,
                "longitude": volunteersMapping.longitude ? Number(volunteersMapping.longitude) : null,
                "tehsilId": volunteersMapping.tehsilId,
                "union_councilId": volunteersMapping.union_councilId,
                "address": volunteersMapping.address,
                "stage": null,
                "is_draft": true,
                "created_by_user": userData.id
            };
            if (volunteersMapping.draft_id) {
                obj.volunteer = volunteersMapping.volunteer;
                obj.volunteerId = data.draft_id;
                const res = await dispatch(updateVolunteerDraftData(obj));
                if (res.type !== "volunteer/updateVolunteerDraftData/rejected") {
                    navigate(-1);
                    toast('success', "volunteer draft update successfully");
                } else {
                    setIsCreating(false);
                }
            } else {
                obj.volunteer = volunteersMapping.volunteer;
                const res = await dispatch(createVolunteerData(obj));
                if (res.type !== "volunteer/createVolunteerData/rejected") {
                    navigate(-1);
                    toast('success', "volunteer draft successfully");
                } else {
                    setIsCreating(false);
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    console.log("is_draft", is_draft);
    console.log("data", data);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formResourceMappingLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Resource Mapping</span>
                        </div>
                    </div>
                    {isForAction ? <div className='form-send-btn' onClick={onAction}>
                        <span className='sand-btn-text'>{actionButtonText}</span>
                    </div>
                        : <div>
                            {!hasDraft ? <div className='form-send-btn' onClick={onSaveAsDraft}>
                                <span className='sand-btn-text'>Save as draft</span>
                            </div> : null}
                            <div className='form-send-btn' onClick={onSaveVolunteerMapping}>
                                <span className='sand-btn-text'>Submit</span>
                            </div>
                        </div>}
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <span>Volunteers Mapping</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Name</span>
                        <Input
                            onChange={({ target }) => setVolunteersMapping(prevState => ({ ...prevState, name: target.value }))}
                            disabled={isViewOnly}
                            status={(isCheckRequired && !volunteersMapping.name) ? 'error' : ""}
                            placeholder="Name of Volunteer"
                            value={volunteersMapping.name}
                            className='input' />
                    </div>
                    {/* <div className='input-cont'>
                        <span>CNIC Number</span>
                        <InputMask
                            disabled={isViewOnly}
                            style={{ border: (isCheckRequired && !volunteersMapping.cnic_number) ? "0.5px solid red" : "" }}
                            placeholder="Enter CNIC Number"
                            value={volunteersMapping.cnic_number}
                            className='ant-input css-dev-only-do-not-override-11xg00t ant-input-outlined input'
                            mask="99999-9999999-9"
                            maskChar={null}
                            onChange={({ target }) => setVolunteersMapping(prevState => ({ ...prevState, cnic_number: target.value }))}
                        ></InputMask>
                    </div> */}
                    <div className='input-cont'>
                        <span>CNIC Number</span>
                        <Input
                            onChange={({ target }) => setVolunteersMapping(prevState => ({ ...prevState, cnic_number: target.value }))}
                            disabled={isViewOnly}
                            placeholder="Enter CNIC Number"
                            value={volunteersMapping.cnic_number}
                            status={(isCheckRequired && !volunteersMapping.cnic_number) ? 'error' : ""}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Select
                            value={volunteersMapping.division || undefined}
                            placeholder="Select the Division"
                            status={(isCheckRequired && !volunteersMapping.divisionId) ? 'error' : ""}
                            disabled
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setVolunteersMapping(prevState => ({ ...prevState, divisionId: item?.id, division: item?.value }))}
                            options={divisionData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the District"
                            value={volunteersMapping.district || undefined}
                            style={{ width: '100%' }}
                            status={(isCheckRequired && !volunteersMapping.districtId) ? 'error' : ""}
                            disabled
                            onChange={(value: any, item: any) => setVolunteersMapping(prevState => ({ ...prevState, districtId: item?.id, district: item?.value }))}
                            options={districtData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Tehsil"
                            style={{ width: '100%' }}
                            // status={(isCheckRequired && !volunteersMapping.tehsilId) ? 'error' : ""}
                            disabled={isViewOnly}
                            value={volunteersMapping.tehsil || undefined}
                            onChange={(value: any, item: any) => setVolunteersMapping(prevState => ({ ...prevState, tehsilId: item?.id, tehsil: item?.value }))}
                            options={tehsilData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Union Council"
                            // status={(isCheckRequired && !volunteersMapping.union_councilId) ? 'error' : ""}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setVolunteersMapping(prevState => ({ ...prevState, union_councilId: item?.id, union_council: item?.value }))}
                            disabled={isViewOnly}
                            options={unionCouncilListData}
                            className='input'
                            value={volunteersMapping.union_council || undefined}
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            onChange={({ target }) => setVolunteersMapping(prevState => ({ ...prevState, address: target.value }))}
                            placeholder="Enter Address"
                            value={volunteersMapping.address || undefined}
                            status={(isCheckRequired && !volunteersMapping.address) ? 'error' : ""}
                            disabled={isViewOnly}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Postal Address</span>
                        <Input
                            onChange={({ target }) => setVolunteersMapping(prevState => ({ ...prevState, postal_address: target.value }))}
                            placeholder="Enter Postal Address"
                            status={(isCheckRequired && !volunteersMapping.postal_address) ? 'error' : ""}
                            disabled={isViewOnly}
                            value={volunteersMapping.postal_address}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Website</span>
                        <Input
                            onChange={({ target }) => setVolunteersMapping(prevState => ({ ...prevState, website: target.value }))}
                            placeholder="Website Link"
                            disabled={isViewOnly}
                            status={(isCheckRequired && !volunteersMapping.website) ? 'error' : ""}
                            value={volunteersMapping.website}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Contact Person</span>
                        <Input
                            onChange={({ target }) => setVolunteersMapping(prevState => ({ ...prevState, contact_person: target.value }))}
                            placeholder="Contact Person Name"
                            status={(isCheckRequired && !volunteersMapping.contact_person) ? 'error' : ""}
                            disabled={isViewOnly}
                            value={volunteersMapping.contact_person}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Contact Person Mobile</span>
                        <Input
                            onChange={({ target }) => setVolunteersMapping(prevState => ({ ...prevState, contact_person_mobile: target.value }))}
                            placeholder="Enter Mobile Number"
                            value={volunteersMapping.contact_person_mobile}
                            disabled={isViewOnly}
                            status={(isCheckRequired && !volunteersMapping.contact_person_mobile) ? 'error' : ""}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            onChange={({ target }) => setVolunteersMapping(prevState => ({ ...prevState, latitude: target.value }))}
                            placeholder="Enter Latitude"
                            status={(isCheckRequired && !volunteersMapping.latitude) ? 'error' : ""}
                            value={volunteersMapping.latitude}
                            disabled={isViewOnly}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            onChange={({ target }) => setVolunteersMapping(prevState => ({ ...prevState, longitude: target.value }))}
                            placeholder="Enter Longitude"
                            value={volunteersMapping.longitude}
                            disabled={isViewOnly}
                            className='input'
                            status={(isCheckRequired && !volunteersMapping.longitude) ? 'error' : ""}
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Forms;
