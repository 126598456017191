// Banner.tsx

import React from 'react';
import './index.css'; // Import CSS for styling
import TopBanner from "../../assets/images/top banner.png";
import { useLocation, useNavigate } from 'react-router-dom';

const Banner: React.FC<{ imageUrl: string; }> = ({ imageUrl }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const substrings = ["/form", "/details"];

  const containsSubstring = substrings.some(substring => location.pathname.includes(substring));

  if (containsSubstring) {
    return null;
  }
  return (
    <div className="banner" style={{ backgroundImage: `url(${imageUrl})` }}>
      <img src={TopBanner} alt="Logo2" />
      {/* Add any content or additional elements inside the banner */}
    </div>
  );
};

export default Banner;
