import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from "../../reduxToolkit/store";
import './index.css';
import MainLogo from '../../assets/images/MainLogo@3x.webp';
import UserIcon from '../../assets/images/UserIcon.png';
import tuning from '../../assets/images/tuning.svg';
// import { Menu, Avatar } from "antd";
import type { MenuProps } from 'antd';
import { Button, Dropdown, Space, Badge, Drawer, Menu } from 'antd';
import { UserOutlined, LogoutOutlined, BellOutlined,EditOutlined , MenuOutlined, QuestionOutlined, FormOutlined } from "@ant-design/icons";
import { BiSolidBell } from "react-icons/bi";
import { IoPerson } from "react-icons/io5";
import { logout, changeUserRoll } from '../../reduxToolkit/auth/authSlice';
import { useSelector, useDispatch } from 'react-redux';

const Header = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, editorMode, userData } = useSelector((state: RootState) => state.auth);
    const { internalWorkflowsList, externalWorkflowsList } = useSelector((state: RootState) => state.entities);
    const [visible, setVisible] = useState(false);
    const [activeItem, setActiveItem] = useState("/");
    const location = useLocation();
    const navigate = useNavigate();

    const showDrawer = () => {
        setVisible(!visible);
    };
    const userExternalWorkflowsList = externalWorkflowsList.filter((item: any) => item?.role === userData?.role?.name);
    const userInternalWorkflowsList = internalWorkflowsList.filter((item: any) => item?.role === userData?.role?.name);
    const items: MenuProps['items'] = [
        {
            key: '1',
            type: 'group',
            label: <div style={{ color: 'black' }}>{userData?.full_name}<br /><small style={{ color: 'rgb(53, 122, 56)' }}>{userData?.division?.division_name} Division Viewer</small></div>,
            children: [
                {
                    type: 'divider',
                },
                {
                    label: <span>Profile Setting</span>,
                    key: '3',
                    icon: <img src={tuning} style={{ width: '1rem', height: '1rem' }} />,
                },
                (userData?.editorAccess) ? {
                    label: <span> {editorMode ? "Change Role to Viewer" : "Change Role to Admin"}</span>,
                    key: '4',
                    icon: <QuestionOutlined />,
                } : null,
                ((userExternalWorkflowsList && userExternalWorkflowsList.length) || (userInternalWorkflowsList && userInternalWorkflowsList.length)) ? {
                    label: <span>My Task</span>,
                    key: '5',
                    icon: <FormOutlined />,
                } : null,
                (isAuthenticated) ? {
                    label: <span>My Draft</span>,
                    key: '6',
                    icon: <EditOutlined />,
                } : null,
                {
                    label: <span>Log out</span>,
                    key: '7',
                    icon: <LogoutOutlined />,
                }
            ],
        },
    ];

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (e.key === "1") {

        } else if (e.key === "4") {
            dispatch(changeUserRoll(editorMode ? "view" : "editor"));
        } else if (e.key === "5") {
            navigate("/mytask");
        } else if (e.key === "6") {
            navigate("/mydraft");
        } else if (e.key === "7") {
            dispatch(logout());
        }
    };

    console.log('userData', userData);

    const isHome = location.pathname === "/resourcemapping" || location.pathname === "/inventory" || location.pathname === "/lossessdamages";
    return (
        <div className="header">
            <div className="logo-container" onClick={() => navigate("/resourcemapping")}>
                <div className="logo">
                    <img src={MainLogo} alt="Logo2" />
                </div>
            </div>
            <div className="nav-links">
                <ul>
                    <li onClick={() => navigate("/")} className={isHome ? "nav_link_active" : ""}>Home</li>
                    <li onClick={() => navigate("/about")} className={location.pathname === "/about" ? "nav_link_active" : ""}>About Us</li>
                    <li onClick={() => navigate("/contact")} className={location.pathname === "/contact" ? "nav_link_active" : ""}>Contact Us</li>
                    {location?.pathname === '/login' ? false : (isAuthenticated ? (
                        <ul>
                            <li>
                                <Dropdown menu={{ items, onClick: handleMenuClick }} placement="bottom">
                                    <a onClick={(e) => e.preventDefault()}>
                                        {/* <UserOutlined /> */}
                                        <div className="UserIconCont">
                                            <img src={UserIcon} alt="" />
                                        </div>
                                    </a>
                                </Dropdown>
                            </li>
                            <li onClick={() => navigate("/")}>
                                <Space size="small">
                                    <Badge count={1}>
                                        <BiSolidBell style={{ width: "30px", height: "30px", color: "#073042" }} />
                                    </Badge>
                                </Space>
                            </li>
                        </ul>
                    ) : (
                        <li>
                            <Button className="nav-login-btn" onClick={() => navigate("/login")} icon={<IoPerson className="nav-login-btn-icon" />}>
                                Login
                            </Button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="nav-drawer">
                <Button className="menuButton" type="text" onClick={showDrawer}>
                    <MenuOutlined />
                </Button>
                <Drawer
                    title={<div className="logo">
                        <img src={MainLogo} alt="Logo2" />
                    </div>}

                    placement="right"
                    closable={true}
                    onClose={showDrawer}
                    visible={visible}
                    style={{ zIndex: 99999 }}
                >
                    <Menu mode="inline">
                        <Menu.Item key="Home">Home</Menu.Item>
                        <Menu.Item key="AboutUs">About Us</Menu.Item>
                        <Menu.Item key="ContactUs">Contact Us</Menu.Item>
                        {location?.pathname === '/login' ? false : (isAuthenticated ? (
                            <>
                                <Menu.Item icon={<Space size="small">
                                    <Badge count={1}>
                                        Notification
                                        <BiSolidBell />
                                    </Badge>
                                </Space>} key="Notification" />
                                {(userData?.editorAccess) && <Menu.Item onClick={() => dispatch(changeUserRoll(editorMode ? "view" : "editor"))} icon={<Space size="small">
                                    {editorMode ? "Change Role to Viewer" : "Change Role to Admin"}
                                    {/* <LogoutOutlined /> */}
                                </Space>} key="modeChange" />}
                                <Menu.Item onClick={() => dispatch(logout())} icon={<Space size="small">
                                    Logout
                                    <LogoutOutlined />
                                </Space>} key="Logout" />
                            </>
                        ) : (
                            <Menu.Item onClick={() => navigate("/login")} icon={<Space>Login<UserOutlined /></Space>} key="Login" />
                        ))}
                    </Menu>
                </Drawer>
            </div>
        </div>
    );
};

export default Header;