import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

interface FoodDamageState {
    foodDamageList: any[];
    foodDamagesLoading: boolean;
    filteredFoodDamagesList: any[];
    foodDamageMeta: any;
    filteredFoodDamagesCount: number;
    foodDamagesChartList: any[];
    foodDamagesCount: number;
    selectedfoodDamage: any;
    growerDetails: any[];
    growerDetailsMeta: any;
}

const initialState: FoodDamageState = {
    foodDamageList: [],
    foodDamagesLoading: false,
    filteredFoodDamagesList: [],
    foodDamageMeta: {},
    filteredFoodDamagesCount: 0,
    foodDamagesChartList: [],
    foodDamagesCount: 0,
    selectedfoodDamage: {},
    growerDetails: [],
    growerDetailsMeta: {},
};

export const fetchFoodDamagesCount = createAsyncThunk('foodDamage/fetchFoodDamagesCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedEvent, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                }
            }
        };
        if (selectedEvent) {
            variables.filter.event = {
                name: {
                    "eq": selectedEvent
                }
            };
        }
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiFoodDamagesCount : API.ApiFoodDamagesCountWithOutChartData,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.foodDamages.meta.pagination.total, foodDamagesChartList: response.data.foodDamages.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFoodDamageData = createAsyncThunk('foodDamage/fetchFoodDamageData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent } = pageData;
        const filter: any = {
            stage: {
                null: true
            }
        };
        if (selectedEvent) {
            filter.event = {
                name: {
                    "eq": selectedEvent
                }
            };
        }
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllFoodDamages,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.foodDamages.data, meta: response.data.foodDamages.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFilterdFoodDamageData = createAsyncThunk('foodDamage/fetchFilterdFoodDamageData', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await client.query({ query: API.ApiFoodDamages, variables, context: { headers }, fetchPolicy: 'no-cache' });
        return { data: response.data.foodDamageDrafts.data, filteredFoodDamagesCount: response.data.foodDamageDrafts.meta.pagination.total };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFoodDamageDataById = createAsyncThunk('foodDamage/fetchFoodDamageDataById', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetFoodDamageById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.foodDamage.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllGrowerDetails = createAsyncThunk('foodDamage/fetchAllGrowerDetails', async (variables: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetAllGrowerDetails, variables, fetchPolicy: 'no-cache' });
        return { data: response.data.growerDetails.data, meta: response.data.growerDetails.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createFoodDamageData = createAsyncThunk('foodDamage/createFoodDamageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateFoodDamage,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createFoodDamageDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateFoodDamageDraftData = createAsyncThunk('foodDamage/updateFoodDamageDraftData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingFoodDamageDraft,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateFoodDamageDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createGrowerData = createAsyncThunk('foodDamage/createGrowerData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateGrowerDetail,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createGrowerDetail.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateFoodDamageStageData = createAsyncThunk('foodDamage/updateFoodDamageStageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { stage, id, record } = data;
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        if (stage) {
            await client.mutate({
                mutation: API.ApiUpdateFoodDamageStage,
                variables: {
                    id: data.id,
                    stage: data.stage
                },
                context: {
                    headers,
                },
            });
        } else {
            const obj = {
                "divisionId": record.divisionId,
                "districtId": record.districtId,
                "agriculture_typeId": record.agriculture_typeId,
                "eventId": record.eventId,
                "acre": record.acre,
                "cost": record.cost ? Number(record.cost) : null,
                "latitude": record.latitude ? Number(record.latitude) : null,
                "longitude": record.longitude ? Number(record.longitude) : null,
                "stage": null,
                "tehsilId": record.tehsilId,
                "union_councilId": record.union_councilId,
                "address": record.address,
                "foodDamageId": record.food_damage
            };
            await client.mutate({
                mutation: obj.foodDamageId ? API.ApiUpdateExistingFoodDamage : API.ApiCreatePublishedFoodDamage,
                variables: obj,
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
            await client.mutate({
                mutation: API.ApiDeleteFoodDamageDraft,
                variables: { id },
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
        }
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteFoodDamageData = createAsyncThunk('foodDamage/deleteFoodDamageData', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteFoodDamage,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteGrowerData = createAsyncThunk('foodDamage/deleteGrowerData', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteGrowerDetail,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateFoodDamageData = createAsyncThunk('inventory/updateFoodDamageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingFoodDamage, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateFoodDamage.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateGrowerData = createAsyncThunk('inventory/updateGrowerData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingGrowerDetail, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateGrowerDetail.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateFoodDamageRecordFromSocket = createAsyncThunk('foodDamage/addAndUpdateFoodDamageRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, internalWorkflowsList } = data;
        const response = await client.query({ query: API.ApiGetFoodDamageById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.foodDamage.data, internalWorkflowsList };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteFoodDamageRecordFromSocket = createAsyncThunk('foodDamage/deleteFoodDamageRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const getFoodDamageDraftByUser = createAsyncThunk('foodDamage/getFoodDamageDraftByUser', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.APIGetFoodDamageDraftByUser, // Assuming you have an API constant for update mutation
            variables,
            context: {
                headers,
            },
        });
        if (response.data && response.data.foodDamageDrafts.data.length > 0) {
            return true;
        }
        return false;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const foodDamageSlice = createSlice({
    name: 'foodDamage',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchFoodDamagesCount.fulfilled, (state, action) => {
                state.foodDamagesCount = action.payload.count;
                state.foodDamagesChartList = action.payload.foodDamagesChartList;
                state.foodDamagesLoading = false;
            })
            .addCase(fetchFoodDamageData.fulfilled, (state, action) => {
                state.foodDamageList = action.payload.data;
                state.foodDamageMeta = action.payload.meta;
                state.foodDamagesLoading = false;
            })
            .addCase(fetchFilterdFoodDamageData.fulfilled, (state, action) => {
                state.filteredFoodDamagesList = action.payload.data;
                state.filteredFoodDamagesCount = action.payload.filteredFoodDamagesCount;
                state.foodDamagesLoading = false;
            })
            .addCase(fetchFoodDamageDataById.fulfilled, (state, action) => {
                state.selectedfoodDamage = action.payload;
                state.foodDamagesLoading = false;
            })
            .addCase(fetchAllGrowerDetails.fulfilled, (state, action) => {
                state.growerDetails = action.payload.data; // Since action.payload is already an array of District
                state.growerDetailsMeta = action.payload.meta; // Since action.payload is already an array of District
                state.foodDamagesLoading = false;
            })
            .addCase(createFoodDamageData.fulfilled, (state, action) => {
                // state.foodDamageList.push(action.payload);
                state.foodDamagesLoading = false;
            })
            .addCase(updateFoodDamageDraftData.fulfilled, (state, action) => {
                state.foodDamagesLoading = false;
            })
            .addCase(getFoodDamageDraftByUser.fulfilled, (state, action) => {
                state.foodDamagesLoading = false;
            })
            .addCase(createGrowerData.fulfilled, (state, action) => {
                state.growerDetails.push(action.payload);
                state.foodDamagesLoading = false;
            })
            .addCase(updateFoodDamageStageData.fulfilled, (state, action) => {
                const index = state.filteredFoodDamagesList.findIndex(item => item.id === action.payload);
                state.filteredFoodDamagesList.splice(index, 1);
                state.foodDamagesLoading = false;
            })
            .addCase(deleteFoodDamageData.fulfilled, (state, action) => {
                state.foodDamagesLoading = false;
            })
            .addCase(deleteGrowerData.fulfilled, (state, action) => {
                const index = state.growerDetails.findIndex(item => item.id === action.payload);
                state.growerDetails.splice(index, 1);
                state.foodDamagesLoading = false;
            })
            .addCase(updateFoodDamageData.fulfilled, (state, action) => {
                const index = state.filteredFoodDamagesList.findIndex(item => item.id === action.payload);
                state.filteredFoodDamagesList.splice(index, 1);
                state.foodDamagesLoading = false;
            })
            .addCase(updateGrowerData.fulfilled, (state, action) => {
                const index = state.growerDetails.findIndex(item => item.id === action.payload.id);
                index !== -1 ? state.growerDetails[index] = action.payload : state.growerDetails.push(action.payload);
                state.foodDamagesLoading = false;
            })
            .addCase(addAndUpdateFoodDamageRecordFromSocket.fulfilled, (state, action) => {
                const internalWorkflowsList = action.payload.internalWorkflowsList;
                const { id, attributes } = action.payload.data;
                if (attributes.stage) {
                    const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === attributes.stage);
                    const index = state.filteredFoodDamagesList.findIndex(item => item.id == id);
                    if (selectedWorkFlow) {
                        index !== -1 ? state.filteredFoodDamagesList[index] = action.payload.data : state.filteredFoodDamagesList.push(action.payload.data);
                    } else {
                        state.filteredFoodDamagesList.splice(index, 1);
                    }
                } else {
                    const index = state.foodDamageList.findIndex(item => item.id == id);
                    index !== -1 ? state.foodDamageList[index] = action.payload.data : state.foodDamageList.push(action.payload.data);
                }
                if (state.selectedfoodDamage.id === id) {
                    state.selectedfoodDamage = action.payload.data;
                }
            })
            .addCase(deleteFoodDamageRecordFromSocket.fulfilled, (state, action) => {
                state.foodDamageList = state.foodDamageList.filter(item => item.id != action.payload);
                state.filteredFoodDamagesList = state.filteredFoodDamagesList.filter(item => item.id != action.payload);
            });
        [
            fetchFoodDamagesCount,
            fetchFoodDamageData,
            fetchFoodDamageDataById,
            fetchFilterdFoodDamageData,
            updateFoodDamageStageData,
            createFoodDamageData,
            deleteFoodDamageData,
            updateFoodDamageData,
            fetchAllGrowerDetails,
            createGrowerData,
            updateGrowerData,
            deleteGrowerData,
            updateFoodDamageDraftData,
            getFoodDamageDraftByUser
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.foodDamagesLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.foodDamagesLoading = false;
                });
        });
    }
});


export default foodDamageSlice.reducer;
