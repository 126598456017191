import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Input, Space, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { fetchFilterdNGOData } from '../../reduxToolkit/ngoSlice';
import ReusableTable from '../../components/table';
import SearchSelect from '../../components/selectDropdown';
import Filters from '../../components/top_filter';
import { fetchFilterdManufacturerData } from '../../reduxToolkit/manufacturerSlice';
import { fetchFilterdSuppliersData } from '../../reduxToolkit/supplierSlice';
import { fetchFilterdVolunteersData } from '../../reduxToolkit/volunteerSlice';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { toast } from '../../components/Toast';
import { fetchFilterdInventoriesData, updateInventoryStageData } from '../../reduxToolkit/inventorySlice';
import { compensatonReliefColumns, financialReliefColumns, foodDamageColumns, humanCasualtieColumns, infrastructureDamageColumns, inventoryColumns, livestockDamageColumns, manufacturersColumns, ngoColumns, suppliersColumns, volunteersColumns } from '../../helpers/tableColumns';
import { alignCompensatonReliefsData, alignFinancialReliefData, alignFoodDamageData, alignHumanCasualtieData, alignInfrastructureDamageData, alignInventoryData, alignLivestockDamageData, alignManufacturersData, alignNgoData, alignsSuppliersData, alignVolunteersData } from '../../helpers/alignData';
import { fetchFilterdFinancialReliefData, updateFinancialReliefStageData } from '../../reduxToolkit/financialReliefSlice';
import { fetchFilterdCompensationStatusesData, updateCompensationStatusesStageData } from '../../reduxToolkit/compensatonReliefSlice';
import { fetchFilterdHumanCasualtieData, updateHumanCasualtieStageData } from '../../reduxToolkit/humanCasualtieSlice';
import { fetchFilterdInfrastructureDamageData, updateInfrastructureDamageStageData } from '../../reduxToolkit/infrastructureDamageSlice';
import { fetchFilterdFoodDamageData, updateFoodDamageStageData } from '../../reduxToolkit/foodDamageSlice';
import { fetchFilterdLivestockDamageData, updateLivestockDamageStageData } from '../../reduxToolkit/livestockDamageSlice';
import './style.css';

const MyDraft: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const { userData } = useSelector((state: RootState) => state.auth);
    const { filteredNgoList, filteredngoCount } = useSelector((state: RootState) => state.ngos);
    const { filteredManufacturerList, filteredManufacturerCount } = useSelector((state: RootState) => state.manufacturer);
    const { filteredSupplierList, filteredSuppliersCount } = useSelector((state: RootState) => state.supplier);
    const { filteredVolunteerList, filteredVolunteersCount } = useSelector((state: RootState) => state.volunteer);
    const { filteredInventoriesList, filteredInventoriesCount } = useSelector((state: RootState) => state.inventory);
    const { filteredFinancialReliefList, filteredFinancialCount } = useSelector((state: RootState) => state.financialRelief);
    const { filteredCompensatonReliefList, filteredCompensatonReliefCount } = useSelector((state: RootState) => state.compensatonRelief);
    const { filteredHumanCasualtieList, filteredHumanCasualtieCount } = useSelector((state: RootState) => state.humanCasualtie);
    const { filteredInfrastructureDamageList, filteredInfrastructureCount } = useSelector((state: RootState) => state.infrastructureDamage);
    const { filteredFoodDamagesList, filteredFoodDamagesCount } = useSelector((state: RootState) => state.foodDamage);
    const { filteredLivestockDamagesList, filteredLivestockDamagesCount } = useSelector((state: RootState) => state.livestockDamage);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedTehsil, setSelectedTehsil] = useState('');
    const [selectedUnionCouncil, setSelectedUnionCouncil] = useState('');
    const [selectedParameter, setSelectedParameter] = useState('');
    const [selectedKeyword, setSelectedKeyword] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedTab, setSelectedTab] = useState('1');

    useEffect(() => {
        if (userData && userData.id) {
            const variables: any = dataFilters();
            if (selectedTab === "1") {
                dispatch(fetchFilterdNGOData(variables));
            } else if (selectedTab === "2") {
                dispatch(fetchFilterdVolunteersData(variables));
            } else if (selectedTab === "3") {
                dispatch(fetchFilterdManufacturerData(variables));
            } else if (selectedTab === "4") {
                dispatch(fetchFilterdSuppliersData(variables));
            } else if (selectedTab === "5") {
                dispatch(fetchFilterdInventoriesData(variables));
            } else if (selectedTab === "6") {
                dispatch(fetchFilterdFinancialReliefData(variables));
            } else if (selectedTab === "7") {
                dispatch(fetchFilterdCompensationStatusesData(variables));
            } else if (selectedTab === "8") {
                dispatch(fetchFilterdHumanCasualtieData(variables));
            } else if (selectedTab === "9") {
                dispatch(fetchFilterdInfrastructureDamageData(variables));
            } else if (selectedTab === "10") {
                dispatch(fetchFilterdFoodDamageData(variables));
            } else if (selectedTab === "11") {
                dispatch(fetchFilterdLivestockDamageData(variables));
            }
        }
    }, [selectedTab, rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, userData]);

    const naviagteToEdit = (formHeading: String, data: any) => {
        navigate("/form", {
            state: {
                formHeading,
                recordId: data.id,
                data: { ...data, draft_id: data.id },
                isForUpdate: true,
                is_draft: true
            }
        });
    };

    const actionButtons = (formHeading: String) => {
        return {
            title: 'Actions',
            width: 150,
            align: 'center' as 'center',
            render: (text: any, record: any) => {
                return <span>
                    <Button
                        type="primary"
                        title='View'
                        style={{ background: '#357A38' }}
                        className='m-v-5'
                        icon={<EditOutlined />}
                        onClick={() => naviagteToEdit(formHeading, record)}
                        shape='circle' />
                </span>;
            }
        };
    };

    const dataFilters = () => {
        const variables: any = {
            page: 1,
            pageSize: rowsPerPage,
            "filter": {
                "is_draft": {
                    "eq": true
                },
                "created_by_user": {
                    "id": {
                        "eq": userData.id
                    }
                }
            }
        };
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        return variables;
    };

    const ngoData = alignNgoData(filteredNgoList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const volunteersData = alignVolunteersData(filteredVolunteerList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const manufacturersData = alignManufacturersData(filteredManufacturerList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const suppliersData = alignsSuppliersData(filteredSupplierList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const inventoryData = alignInventoryData(filteredInventoriesList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const financialReliefListData = alignFinancialReliefData(filteredFinancialReliefList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const compensatonReliefListData = alignCompensatonReliefsData(filteredCompensatonReliefList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const humanCasualtieData = alignHumanCasualtieData(filteredHumanCasualtieList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const infrastructureDamageData = alignInfrastructureDamageData(filteredInfrastructureDamageList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const foodDamageData = alignFoodDamageData(filteredFoodDamagesList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    const livestockDamageData = alignLivestockDamageData(filteredLivestockDamagesList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);

    const tabitems = [
        {
            label: 'NGO Mapping',
            key: '1',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '1') ? ngoData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : ngoData}
                columns={[
                    ...ngoColumns,
                    actionButtons("NGO Mapping")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredngoCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdNGOData({ ...dataFilters(), page }))}
            />
        },
        {
            label: 'Volunteers Mapping',
            key: '2',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '2') ? volunteersData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : volunteersData}
                columns={[
                    ...volunteersColumns,
                    actionButtons("Volunteers Mapping")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredVolunteersCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdVolunteersData({ ...dataFilters(), page }))}
            />
        },
        {
            label: 'Manufacturers Mapping',
            key: '3',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '3') ? manufacturersData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : manufacturersData}
                columns={[
                    ...manufacturersColumns,
                    actionButtons("Manufacturers Mapping")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredManufacturerCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdManufacturerData({ ...dataFilters(), page }))}
            />
        },
        {
            label: 'Suppliers Mapping',
            key: '4',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? suppliersData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : suppliersData}
                columns={[
                    ...suppliersColumns,
                    actionButtons("Suppliers Mapping")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredSuppliersCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdSuppliersData({ ...dataFilters(), page }))}
            />
        },
        {
            label: 'Inventory',
            key: '5',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? inventoryData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : inventoryData}
                columns={[
                    ...inventoryColumns,
                    actionButtons("Inventory")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredInventoriesCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdInventoriesData({ ...dataFilters(), page }))}
            />
        },
        {
            label: 'Contributors relief',
            key: '6',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? financialReliefListData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : financialReliefListData}
                columns={[
                    ...financialReliefColumns,
                    actionButtons("Contributors relief")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredFinancialCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdFinancialReliefData({ ...dataFilters(), page }))}
            />
        },
        {
            label: 'Compensation relief',
            key: '7',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? compensatonReliefListData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : compensatonReliefListData}
                columns={[
                    ...compensatonReliefColumns,
                    actionButtons("Compensation relief")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredCompensatonReliefCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdCompensationStatusesData({ ...dataFilters(), page }))}
            />
        },
        {
            label: 'Human Casualties',
            key: '8',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? humanCasualtieData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : humanCasualtieData}
                columns={[
                    ...humanCasualtieColumns,
                    actionButtons("Human Casualties")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredHumanCasualtieCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdHumanCasualtieData({ ...dataFilters(), page }))}
            />
        },
        {
            label: 'Infrastructure Damages',
            key: '9',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? infrastructureDamageData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : infrastructureDamageData}
                columns={[
                    ...infrastructureDamageColumns,
                    actionButtons("Infrastructure Damages")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredInfrastructureCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdInfrastructureDamageData({ ...dataFilters(), page }))}
            />
        },
        {
            label: 'Food Damages',
            key: '10',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? foodDamageData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : foodDamageData}
                columns={[
                    ...foodDamageColumns,
                    actionButtons("Food Damages")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredFoodDamagesCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdFoodDamageData({ ...dataFilters(), page }))}
            />
        },
        {
            label: 'Livestock Damages',
            key: '11',
            children: <ReusableTable
                data={(selectedParameter && selectedKeyword && selectedTab === '4') ? livestockDamageData.filter((item: any) => {
                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                }) : livestockDamageData}
                columns={[
                    ...livestockDamageColumns,
                    actionButtons("Livestock Damages")
                ]}
                rowsPerPage={rowsPerPage}
                total={filteredLivestockDamagesCount}
                onChangePagination={(page: number) => dispatch(fetchFilterdLivestockDamageData({ ...dataFilters(), page }))}
            />
        },
    ];

    return (
        <div className='Inventory-mainCont'>
            <Filters
                setSelectedDivision={setSelectedDivision}
                selectedDivision={selectedDivision}
                setSelectedDistrict={setSelectedDistrict}
                selectedDistrict={selectedDistrict}
                setSelectedTehsil={setSelectedTehsil}
                selectedTehsil={selectedTehsil}
                setSelectedUnionCouncil={setSelectedUnionCouncil}
                selectedUnionCouncil={selectedUnionCouncil}
            />
            <div className='ResourceMapping-table-cont'>
                <div className='ResourceMapping-table-filter-btn-cont'>
                    <div />
                    <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap', width: "500px" }}>
                        <SearchSelect
                            onChange={env => { setSelectedParameter(env); setSelectedKeyword(''); }}
                            placeholder="Select the Parameter"
                            className="filter-input select-param-input"
                            value={selectedParameter || undefined}
                            options={(selectedTab === '1' ? ngoColumns : selectedTab === '2' ? volunteersColumns : selectedTab === '3' ? manufacturersColumns : selectedTab === '4' ? suppliersColumns : selectedTab === '5' ? inventoryColumns : selectedTab === '6' ? financialReliefColumns : selectedTab === '7' ? compensatonReliefColumns : selectedTab === '8' ? humanCasualtieColumns : selectedTab === '9' ? infrastructureDamageColumns : selectedTab === '10' ? foodDamageColumns : selectedTab === '11' ? livestockDamageColumns : [])
                                // .filter((item:any) => item.title !== 'Google Map Location')
                                .map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))
                            }
                            allowClear={true}
                        />
                        <Input
                            placeholder="Keyword Search"
                            className=' filter-input select-param-input'
                            allowClear={true}
                            disabled={!selectedParameter}
                            onChange={({ target }) => setSelectedKeyword(target.value)}
                            value={selectedKeyword}
                        />
                        <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                            <span>Show:</span>
                            <SearchSelect
                                onChange={(value: String) => setRowsPerPage(+value)}
                                placeholder="Select the District"
                                className="w-100px"
                                value={rowsPerPage}
                                options={[
                                    { value: "2", label: "2 Entries" },
                                    { value: "5", label: "5 Entries" },
                                    { value: "8", label: "8 Entries" },
                                    { value: "10", label: "10 Entries" },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div id="BottomTabs">
                    <Tabs
                        defaultActiveKey="1"
                        onTabClick={(activeKey) => { setSelectedTab(activeKey); setSelectedParameter(''); setSelectedKeyword(''); }}
                        activeKey={selectedTab}
                        type='card'
                        tabBarStyle={{ color: "#8D949C" }}
                        style={{ width: "100%" }}
                        items={tabitems}
                    />
                </div>
            </div>
        </div>
    );
};

export default MyDraft;