import { io } from "socket.io-client";
import { addAndUpdateNgoRecordFromSocket, deleteNgoRecordFromSocket } from "../reduxToolkit/ngoSlice";
import { addAndUpdateInventoryRecordFromSocket, addAndUpdateProductCategoryRecordFromSocket, addAndUpdateProductNameRecordFromSocket, addAndUpdateProductTypeRecordFromSocket, deleteInventoryEntitiesRecordFromSocket, deleteInventoryRecordFromSocket } from "../reduxToolkit/inventorySlice";
import { addAndUpdateFinancialReliefRecordFromSocket, deleteFinancialReliefRecordFromSocket } from "../reduxToolkit/financialReliefSlice";
import { addAndUpdateCompensationStatusRecordFromSocket, addAndUpdateCompensatonReliefRecordFromSocket, deleteCompensationStatuseRecordFromSocket, deleteCompensatonReliefRecordFromSocket } from "../reduxToolkit/compensatonReliefSlice";
import { addAndUpdateHumanCasualtieRecordFromSocket, addAndUpdateHumanCasualtieTypeRecordFromSocket, deleteHumanCasualtieRecordFromSocket, deleteHumanCasualtieTypeRecordFromSocket } from "../reduxToolkit/humanCasualtieSlice";
import { addAndUpdateInfrastructureDamageRecordFromSocket, deleteInfrastructureDamageRecordFromSocket } from "../reduxToolkit/infrastructureDamageSlice";
import { addAndUpdateFoodDamageRecordFromSocket, deleteFoodDamageRecordFromSocket } from "../reduxToolkit/foodDamageSlice";
import { addAndUpdateLivestockDamageRecordFromSocket, addAndUpdateLivestockTypeRecordFromSocket, deleteLivestockDamageRecordFromSocket, deleteLivestockTypeRecordFromSocket } from "../reduxToolkit/livestockDamageSlice";
import { addAndUpdateVolunteerRecordFromSocket, deleteVolunteerRecordFromSocket } from "../reduxToolkit/volunteerSlice";
import { addAndUpdateManufacturerRecordFromSocket, deleteManufacturerRecordFromSocket } from "../reduxToolkit/manufacturerSlice";
import { addAndUpdateSupplierRecordFromSocket, deleteSupplierRecordFromSocket } from "../reduxToolkit/supplierSlice";
import { addAndUpdateAgricultureTypeRecordFromSocket, addAndUpdateDistrictRecordFromSocket, addAndUpdateDivisionRecordFromSocket, addAndUpdateEventRecordFromSocket, addAndUpdateGenderRecordFromSocket, addAndUpdateInfrastructureTypeRecordFromSocket, addAndUpdatePrimaryAreaRecordFromSocket, addAndUpdateSecondaryAreaRecordFromSocket, addAndUpdateTehsilRecordFromSocket, addAndUpdateUnionCouncilRecordFromSocket, deleteEntitiesRecordFromSocket } from "../reduxToolkit/entitiesSlice";
import { store } from "../reduxToolkit/store";
import { addAndUpdateSchoolRecordFromSocket, deleteSchoolRecordFromSocket } from "../reduxToolkit/schoolSlice";

const SERVER_URL = 'https://db.pdma.hexalyze.com';
// const SERVER_URL = 'http://localhost:1337';

const socket = io(SERVER_URL);

// Action to initialize socket connection and listen for events
export const initializeSocket = () => async (dispatch) => {
    socket.on('connect', () => {
        // ngo
        socket.on('ngo:create', ({ data }) => {
            const { recordId } = data;
            const externalWorkflowsList = store.getState().entities.externalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userExternalWorkflowsList = externalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateNgoRecordFromSocket({ recordId, externalWorkflowsList: userExternalWorkflowsList }));
        });
        socket.on('ngo:update', ({ data }) => {
            const { recordId } = data;
            const externalWorkflowsList = store.getState().entities.externalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userExternalWorkflowsList = externalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateNgoRecordFromSocket({ recordId, externalWorkflowsList: userExternalWorkflowsList }));
        });
        socket.on('ngo:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteNgoRecordFromSocket(recordId));
        });
        // volunteer
        socket.on('volunteer:create', ({ data }) => {
            const { recordId } = data;
            const externalWorkflowsList = store.getState().entities.externalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userExternalWorkflowsList = externalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateVolunteerRecordFromSocket({ recordId, externalWorkflowsList: userExternalWorkflowsList }));
        });
        socket.on('volunteer:update', ({ data }) => {
            const { recordId } = data;
            const externalWorkflowsList = store.getState().entities.externalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userExternalWorkflowsList = externalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateVolunteerRecordFromSocket({ recordId, externalWorkflowsList: userExternalWorkflowsList }));
        });
        socket.on('volunteer:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteVolunteerRecordFromSocket(recordId));
        });
        // manufacturer
        socket.on('manufacturer:create', ({ data }) => {
            const { recordId } = data;
            const externalWorkflowsList = store.getState().entities.externalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userExternalWorkflowsList = externalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateManufacturerRecordFromSocket({ recordId, externalWorkflowsList: userExternalWorkflowsList }));
        });
        socket.on('manufacturer:update', ({ data }) => {
            const { recordId } = data;
            const externalWorkflowsList = store.getState().entities.externalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userExternalWorkflowsList = externalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateManufacturerRecordFromSocket({ recordId, externalWorkflowsList: userExternalWorkflowsList }));
        });
        socket.on('manufacturer:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteManufacturerRecordFromSocket(recordId));
        });
        // supplier
        socket.on('supplier:create', ({ data }) => {
            const { recordId } = data;
            const externalWorkflowsList = store.getState().entities.externalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userExternalWorkflowsList = externalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateSupplierRecordFromSocket({ recordId, externalWorkflowsList: userExternalWorkflowsList }));
        });
        socket.on('supplier:update', ({ data }) => {
            const { recordId } = data;
            const externalWorkflowsList = store.getState().entities.externalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userExternalWorkflowsList = externalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateSupplierRecordFromSocket({ recordId, externalWorkflowsList: userExternalWorkflowsList }));
        });
        socket.on('supplier:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteSupplierRecordFromSocket(recordId));
        });
        // school
        socket.on('school:create', ({ data }) => {
            const { recordId } = data;
            const externalWorkflowsList = store.getState().entities.externalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userExternalWorkflowsList = externalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateSchoolRecordFromSocket({ recordId, externalWorkflowsList: userExternalWorkflowsList }));
        });
        socket.on('school:update', ({ data }) => {
            const { recordId } = data;
            const externalWorkflowsList = store.getState().entities.externalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userExternalWorkflowsList = externalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateSchoolRecordFromSocket({ recordId, externalWorkflowsList: userExternalWorkflowsList }));
        });
        socket.on('school:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteSchoolRecordFromSocket(recordId));
        });
        // inventory
        socket.on('inventory:create', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateInventoryRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('inventory:update', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateInventoryRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('inventory:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteInventoryRecordFromSocket(recordId));
        });
        // financial-relief
        socket.on('financial-relief:create', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateFinancialReliefRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('financial-relief:update', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateFinancialReliefRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('financial-relief:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteFinancialReliefRecordFromSocket(recordId));
        });
        // compensaton-relief
        socket.on('compensaton-relief:create', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateCompensatonReliefRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('compensaton-relief:update', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateCompensatonReliefRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('compensaton-relief:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteCompensatonReliefRecordFromSocket(recordId));
        });
        // human-casualtie
        socket.on('human-casualtie:create', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateHumanCasualtieRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('human-casualtie:update', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateHumanCasualtieRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('human-casualtie:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteHumanCasualtieRecordFromSocket(recordId));
        });
        // infrastructure-damage
        socket.on('infrastructure-damage:create', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateInfrastructureDamageRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('infrastructure-damage:update', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateInfrastructureDamageRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('infrastructure-damage:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteInfrastructureDamageRecordFromSocket(recordId));
        });
        // food-damage
        socket.on('food-damage:create', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateFoodDamageRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('food-damage:update', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateFoodDamageRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('food-damage:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteFoodDamageRecordFromSocket(recordId));
        });
        // livestock-damage
        socket.on('livestock-damage:create', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateLivestockDamageRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('livestock-damage:update', ({ data }) => {
            const { recordId } = data;
            const internalWorkflowsList = store.getState().entities.internalWorkflowsList;
            const userData = store.getState().auth.userData;
            const userInternalWorkflowsList = internalWorkflowsList.filter(item => item?.role === userData?.role?.name);
            dispatch(addAndUpdateLivestockDamageRecordFromSocket({ recordId, internalWorkflowsList: userInternalWorkflowsList }));
        });
        socket.on('livestock-damage:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteLivestockDamageRecordFromSocket(recordId));
        });
        // division
        socket.on('division:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateDivisionRecordFromSocket(recordId));
        });
        socket.on('division:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateDivisionRecordFromSocket(recordId));
        });
        socket.on('division:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["divisionList"] }));
        });
        // district
        socket.on('district:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateDistrictRecordFromSocket(recordId));
        });
        socket.on('district:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateDistrictRecordFromSocket(recordId));
        });
        socket.on('district:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["allDistrictList", "districtList"] }));
        });
        // tehsil
        socket.on('tehsil:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateTehsilRecordFromSocket(recordId));
        });
        socket.on('tehsil:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateTehsilRecordFromSocket(recordId));
        });
        socket.on('tehsil:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["tehsilList"] }));
        });
        // union - council;
        socket.on('union-council:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateUnionCouncilRecordFromSocket(recordId));
        });
        socket.on('union-council:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateUnionCouncilRecordFromSocket(recordId));
        });
        socket.on('union-council:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["unionCouncilList"] }));
        });
        // gender
        socket.on('gender:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateGenderRecordFromSocket(recordId));
        });
        socket.on('gender:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateGenderRecordFromSocket(recordId));
        });
        socket.on('gender:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["genderList"] }));
        });
        // event
        socket.on('event:create', ({ data }) => {
            const { recordId } = data;
            const userData = store.getState().auth.userData;
            dispatch(addAndUpdateEventRecordFromSocket({ recordId, userData }));
        });
        socket.on('event:update', ({ data }) => {
            const { recordId } = data;
            const userData = store.getState().auth.userData;
            dispatch(addAndUpdateEventRecordFromSocket({ recordId, userData }));
        });
        socket.on('event:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["eventList"] }));
        });
        // infrastructure-type
        socket.on('infrastructure-type:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateInfrastructureTypeRecordFromSocket(recordId));
        });
        socket.on('infrastructure-type:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateInfrastructureTypeRecordFromSocket(recordId));
        });
        socket.on('infrastructure-type:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["infrastructureTypeList"] }));
        });
        // agriculture-type
        socket.on('agriculture-type:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateAgricultureTypeRecordFromSocket(recordId));
        });
        socket.on('agriculture-type:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateAgricultureTypeRecordFromSocket(recordId));
        });
        socket.on('agriculture-type:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["agricultureTypeList"] }));
        });
        // compensation-status
        socket.on('compensation-status:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateCompensationStatusRecordFromSocket(recordId));
        });
        socket.on('compensation-status:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateCompensationStatusRecordFromSocket(recordId));
        });
        socket.on('compensation-status:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteCompensationStatuseRecordFromSocket(recordId));
        });
        // casualty-type
        socket.on('casualty-type:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateHumanCasualtieTypeRecordFromSocket(recordId));
        });
        socket.on('casualty-type:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateHumanCasualtieTypeRecordFromSocket(recordId));
        });
        socket.on('casualty-type:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteHumanCasualtieTypeRecordFromSocket(recordId));
        });
        // product-type
        socket.on('product-type:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateProductTypeRecordFromSocket(recordId));
        });
        socket.on('product-type:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateProductTypeRecordFromSocket(recordId));
        });
        socket.on('product-type:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteInventoryEntitiesRecordFromSocket({ recordId, list: ["productTypeList"] }));
        });
        // product-name
        socket.on('product-name:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateProductNameRecordFromSocket(recordId));
        });
        socket.on('product-name:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateProductNameRecordFromSocket(recordId));
        });
        socket.on('product-name:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteInventoryEntitiesRecordFromSocket({ recordId, list: ["productNameList"] }));
        });
        // product-category
        socket.on('product-category:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateProductCategoryRecordFromSocket(recordId));
        });
        socket.on('product-category:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateProductCategoryRecordFromSocket(recordId));
        });
        socket.on('product-category:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteInventoryEntitiesRecordFromSocket({ recordId, list: ["productCategorieList"] }));
        });
        // livestock-type
        socket.on('livestock-type:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateLivestockTypeRecordFromSocket(recordId));
        });
        socket.on('livestock-type:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateLivestockTypeRecordFromSocket(recordId));
        });
        socket.on('livestock-type:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteLivestockTypeRecordFromSocket(recordId));
        });
        // primary-area
        socket.on('primary-area:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdatePrimaryAreaRecordFromSocket(recordId));
        });
        socket.on('primary-area:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdatePrimaryAreaRecordFromSocket(recordId));
        });
        socket.on('primary-area:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["primaryAreasList"] }));
        });
        // secondary-area
        socket.on('secondary-area:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateSecondaryAreaRecordFromSocket(recordId));
        });
        socket.on('secondary-area:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateSecondaryAreaRecordFromSocket(recordId));
        });
        socket.on('secondary-area:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["secondaryAreasList"] }));
        });
    });
};