import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

interface InfrastructureDamageState {
    infrastructureDamageList: any[];
    infrastructureDamagesLoading: boolean;
    filteredInfrastructureDamageList: any[];
    infrastructureDamageMeta: any;
    filteredInfrastructureCount: number;
    infrastructureDamageChartList: any;
    infrastructureDamageCount: number;
    selectedInfrastructure: any;
    infrastructureOwnerDetails: any[];
    infrastructureOwnerDetailsMeta: any;
}

const initialState: InfrastructureDamageState = {
    infrastructureDamageList: [],
    infrastructureDamagesLoading: false,
    filteredInfrastructureDamageList: [],
    infrastructureDamageMeta: {},
    filteredInfrastructureCount: 0,
    infrastructureDamageChartList: [],
    infrastructureDamageCount: 0,
    selectedInfrastructure: {},
    infrastructureOwnerDetails: [],
    infrastructureOwnerDetailsMeta: {},
};

export const fetchInfrastructureDamageCount = createAsyncThunk('infrastructureDamage/fetchInfrastructureDamageCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedEvent, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                }
            }
        };
        if (selectedEvent) {
            variables.filter.event = {
                name: {
                    "eq": selectedEvent
                }
            };
        }
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiInfrastructureDamagesCount : API.ApiInfrastructureDamagesCountWithOutChartData,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.infrastructureDamages.meta.pagination.total, infrastructureDamageChartList: response.data.infrastructureDamages.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchInfrastructureDamageData = createAsyncThunk('infrastructureDamage/fetchInfrastructureDamageData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent } = pageData;
        const filter: any = {
            stage: {
                null: true
            }
        };
        if (selectedEvent) {
            filter.event = {
                name: {
                    "eq": selectedEvent
                }
            };
        }
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllInfrastructureDamages,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.infrastructureDamages.data, meta: response.data.infrastructureDamages.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFilterdInfrastructureDamageData = createAsyncThunk('infrastructureDamage/fetchFilterdInfrastructureDamageData', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await client.query({ query: API.ApiInfrastructureDamages, variables, context: { headers }, fetchPolicy: 'no-cache' });
        return { data: response.data.infrastructureDamageDrafts.data, filteredInfrastructureCount: response.data.infrastructureDamageDrafts.meta.pagination.total };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchInfrastructureDamageDataById = createAsyncThunk('infrastructureDamage/fetchInfrastructureDamageDataById', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetInfrastructureDamageById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.infrastructureDamage.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllInfrastructureOwnerDetails = createAsyncThunk('infrastructureDamage/fetchAllInfrastructureOwnerDetails', async (variables: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetAllInfrastructureOwnerDetails, variables, fetchPolicy: 'no-cache' });
        return { data: response.data.infrastructureOwnerDetails.data, meta: response.data.infrastructureOwnerDetails.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createInfrastructureDamageData = createAsyncThunk('infrastructureDamage/createInfrastructureDamageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateInfrastructureDamage,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createInfrastructureDamageDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createInfrastructureOwnerDetail = createAsyncThunk('infrastructureDamage/createInfrastructureOwnerDetail', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateInfrastructureOwnerDetail,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createInfrastructureOwnerDetail.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateInfrastructureDraftData = createAsyncThunk('infrastructureDamage/updateInfrastructureDraftData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingInfrastructureDamageDraft,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateInfrastructureDamageDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateInfrastructureDamageStageData = createAsyncThunk('infrastructureDamage/updateInfrastructureDamageStageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { stage, id, record } = data;
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        if (stage) {
            await client.mutate({
                mutation: API.ApiUpdateInfrastructureDamageStage,
                variables: {
                    id: data.id,
                    stage: data.stage
                },
                context: {
                    headers,
                },
            });
        } else {
            const obj = {
                "divisionId": record.divisionId,
                "districtId": record.districtId,
                "infrastructure_typeId": record.infrastructure_typeId,
                "eventId": record.eventId,
                "damaged": record.damaged,
                "restored": record.restored,
                "latitude": record.latitude ? Number(record.latitude) : null,
                "longitude": record.longitude ? Number(record.longitude) : null,
                "stage": null,
                "tehsilId": record.tehsilId,
                "union_councilId": record.union_councilId,
                "address": record.address,
                "infrastructureDamageId": record.infrastructure_damage
            };
            await client.mutate({
                mutation: obj.infrastructureDamageId ? API.ApiUpdateExistingInfrastructureDamage : API.ApiCreatePublisedInfrastructureDamage,
                variables: obj,
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
            await client.mutate({
                mutation: API.ApiDeleteInfrastructureDamageDraft,
                variables: { id },
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
        }
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteInfrastructureDamageData = createAsyncThunk('infrastructureDamage/deleteInfrastructureDamageData', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteInfrastructureDamage,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateInfrastructureDamageData = createAsyncThunk('infrastructureDamage/updateInfrastructureDamageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingInfrastructureDamage, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateInfrastructureDamage.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateInfrastructureOwnerDetail = createAsyncThunk('infrastructureDamage/updateInfrastructureOwnerDetail', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingInfrastructureOwnerDetail, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateInfrastructureOwnerDetail.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateInfrastructureDamageRecordFromSocket = createAsyncThunk('infrastructureDamage/addAndUpdateInfrastructureDamageRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, internalWorkflowsList } = data;
        const response = await client.query({ query: API.ApiGetInfrastructureDamageById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.infrastructureDamage.data, internalWorkflowsList };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteInfrastructureDamageRecordFromSocket = createAsyncThunk('infrastructureDamage/deleteInfrastructureDamageRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteInfrastructureOwnerDetail = createAsyncThunk('infrastructureDamage/deleteInfrastructureOwnerDetail', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteInfrastructureOwnerDetail,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const getInfrastructureDraftByUser = createAsyncThunk('infrastructureDamage/getInfrastructureDraftByUser', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.APIGetInfrastructureDamageDraftByUser, // Assuming you have an API constant for update mutation
            variables,
            context: {
                headers,
            },
        });
        if (response.data && response.data.infrastructureDamageDrafts.data.length > 0) {
            return true;
        }
        return false;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const infrastructureDamageSlice = createSlice({
    name: 'infrastructureDamage',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchInfrastructureDamageCount.fulfilled, (state, action) => {
                state.infrastructureDamageCount = action.payload.count;
                state.infrastructureDamageChartList = action.payload.infrastructureDamageChartList;
                state.infrastructureDamagesLoading = false;
            })
            .addCase(fetchInfrastructureDamageData.fulfilled, (state, action) => {
                state.infrastructureDamageList = action.payload.data;
                state.infrastructureDamageMeta = action.payload.meta;
                state.infrastructureDamagesLoading = false;
            })
            .addCase(fetchFilterdInfrastructureDamageData.fulfilled, (state, action) => {
                state.filteredInfrastructureDamageList = action.payload.data;
                state.filteredInfrastructureCount = action.payload.filteredInfrastructureCount;
                state.infrastructureDamagesLoading = false;
            })
            .addCase(fetchInfrastructureDamageDataById.fulfilled, (state, action) => {
                state.selectedInfrastructure = action.payload;
                state.infrastructureDamagesLoading = false;
            })
            .addCase(fetchAllInfrastructureOwnerDetails.fulfilled, (state, action) => {
                state.infrastructureOwnerDetails = action.payload.data; // Since action.payload is already an array of District
                state.infrastructureOwnerDetailsMeta = action.payload.meta; // Since action.payload is already an array of District
                state.infrastructureDamagesLoading = false;
            })
            .addCase(createInfrastructureDamageData.fulfilled, (state, action) => {
                // state.infrastructureDamageList.push(action.payload);
                state.infrastructureDamagesLoading = false;
            })
            .addCase(updateInfrastructureDraftData.fulfilled, (state, action) => {
                state.infrastructureDamagesLoading = false;
            })
            .addCase(getInfrastructureDraftByUser.fulfilled, (state, action) => {
                state.infrastructureDamagesLoading = false;
            })
            .addCase(createInfrastructureOwnerDetail.fulfilled, (state, action) => {
                state.infrastructureOwnerDetails.push(action.payload);
                state.infrastructureDamagesLoading = false;
            })
            .addCase(updateInfrastructureDamageStageData.fulfilled, (state, action) => {
                const index = state.filteredInfrastructureDamageList.findIndex(item => item.id === action.payload);
                state.filteredInfrastructureDamageList.splice(index, 1);
                state.infrastructureDamagesLoading = false;
            })
            .addCase(deleteInfrastructureDamageData.fulfilled, (state, action) => {
                state.infrastructureDamagesLoading = false;
            })
            .addCase(updateInfrastructureDamageData.fulfilled, (state, action) => {
                const index = state.filteredInfrastructureDamageList.findIndex(item => item.id === action.payload);
                state.filteredInfrastructureDamageList.splice(index, 1);
                state.infrastructureDamagesLoading = false;
            })
            .addCase(updateInfrastructureOwnerDetail.fulfilled, (state, action) => {
                const index = state.infrastructureOwnerDetails.findIndex(item => item.id === action.payload.id);
                index !== -1 ? state.infrastructureOwnerDetails[index] = action.payload : state.infrastructureOwnerDetails.push(action.payload);
                state.infrastructureDamagesLoading = false;
            })
            .addCase(deleteInfrastructureOwnerDetail.fulfilled, (state, action) => {
                const index = state.infrastructureOwnerDetails.findIndex(item => item.id === action.payload);
                state.infrastructureOwnerDetails.splice(index, 1);
                state.infrastructureDamagesLoading = false;
            })
            .addCase(addAndUpdateInfrastructureDamageRecordFromSocket.fulfilled, (state, action) => {
                const internalWorkflowsList = action.payload.internalWorkflowsList;
                const { id, attributes } = action.payload.data;
                if (attributes.stage) {
                    const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === attributes.stage);
                    const index = state.filteredInfrastructureDamageList.findIndex(item => item.id == id);
                    if (selectedWorkFlow) {
                        index !== -1 ? state.filteredInfrastructureDamageList[index] = action.payload.data : state.filteredInfrastructureDamageList.push(action.payload.data);
                    } else {
                        state.filteredInfrastructureDamageList.splice(index, 1);
                    }
                } else {
                    const index = state.infrastructureDamageList.findIndex(item => item.id == id);
                    index !== -1 ? state.infrastructureDamageList[index] = action.payload.data : state.infrastructureDamageList.push(action.payload.data);
                }
            })
            .addCase(deleteInfrastructureDamageRecordFromSocket.fulfilled, (state, action) => {
                state.infrastructureDamageList = state.infrastructureDamageList.filter(item => item.id != action.payload);
                state.filteredInfrastructureDamageList = state.filteredInfrastructureDamageList.filter(item => item.id != action.payload);
            });

        [
            fetchInfrastructureDamageCount,
            fetchInfrastructureDamageData,
            fetchFilterdInfrastructureDamageData,
            fetchInfrastructureDamageDataById,
            updateInfrastructureDamageStageData,
            createInfrastructureDamageData,
            deleteInfrastructureDamageData,
            updateInfrastructureDamageData,
            fetchAllInfrastructureOwnerDetails,
            createInfrastructureOwnerDetail,
            updateInfrastructureOwnerDetail,
            deleteInfrastructureOwnerDetail,
            updateInfrastructureDraftData,
            getInfrastructureDraftByUser
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.infrastructureDamagesLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.infrastructureDamagesLoading = false;
                });
        });
    }
});


export default infrastructureDamageSlice.reducer;
