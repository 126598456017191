import React, { useEffect, useState } from 'react';
import OverviewIcon from '../../assets/images/OverviewIcon.png';
import lineIcon from '../../assets/images/lineIcno.png';
import NGOMapping from '../../assets/images/NGO Mapping.png';
import VolunteersMapping from '../../assets/images/Volunteers Mapping.png';
import ManufacturersMapping from '../../assets/images/Manufacturers Mapping.png';
import SuppliersMapping from '../../assets/images/Suppliers Mapping.png';
import SchoolMapping from '../../assets/images/School Mapping.png';
import PlusIcon from '../../assets/images/plusIcon.png';
import ReusableTable from '../../components/table';
import SearchSelect from '../../components/selectDropdown';
import Filters from '../../components/top_filter';
import MapChartComponent from '../../components/Map';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../reduxToolkit/store";
import { Button, Dropdown, Input, MenuProps, Tabs } from 'antd';
import { deleteNgoData, fetchNGOCount, fetchNGOData } from '../../reduxToolkit/ngoSlice';
import { deleteVolunteerData, fetchVolunteerCount, fetchVolunteerData } from '../../reduxToolkit/volunteerSlice';
import { deleteManufacturerData, fetchManufacturerCount, fetchManufacturerData } from '../../reduxToolkit/manufacturerSlice';
import { deleteSupplierData, fetchSupplierCount, fetchSupplierData } from '../../reduxToolkit/supplierSlice';
import { numberFormater } from '../../helpers/common';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { alignManufacturersData, alignNgoData, alignsSchoolsData, alignsSuppliersData, alignVolunteersData } from '../../helpers/alignData';
import { manufacturersColumns, ngoColumns, schoolsColumns, suppliersColumns, volunteersColumns } from '../../helpers/tableColumns';
import { fetchSchoolCount, fetchSchoolData } from '../../reduxToolkit/schoolSlice';
import './index.css';
import { CardComponent } from '../../components';

const RecourceMapping: React.FC = () => {
    const dispatch = useDispatch<any>();
    const { userData } = useSelector((state: RootState) => state.auth);
    const { editorMode, isAuthenticated } = useSelector((state: RootState) => state.auth);
    const { ngoList, ngoMeta, ngoCount, ngoChartList } = useSelector((state: RootState) => state.ngos);
    const { volunteerList, volunteersMeta, volunteerCount, volunteerChartList } = useSelector((state: RootState) => state.volunteer);
    const { manufacturerList, manufacturersMeta, manufacturerCount, manufacturerChartList } = useSelector((state: RootState) => state.manufacturer);
    const { supplierList, suppliersMeta, supplierCount, supplierChartList } = useSelector((state: RootState) => state.supplier);
    const { schoolList, schoolMeta, schoolCount, schoolChartList } = useSelector((state: RootState) => state.school);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedTehsil, setSelectedTehsil] = useState('');
    const [selectedUnionCouncil, setSelectedUnionCouncil] = useState('');
    const [selectedParameter, setSelectedParameter] = useState('');
    const [selectedKeyword, setSelectedKeyword] = useState('');
    const [selectedCard, setSelectedCard] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (selectedCard === 1) {
                await dispatch(fetchNGOCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 2) {
                await dispatch(fetchVolunteerCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 3) {
                await dispatch(fetchManufacturerCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 4) {
                await dispatch(fetchSupplierCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 5) {
                await dispatch(fetchSchoolCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            }
            if (selectedCard === 1 && isAuthenticated) {
                await dispatch(fetchNGOData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 2 && isAuthenticated) {
                await dispatch(fetchVolunteerData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 3 && isAuthenticated) {
                await dispatch(fetchManufacturerData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 4 && isAuthenticated) {
                await dispatch(fetchSupplierData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 5 && isAuthenticated) {
                await dispatch(fetchSchoolData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            }
        })();
    }, [selectedCard, isAuthenticated]);

    useEffect(() => {
        dispatch(fetchNGOCount({ withLocation: selectedCard === 1, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        dispatch(fetchVolunteerCount({ withLocation: selectedCard === 2, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        dispatch(fetchManufacturerCount({ withLocation: selectedCard === 3, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        dispatch(fetchSupplierCount({ withLocation: selectedCard === 4, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        dispatch(fetchSchoolCount({ withLocation: selectedCard === 5, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
    }, [selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil]);

    useEffect(() => {
        if (isAuthenticated && selectedCard === 1) {
            dispatch(fetchNGOData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        } else if (isAuthenticated && selectedCard === 2) {
            dispatch(fetchVolunteerData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        } else if (isAuthenticated && selectedCard === 3) {
            dispatch(fetchManufacturerData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        } else if (isAuthenticated && selectedCard === 4) {
            dispatch(fetchSupplierData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        } else if (isAuthenticated && selectedCard === 5) {
            dispatch(fetchSchoolData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        }
    }, [rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, isAuthenticated]);

    useEffect(() => {
        if (userData?.division?.division_name && userData?.district?.district_name) {
            setSelectedDivision(userData?.division?.division_name);
            setSelectedDistrict(userData?.district?.district_name);
        }
    }, [userData]);

    const ngoData = alignNgoData(ngoList);
    const volunteersData = alignVolunteersData(volunteerList);
    const manufacturersData = alignManufacturersData(manufacturerList);
    const suppliersData = alignsSuppliersData(supplierList);
    const schoolsData = alignsSchoolsData(schoolList);

    const handleMenuClick = () => {
        const formHeading = selectedCard === 1 ? 'NGO Mapping' : selectedCard === 2 ? 'Volunteers Mapping' : selectedCard === 3 ? 'Manufacturers Mapping' : selectedCard === 4 ? 'Suppliers Mapping' : '';
        navigate("/form", { state: { formHeading } });
    };

    const items: MenuProps['items'] = [
        {
            label: 'NGO Mapping',
            key: '1',
        },
        {
            label: 'Volunteers Mapping',
            key: '2',
        },
        {
            label: 'Manufacturers Mapping',
            key: '3',
        },
        {
            label: 'Suppliers Mapping',
            key: '4',
        },
    ];

    const deleteData = (formHeading: String, id: String) => {
        if (formHeading === "NGO Mapping") {
            dispatch(deleteNgoData(id));
        } else if (formHeading === 'Volunteers Mapping') {
            dispatch(deleteVolunteerData(id));
        } else if (formHeading === 'Manufacturers Mapping') {
            dispatch(deleteManufacturerData(id));
        } else if (formHeading === 'Suppliers Mapping') {
            dispatch(deleteSupplierData(id));
        }
    };

    const naviagteToEdit = (formHeading: String, data: any) => {
        navigate("/form", {
            state: {
                formHeading,
                recordId: data.id,
                data: {
                    ...data,
                    ngo: data.id,
                    volunteer: data.id,
                    manufacturer: data.id,
                    supplier: data.id,
                },
                isForUpdate: true
            }
        });
    };

    const actionButtons = (formHeading: String) => {
        return {
            title: 'Actions',
            hidden: !editorMode,
            width: 150,
            align: 'center' as 'center',
            render: (text: any, record: any) => {
                const ableToAction = userData?.division?.division_name === record.division && userData?.district?.district_name === record.district;
                return (ableToAction) && <span onClick={(e) => e.stopPropagation()}>
                    <Button
                        type="primary"
                        title='Edit'
                        style={{ background: '#357A38' }}
                        className='m-v-5'
                        icon={<EditOutlined />}
                        onClick={() => naviagteToEdit(formHeading, record)}
                        shape='circle' />
                    <Button
                        type="primary"
                        title='Delete'
                        style={{ background: '#357A38' }}
                        className='m-v-5'
                        icon={<DeleteOutlined />}
                        onClick={() => deleteData(formHeading, record.id)}
                        shape='circle' />
                </span>;
            },
        };
    };

    const mapData = [
        ...selectedCard === 1 ? alignNgoData(ngoChartList) : [],
        ...selectedCard === 2 ? alignVolunteersData(volunteerChartList) : [],
        ...selectedCard === 3 ? alignManufacturersData(manufacturerChartList) : [],
        ...selectedCard === 4 ? alignsSuppliersData(supplierChartList) : [],
        ...selectedCard === 5 ? alignsSchoolsData(schoolChartList) : []
    ];

    return (
        <div className='mainCont'>
            <Filters
                setSelectedDivision={setSelectedDivision}
                selectedDivision={selectedDivision}
                setSelectedDistrict={setSelectedDistrict}
                selectedDistrict={selectedDistrict}
                setSelectedTehsil={setSelectedTehsil}
                selectedTehsil={selectedTehsil}
                setSelectedUnionCouncil={setSelectedUnionCouncil}
                selectedUnionCouncil={selectedUnionCouncil}
            />
            <div className='top-lable-cont'>
                <div>
                    <img src={OverviewIcon} alt="Overview_icon" style={{ width: "21px" }} />
                    <span>Overview</span>
                </div>
                <img src={lineIcon} alt="line_icon" style={{ width: "32px" }} />
            </div>
            <div className='card-cont'>
                <CardComponent
                    title="NGO Mapping"
                    icon={NGOMapping}
                    count={ngoCount}
                    selectedCard={selectedCard}
                    cardNumber={1}
                    onChangeCard={() => { setSelectedCard(1); setSelectedParameter(''); setSelectedKeyword(''); }}
                />
                <CardComponent
                    title="Volunteers Mapping"
                    icon={VolunteersMapping}
                    count={volunteerCount}
                    selectedCard={selectedCard}
                    cardNumber={2}
                    onChangeCard={() => { setSelectedCard(2); setSelectedParameter(''); setSelectedKeyword(''); }}
                />
                <CardComponent
                    title="Manufacturers Mapping"
                    icon={ManufacturersMapping}
                    count={manufacturerCount}
                    selectedCard={selectedCard}
                    cardNumber={3}
                    onChangeCard={() => { setSelectedCard(3); setSelectedParameter(''); setSelectedKeyword(''); }}
                />
                <CardComponent
                    title="Suppliers Mapping"
                    icon={SuppliersMapping}
                    count={supplierCount}
                    selectedCard={selectedCard}
                    cardNumber={4}
                    onChangeCard={() => { setSelectedCard(4); setSelectedParameter(''); setSelectedKeyword(''); }}
                />
                <CardComponent
                    title="School Mapping"
                    icon={SchoolMapping}
                    count={schoolCount}
                    selectedCard={selectedCard}
                    cardNumber={5}
                    onChangeCard={() => { setSelectedCard(5); setSelectedParameter(''); setSelectedKeyword(''); }}
                />
            </div>
            {!editorMode && <>
                <div className='LossesDamages-Mapcharts-cont' style={{ marginTop: "20px", height: "auto" }}>
                    <div className='LossesDamages_charts_headingCont'>
                        <span style={{ padding: '0' }}>Sindh Map Overview</span>
                        <div className='LossesDamages_charts_heading'>
                        </div>
                        <img src={lineIcon} alt="" style={{ height: '32px' }} />
                    </div>
                    <div className='LossesDamageschartOne' style={{ height: "auto" }}>
                        <div className='LchartLabalInner'>
                            <span style={{ color: '#357a38' }}>Resource Mapping</span>
                        </div>
                        <MapChartComponent data={mapData} />
                    </div>
                </div>
            </>}
            {isAuthenticated ? <>
                <div className='ResourceMapping-table-cont'>
                    <div className='ResourceMapping-table-filter-btn-cont'>
                        {(!!editorMode && selectedCard !== 5) ? <div className='admin-add-data-btn' onClick={handleMenuClick}>
                            <img src={PlusIcon} alt="" />
                            <span>Add New Data</span>
                        </div> : <div />}
                        <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap', width: "500px" }}>
                            <SearchSelect
                                onChange={env => { setSelectedParameter(env); setSelectedKeyword(''); }}
                                placeholder="Select the Parameter"
                                className="filter-input select-param-input"
                                value={selectedParameter || undefined}
                                options={(selectedCard === 1 ? ngoColumns : selectedCard === 2 ? volunteersColumns : selectedCard === 3 ? manufacturersColumns : selectedCard === 4 ? suppliersColumns : selectedCard === 5 ? schoolsColumns : [])
                                    .filter(item => item.title !== 'Google Map Location')
                                    .map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))
                                }
                                allowClear={true}
                            />
                            <Input
                                placeholder="Keyword Search"
                                className=' filter-input select-param-input'
                                allowClear={true}
                                disabled={!selectedParameter}
                                onChange={({ target }) => setSelectedKeyword(target.value)}
                                value={selectedKeyword}
                            />
                            <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                                <span>Show:</span>
                                <SearchSelect
                                    onChange={(value: String) => setRowsPerPage(+value)}
                                    placeholder="Select the District"
                                    className="w-100px"
                                    value={rowsPerPage}
                                    options={[
                                        { value: "2", label: "2 Entries" },
                                        { value: "5", label: "5 Entries" },
                                        { value: "8", label: "8 Entries" },
                                        { value: "10", label: "10 Entries" },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    {selectedCard === 1 ? <ReusableTable
                        data={(selectedParameter && selectedKeyword) ? ngoData.filter((item: any) => {
                            const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                            return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                        }) : ngoData}
                        columns={[...ngoColumns, actionButtons("NGO Mapping")]}
                        rowsPerPage={rowsPerPage}
                        total={ngoMeta?.pagination?.total}
                        onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'NGO Mapping', recordId: r.id } })}
                        onChangePagination={(page: number) => dispatch(fetchNGOData({ page, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }))}
                    />
                        : selectedCard === 2 ? <ReusableTable
                            data={(selectedParameter && selectedKeyword) ? volunteersData.filter((item: any) => {
                                const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                                return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                            }) : volunteersData}
                            columns={[...volunteersColumns, actionButtons("Volunteers Mapping")]}
                            rowsPerPage={rowsPerPage}
                            total={volunteersMeta?.pagination?.total}
                            onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Volunteers Mapping', recordId: r.id } })}
                            onChangePagination={(page: number) => dispatch(fetchVolunteerData({ page, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }))}
                        />
                            : selectedCard === 3 ? <ReusableTable
                                data={(selectedParameter && selectedKeyword) ? manufacturersData.filter((item: any) => {
                                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                                }) : manufacturersData}
                                columns={[...manufacturersColumns, actionButtons("Manufacturers Mapping")]}
                                rowsPerPage={rowsPerPage}
                                total={manufacturersMeta?.pagination?.total}
                                onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Manufacturers Mapping', recordId: r.id } })}
                                onChangePagination={(page: number) => dispatch(fetchManufacturerData({ page, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }))}
                            />
                                : selectedCard === 4 ? <ReusableTable
                                    data={(selectedParameter && selectedKeyword) ? suppliersData.filter((item: any) => {
                                        const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                                        return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                                    }) : suppliersData}
                                    columns={[...suppliersColumns, actionButtons("Suppliers Mapping")]}
                                    rowsPerPage={rowsPerPage}
                                    total={suppliersMeta?.pagination?.total}
                                    onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Suppliers Mapping', recordId: r.id } })}
                                    onChangePagination={(page: number) => dispatch(fetchSupplierData({ page, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }))}
                                />
                                    : selectedCard === 5 ? <ReusableTable
                                        data={(selectedParameter && selectedKeyword) ? schoolsData.filter((item: any) => {
                                            const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                                            return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                                        }) : schoolsData}
                                        columns={schoolsColumns}
                                        rowsPerPage={rowsPerPage}
                                        total={schoolMeta?.pagination?.total}
                                        onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'School Mapping', recordId: r.id } })}
                                        onChangePagination={(page: number) => dispatch(fetchSchoolData({ page, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }))}
                                    />
                                        : null}
                </div>
            </> : null}
        </div>
    );
};

export default RecourceMapping;