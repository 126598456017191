import React from 'react';
import './index.css'; // Assuming you have a CSS file for styling

const ProgressBar = ({ percentage , color } : any) => {
    return (
        <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${percentage}%`, backgroundColor: `${color}` }} />
        </div>
    );
};

export default ProgressBar;
