import React, { useEffect, useState } from 'react';
import lineIcon from '../../assets/images/lineIcno.png';
import foodStockChartIcon from '../../assets/images/foodStockChartIcon.png';
import madicenChartIcon from '../../assets/images/medicineChartIcon.png';
import machrineChartIcon from '../../assets/images/machrineChartIcon.png';
import houseHoldChartIcon from '../../assets/images/houseHoldChartIcon.png';
import PieChart from '../../components/charts/pie_chart';
import PieChartWithRadius from '../../components/charts/pie_chart_with_radius';
import PlusIcon from '../../assets/images/plusIcon.png';
import ReusableTable from '../../components/table';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../reduxToolkit/store";
import SearchSelect from '../../components/selectDropdown';
import {
    deleteInventoryData,
    fetchFoodData,
    fetchHouseholdData,
    fetchFoodCount,
    fetchMachineryData,
    fetchMedicineData,
    fetchMedicineCount,
    fetchHouseholdCount,
    fetchMachineryCount
} from '../../reduxToolkit/inventorySlice';
import OverviewIcon from '../../assets/images/OverviewIcon.png';
import { Dropdown, Tabs, MenuProps, Input, Space, Button } from 'antd';
import Filters from '../../components/top_filter';
import { useNavigate } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { alignInventoryData } from '../../helpers/alignData';
import { inventoryColumns } from '../../helpers/tableColumns';
import './index.css';
import { CardComponent } from '../../components';

const Inventory: React.FC = () => {
    const dispatch = useDispatch<any>();
    const { editorMode, isAuthenticated } = useSelector((state: RootState) => state.auth);
    const {
        foodList,
        foodMeta,
        foodCount,
        foodChartList,
        medicineList,
        medicineMeta,
        medicineCount,
        medicineChartList,
        householdList,
        householdMeta,
        householdCount,
        householdChartList,
        machineryList,
        machineryMeta,
        machineryCount,
        machineryChartList
    } = useSelector((state: RootState) => state.inventory);
    const { userData } = useSelector((state: RootState) => state.auth);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedTehsil, setSelectedTehsil] = useState('');
    const [selectedUnionCouncil, setSelectedUnionCouncil] = useState('');
    const [selectedParameter, setSelectedParameter] = useState('');
    const [selectedKeyword, setSelectedKeyword] = useState('');
    const [selectedCard, setSelectedCard] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchFoodCount({ withLocation: selectedCard === 1, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        dispatch(fetchMedicineCount({ withLocation: selectedCard === 2, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        dispatch(fetchHouseholdCount({ withLocation: selectedCard === 3, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        dispatch(fetchMachineryCount({ withLocation: selectedCard === 4, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
    }, [selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil]);

    useEffect(() => {
        (async () => {
            if (selectedCard === 1) {
                await dispatch(fetchFoodCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 2) {
                await dispatch(fetchMedicineCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 3) {
                await dispatch(fetchHouseholdCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 4) {
                await dispatch(fetchMachineryCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            }
            if (selectedCard === 1 && isAuthenticated) {
                await dispatch(fetchFoodData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 2 && isAuthenticated) {
                await dispatch(fetchMedicineData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 3 && isAuthenticated) {
                await dispatch(fetchHouseholdData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            } else if (selectedCard === 4 && isAuthenticated) {
                await dispatch(fetchMachineryData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
            }
        })();
    }, [selectedCard, isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && selectedCard === 1) {
            dispatch(fetchFoodData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        } else if (isAuthenticated && selectedCard === 2) {
            dispatch(fetchMedicineData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        } else if (isAuthenticated && selectedCard === 3) {
            dispatch(fetchHouseholdData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        } else if (isAuthenticated && selectedCard === 4) {
            dispatch(fetchMachineryData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }));
        }
    }, [rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, isAuthenticated]);

    useEffect(() => {
        if (userData?.division?.division_name && userData?.district?.district_name) {
            setSelectedDivision(userData?.division?.division_name);
            setSelectedDistrict(userData?.district?.district_name);
        }
    }, [userData]);

    const foodStockData = alignInventoryData(foodList);
    const medicineStockData = alignInventoryData(medicineList);
    const householdStockData = alignInventoryData(householdList);
    const machineryStockData = alignInventoryData(machineryList);

    const foodStockChartData = calculateQtyChartData(alignInventoryData(foodChartList), 'Kg/L');
    const medicineStockChartData = calculateQtyChartData(alignInventoryData(medicineChartList), 'Units');
    const householdStockChartData = calculateQtyChartData(alignInventoryData(householdChartList), 'Qty');
    const machineryStockChartData = calculateQtyChartData(alignInventoryData(machineryChartList), 'Qty');

    const actionButtons = () => {
        return {
            title: 'Actions',
            hidden: !editorMode,
            render: (text: any, record: any) => {
                const ableToAction = userData?.division?.division_name === record.division && userData?.district?.district_name === record.district;
                return (ableToAction) && <span onClick={(e) => e.stopPropagation()}>
                    <Button
                        type="primary"
                        title='Edit'
                        style={{ background: '#357A38' }}
                        className='m-v-5'
                        icon={<EditOutlined />}
                        onClick={() => navigate("/form", {
                            state: {
                                formHeading: "Inventory",
                                recordId: record.id,
                                data: {
                                    ...record,
                                    inventory: record.id,
                                },
                                isForUpdate: true
                            }
                        })}
                        shape='circle' />
                    <Button
                        type="primary"
                        title='Delete'
                        style={{ background: '#357A38' }}
                        className='m-v-5'
                        icon={<DeleteOutlined />}
                        onClick={() => dispatch(deleteInventoryData(record.id))}
                        shape='circle' />
                </span>;
            },
        };
    };

    return (
        <div className='Inventory-mainCont'>
            <Filters
                setSelectedDivision={setSelectedDivision}
                selectedDivision={selectedDivision}
                setSelectedDistrict={setSelectedDistrict}
                selectedDistrict={selectedDistrict}
                setSelectedTehsil={setSelectedTehsil}
                selectedTehsil={selectedTehsil}
                setSelectedUnionCouncil={setSelectedUnionCouncil}
                selectedUnionCouncil={selectedUnionCouncil}
            />
            <div className='top-lable-cont'>
                <div>
                    <img src={OverviewIcon} alt="Overview_icon" style={{ width: "21px" }} />
                    <span>Overview</span>
                </div>
                <img src={lineIcon} alt="line_icon" style={{ width: "32px" }} />
            </div>
            <div className='card-cont'>
                <CardComponent
                    title="Food Stock"
                    icon={foodStockChartIcon}
                    count={foodCount}
                    selectedCard={selectedCard}
                    cardNumber={1}
                    onChangeCard={() => { setSelectedCard(1); setSelectedParameter(''); setSelectedKeyword(''); }}
                />
                <CardComponent
                    title="Medicine Stock"
                    icon={madicenChartIcon}
                    count={medicineCount}
                    selectedCard={selectedCard}
                    cardNumber={2}
                    onChangeCard={() => { setSelectedCard(2); setSelectedParameter(''); setSelectedKeyword(''); }}
                />
                <CardComponent
                    title="Household Stock"
                    icon={houseHoldChartIcon}
                    count={householdCount}
                    selectedCard={selectedCard}
                    cardNumber={3}
                    onChangeCard={() => { setSelectedCard(3); setSelectedParameter(''); setSelectedKeyword(''); }}
                />
                <CardComponent
                    title="Machinery Stock"
                    icon={machrineChartIcon}
                    count={machineryCount}
                    selectedCard={selectedCard}
                    cardNumber={4}
                    onChangeCard={() => { setSelectedCard(4); setSelectedParameter(''); setSelectedKeyword(''); }}
                />
            </div>
            {!editorMode && <>
                {
                    selectedCard === 1 ? <div className='Inventory_charts'>
                        <div className='Inventory_charts_headingCont'>
                            <div className='Inventory_charts_heading'>
                                <img src={foodStockChartIcon} alt="" />
                                <span>Food Stock Overview:</span>
                            </div>
                            <img src={lineIcon} className='filter-image' alt="" />
                        </div>
                        <div className='Inventorychart'>
                            <div className='IchartLabal'>
                                <span>Total no of Items: {foodCount}</span>
                                <span style={{ color: "#357A38" }}>In-Stock ( Kg/L )</span>
                            </div>
                            <div className='Ichart'>
                                <PieChart data={foodStockChartData} angleField="Kg/L" />
                            </div>
                        </div>
                    </div>
                        : selectedCard === 2 ? <div className='Inventory_charts'>
                            <div className='Inventory_charts_headingCont'>
                                <div className='Inventory_charts_heading'>
                                    <img src={madicenChartIcon} alt="" />
                                    <span>Medicine Stock Overview:</span>
                                </div>
                                <img src={lineIcon} className='filter-image' alt="" />
                            </div>
                            <div className='Inventorychart'>
                                <div className='IchartLabal'>
                                    <span>Total no of Items: {medicineCount}</span>
                                    <span style={{ color: "#357A38" }}>In-Stock ( Units )</span>
                                </div>
                                <div className='Ichart'>
                                    <PieChartWithRadius data={medicineStockChartData} angleField="Units" annotations={annotationsUnits} />
                                </div>
                            </div>
                        </div>
                            : selectedCard === 3 ? <div className='Inventory_charts'>
                                <div className='Inventory_charts_headingCont'>
                                    <div className='Inventory_charts_heading'>
                                        <img src={houseHoldChartIcon} alt="" />
                                        <span>Household Stock Overview:</span>
                                    </div>
                                    <img className='filter-image' src={lineIcon} alt="" />
                                </div>
                                <div className='Inventorychart'>
                                    <div className='IchartLabal'>
                                        <span>Total no of Items: {householdCount}</span>
                                        <span style={{ color: "#357A38" }}>In-Stock ( Qty )</span>
                                    </div>
                                    <div className='Ichart'>
                                        <PieChartWithRadius data={householdStockChartData} angleField="Qty" annotations={annotationsQty} />
                                    </div>
                                </div>
                            </div>
                                : selectedCard === 4 ? <div className='Inventory_charts'>
                                    <div className='Inventory_charts_headingCont'>
                                        <div className='Inventory_charts_heading'>
                                            <img src={machrineChartIcon} alt="" />
                                            <span>Machinery Stock Overview:</span>
                                        </div>
                                        <img src={lineIcon} className='filter-image' alt="" />
                                    </div>
                                    <div className='Inventorychart'>
                                        <div className='IchartLabal'>
                                            <span>Total no of Items: {machineryCount}</span>
                                            <span style={{ color: "#357A38" }}>In-Stock ( Qty )</span>
                                        </div>
                                        <div className='Ichart'>
                                            <PieChart data={machineryStockChartData} angleField="Qty" />
                                        </div>
                                    </div>
                                </div>
                                    : null
                }
                {/* <div className='Inventory-charts-cont' style={{ borderTop: '1px solid rgb(219,219,219)', paddingTop: '10px', marginTop: '10px' }}> */}

                {/*  */}
                {/* </div>
                <div className='Inventory-charts-cont' style={{ marginTop: "0px" }}>
                   
                 
                </div> */}
            </>}
            {isAuthenticated ? <>
                <div className='Inventory-table-cont' >
                    <div className='ResourceMapping-table-filter-btn-cont'>
                        {!!editorMode ? <div className='admin-add-data-btn' onClick={() => navigate("/form", { state: { formHeading: 'Inventory' } })}>
                            <img src={PlusIcon} alt="" />
                            <span>Add New Data</span>
                        </div> : <div />}
                        <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap' }}>
                            <SearchSelect
                                onChange={env => { setSelectedParameter(env); setSelectedKeyword(''); }}
                                placeholder="Select the Parameter"
                                className="filter-input select-param-input"
                                value={selectedParameter || undefined}
                                options={inventoryColumns
                                    .filter(item => item.title !== 'Google Map Location')
                                    .map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))
                                }
                                allowClear={true}
                            />
                            <Input
                                placeholder="Keyword Search"
                                className=' filter-input select-param-input'
                                disabled={!selectedParameter}
                                allowClear={true}
                                onChange={({ target }) => setSelectedKeyword(target.value)}
                                value={selectedKeyword}
                            />
                            <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                                <span>Show:</span>
                                <SearchSelect
                                    onChange={(value: String) => setRowsPerPage(+value)}
                                    placeholder="Select the District"
                                    className="w-100px"
                                    value={rowsPerPage}
                                    options={[
                                        { value: "2", label: "2 Entries" },
                                        { value: "5", label: "5 Entries" },
                                        { value: "8", label: "8 Entries" },
                                        { value: "10", label: "10 Entries" },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    {selectedCard === 1 ? <ReusableTable
                        data={(selectedParameter && selectedKeyword) ? foodStockData.filter((item: any) => {
                            const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                            return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                        }) : foodStockData}
                        columns={[...inventoryColumns, actionButtons()]}
                        rowsPerPage={rowsPerPage}
                        total={foodMeta?.pagination?.total}
                        onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Inventory', recordId: r.id } })}
                        onChangePagination={(page: number) => dispatch(fetchFoodData({ page, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }))}
                    />
                        : selectedCard === 2 ? <ReusableTable
                            data={(selectedParameter && selectedKeyword) ? medicineStockData.filter((item: any) => {
                                const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                                return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                            }) : medicineStockData}
                            columns={[...inventoryColumns, actionButtons()]}
                            rowsPerPage={rowsPerPage}
                            total={medicineMeta?.pagination?.total}
                            onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Inventory', recordId: r.id } })}
                            onChangePagination={(page: number) => dispatch(fetchMedicineData({ page, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }))}
                        />
                            : selectedCard === 3 ? <ReusableTable
                                data={(selectedParameter && selectedKeyword) ? householdStockData.filter((item: any) => {
                                    const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                                    return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                                }) : householdStockData}
                                columns={[...inventoryColumns, actionButtons()]}
                                rowsPerPage={rowsPerPage}
                                total={householdMeta?.pagination?.total}
                                onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Inventory', recordId: r.id } })}
                                onChangePagination={(page: number) => dispatch(fetchHouseholdData({ page, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }))}
                            />
                                : selectedCard === 4 ? <ReusableTable
                                    data={(selectedParameter && selectedKeyword) ? machineryStockData.filter((item: any) => {
                                        const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                                        return value && (Array.isArray(item[selectedParameter]) ? item[selectedParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedKeyword.toLowerCase())) : value.includes(selectedKeyword.toLowerCase()));
                                    }) : machineryStockData}
                                    columns={[...inventoryColumns, actionButtons()]}
                                    rowsPerPage={rowsPerPage}
                                    total={machineryMeta?.pagination?.total}
                                    onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Inventory', recordId: r.id } })}
                                    onChangePagination={(page: number) => dispatch(fetchMachineryData({ page, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil }))}
                                />
                                    : null}

                </div>
            </> : null}
        </div>
    );
};

export default Inventory;

const calculateQtyChartData = (StockData: any, value: any) => {
    const cumulativeQuantities: any = {};

    StockData.forEach((item: any) => {
        cumulativeQuantities[item.product_name] = (cumulativeQuantities[item.product_name] || 0) + item.on_hand_quantity;
    });

    return Object.entries(cumulativeQuantities).map(([Item, Qty]) => ({ Item, [value]: Qty, Unit: value }));
};

const annotationsUnits = [
    {
        type: 'text',
        style: {
            text: 'In-Stock ( Units )',
            x: '50%',
            y: '50%',
            textAlign: 'center',
            fontSize: 18,
            fontStyle: 'bold',
        },
    },
];

const annotationsQty = [
    {
        type: 'text',
        style: {
            text: 'In-Stock ( Qty )',
            x: '50%',
            y: '50%',
            textAlign: 'center',
            fontSize: 18,
            fontStyle: 'bold',
        },
    },
];
