import { Dropdown, Space } from "antd";
import googleMaps from '../assets/images/google-maps-2020-icon 1.png';

export const ngoColumns = [
    {
        title: 'NGO Name',
        dataIndex: 'ngo_name',
        key: 'ngo_name',
        sorter: (a, b) => (a.ngo_name ?? '').localeCompare(b.ngo_name ?? '')
    },
    // {
    //     title: 'Primary Areas',
    //     dataIndex: 'primary_areas',
    //     key: 'primary_areas',
    //     render: (tags) => {
    //         if (!tags) {
    //             return <span></span>;
    //         } else if (tags && tags.length > 1) {
    //             return <Space size="middle">
    //                 <Dropdown menu={{
    //                     items: tags.slice(1).map(item => ({ key: item || '', label: item || '' }))
    //                 }}>
    //                     <span> {tags[0]} ...</span>
    //                 </Dropdown>
    //             </Space>;
    //         } else {
    //             return <span> {tags[0]}</span>;
    //         }
    //     },
    // },
    // {
    //     title: 'Secondary Areas',
    //     dataIndex: 'secondary_areas',
    //     key: 'secondary_areas',
    //     render: (tags) => {
    //         if (!tags) {
    //             return <span></span>;
    //         } else if (tags && tags.length > 1) {
    //             return <Space size="middle">
    //                 <Dropdown menu={{
    //                     items: tags.slice(1).map(item => ({ key: item || '', label: item || '' }))
    //                 }}>
    //                     <span> {tags[0]} ...</span>
    //                 </Dropdown>
    //             </Space>;
    //         } else {
    //             return <span> {tags[0]}</span>;
    //         }
    //     },
    // },
    {
        title: 'License Registration Number',
        dataIndex: 'license_registration_number',
        key: 'license_registration_number',
        sorter: (a, b) => (a.license_registration_number ?? '').localeCompare(b.license_registration_number ?? ''),
    },
    {
        title: 'Organizational Head',
        dataIndex: 'organizational_head',
        key: 'organizational_head',
        sorter: (a, b) => (a.organizational_head ?? '').localeCompare(b.organizational_head ?? ''),
    },
    {
        title: 'Google Map Location',
        render: (text, record) => <span
            className='map-icon'
            onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${record.latitude},${record.longitude}`, "_blank")}>
            <img src={googleMaps} />
        </span>,
    }
];

export const volunteersColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
        title: 'CNIC Number',
        dataIndex: 'cnic_number',
        key: 'cnic_number',
        sorter: (a, b) => (a.cnic_number ?? 0) - (b.cnic_number ?? 0),
    },
    {
        title: 'Contact Person',
        dataIndex: 'contact_person',
        key: 'contact_person',
        sorter: (a, b) => (a.contact_person ?? '').localeCompare(b.contact_person ?? ''),
    },
    {
        title: 'Google Map Location',
        render: (text, record) => <span
            className='map-icon'
            onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${record.latitude},${record.longitude}`, "_blank")}>
            <img src={googleMaps} />
        </span>,
    },
];

export const manufacturersColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
        title: 'Organizational Head',
        dataIndex: 'organizational_head',
        key: 'organizational_head',
        sorter: (a, b) => (a.organizational_head ?? '').localeCompare(b.organizational_head ?? ''),
    },
    {
        title: 'Contact Number',
        dataIndex: 'contact_number',
        key: 'contact_number',
        sorter: (a, b) => (a.contact_number ?? 0) - (b.contact_number ?? 0),
    },
    {
        title: 'Google Map Location',
        render: (text, record) => <span
            className='map-icon'
            onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${record.latitude},${record.longitude}`, "_blank")}>
            <img src={googleMaps} />
        </span>,
    },
];

export const suppliersColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
        title: 'Registration Number',
        dataIndex: 'registration_number',
        key: 'registration_number',
        sorter: (a, b) => (a.registration_number ?? '').localeCompare(b.registration_number ?? ''),
    },
    {
        title: 'Organizational Head',
        dataIndex: 'organizational_head',
        key: 'organizational_head',
        sorter: (a, b) => (a.organizational_head ?? '').localeCompare(b.organizational_head ?? ''),
    },
    {
        title: 'Contact Number',
        dataIndex: 'contact_number',
        key: 'contact_number',
        sorter: (a, b) => (a.contact_number ?? 0) - (b.contact_number ?? 0),
    },
    {
        title: 'Google Map Location',
        render: (text, record) => <span
            className='map-icon'
            onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${record.latitude},${record.longitude}`, "_blank")}>
            <img src={googleMaps} />
        </span>,
    },
];

export const schoolsColumns = [
    {
        title: 'SEMIS Code',
        dataIndex: 'semis_code',
        key: 'semis_code',
        sorter: (a, b) => (a.semis_code ?? 0) - (b.semis_code ?? 0),
    },
    {
        title: 'School Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
        title: 'Building Condition',
        dataIndex: 'building_condition',
        key: 'building_condition',
        sorter: (a, b) => (a.building_condition ?? '').localeCompare(b.building_condition ?? ''),
    },
    {
        title: 'Number of Rooms',
        dataIndex: 'number_of_rooms',
        key: 'number_of_rooms',
        sorter: (a, b) => (a.number_of_rooms ?? 0) - (b.number_of_rooms ?? 0),
    },
    {
        title: 'Google Map Location',
        render: (text, record) => <span
            className='map-icon'
            onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${record.latitude},${record.longitude}`, "_blank")}>
            <img src={googleMaps} />
        </span>,
    },
];

export const inventoryColumns = [
    {
        title: 'Product type',
        dataIndex: 'product_type',
        key: 'product_type',
        sorter: (a, b) => (a.product_type ?? '').localeCompare(b.product_type ?? ''),
    },
    {
        title: 'Product Name',
        dataIndex: 'product_name',
        key: 'product_name',
        sorter: (a, b) => (a.product_name ?? '').localeCompare(b.product_name ?? ''),
    },
    {
        title: 'Received Quantity',
        dataIndex: 'received_quantity',
        key: 'received_quantity',
        sorter: (a, b) => (a.received_quantity ?? 0) - (b.received_quantity ?? 0),
    },
    {
        title: 'Consumed Quantity',
        dataIndex: 'consumed_quantity',
        key: 'consumed_quantity',
        sorter: (a, b) => (a.consumed_quantity ?? 0) - (b.consumed_quantity ?? 0),
    },
    {
        title: 'On-Hand Quantity',
        dataIndex: 'on_hand_quantity',
        key: 'on_hand_quantity',
        sorter: (a, b) => (a.on_hand_quantity ?? 0) - (b.on_hand_quantity ?? 0),
    },
];

export const financialReliefColumns = [
    {
        title: 'Event',
        dataIndex: 'event',
        key: 'event',
        sorter: (a, b) => (a.event ?? '').localeCompare(b.event ?? ''),
    },
    {
        title: 'Contributor',
        dataIndex: 'contributor',
        key: 'contributor',
        sorter: (a, b) => (a.contributor ?? '').localeCompare(b.contributor ?? ''),
    },
    {
        title: 'Amount Received in PKR',
        dataIndex: 'amount_received_in_pkr',
        key: 'amount_received_in_pkr',
        sorter: (a, b) => (a.amount_received_in_pkr ?? 0) - (b.amount_received_in_pkr ?? 0),
    },
    {
        title: 'Amount Consumed in PKR',
        dataIndex: 'amount_consumed_in_pkr',
        key: 'amount_consumed_in_pkr',
        sorter: (a, b) => (a.amount_consumed_in_pkr ?? 0) - (b.amount_consumed_in_pkr ?? 0),
    },
    {
        title: 'Amount On Hand in PKR',
        dataIndex: 'amount_on_hand_in_pkr',
        key: 'amount_on_hand_in_pkr',
        sorter: (a, b) => (a.amount_on_hand_in_pkr ?? 0) - (b.amount_on_hand_in_pkr ?? 0),
    },
];

export const compensatonReliefColumns = [
    {
        title: 'Event',
        dataIndex: 'event',
        key: 'event',
        sorter: (a, b) => (a.event ?? '').localeCompare(b.event ?? ''),
    },
    {
        title: 'Full Name',
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: (a, b) => (a.full_name ?? '').localeCompare(b.full_name ?? ''),
    },
    {
        title: 'CNIC',
        dataIndex: 'cnic',
        key: 'cnic',
        sorter: (a, b) => (a.cnic ?? 0) - (b.cnic ?? 0),
    },
    {
        title: 'Mobile Number',
        dataIndex: 'mobile_number',
        key: 'mobile_number',
        sorter: (a, b) => (a.amount_on_hand_in_pkr ?? 0) - (b.amount_on_hand_in_pkr ?? 0),
    },
    {
        title: 'Gender',
        dataIndex: 'gender',
        key: 'gender',
        sorter: (a, b) => (a.gender ?? '').localeCompare(b.gender ?? ''),
    },
    {
        columnName: 'Application Status',
        title: 'Application Status',
        key: 'compensation_status',
        sorter: (a, b) => (a.compensation_status ?? '').localeCompare(b.compensation_status ?? ''),
        render: ({ compensation_status }) => {
            let color = "";
            if (compensation_status === "Application Approved") {
                color = "#80F884";
            } else if (compensation_status === "Application Received") {
                color = "#F3ABA5";
            } else if (compensation_status === "Application Scruitnized") {
                color = "#F2ED73";
            } else if (compensation_status === "Cheque Cashed") {
                color = "#357A38";
            } else if (compensation_status === "Chqeue Issued") {
                color = "#FFFFFF";
            }

            return <div style={{ backgroundColor: color, borderRadius: "20px", height: "30px", width: "160px", display: "flex", justifyContent: "center", alignItems: "center" }}><span>{compensation_status || ""}</span></div>;
        }
    },
];

export const humanCasualtieColumns = [
    {
        title: 'Event',
        dataIndex: 'event',
        key: 'event',
        sorter: (a, b) => (a.event ?? '').localeCompare(b.event ?? ''),
    },
    {
        title: 'Casualty Type',
        dataIndex: 'casualty_type',
        key: 'casualty_type',
        sorter: (a, b) => (a.casualty_type ?? '').localeCompare(b.casualty_type ?? ''),
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        sorter: (a, b) => (a.quantity ?? '').localeCompare(b.quantity ?? ''),
    },
];

export const infrastructureDamageColumns = [
    {
        title: 'Event',
        dataIndex: 'event',
        key: 'event',
        sorter: (a, b) => (a.event ?? '').localeCompare(b.event ?? ''),
    },
    {
        title: 'Infrastructure Type',
        dataIndex: 'infrastructure_type',
        key: 'infrastructure_type',
        sorter: (a, b) => (a.infrastructure_type ?? '').localeCompare(b.infrastructure_type ?? ''),
    },
    {
        title: 'Damaged',
        dataIndex: 'damaged',
        key: 'damaged',
        sorter: (a, b) => (a.damaged ?? '').localeCompare(b.damaged ?? ''),
    },
    {
        title: 'restored',
        dataIndex: 'restored',
        key: 'restored',
        sorter: (a, b) => (a.restored ?? '').localeCompare(b.restored ?? ''),
    },
];

export const foodDamageColumns = [
    {
        title: 'Event',
        dataIndex: 'event',
        key: 'event',
        sorter: (a, b) => (a.event ?? '').localeCompare(b.event ?? ''),
    },
    {
        title: 'Agriculture Type',
        dataIndex: 'agriculture_type',
        key: 'agriculture_type',
        sorter: (a, b) => (a.agriculture_type ?? '').localeCompare(b.agriculture_type ?? ''),
    },
    {
        title: 'Acre',
        dataIndex: 'acre',
        key: 'acre',
        sorter: (a, b) => (a.acre ?? '').localeCompare(b.acre ?? ''),
    },
    {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
        sorter: (a, b) => (a.cost ?? 0) - (b.cost ?? 0),
    },
];

export const growerDetailsColumn = [
    {
        title: 'Grower Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
        title: 'Grower CNIC',
        dataIndex: 'cnic',
        key: 'cnic',
        sorter: (a, b) => (a.cnic ?? 0) - (b.cnic ?? 0),
    },
    {
        title: 'Grower Address',
        dataIndex: 'address',
        key: 'address',
        sorter: (a, b) => (a.address ?? '').localeCompare(b.address ?? ''),
    },
    {
        title: 'Grower Mobile Number',
        dataIndex: 'mobile_number',
        key: 'mobile_number',
        sorter: (a, b) => (a.mobile_number ?? '').localeCompare(b.mobile_number ?? ''),
    },
    {
        title: 'Acre',
        dataIndex: 'acre',
        key: 'acre',
        sorter: (a, b) => (a.acre ?? 0) - (b.acre ?? 0),
    }
];

export const livestockDamageColumns = [
    {
        title: 'Event',
        dataIndex: 'event',
        key: 'event',
        sorter: (a, b) => (a.event ?? '').localeCompare(b.event ?? ''),
    },
    {
        title: 'Livestock Type',
        dataIndex: 'livestock_type',
        key: 'livestock_type',
        sorter: (a, b) => (a.livestock_type ?? '').localeCompare(b.livestock_type ?? ''),
    },
    {
        title: 'Casualty Type',
        dataIndex: 'casualty_type',
        key: 'casualty_type',
        sorter: (a, b) => (a.casualty_type ?? '').localeCompare(b.casualty_type ?? ''),
    },
    {
        title: 'Total Quantity',
        dataIndex: 'total_quantity',
        key: 'total_quantity',
        sorter: (a, b) => (a.total_quantity ?? 0) - (b.total_quantity ?? 0),
    },
    {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
        sorter: (a, b) => (a.cost ?? 0) - (b.cost ?? 0),
    },
];

export const livestockOwnerDetailColumn = [
    {
        title: 'Owner Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
        title: 'Owner CNIC',
        dataIndex: 'cnic',
        key: 'cnic',
        sorter: (a, b) => (a.cnic ?? 0) - (b.cnic ?? 0),
    },
    {
        title: 'Owner Address',
        dataIndex: 'address',
        key: 'address',
        sorter: (a, b) => (a.address ?? '').localeCompare(b.address ?? ''),
    },
    {
        title: 'Owner Mobile Number',
        dataIndex: 'mobile_number',
        key: 'mobile_number',
        sorter: (a, b) => (a.mobile_number ?? 0) - (b.mobile_number ?? 0),
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        sorter: (a, b) => (a.quantity ?? 0) - (b.quantity ?? 0),
    }
];

export const infrastructureOwnerDetailColumn = [
    {
        title: 'Owner Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
        title: 'Owner CNIC',
        dataIndex: 'cnic',
        key: 'cnic',
        sorter: (a, b) => (a.cnic ?? 0) - (b.cnic ?? 0),
    },
    {
        title: 'Owner Address',
        dataIndex: 'address',
        key: 'address',
        sorter: (a, b) => (a.address ?? '').localeCompare(b.address ?? ''),
    },
    {
        title: 'Owner Mobile Number',
        dataIndex: 'mobile_number',
        key: 'mobile_number',
        sorter: (a, b) => (a.mobile_number ?? 0) - (b.mobile_number ?? 0),
    },
    {
        title: 'Damaged',
        dataIndex: 'damaged',
        key: 'damaged',
        sorter: (a, b) => (a.damaged ?? '').localeCompare(b.damaged ?? ''),
    },
    {
        title: 'Restored',
        dataIndex: 'restored',
        key: 'restored',
        sorter: (a, b) => (a.restored ?? '').localeCompare(b.restored ?? ''),
    }
];

export const casualtiesDetailsColumn = [
    {
        title: 'Full name',
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: (a, b) => (a.full_name ?? '').localeCompare(b.full_name ?? ''),
    },
    {
        title: 'CNIC',
        dataIndex: 'cnic',
        key: 'cnic',
        sorter: (a, b) => (a.cnic ?? 0) - (b.cnic ?? 0),
    },
    {
        title: 'Date of Birth',
        dataIndex: 'date_of_birth',
        key: 'date_of_birth',
        sorter: (a, b) => (a.quantity ?? 0) - (b.quantity ?? 0),
    },
    {
        title: 'Gender',
        dataIndex: 'gender',
        key: 'gender',
        sorter: (a, b) => (a.gender ?? '').localeCompare(b.gender ?? ''),
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        sorter: (a, b) => (a.address ?? '').localeCompare(b.address ?? ''),
    },
    {
        title: 'Mobile Number',
        dataIndex: 'mobile_number',
        key: 'mobile_number',
        sorter: (a, b) => (a.mobile_number ?? 0) - (b.mobile_number ?? 0),
    }
];