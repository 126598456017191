import React, { useEffect, useState } from 'react';
import './index.css';
import lineIcon from '../../assets/images/lineIcno.png';
import BlacklineIcon from '../../assets/images/blackLineIcon.png';
import FinancialIcon from '../../assets/images/FinancialIcon.png';
import upPriceIcon from '../../assets/images/upPriceIcon.png';
import downPriceIcon from '../../assets/images/downPriceIcon.png';
import stablePriceIcon from '../../assets/images/stablePriceIcon.png';
import CompensationIcon from '../../assets/images/CompensationIcon.png';
import ApplicationsReceivedIcon from '../../assets/images/Applications Received.png';
import ApplicationsScrutinizedIcon from '../../assets/images/Applications Scrutinized.png';
import ApplicationsApprovedIcon from '../../assets/images//Applications Approved.png';
import ChequesIssuedIcon from '../../assets/images/Cheques Issued.png';
import ChequesCashedIcon from '../../assets/images/Cheques Cashed.png';
import PlusIcon from '../../assets/images/plusIcon.png';
import OverviewIcon from '../../assets/images/OverviewIcon.png';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../reduxToolkit/store";
import ReusableTable from '../../components/table';
import { Popconfirm, Tag, Button, Space, Input, Dropdown } from 'antd';
import SearchSelect from '../../components/selectDropdown';
import { deleteFinancialReliefData, fetchFinancialReliefCount, fetchFinancialReliefData } from '../../reduxToolkit/financialReliefSlice';
import Filters from '../../components/top_filter';
import { numberFormater } from '../../helpers/common';
import { deleteCompensationStatusesData, fetchCompensationStatusesData, fetchCompensatonReliefCount, fetchCompensatonReliefData } from '../../reduxToolkit/compensatonReliefSlice';
import { useNavigate } from 'react-router-dom';
import { compensatonReliefColumns, financialReliefColumns } from '../../helpers/tableColumns';
import { alignCompensatonReliefsData, alignFinancialReliefData } from '../../helpers/alignData';
// import { Header } from '../../components';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { CardComponent } from '../../components';

const ReliefDistribution: React.FC = () => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    // const { isAuthenticated, userRole } = useSelector((state: RootState) => state.auth);
    const { userData } = useSelector((state: RootState) => state.auth);
    const { editorMode } = useSelector((state: RootState) => state.auth);
    const { financialReliefList, financialReliefMeta, financialReliefChartList, financialReliefCount } = useSelector((state: RootState) => state.financialRelief);
    const { compensatonReliefList, compensatonReliefMeta, compensatonStatusList, compensatonReliefChartList, compensatonReliefCount } = useSelector((state: RootState) => state.compensatonRelief);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedTehsil, setSelectedTehsil] = useState('');
    const [selectedUnionCouncil, setSelectedUnionCouncil] = useState('');
    const [selectedEvent, setSelectedEvent] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedContributorsParameter, setSelectedContributorsParameter] = useState('');
    const [selectedContributorsKeyword, setSelectedContributorsKeyword] = useState('');
    const [selectedCompensationParameter, setSelectedCompensationParameter] = useState('');
    const [selectedCompensationKeyword, setSelectedCompensationKeyword] = useState('');
    const [selectedCard, setSelectedCard] = useState(1);

    useEffect(() => {
        dispatch(fetchCompensationStatusesData());
    }, []);

    useEffect(() => {
        (async () => {
            if (selectedCard === 1) {
                await dispatch(fetchFinancialReliefCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
            } else if (selectedCard === 2) {
                await dispatch(fetchCompensatonReliefCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
            }
            if (selectedCard === 1) {
                await dispatch(fetchFinancialReliefData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
            } else if (selectedCard === 2) {
                await dispatch(fetchCompensatonReliefData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
            }
        })();
    }, [selectedCard]);

    useEffect(() => {
        dispatch(fetchFinancialReliefCount({ withLocation: selectedCard === 1, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
        dispatch(fetchCompensatonReliefCount({ withLocation: selectedCard === 2, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
    }, [selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent]);

    useEffect(() => {
        if (selectedCard === 1) {
            dispatch(fetchFinancialReliefData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
        } else if (selectedCard === 2) {
            dispatch(fetchCompensatonReliefData({ page: 1, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
        }
    }, [rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent]);

    useEffect(() => {
        if (userData?.division?.division_name && userData?.district?.district_name) {
            setSelectedDivision(userData?.division?.division_name);
            setSelectedDistrict(userData?.district?.district_name);
        }
    }, [userData]);

    const financialReliefListData = alignFinancialReliefData(financialReliefList);
    const financialReliefAlignChartData = alignFinancialReliefData(financialReliefChartList);
    const amount_received_in_pkr_sum = financialReliefAlignChartData.reduce((acc: any, obj: any) => acc + obj.amount_received_in_pkr, 0);
    const amount_consumed_in_pkr_sum = financialReliefAlignChartData.reduce((acc: any, obj: any) => acc + obj.amount_consumed_in_pkr, 0);
    const amount_on_hand_in_pkr_sum = financialReliefAlignChartData.reduce((acc: any, obj: any) => acc + obj.amount_on_hand_in_pkr, 0);
    const compensatonReliefListData = alignCompensatonReliefsData(compensatonReliefList);
    const compensatonReliefListAlignChartData = alignCompensatonReliefsData(compensatonReliefChartList);

    const actionButtons = (formHeading: String) => {
        return {
            title: 'Actions',
            hidden: !editorMode,
            width: 150,
            align: 'center' as 'center',
            render: (text: any, record: any) => {
                const ableToAction = userData?.division?.division_name === record.division && userData?.district?.district_name === record.district;
                return (ableToAction) && <span onClick={(e) => e.stopPropagation()}>
                    <Button
                        type="primary"
                        title='Edit'
                        style={{ background: '#357A38' }}
                        className='m-v-5'
                        icon={<EditOutlined />}
                        onClick={() => naviagteToEdit(formHeading, record)}
                        shape='circle' />
                    <Button
                        type="primary"
                        title='Delete'
                        style={{ background: '#357A38' }}
                        className='m-v-5'
                        icon={<DeleteOutlined />}
                        onClick={() => deleteData(formHeading, record.id)}
                        shape='circle' />
                </span>;
            },
        };
    };

    const deleteData = (formHeading: String, id: String) => {
        if (formHeading === "Contributors relief") {
            dispatch(deleteFinancialReliefData(id));
        } else if (formHeading === "Compensation relief") {
            dispatch(deleteCompensationStatusesData(id));
        }
    };

    const naviagteToEdit = (formHeading: String, data: any) => {
        navigate("/form", {
            state: {
                formHeading,
                recordId: data.id,
                data: {
                    ...data,
                    compensaton_relief: data.id,
                    financial_relief: data.id,
                },
                isForUpdate: true
            }
        });
    };

    return (
        <div className='ReliefDistribution-mainCont'>
            <Filters
                setSelectedDivision={setSelectedDivision}
                selectedDivision={selectedDivision}
                setSelectedDistrict={setSelectedDistrict}
                selectedDistrict={selectedDistrict}
                setSelectedTehsil={setSelectedTehsil}
                selectedTehsil={selectedTehsil}
                setSelectedUnionCouncil={setSelectedUnionCouncil}
                selectedUnionCouncil={selectedUnionCouncil}
                setSelectedEvent={setSelectedEvent}
                selectedEvent={selectedEvent}
            />
            <div className='top-lable-cont'>
                <div>
                    <img src={OverviewIcon} alt="Overview_icon" style={{ width: "21px" }} />
                    <span>Overview</span>
                </div>
                <img src={lineIcon} alt="line_icon" style={{ width: "32px" }} />
            </div>
            <div className='card-cont'>
                <CardComponent
                    title="Contributors relief"
                    icon={FinancialIcon}
                    count={financialReliefCount}
                    selectedCard={selectedCard}
                    cardNumber={1}
                    onChangeCard={() => {
                        setSelectedCard(1);
                        setSelectedContributorsParameter('');
                        setSelectedCompensationParameter('');
                        setSelectedContributorsKeyword('');
                        setSelectedCompensationKeyword('');
                    }}
                />
                <CardComponent
                    title="Compensation relief"
                    icon={CompensationIcon}
                    count={compensatonReliefCount}
                    selectedCard={selectedCard}
                    cardNumber={2}
                    onChangeCard={() => {
                        setSelectedCard(2);
                        setSelectedContributorsParameter('');
                        setSelectedCompensationParameter('');
                        setSelectedContributorsKeyword('');
                        setSelectedCompensationKeyword('');
                    }}
                />
                <div />
                <div />
            </div>
            {!editorMode && <>
                {selectedCard === 1 ? <>
                    <div className='ReliefDistribution-card-cont'>
                        <div className='ReliefDistribution-card'>
                            <div className='first-child'>
                                <img src={upPriceIcon} alt="line_icon" style={{ height: "38px" }} />
                                <img src={BlacklineIcon} style={{ height: "23px", cursor: 'pointer' }} alt="line_icon" />
                            </div>
                            <div className='Secend-child' style={{ marginTop: "12px", display: "flex", flexWrap: "wrap" }}>
                                <h2>Amount Received in PKR</h2>
                                <span>{numberFormater(amount_received_in_pkr_sum)}</span>
                            </div>
                        </div>
                        <div className='ReliefDistribution-card'>
                            <div className='first-child'>
                                <img src={downPriceIcon} alt="line_icon" style={{ height: "38px" }} />
                                <img src={BlacklineIcon} style={{ height: "23px", cursor: 'pointer' }} alt="line_icon" />
                            </div>
                            <div className='Secend-child' style={{ marginTop: "12px", display: "flex", flexWrap: "wrap" }}>
                                <h2>Amount Consumed in PKR</h2>
                                <span>{numberFormater(amount_consumed_in_pkr_sum)}</span>
                            </div>
                        </div>
                        <div className='ReliefDistribution-card'>
                            <div className='first-child'>
                                <img src={stablePriceIcon} alt="line_icon" style={{ height: "38px" }} />
                                <img src={BlacklineIcon} style={{ height: "23px", cursor: 'pointer' }} alt="line_icon" />
                            </div>
                            <div className='Secend-child' style={{ marginTop: "12px", display: "flex", flexWrap: "wrap" }}>
                                <h2>Amount on hand in PKR</h2>
                                <span>{numberFormater(amount_on_hand_in_pkr_sum)}</span>
                            </div>
                        </div>
                    </div>
                </> : null}
                {selectedCard === 2 ? <>
                    <div className='ReliefDistribution-card-cont'>
                        {compensatonStatusList?.map((item, index) => {
                            const icon = compensatonStatusIcons[item?.attributes?.name];
                            return <CompensationCard
                                key={index}
                                title={item?.attributes?.name}
                                icon={icon}
                                data={compensatonReliefListAlignChartData} />;
                        })}
                    </div>
                </> : null}
            </>}
            {selectedCard === 1 ? <>
                <div className='ReliefDistribution-table-cont'>
                    <div className='ReliefDistribution-table-filter-btn-cont'>
                        {!!editorMode ?
                            <div className='admin-add-data-btn' onClick={() => navigate("/form", { state: { formHeading: 'Contributors relief' } })}>
                                <img src={PlusIcon} alt="" />
                                <span>Add New Data</span>
                            </div> : <div />}
                        <div className='d-flex justify-content-between align-items-center ' style={{ flexWrap: 'wrap', width: "500px" }}>
                            <SearchSelect
                                onChange={env => { setSelectedContributorsParameter(env); setSelectedContributorsKeyword(''); }}
                                placeholder="Select the Parameter"
                                className="filter-input select-param-input"
                                value={selectedContributorsParameter || undefined}
                                options={financialReliefColumns
                                    .filter(item => item.title !== 'Google Map Location')
                                    .map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))
                                }
                                allowClear={true}
                            />
                            <Input
                                placeholder="Keyword Search"
                                className=' filter-input select-param-input'
                                disabled={!selectedContributorsParameter}
                                allowClear={true}
                                onChange={({ target }) => setSelectedContributorsKeyword(target.value)}
                                value={selectedContributorsKeyword}
                            />
                            <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                                <span>Show:</span>
                                <SearchSelect
                                    onChange={(value: String) => setRowsPerPage(+value)}
                                    placeholder="Select the District"
                                    className="w-100px"
                                    value={rowsPerPage}
                                    options={[
                                        { value: "2", label: "2 Entries" },
                                        { value: "5", label: "5 Entries" },
                                        { value: "8", label: "8 Entries" },
                                        { value: "10", label: "10 Entries" },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <ReusableTable
                        data={(selectedContributorsParameter && selectedContributorsKeyword) ? financialReliefListData.filter((item: any) => {
                            const value = typeof item[selectedContributorsParameter] === 'string' ? item[selectedContributorsParameter].toLowerCase() : item[selectedContributorsParameter]?.toString();
                            return value && (Array.isArray(item[selectedContributorsParameter]) ? item[selectedContributorsParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedContributorsKeyword.toLowerCase())) : value.includes(selectedContributorsKeyword.toLowerCase()));
                        }) : financialReliefListData}
                        columns={[...financialReliefColumns, actionButtons("Contributors relief")]}
                        rowsPerPage={rowsPerPage}
                        onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Contributors relief', recordId: r.id } })}
                        total={financialReliefMeta?.pagination?.total}
                        onChangePagination={(page: number) => dispatch(fetchFinancialReliefData({ page, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }))}
                    />
                </div>
            </> : null}
            {selectedCard === 2 ? <>
                <div className='ReliefDistribution-table-cont'>
                    <div className='ReliefDistribution-table-filter-btn-cont'>
                        {!!editorMode ?
                            <div className='admin-add-data-btn' onClick={() => navigate("/form", { state: { formHeading: 'Compensation relief' } })}>
                                <img src={PlusIcon} alt="" />
                                <span>Add New Data</span>
                            </div> : <div />}
                        <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap', width: "500px" }}>
                            <SearchSelect
                                onChange={env => { setSelectedCompensationParameter(env); setSelectedCompensationKeyword(''); }}
                                placeholder="Select the Parameter"
                                className="filter-input select-param-input"
                                value={selectedCompensationParameter || undefined}
                                options={compensatonReliefColumns
                                    .filter(item => item.title !== 'Google Map Location')
                                    .map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))
                                }
                                allowClear={true}
                            />
                            <Input
                                placeholder="Keyword Search"
                                className=' filter-input select-param-input'
                                allowClear={true}
                                disabled={!selectedCompensationParameter}
                                onChange={({ target }) => setSelectedCompensationKeyword(target.value)}
                                value={selectedCompensationKeyword}
                            />
                            <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                                <span>Show:</span>
                                <SearchSelect
                                    onChange={(value: String) => setRowsPerPage(+value)}
                                    placeholder="Select the Entries count"
                                    className="w-100px"
                                    value={rowsPerPage}
                                    options={[
                                        { value: "2", label: "2 Entries" },
                                        { value: "5", label: "5 Entries" },
                                        { value: "8", label: "8 Entries" },
                                        { value: "10", label: "10 Entries" },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <ReusableTable
                        data={(selectedCompensationParameter && selectedCompensationKeyword) ? compensatonReliefListData.filter((item: any) => {
                            const value = typeof item[selectedCompensationParameter] === 'string' ? item[selectedCompensationParameter].toLowerCase() : item[selectedCompensationParameter]?.toString();
                            return value && (Array.isArray(item[selectedCompensationParameter]) ? item[selectedCompensationParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedCompensationKeyword.toLowerCase())) : value.includes(selectedCompensationKeyword.toLowerCase()));
                        }) : compensatonReliefListData}
                        columns={[...compensatonReliefColumns, actionButtons("Compensation relief")]}
                        rowsPerPage={rowsPerPage}
                        total={compensatonReliefMeta?.pagination?.total}
                        onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Compensation relief', recordId: r.id } })}
                        onChangePagination={(page: number) => dispatch(fetchCompensatonReliefData({ page, pageSize: rowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }))}
                    />
                </div>
            </> : null}
        </div>
    );
};

export default ReliefDistribution;


const CompensationCard = ({ title, icon, data }: any) => {
    const selectedData = data.filter((item: any) => item.compensation_status === title);
    return (
        <div className='Compensation-card'>
            <img src={icon} alt="line_icon" style={{ width: (title == 'Chqeue Issued' || title == 'Cheque Cashed') ? "50px" : "30px", height: "38px" }} />
            <h2>{title}</h2>
            <span>{numberFormater(selectedData.length)}</span>
        </div>
    );
};

const compensatonStatusIcons: any = {
    "Application Received": ApplicationsReceivedIcon,
    "Application Scruitnized": ApplicationsScrutinizedIcon,
    "Application Approved": ApplicationsApprovedIcon,
    "Chqeue Issued": ChequesIssuedIcon,
    "Cheque Cashed": ChequesCashedIcon,
};

