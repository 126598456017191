import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

interface NGOState {
    ngoList: any[];
    filteredNgoList: any[];
    ngosLoading: boolean;
    ngoMeta: any;
    ngoCount: number;
    ngoChartList: any[];
    filteredngoCount: number;
    selectedNgo: any;
}

const initialState: NGOState = {
    ngoList: [],
    ngosLoading: false,
    filteredNgoList: [],
    ngoMeta: {},
    ngoCount: 0,
    ngoChartList: [],
    filteredngoCount: 0,
    selectedNgo: {},
};

export const fetchNGOCount = createAsyncThunk('ngo/fetchNGOCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                }
            }
        };
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiGetNgoCount : API.ApiGetNgoCountWithOutLocation,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.ngos.meta.pagination.total, ngoChartList: response.data.ngos.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchNGOData = createAsyncThunk('ngo/fetchNGOData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil } = pageData;
        const filter: any = {
            stage: {
                null: true
            }
        };
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllNGOS,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.ngos.data, meta: response.data.ngos.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFilterdNGOData = createAsyncThunk('ngo/fetchFilterdNGOData', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await client.query({ query: API.ApiNGOS, variables, context: { headers }, fetchPolicy: 'no-cache' });
        return { data: response.data.ngoDrafts.data, filteredngoCount: response.data.ngoDrafts.meta.pagination.total };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchNGODataById = createAsyncThunk('ngo/fetchNGODataById', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetNGOById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.ngo.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createNGOData = createAsyncThunk('ngo/createNGOData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateNGO,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createNgoDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateNGODraftData = createAsyncThunk('ngo/updateNGODraftData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingNgoDraft,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateNgoDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateNGOStageData = createAsyncThunk('ngo/updateNGOStageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { stage, id, record } = data;
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        if (stage) {
            await client.mutate({
                mutation: API.ApiUpdateNgoStage,
                variables: {
                    id: id,
                    stage: stage
                },
                context: {
                    headers,
                },
            });
        } else {
            const obj = {
                "divisionId": record.divisionId,
                "districtId": record.districtId,
                "ngo_name": record.ngo_name,
                "postal_address": record.postal_address,
                "primary_areasId": record.primary_areasId,
                "secondary_areasId": record.secondary_areasId,
                "website": record.website,
                "license_registration_number": record.license_registration_number,
                "year_established": record.year_established,
                "organizational_head": record.organizational_head,
                "ngo_contact_person_in_district": record.ngo_contact_person_in_district,
                "ngo_contact_person_mobile_number_in_district": record.ngo_contact_person_mobile_number_in_district ? Number(record.ngo_contact_person_mobile_number_in_district) : null,
                "ngo_contact_number": record.ngo_contact_number ? Number(record.ngo_contact_number) : null,
                "latitude": record.latitude ? Number(record.latitude) : null,
                "longitude": record.longitude ? Number(record.longitude) : null,
                "stage": null,
                "tehsilId": record.tehsilId,
                "union_councilId": record.union_councilId,
                "address": record.address,
                "ngoId": record.ngo
            };
            console.log('obj', obj);
            await client.mutate({
                mutation: obj.ngoId ? API.ApiUpdateExistingNgo : API.ApiCreatePublisedNgo,
                variables: obj,
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
            await client.mutate({
                mutation: API.ApiDeleteNgoDraft,
                variables: { id },
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
        }
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteNgoData = createAsyncThunk('ngo/deleteNgoData', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteNgo,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateNGOData = createAsyncThunk('ngo/updateNGOData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingNgo, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateNgo.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateNgoRecordFromSocket = createAsyncThunk('ngo/addAndUpdateNgoRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, externalWorkflowsList } = data;
        const response = await client.query({ query: API.ApiGetNGOById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.ngo.data, externalWorkflowsList };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteNgoRecordFromSocket = createAsyncThunk('ngo/deleteNgoRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const getDraftByUser = createAsyncThunk('ngo/getDraftByUser', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.APIGetNgoDraftByUser, // Assuming you have an API constant for update mutation
            variables,
            context: {
                headers,
            },
        });
        if (response.data && response.data.ngoDrafts.data.length > 0) {
            return true;
        }
        return false;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const checkLicenseRegistrationNumberExists = createAsyncThunk('ngo/checkLicenseRegistrationNumberExists', async (record: any, { rejectWithValue, getState }) => {
    try {
        const { license_registration_number, excludeNgoDraftId, excludeNgoId } = record;
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.query({
            query: API.APICheckLicenseRegistrationNumber,
            variables: { license_registration_number, excludeNgoDraftId, excludeNgoId },
            context: {
                headers,
            },
        });
        return {
            ngos: response.data.ngos.data,
            ngoDrafts: response.data.ngoDrafts.data,
        };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const ngoSlice = createSlice({
    name: 'ngo',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchNGOCount.fulfilled, (state, action) => {
                state.ngoCount = action.payload.count;
                state.ngoChartList = action.payload.ngoChartList;
                state.ngosLoading = false;
            })
            .addCase(fetchNGOData.fulfilled, (state, action) => {
                state.ngoList = action.payload.data;
                state.ngoMeta = action.payload.meta;
                state.ngosLoading = false;
            })
            .addCase(fetchFilterdNGOData.fulfilled, (state, action) => {
                state.filteredNgoList = action.payload.data;
                state.filteredngoCount = action.payload.filteredngoCount;
                state.ngosLoading = false;
            })
            .addCase(fetchNGODataById.fulfilled, (state, action) => {
                state.selectedNgo = action.payload;
                state.ngosLoading = false;
            })
            .addCase(createNGOData.fulfilled, (state, action) => {
                // state.filteredNgoList.push(action.payload);
                state.ngosLoading = false;
            })
            .addCase(updateNGODraftData.fulfilled, (state, action) => {
                // state.filteredNgoList.push(action.payload);
                state.ngosLoading = false;
            })
            .addCase(updateNGOData.fulfilled, (state, action) => {
                state.ngosLoading = false;
            })
            .addCase(updateNGOStageData.fulfilled, (state, action) => {
                const index = state.filteredNgoList.findIndex(item => item.id === action.payload);
                state.filteredNgoList.splice(index, 1);
                state.ngosLoading = false;
            })
            .addCase(deleteNgoData.fulfilled, (state, action) => {
                state.ngosLoading = false;
            })
            .addCase(addAndUpdateNgoRecordFromSocket.fulfilled, (state, action) => {
                const externalWorkflowsList = action.payload.externalWorkflowsList;
                const { id, attributes } = action.payload.data;
                if (attributes.stage) {
                    const selectedWorkFlow: any = externalWorkflowsList.find((item: any) => item.stage === attributes.stage);
                    const index = state.filteredNgoList.findIndex(item => item.id == id);
                    if (selectedWorkFlow) {
                        index !== -1 ? state.filteredNgoList[index] = action.payload.data : state.filteredNgoList.push(action.payload.data);
                    } else {
                        state.filteredNgoList.splice(index, 1);
                    }
                } else {
                    const index = state.ngoList.findIndex(item => item.id == id);
                    index !== -1 ? state.ngoList[index] = action.payload.data : state.ngoList.push(action.payload.data);
                }
            })
            .addCase(deleteNgoRecordFromSocket.fulfilled, (state, action) => {
                state.ngoList = state.ngoList.filter(item => item.id != action.payload);
                state.filteredNgoList = state.filteredNgoList.filter(item => item.id != action.payload);
            })
            .addCase(getDraftByUser.fulfilled, (state, action) => {
                state.ngosLoading = false;
            });
        [
            fetchNGOCount,
            fetchNGOData,
            fetchFilterdNGOData,
            updateNGOStageData,
            deleteNgoData,
            createNGOData,
            updateNGOData,
            fetchNGODataById,
            updateNGODraftData,
            getDraftByUser
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.ngosLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.ngosLoading = false;
                });
        });
    }
});


export default ngoSlice.reducer;
