import React, { useEffect, useState } from 'react';
import './index.css'; // Import CSS for styling
import { FaArrowLeft } from "react-icons/fa6";
import InfrastructureDamagesIcon from '../../assets/images/infrastructure_card.png';
import formLossesDamagesLogo from '../../assets/images/formLosses&DamagesLogo.png';
import { Button, Input } from 'antd';
import { Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { toast } from '../../components/Toast';
import { fetchAllInfrastructureTypes, fetchTehsilsData, fetchUnionCouncilData } from '../../reduxToolkit/entitiesSlice';
import { createInfrastructureDamageData, createInfrastructureOwnerDetail, deleteInfrastructureOwnerDetail, fetchAllInfrastructureOwnerDetails, getInfrastructureDraftByUser, updateInfrastructureDamageData, updateInfrastructureDamageStageData, updateInfrastructureDraftData, updateInfrastructureOwnerDetail } from '../../reduxToolkit/infrastructureDamageSlice';
import { validateObject } from '../../helpers/common';
import DetailIcon from '../../assets/images/Detail.png';
import addIcon from '../../assets/images/Add.png';
import ReusableTable from '../../components/table';
import { DeleteOutlined } from '@ant-design/icons';
import { alignInfrastructureOwnerDetailsData } from '../../helpers/alignData';

const InfrastructureDamages = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { allDistrictList, divisionList, tehsilList, unionCouncilList, eventList, infrastructureTypeList, internalWorkflowsList } = useSelector((state: RootState) => state.entities);
    const { userData } = useSelector((state: RootState) => state.auth);
    const { infrastructureOwnerDetails } = useSelector((state: RootState) => state.infrastructureDamage);
    const [infrastructureDamageDetails, setInfrastructureDamageDetails] = useState<{ [key: string]: any; }>({});
    const [actionButtonText, setActionButtonText] = useState("");
    const { data, isForAction, isViewOnly, isForUpdate, is_draft } = location.state;
    const [isCreating, setIsCreating] = useState(false);
    const [isCheckRequired, setIsCheckRequired] = useState(false);
    const [ownerData, setOwnerDetails] = useState<any>([{}]);
    const [hasDraft, setHasDraft] = useState(false);

    useEffect(() => {
        (async () => {
            if (userData && userData.id) {
                const userDraft = (await dispatch(getInfrastructureDraftByUser({
                    id: infrastructureDamageDetails.draft_id,
                    created_by_user: userData.id
                }))).payload;
                setHasDraft(userDraft);
            }
        })();
    }, [userData, infrastructureDamageDetails.draft_id]);

    useEffect(() => {
        if ((isForAction || isViewOnly) && internalWorkflowsList.length) {
            setInfrastructureDamageDetails(data);
            const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === data.stage);
            setActionButtonText(selectedWorkFlow?.title);
        } else if (isForUpdate) {
            setInfrastructureDamageDetails(data);
            setActionButtonText("Update Information");
        } else {
            const obj = {
                division: userData?.division?.division_name,
                divisionId: userData?.division?.id,
                district: userData?.district?.district_name,
                districtId: userData?.district?.id,
            };
            setInfrastructureDamageDetails(prevState => ({ ...prevState, ...obj }));
        }
        if (data?.infrastructure_damage) {
            dispatch(fetchAllInfrastructureOwnerDetails({
                page: 1,
                pageSize: 1000000,
                "filter": {
                    "infrastructure_damage": {
                        "id": {
                            "eq": data.infrastructure_damage
                        }
                    }
                }
            }));
        }
        dispatch(fetchAllInfrastructureTypes());
    }, [userData, internalWorkflowsList]);

    useEffect(() => {
        const growerData = alignInfrastructureOwnerDetailsData(infrastructureOwnerDetails);
        console.log("infrastructureOwnerDetails", infrastructureOwnerDetails);
        setOwnerDetails(growerData);
    }, [infrastructureOwnerDetails]);

    useEffect(() => {
        dispatch(fetchTehsilsData(infrastructureDamageDetails?.district));
    }, [dispatch, infrastructureDamageDetails?.district]);

    useEffect(() => {
        dispatch(fetchUnionCouncilData(infrastructureDamageDetails?.tehsil));
    }, [dispatch, infrastructureDamageDetails?.tehsil]);

    const divisionData = divisionList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.division_name,
        label: item.attributes.division_name,
    })) || [];

    const districtData = allDistrictList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.district_name,
        label: item.attributes.district_name,
    })) || [];

    const tehsilData = tehsilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.tehsil_name,
        label: item.attributes.tehsil_name,
    })) || [];

    const unionCouncilListData = unionCouncilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.union_council_name,
        label: item.attributes.union_council_name,
    })) || [];

    const eventListData = eventList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const infrastructureTypeListData = infrastructureTypeList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const onSave = async () => {
        try {
            if (hasDraft) {
                throw new Error("You already have a draft in progress or pending approval.");
            } else {
                const obj: any = {
                    "divisionId": infrastructureDamageDetails.divisionId,
                    "districtId": infrastructureDamageDetails.districtId,
                    "infrastructure_typeId": infrastructureDamageDetails.infrastructure_typeId,
                    "eventId": infrastructureDamageDetails.eventId,
                    "damaged": infrastructureDamageDetails.damaged,
                    "restored": infrastructureDamageDetails.restored,
                    "latitude": infrastructureDamageDetails.latitude ? Number(infrastructureDamageDetails.latitude) : null,
                    "longitude": infrastructureDamageDetails.longitude ? Number(infrastructureDamageDetails.longitude) : null,
                    "stage": 1,
                    "is_draft": false,
                    "created_by_user": userData.id,
                };
                if (!validateObject(obj)) {
                    setIsCheckRequired(true);
                    throw new Error('Please fill the all highlighted fields');
                }
                obj.tehsilId = infrastructureDamageDetails.tehsilId;
                obj.union_councilId = infrastructureDamageDetails.union_councilId;
                obj.address = infrastructureDamageDetails.address;
                obj.infrastructure_damage = infrastructureDamageDetails.infrastructure_damage;
                if (infrastructureDamageDetails.draft_id) {
                    obj.infrastructureDamageId = infrastructureDamageDetails.id;
                    await updateChild();
                    const res = await dispatch(updateInfrastructureDraftData(obj));
                    if (res.type !== "infrastructureDamage/updateInfrastructureDraftData/rejected") {
                        navigate(-1);
                        toast('success', "infrastructure damage draft update successfully");
                    } else {
                        setIsCreating(false);
                    }
                } else {
                    if (isForUpdate) {
                        await updateChild();
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createInfrastructureDamageData(obj));
                        if (res.type !== "infrastructureDamage/createInfrastructureDamageData/rejected") {
                            navigate(-1);
                            toast('success', "Infrastructure Damage update successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                        // const res = await dispatch(updateInfrastructureDamageData(obj));
                        // if (res.type !== "infrastructureDamage/updateInfrastructureDamageData/rejected") {
                        //     navigate(-1);
                        //     toast('success', "Infrastructure Damage update successfully");
                        // }
                    } else {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createInfrastructureDamageData(obj));
                        if (res.type !== "infrastructureDamage/createInfrastructureDamageData/rejected") {
                            navigate(-1);
                            toast('success', "Infrastructure Damage created successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                    }
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    const onAction = async () => {
        const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === infrastructureDamageDetails.stage);
        let smallestStageNumber = internalWorkflowsList.reduce((min: any, record: any) => record.stage < min && record.stage > infrastructureDamageDetails.stage ? record.stage : min, Infinity);
        const res = await dispatch(updateInfrastructureDamageStageData({
            id: infrastructureDamageDetails.id,
            stage: (!smallestStageNumber || smallestStageNumber == Infinity) ? null : smallestStageNumber,
            record: infrastructureDamageDetails
        }));
        if (res.type !== "infrastructureDamage/updateInfrastructureDamageStageData/rejected") {
            navigate(-1);
            toast("success", `${(!smallestStageNumber || smallestStageNumber == Infinity) ? "Publish" : selectedWorkFlow.title} successfully`);
        }
    };

    const deleteData = (index: number, id: String) => {
        if (id) {
            dispatch(deleteInfrastructureOwnerDetail(id));
        } else {
            setOwnerDetails((prevState: any) => {
                const newState = [...prevState];
                newState.splice(index, 1);
                return newState;
            });
        }
    };

    const growerDetailsColumn = [
        {
            title: 'Owner Name',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Owner Name'
                disabled={isViewOnly}
                onChange={({ target }) => setOwnerDetails((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].name = target.value;
                    return newState;
                })}
                value={record.name}
                className='input'
            />,
        },
        {
            title: 'Owner CNIC',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter CNIC No.'
                disabled={isViewOnly}
                onChange={({ target }) => setOwnerDetails((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].cnic = target.value;
                    return newState;
                })}
                value={record.cnic}
                className='input'
            />,
        },
        {
            title: 'Owner Address',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Address Here'
                disabled={isViewOnly}
                onChange={({ target }) => setOwnerDetails((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].address = target.value;
                    return newState;
                })}
                value={record.address}
                className='input'
            />,
        },
        {
            title: 'Owner Mobile Number',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Mobile no.'
                disabled={isViewOnly}
                onChange={({ target }) => setOwnerDetails((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].mobile_number = target.value;
                    return newState;
                })}
                value={record.mobile_number}
                className='input'
            />,
        },
        {
            title: 'Damaged',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Damaged'
                disabled={isViewOnly}
                onChange={({ target }) => setOwnerDetails((prevState: any) => {
                    const newState = [...prevState];
                    const newTotalDamaged = prevState.reduce((sum: any, item: any, idx: any) =>
                        sum + (idx === index ? parseFloat(target.value) : parseFloat(item.damaged) || 0)
                        , 0);

                    if (newTotalDamaged > Number(infrastructureDamageDetails.damaged)) {
                        toast("error", `Cannot exceed the damaged limit of ${Number(infrastructureDamageDetails.damaged)}.`);
                        return prevState;
                    }

                    newState[index].damaged = target.value;
                    return newState;
                })}
                value={record.damaged}
                className='input'
            />,
        },
        {
            title: 'Restored',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Restored'
                disabled={isViewOnly}
                onChange={({ target }) => setOwnerDetails((prevState: any) => {
                    const newState = [...prevState];
                    const newTotalRestored = prevState.reduce((sum: any, item: any, idx: any) =>
                        sum + (idx === index ? parseFloat(target.value) : parseFloat(item.restored) || 0)
                        , 0);

                    if (newTotalRestored > Number(infrastructureDamageDetails.restored)) {
                        toast("error", `Cannot exceed the restored limit of ${Number(infrastructureDamageDetails.restored)}.`);
                        return prevState;
                    }

                    newState[index].restored = target.value;
                    return newState;
                })}
                value={record.restored}
                className='input'
            />,
        },
        {
            title: "Delete",
            render: (a: any, b: any, index: number) => (
                <Button
                    type="primary"
                    title='Delete'
                    style={{ background: '#357A38' }}
                    disabled={isViewOnly}
                    className='m-v-5'
                    icon={<DeleteOutlined />}
                    onClick={() => deleteData(index, a.id)}
                    shape='circle'
                />
            ),
        }
    ];

    const onSaveAsDraft = async () => {
        try {
            if (isCreating) return;
            setIsCreating(true);
            await updateChild();
            const obj: any = {
                "divisionId": infrastructureDamageDetails.divisionId,
                "districtId": infrastructureDamageDetails.districtId,
                "infrastructure_typeId": infrastructureDamageDetails.infrastructure_typeId,
                "eventId": infrastructureDamageDetails.eventId,
                "damaged": infrastructureDamageDetails.damaged,
                "restored": infrastructureDamageDetails.restored,
                "latitude": infrastructureDamageDetails.latitude ? Number(infrastructureDamageDetails.latitude) : null,
                "longitude": infrastructureDamageDetails.longitude ? Number(infrastructureDamageDetails.longitude) : null,
                "tehsilId": infrastructureDamageDetails.tehsilId,
                "union_councilId": infrastructureDamageDetails.union_councilId,
                "address": infrastructureDamageDetails.address,
                "stage": null,
                "is_draft": true,
                "created_by_user": userData.id,
            };
            if (infrastructureDamageDetails.draft_id) {
                obj.infrastructure_damage = infrastructureDamageDetails.infrastructure_damage;
                obj.infrastructureDamageId = data.draft_id;
                const res = await dispatch(updateInfrastructureDraftData(obj));
                if (res.type !== "infrastructureDamage/updateInfrastructureDraftData/rejected") {
                    navigate(-1);
                    toast('success', "infrastructure damage draft update successfully");
                } else {
                    setIsCreating(false);
                }
            } else {
                obj.infrastructure_damage = infrastructureDamageDetails.infrastructure_damage;
                const res = await dispatch(createInfrastructureDamageData(obj));
                if (res.type !== "infrastructureDamage/createInfrastructureDamageData/rejected") {
                    navigate(-1);
                    toast('success', "infrastructure damage draft successfully");
                } else {
                    setIsCreating(false);
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    const updateChild = async () => {
        for (let i = 0; i < ownerData.length; i++) {
            const element = ownerData[i];
            const growerObj = {
                infrastructure_damageId: infrastructureDamageDetails.id,
                name: element.name,
                "cnic": element.cnic ? Number(element.cnic) : null,
                "mobile_number": element.mobile_number ? Number(element.mobile_number) : null,
                damaged: element.damaged,
                address: element.address,
                restored: element.restored,
            };
            if (Object.values(growerObj).some(item => item)) {
                if (element.id) {
                    await dispatch(updateInfrastructureOwnerDetail({ ...growerObj, infrastructure_owner_detail_id: element.id }));
                } else {
                    await dispatch(createInfrastructureOwnerDetail(growerObj));
                }
            }
        }
    };

    const handleAddMoreData = () => {
        // Calculate total restored and damaged values
        const totalRestored = ownerData.reduce((sum: any, item: any) => sum + (parseFloat(item.restored) || 0), 0);
        const totalDamaged = ownerData.reduce((sum: any, item: any) => sum + (parseFloat(item.damaged) || 0), 0);

        // Check if adding another record would exceed the limit
        if (totalRestored >= Number(infrastructureDamageDetails.restored)) {
            toast('error', `Cannot add more records. The total 'Restored' value cannot exceed ${Number(infrastructureDamageDetails.restored)}.`);
            return;
        }

        if (totalDamaged >= Number(infrastructureDamageDetails.damaged)) {
            toast('error', `Cannot add more records. The total 'Damaged' value cannot exceed ${Number(infrastructureDamageDetails.damaged)}.`);
            return;
        }

        setOwnerDetails((prevState: any) => ([...prevState, {}]));
    };

    console.log("is_draft", is_draft);
    console.log("data", data);
    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formLossesDamagesLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Losses & Damages</span>
                        </div>
                    </div>
                    {isForAction ? <div className='form-send-btn' onClick={onAction}>
                        <span className='sand-btn-text'>{actionButtonText}</span>
                    </div>
                        : <div>
                            {!hasDraft ? <div className='form-send-btn' onClick={onSaveAsDraft}>
                                <span className='sand-btn-text'>Save as draft</span>
                            </div> : null}
                            <div className='form-send-btn' onClick={onSave}>
                                <span className='sand-btn-text'>Submit</span>
                            </div>
                        </div>}
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <img src={InfrastructureDamagesIcon} alt="" />
                    <span>Infrastructure Damage</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Damaged</span>
                        <Input
                            onChange={({ target }) => setInfrastructureDamageDetails(prevState => ({ ...prevState, damaged: target.value }))}
                            placeholder="Enter Number of Damaged"
                            status={(isCheckRequired && !infrastructureDamageDetails.damaged) ? 'error' : ""}
                            disabled={isViewOnly}
                            value={infrastructureDamageDetails.damaged}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Restored</span>
                        <Input
                            disabled={isViewOnly}
                            onChange={({ target }) => setInfrastructureDamageDetails(prevState => ({ ...prevState, restored: target.value }))}
                            placeholder="Enter Number of Restored"
                            value={infrastructureDamageDetails.restored}
                            status={(isCheckRequired && !infrastructureDamageDetails.restored) ? 'error' : ""}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Infrastructure Type</span>
                        <Select
                            style={{ width: '100%' }}
                            disabled={isViewOnly}
                            onChange={(value: any, item: any) => setInfrastructureDamageDetails(prevState => ({ ...prevState, infrastructure_typeId: item.id, infrastructure_type: item.value }))}
                            status={(isCheckRequired && !infrastructureDamageDetails.infrastructure_typeId) ? 'error' : ""}
                            options={infrastructureTypeListData}
                            placeholder="Select Infrastructure Type"
                            className='input'
                            value={infrastructureDamageDetails.infrastructure_type}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Event</span>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setInfrastructureDamageDetails(prevState => ({ ...prevState, eventId: item.id, event: item.value }))}
                            status={(isCheckRequired && !infrastructureDamageDetails.eventId) ? 'error' : ""}
                            options={eventListData}
                            disabled={isViewOnly}
                            placeholder="Select Event"
                            className='input'
                            value={infrastructureDamageDetails.event}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Select
                            value={infrastructureDamageDetails.division || undefined}
                            placeholder="Select the Division"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setInfrastructureDamageDetails(prevState => ({ ...prevState, divisionId: item?.id, division: item?.value }))}
                            status={(isCheckRequired && !infrastructureDamageDetails.divisionId) ? 'error' : ""}
                            disabled
                            options={divisionData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the District"
                            value={infrastructureDamageDetails.district || undefined}
                            style={{ width: '100%' }}
                            disabled
                            onChange={(value: any, item: any) => setInfrastructureDamageDetails(prevState => ({ ...prevState, districtId: item?.id, district: item?.value }))}
                            status={(isCheckRequired && !infrastructureDamageDetails.districtId) ? 'error' : ""}
                            options={districtData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Tehsil"
                            style={{ width: '100%' }}
                            value={infrastructureDamageDetails.tehsil || undefined}
                            onChange={(value: any, item: any) => setInfrastructureDamageDetails(prevState => ({ ...prevState, tehsilId: item?.id, tehsil: item?.value }))}
                            options={tehsilData}
                            disabled={isViewOnly}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Union Council"
                            style={{ width: '100%' }}
                            disabled={isViewOnly}
                            onChange={(value: any, item: any) => setInfrastructureDamageDetails(prevState => ({ ...prevState, union_councilId: item?.id, union_council: item?.value }))}
                            options={unionCouncilListData}
                            className='input'
                            value={infrastructureDamageDetails.union_council || undefined}
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            onChange={({ target }) => setInfrastructureDamageDetails(prevState => ({ ...prevState, address: target.value }))}
                            placeholder="Enter Address"
                            value={infrastructureDamageDetails.address || undefined}
                            status={(isCheckRequired && !infrastructureDamageDetails.address) ? 'error' : ""}
                            disabled={isViewOnly}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            onChange={({ target }) => setInfrastructureDamageDetails(prevState => ({ ...prevState, latitude: target.value }))}
                            placeholder="Enter Latitude"
                            value={infrastructureDamageDetails.latitude}
                            className='input'
                            status={(isCheckRequired && !infrastructureDamageDetails.latitude) ? 'error' : ""}
                            disabled={isViewOnly}
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            onChange={({ target }) => setInfrastructureDamageDetails(prevState => ({ ...prevState, longitude: target.value }))}
                            placeholder="Enter Longitude"
                            value={infrastructureDamageDetails.longitude}
                            disabled={isViewOnly}
                            className='input'
                            status={(isCheckRequired && !infrastructureDamageDetails.longitude) ? 'error' : ""}
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                </div>
            </div>
            {(isForUpdate || isViewOnly) ? <div className='form-body '>
                <div className='form-bady-heading grower-heading'>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <img src={DetailIcon} alt="" style={{ height: "35px" }} />
                        <span>Owner Details</span>
                    </div>
                    {isForUpdate ? <div className='add-more-button' onClick={handleAddMoreData}>
                        <img style={{ height: "20px" }} src={addIcon} />
                        <p style={{ fontSize: "14px", color: 'white' }}>Add more Data</p>
                    </div> : null}
                </div>
                <div className='form-cont'>
                    <ReusableTable
                        data={ownerData}
                        columns={growerDetailsColumn}
                        rowsPerPage={10}
                        total={ownerData.length}
                    />
                </div>
            </div> : null}
        </div >
    );
};

export default InfrastructureDamages;
