import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import client from "../helpers";
import API from "../graphql";
import { toast } from "../components/Toast";
import { RootState } from "./store";


interface NGOState {
    schoolList: any[];
    filteredSchoolList: any[];
    schoolsLoading: boolean;
    schoolMeta: any;
    schoolCount: number;
    schoolChartList: any[];
    selectedSchool: any;
}

const initialState: NGOState = {
    schoolList: [],
    filteredSchoolList: [],
    schoolsLoading: false,
    schoolMeta: {},
    schoolCount: 0,
    schoolChartList: [],
    selectedSchool: {},
};

export const fetchSchoolCount = createAsyncThunk('school/fetchSchoolCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {}
        };
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiGetSchoolsCount : API.ApiGetSchoolsCountWithOutLocation,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.schools.meta.pagination.total, schoolChartList: response.data.schools.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchSchoolData = createAsyncThunk('school/fetchSchoolData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil } = pageData;
        const filter: any = {};
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiGetAllSchool,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.schools.data, meta: response.data.schools.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchSchoolDataById = createAsyncThunk('school/fetchSchoolDataById', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetAllSchoolById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.school.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateSchoolRecordFromSocket = createAsyncThunk('school/addAndUpdateSchoolRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, externalWorkflowsList } = data;
        const response = await client.query({ query: API.ApiGetAllSchoolById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.school.data, externalWorkflowsList };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteSchoolRecordFromSocket = createAsyncThunk('school/deleteSchoolRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createSchoolData = createAsyncThunk('school/createSchoolData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateSchool,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createSchool.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const schoolSlice = createSlice({
    name: 'school',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchSchoolCount.fulfilled, (state, action) => {
                state.schoolCount = action.payload.count;
                state.schoolChartList = action.payload.schoolChartList;
                state.schoolsLoading = false;
            })
            .addCase(fetchSchoolData.fulfilled, (state, action) => {
                state.schoolList = action.payload.data;
                state.schoolMeta = action.payload.meta;
                state.schoolsLoading = false;
            })
            .addCase(fetchSchoolDataById.fulfilled, (state, action) => {
                state.selectedSchool = action.payload;
                state.schoolsLoading = false;
            })
            .addCase(createSchoolData.fulfilled, (state, action) => {
                state.schoolsLoading = false;
            })
            .addCase(addAndUpdateSchoolRecordFromSocket.fulfilled, (state, action) => {
                const externalWorkflowsList = action.payload.externalWorkflowsList;
                const { id, attributes } = action.payload.data;
                if (attributes.stage) {
                    const selectedWorkFlow: any = externalWorkflowsList.find((item: any) => item.stage === attributes.stage);
                    const index = state.filteredSchoolList.findIndex(item => item.id == id);
                    if (selectedWorkFlow) {
                        index !== -1 ? state.filteredSchoolList[index] = action.payload.data : state.filteredSchoolList.push(action.payload.data);
                    } else {
                        state.filteredSchoolList.splice(index, 1);
                    }
                } else {
                    const index = state.schoolList.findIndex(item => item.id == id);
                    index !== -1 ? state.schoolList[index] = action.payload.data : state.schoolList.push(action.payload.data);
                }
            })
            .addCase(deleteSchoolRecordFromSocket.fulfilled, (state, action) => {
                state.schoolList = state.schoolList.filter(item => item.id != action.payload);
                state.filteredSchoolList = state.filteredSchoolList.filter(item => item.id != action.payload);
            });
        [
            fetchSchoolCount,
            fetchSchoolData,
            createSchoolData,
            fetchSchoolDataById
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.schoolsLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.schoolsLoading = false;
                });
        });
    }
});


export default schoolSlice.reducer;