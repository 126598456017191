import React, { useRef, useState } from 'react';
import './index.css';
// import { SearchOutlined } from '@ant-design/icons';
import type { GetRef, TableColumnsType, TableColumnType } from 'antd';
import { Button, Input, Pagination, Space, Table } from 'antd';
// import type { FilterDropdownProps } from 'antd/es/table/interface';
// import Highlighter from 'react-highlight-words';

// type InputRef = GetRef<typeof Input>;

interface Props {
    data: any[];
    columns: TableColumnsType<any>;
    rowsPerPage?: number; // Optional number of rows per page (default: 10)
    total?: number; // Optional number of rows per page (default: 10)
    onChangePagination?: any; // Optional number of rows per page (default: 10)
    onSelectRow?: any; // Optional number of rows per page (default: 10)
}

const ReusableTable: React.FC<Props> = ({
    data,
    columns,
    rowsPerPage = 10,
    total,
    onChangePagination,
    onSelectRow
}) => {
    // const [searchText, setSearchText] = useState('');
    // const [searchedColumn, setSearchedColumn] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    // const searchInput = useRef<InputRef>(null);

    // const handleSearch = (
    //     selectedKeys: string[],
    //     confirm: FilterDropdownProps['confirm'],
    //     dataIndex: any,
    // ) => {
    //     confirm();
    //     setSearchText(selectedKeys[0]);
    //     setSearchedColumn(dataIndex);
    // };


    // const handleReset = (clearFilters: any, confirm: any) => {
    //     clearFilters();
    //     confirm();
    //     setSearchText("");
    //     setSearchedColumn("");
    // };

    // const getColumnSearchProps = (dataIndex: any): TableColumnType<any> => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    //         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
    //             <Input
    //                 ref={searchInput}
    //                 placeholder={`Search ${dataIndex}`}
    //                 value={selectedKeys[0]}
    //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
    //                 style={{ marginBottom: 8, display: 'block' }}
    //             />
    //             <Space>
    //                 <Button
    //                     type="primary"
    //                     onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
    //                     icon={<SearchOutlined />}
    //                     size="small"
    //                     style={{ width: 90 }}
    //                 >
    //                     Search
    //                 </Button>
    //                 <Button
    //                     onClick={() => clearFilters && handleReset(clearFilters, confirm)}
    //                     size="small"
    //                     style={{ width: 90 }}
    //                 >
    //                     Reset
    //                 </Button>
    //             </Space>
    //         </div>
    //     ),
    //     filterIcon: (filtered: boolean) => (
    //         <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    //     ),
    //     onFilter: (value: any, record) => {
    //         const fieldValue = record[dataIndex];
    //         if (typeof fieldValue === 'number') {
    //             // Convert both the filter value and the field value to numbers
    //             return fieldValue === parseFloat(value);
    //         } else if (typeof fieldValue === 'string') {
    //             // Case-insensitive string comparison
    //             return fieldValue.toLowerCase().includes(value.toLowerCase());
    //         }
    //         return false; // Return false for other types of data
    //     },
    //     render: (text, record) => {
    //         const textToRender = text ? (typeof text === 'string' ? text : text.toString()) : '';
    //         const highlightText = searchedColumn === dataIndex ? (
    //             <Highlighter
    //                 highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //                 searchWords={[searchText]}
    //                 autoEscape
    //                 textToHighlight={textToRender}
    //             />
    //         ) : (
    //             textToRender
    //         );
    //         return highlightText;
    //     },
    // });

    const enhancedColumns = columns.map((column) => {
        if ('dataIndex' in column) {
            return {
                ...column,
                // ...getColumnSearchProps(column.dataIndex as any),
            };
        } else {
            return column;
        }
    });

    const handlePageChange = (page: number) => {
        onChangePagination(page);
        setCurrentPage(page);
    };

    // const currentPageData = data.slice(
    //     (currentPage - 1) * rowsPerPage,
    //     currentPage * rowsPerPage
    // );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}>
            <Table
                rowKey={(record: any) => record.id}
                columns={enhancedColumns}
                dataSource={data}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%'
                }}
                pagination={false}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => onSelectRow ? onSelectRow(record) : null, // click row
                    };
                }}
            // pagination={{
            //     pageSize: rowsPerPage,
            //     total: data.length,
            //     onChange: handlePageChange,
            //     current: currentPage,
            // }}
            />
            <Pagination
                current={currentPage}
                style={{ margin: 10 }}
                total={total}
                onChange={handlePageChange}
                responsive={true}
                showSizeChanger={false}
                pageSize={rowsPerPage}
                showQuickJumper
            />
        </div>
    );
};

export default ReusableTable;
