
export const alignNgoData = (data, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil) => {
    const alignedNgoData = data?.filter((item) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item, index) => ({
        key: index + 1,
        id: item.id,
        ngo_name: item?.attributes?.ngo_name,
        divisionId: item?.attributes?.division?.data?.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        districtId: item?.attributes?.district?.data?.id,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsilId: item?.attributes?.tehsil?.data?.id,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        ngo: item?.attributes?.ngo?.data?.id,
        union_councilId: item?.attributes?.union_council?.data?.id,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        primary_areasId: item?.attributes?.primary_areas?.data?.map((area) => area?.id),
        primary_areas: item?.attributes?.primary_areas?.data?.map((area) => area?.attributes?.name),
        secondary_areasId: item?.attributes?.secondary_areas?.data?.map((area) => area?.id),
        secondary_areas: item?.attributes?.secondary_areas?.data?.map((area) => area?.attributes?.name),
        postal_address: item?.attributes?.postal_address,
        website: item?.attributes?.website,
        license_registration_number: item?.attributes?.license_registration_number,
        year_established: item?.attributes?.year_established,
        organizational_head: item?.attributes?.organizational_head,
        ngo_contact_person_in_district: item?.attributes?.ngo_contact_person_in_district,
        ngo_contact_person_mobile_number_in_district: item?.attributes?.ngo_contact_person_mobile_number_in_district,
        ngo_contact_number: item?.attributes?.ngo_contact_number,
        address: item?.attributes?.address,
        latitude: item?.attributes?.latitude,
        longitude: item?.attributes?.longitude,
        stage: item?.attributes?.stage,
        type: 'NGO Mapping'
    })) || [];
    return alignedNgoData;
};

export const alignVolunteersData = (data, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil) => {
    const alignedVolunteersData = data?.filter((item) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item, index) => ({
        key: index + 1,
        id: item.id,
        name: item?.attributes?.name,
        cnic_number: item?.attributes?.cnic_number,
        volunteer: item?.attributes?.volunteer?.data?.id,
        divisionId: item?.attributes?.division?.data?.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        districtId: item?.attributes?.district?.data?.id,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsilId: item?.attributes?.tehsil?.data?.id,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_councilId: item?.attributes?.union_council?.data?.id,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        postal_address: item?.attributes?.postal_address,
        website: item?.attributes?.website,
        contact_person: item?.attributes?.contact_person,
        address: item?.attributes?.address,
        contact_person_mobile: item?.attributes?.contact_person_mobile,
        latitude: item?.attributes?.latitude,
        longitude: item?.attributes?.longitude,
        stage: item?.attributes?.stage,
        type: 'Volunteers Mapping'
    })) || [];
    return alignedVolunteersData;
};

export const alignManufacturersData = (data, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil) => {
    const alignedManufacturersData = data?.filter((item) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item, index) => ({
        key: index + 1,
        id: item.id,
        name: item?.attributes?.name,
        divisionId: item?.attributes?.division?.data?.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        districtId: item?.attributes?.district?.data?.id,
        manufacturer: item?.attributes?.manufacturer?.data?.id,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsilId: item?.attributes?.tehsil?.data?.id,
        postal_address: item?.attributes?.postal_address,
        address: item?.attributes?.address,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_councilId: item?.attributes?.union_council?.data?.id,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        primary_areasId: item?.attributes?.primary_areas?.data?.map((area) => area?.id),
        primary_areas: item?.attributes?.primary_areas?.data?.map((area) => area?.attributes?.name),
        secondary_areasId: item?.attributes?.secondary_areas?.data?.map((area) => area?.id),
        secondary_areas: item?.attributes?.secondary_areas?.data?.map((area) => area?.attributes?.name),
        website: item?.attributes?.website,
        registration_number: item?.attributes?.registration_number,
        year_established: item?.attributes?.year_established,
        organizational_head: item?.attributes?.organizational_head,
        contact_person_in_district: item?.attributes?.contact_person_in_district,
        contact_person_mobile_number_in_district: item?.attributes?.contact_person_mobile_number_in_district,
        contact_number: item?.attributes?.contact_number,
        latitude: item?.attributes?.latitude,
        longitude: item?.attributes?.longitude,
        stage: item?.attributes?.stage,
        type: 'Manufacturers Mapping'
    })) || [];
    return alignedManufacturersData;
};

export const alignsSuppliersData = (data, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil) => {
    const alignedSuppliersData = data?.filter((item) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item, index) => ({
        key: index + 1,
        id: item.id,
        name: item?.attributes?.name,
        address: item?.attributes?.address,
        divisionId: item?.attributes?.division?.data?.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        supplier: item?.attributes?.supplier?.data?.id,
        districtId: item?.attributes?.district?.data?.id,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsilId: item?.attributes?.tehsil?.data?.id,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_councilId: item?.attributes?.union_council?.data?.id,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        primary_areasId: item?.attributes?.primary_areas?.data?.map((area) => area?.id),
        primary_areas: item?.attributes?.primary_areas?.data?.map((area) => area?.attributes?.name),
        secondary_areasId: item?.attributes?.secondary_areas?.data?.map((area) => area?.id),
        secondary_areas: item?.attributes?.secondary_areas?.data?.map((area) => area?.attributes?.name),
        postal_address: item?.attributes?.postal_address,
        website: item?.attributes?.website,
        registration_number: item?.attributes?.registration_number,
        year_established: item?.attributes?.year_established,
        organizational_head: item?.attributes?.organizational_head,
        contact_person_in_district: item?.attributes?.contact_person_in_district,
        contact_person_mobile_number_in_district: item?.attributes?.contact_person_mobile_number_in_district,
        contact_number: item?.attributes?.contact_number,
        latitude: item?.attributes?.latitude,
        longitude: item?.attributes?.longitude,
        stage: item?.attributes?.stage,
        type: 'Suppliers Mapping'
    })) || [];
    return alignedSuppliersData;
};

export const alignsSchoolsData = (data, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil) => {
    const alignedSuppliersData = data?.filter((item) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item, index) => ({
        key: index + 1,
        id: item.id,
        semis_code: item?.attributes?.semis_code,
        name: item?.attributes?.name,
        address: item?.attributes?.address,
        building_condition: item?.attributes?.building_condition,
        number_of_rooms: item?.attributes?.number_of_rooms,
        electricity: item?.attributes?.electricity,
        toilet: item?.attributes?.toilet,
        drinking_water: item?.attributes?.drinking_water,
        boundary_wall: item?.attributes?.boundary_wall,
        drinking_water: item?.attributes?.drinking_water,
        area_type: item?.attributes?.area_type,
        divisionId: item?.attributes?.division?.data?.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        districtId: item?.attributes?.district?.data?.id,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsilId: item?.attributes?.tehsil?.data?.id,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_councilId: item?.attributes?.union_council?.data?.id,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        latitude: item?.attributes?.latitude,
        longitude: item?.attributes?.longitude,
        stage: item?.attributes?.stage,
        type: 'School Mapping'
    })) || [];
    return alignedSuppliersData;
};

export const alignInventoryData = (data, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil) => {
    const alignedInventoryData = data?.filter((item) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item, index) => ({
        key: index + 1,
        id: item.id,
        product_typeId: item?.attributes?.product_type?.data?.id,
        product_type: item?.attributes?.product_type?.data?.attributes?.name,
        product_nameId: item?.attributes?.product_name?.data?.id,
        product_name: item?.attributes?.product_name?.data?.attributes?.name,
        product_categoryId: item?.attributes?.product_category?.data?.id,
        product_category: item?.attributes?.product_category?.data?.attributes?.name,
        classId: item?.attributes?.class?.data?.id,
        class: item?.attributes?.class?.data?.attributes?.name,
        product_section: item?.attributes?.product_section,
        received_quantity: item?.attributes?.received_quantity,
        consumed_quantity: item?.attributes?.consumed_quantity,
        inventory: item?.attributes?.inventory?.data?.id,
        on_hand_quantity: item?.attributes?.on_hand_quantity,
        warehouse_address: item?.attributes?.warehouse_address,
        description: item?.attributes?.description,
        unit_of_measure: item?.attributes?.unit_of_measure,
        product_code: item?.attributes?.product_code,
        product_barcode: item?.attributes?.product_barcode,
        product_qr_code: item?.attributes?.product_qr_code,
        product_brand: item?.attributes?.product_brand,
        product_company: item?.attributes?.product_company,
        induction_date: item?.attributes?.induction_date,
        warranty_year: item?.attributes?.warranty_year,
        expiry_date: item?.attributes?.expiry_date,
        divisionId: item?.attributes?.division?.data?.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        districtId: item?.attributes?.district?.data?.id,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsilId: item?.attributes?.tehsil?.data?.id,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_councilId: item?.attributes?.union_council?.data?.id,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        latitude: item?.attributes?.latitude,
        longitude: item?.attributes?.longitude,
        stage: item?.attributes?.stage,
    })) || [];
    return alignedInventoryData;
};

export const alignFinancialReliefData = (data, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil) => {
    const alignedFinancialReliefData = data?.filter((item) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item, index) => ({
        key: index + 1,
        id: item.id,
        contributor: item?.attributes?.contributor,
        amount_received_in_pkr: item?.attributes?.amount_received_in_pkr,
        amount_consumed_in_pkr: item?.attributes?.amount_consumed_in_pkr,
        financial_relief: item?.attributes?.financial_relief?.data?.id,
        amount_on_hand_in_pkr: item?.attributes?.amount_on_hand_in_pkr,
        eventData: item?.attributes?.event?.data,
        divisionId: item?.attributes?.division?.data?.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        districtId: item?.attributes?.district?.data?.id,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsilId: item?.attributes?.tehsil?.data?.id,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_councilId: item?.attributes?.union_council?.data?.id,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        eventId: item?.attributes?.event?.data?.id,
        event: item?.attributes?.event?.data?.attributes?.name,
        stage: item?.attributes?.stage,
    })) || [];
    return alignedFinancialReliefData;
};

export const alignCompensatonReliefsData = (data, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil) => {
    const alignedCompensatonReliefData = data?.filter((item) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item, index) => ({
        key: index + 1,
        id: item.id,
        full_name: item?.attributes?.full_name,
        compensaton_relief: item?.attributes?.compensaton_relief?.data?.id,
        cnic: item?.attributes?.cnic,
        date_of_birth: item?.attributes?.date_of_birth,
        type: item?.attributes?.type,
        reason: item?.attributes?.reason,
        eventData: item?.attributes?.event?.data,
        address: item?.attributes?.address,
        mobile_number: item?.attributes?.mobile_number,
        eventId: item?.attributes?.event?.data?.id,
        event: item?.attributes?.event?.data?.attributes?.name,
        compensation_status: item?.attributes?.compensation_status?.data?.attributes?.name,
        genderId: item?.attributes?.gender?.data?.id,
        gender: item?.attributes?.gender?.data?.attributes?.name,
        divisionId: item?.attributes?.division?.data?.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        districtId: item?.attributes?.district?.data?.id,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsilId: item?.attributes?.tehsil?.data?.id,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_councilId: item?.attributes?.union_council?.data?.id,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        stage: item?.attributes?.stage,
    })) || [];
    return alignedCompensatonReliefData;
};

export const alignHumanCasualtieData = (data, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil) => {
    const alignedHumanCasualtieData = data?.filter((item) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item, index) => ({
        key: index + 1,
        id: item.id,
        casualty_typeId: item?.attributes?.casualty_type?.data?.id,
        eventData: item?.attributes?.event?.data,
        eventId: item?.attributes?.event?.data?.id,
        event: item?.attributes?.event?.data?.attributes?.name,
        human_casualtie: item?.attributes?.human_casualtie?.data?.id,
        casualty_type: item?.attributes?.casualty_type?.data?.attributes?.name,
        quantity: item?.attributes?.quantity,
        divisionId: item?.attributes?.division?.data?.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        casualties_details: item?.attributes?.casualties_details?.data.map((district) => ({ gender: district?.attributes?.gender?.data?.attributes?.name })),
        districtId: item?.attributes?.district?.data?.id,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsilId: item?.attributes?.tehsil?.data?.id,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_councilId: item?.attributes?.union_council?.data?.id,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        latitude: item?.attributes?.latitude,
        longitude: item?.attributes?.longitude,
        stage: item?.attributes?.stage,
        type: 'Human Casualties'
    })) || [];
    return alignedHumanCasualtieData;
};

export const alignInfrastructureDamageData = (data, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil) => {
    const alignedInfrastructureDamageData = data?.filter((item) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item, index) => ({
        key: index + 1,
        id: item.id,
        damaged: item?.attributes?.damaged,
        restored: item?.attributes?.restored,
        infrastructure_typeId: item?.attributes?.infrastructure_type?.data?.id,
        infrastructure_type: item?.attributes?.infrastructure_type?.data?.attributes?.name,
        address: item?.attributes?.address,
        infrastructure_damage: item?.attributes?.infrastructure_damage?.data?.id,
        eventId: item?.attributes?.event?.data?.id,
        eventData: item?.attributes?.event?.data,
        event: item?.attributes?.event?.data?.attributes?.name,
        divisionId: item?.attributes?.division?.data?.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        districtId: item?.attributes?.district?.data?.id,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsilId: item?.attributes?.tehsil?.data?.id,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_councilId: item?.attributes?.union_council?.data?.id,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        latitude: item?.attributes?.latitude,
        longitude: item?.attributes?.longitude,
        stage: item?.attributes?.stage,
        type: 'Infrastructure Damages'
    })) || [];
    return alignedInfrastructureDamageData;
};

export const alignFoodDamageData = (data, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil) => {
    const alignedFoodDamageData = data?.filter((item) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item, index) => ({
        key: index + 1,
        id: item.id,
        acre: item?.attributes?.acre,
        cost: item?.attributes?.cost,
        agriculture_typeId: item?.attributes?.agriculture_type?.data?.id,
        address: item?.attributes?.address,
        food_damage: item?.attributes?.food_damage?.data?.id,
        agriculture_type: item?.attributes?.agriculture_type?.data?.attributes?.name,
        eventId: item?.attributes?.event?.data?.id,
        event: item?.attributes?.event?.data?.attributes?.name,
        divisionId: item?.attributes?.division?.data?.id,
        eventData: item?.attributes?.event?.data,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        districtId: item?.attributes?.district?.data?.id,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsilId: item?.attributes?.tehsil?.data?.id,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_councilId: item?.attributes?.union_council?.data?.id,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        latitude: item?.attributes?.latitude,
        longitude: item?.attributes?.longitude,
        stage: item?.attributes?.stage,
        type: 'Food Damages'
    })) || [];
    return alignedFoodDamageData;
};

export const alignGrowerDetailData = (data) => {
    const alignedFoodDamageData = data?.map((item, index) => ({
        key: index + 1,
        id: item.id,
        name: item?.attributes?.name,
        mobile_number: item?.attributes?.mobile_number,
        cnic: item?.attributes?.cnic,
        address: item?.attributes?.address,
        acre: item?.attributes?.acre,
        food_damageId: item?.attributes?.food_damage?.data?.id,
        food_damage: item?.attributes?.food_damage?.data?.attributes?.acre,
    })) || [];
    return alignedFoodDamageData;
};

export const alignLivestockDamageData = (data, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil) => {
    const alignedFoodDamageData = data?.filter((item) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item, index) => ({
        key: index + 1,
        id: item.id,
        total_quantity: item?.attributes?.total_quantity,
        cost: item?.attributes?.cost,
        address: item?.attributes?.address,
        livestock_typeId: item?.attributes?.livestock_type?.data?.id,
        livestock_damage: item?.attributes?.livestock_damage?.data?.id,
        livestock_type: item?.attributes?.livestock_type?.data?.attributes?.name,
        eventId: item?.attributes?.event?.data?.id,
        casualty_typeId: item?.attributes?.casualty_type?.data?.id,
        event: item?.attributes?.event?.data?.attributes?.name,
        eventData: item?.attributes?.event?.data,
        casualty_type: item?.attributes?.casualty_type?.data?.attributes?.name,
        divisionId: item?.attributes?.division?.data?.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        districtId: item?.attributes?.district?.data?.id,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsilId: item?.attributes?.tehsil?.data?.id,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_councilId: item?.attributes?.union_council?.data?.id,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        latitude: item?.attributes?.latitude,
        longitude: item?.attributes?.longitude,
        stage: item?.attributes?.stage,
        type: 'Livestock Damages'
    })) || [];
    return alignedFoodDamageData;
};

export const alignLivestockOwnerDetailsData = (data) => {
    const alignedData = data?.map((item, index) => ({
        key: index + 1,
        id: item.id,
        name: item?.attributes?.name,
        mobile_number: item?.attributes?.mobile_number,
        cnic: item?.attributes?.cnic,
        address: item?.attributes?.address,
        quantity: item?.attributes?.quantity,
        livestock_damageId: item?.attributes?.livestock_damage?.data?.id,
        livestock_damage: item?.attributes?.livestock_damage?.data?.attributes?.cost,
    })) || [];
    return alignedData;
};

export const alignInfrastructureOwnerDetailsData = (data) => {
    const alignedData = data?.map((item, index) => ({
        key: index + 1,
        id: item.id,
        name: item?.attributes?.name,
        mobile_number: item?.attributes?.mobile_number,
        cnic: item?.attributes?.cnic,
        address: item?.attributes?.address,
        damaged: item?.attributes?.damaged,
        restored: item?.attributes?.restored,
        infrastructure_damageId: item?.attributes?.infrastructure_damage?.data?.id,
        infrastructure_damage: item?.attributes?.infrastructure_damage?.data?.attributes?.damaged,
    })) || [];
    return alignedData;
};

export const alignEventData = (item) => {
    const alignedUserData = {
        key: 1,
        id: item?.id,
        name: item?.attributes?.name,
        description: item?.attributes?.description,
        from_date: item?.attributes?.from_date,
        to_date: item?.attributes?.to_date,
        divisions: item?.attributes?.divisions?.data.map((division) => (division?.attributes?.division_name)),
        districts: item?.attributes?.districts?.data.map((district) => (district?.attributes?.district_name)),
        tehsils: item?.attributes?.tehsils?.data.map((tehsil) => (tehsil?.attributes?.tehsil_name)),
        union_councils: item?.attributes?.union_councils?.data.map((union_council) => (union_council?.attributes?.union_council_name)),
    } || {};
    return alignedUserData;
};

export const alignCasualtiesDetailsData = (data) => {
    const alignedData = data?.map((item, index) => ({
        key: index + 1,
        id: item.id,
        full_name: item?.attributes?.full_name,
        cnic: item?.attributes?.cnic,
        genderId: item?.attributes?.gender?.data?.id,
        gender: item?.attributes?.gender?.data?.attributes?.name,
        date_of_birth: item?.attributes?.date_of_birth,
        mobile_number: item?.attributes?.mobile_number,
        address: item?.attributes?.address,
        human_casualtieId: item?.attributes?.human_casualtie?.data?.id,
        human_casualtie: item?.attributes?.human_casualtie?.data?.attributes?.cost,
    })) || [];
    return alignedData;
};
