import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import formResourceMappingLogo from '../../assets/images/formResourceMappingLogo.png';
import { Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { fetchManufacturerDataById } from '../../reduxToolkit/manufacturerSlice';
import { alignManufacturersData } from '../../helpers/alignData';

const ManufacturersMappingDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { recordId } = location.state || {};
    const [manufacturersMapping, setManufacturersMapping] = useState<{ [key: string]: any; }>({});
    const { selectedManufacturer } = useSelector((state: RootState) => state.manufacturer);

    useEffect(() => {
        dispatch(fetchManufacturerDataById(recordId));
    }, []);

    useEffect(() => {
        const manufacturerData = alignManufacturersData([selectedManufacturer]);
        const selectedManufacturerData = manufacturerData[0] ? manufacturerData[0] : {};
        setManufacturersMapping(selectedManufacturerData);
    }, [selectedManufacturer]);
    console.log('manufacturersMapping', manufacturersMapping);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formResourceMappingLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Resource Mapping</span>
                        </div>
                    </div>
                    <div />
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <span>Manufacturers Mapping</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Name</span>
                        <Input
                            value={manufacturersMapping.name}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Input
                            value={manufacturersMapping.division}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Input
                            value={manufacturersMapping.district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Input
                            value={manufacturersMapping.tehsil}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Input
                            value={manufacturersMapping.union_council}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            value={manufacturersMapping.address}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Postal Address</span>
                        <Input
                            value={manufacturersMapping.postal_address}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Primary Area</span>
                        <Input
                            value={manufacturersMapping.primary_areas?.map((uc: any) => uc).join(', ') ?? 'N/A'}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Secondary Area</span>
                        <Input
                            value={manufacturersMapping.secondary_areas?.map((uc: any) => uc).join(', ') ?? 'N/A'}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Website</span>
                        <Input
                            value={manufacturersMapping.postal_address}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Registration Number</span>
                        <Input
                            value={manufacturersMapping.registration_number}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Year established</span>
                        <Input
                            value={manufacturersMapping.year_established}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Organizational Head</span>
                        <Input
                            value={manufacturersMapping.organizational_head}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Contact Person in District</span>
                        <Input
                            value={manufacturersMapping.contact_person_in_district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Contact Person Mobile Number in District</span>
                        <Input
                            value={manufacturersMapping.contact_person_in_district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Contact Number</span>
                        <Input
                            value={manufacturersMapping.contact_number}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            value={manufacturersMapping.latitude}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            value={manufacturersMapping.longitude}
                            disabled
                            className='input'
                        />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ManufacturersMappingDetails;
