import React from 'react';
import { useLocation } from 'react-router-dom';
import NGOMappingDetails from './NGOMapping';
import VolunteersMappingDetails from './volunteersMapping';
import ManufacturersMappingDetails from './manufacturersMapping';
import SuppliersMappingDetails from './suppliersMapping';
import SchoolMappingDetails from './schoolMapping';
import InventoryDetails from './inventory';
import CompensationDetails from './compensation';
import ContributorsReliefDetails from './ContributorsRelief';
import HumanCasualtieDetails from './humanCasualties';
import InfrastructureDamageDetails from './infrastructureDamages';
import FoodDamageDetails from './foodDamages';
import LivestockDamageDetails from './livestockDamages';

const ItemDetails = () => {
    const location = useLocation();
    const { formHeading } = location.state || {};

    return (
        <div>
            {formHeading === 'NGO Mapping' ? <NGOMappingDetails /> : null}
            {formHeading === 'Volunteers Mapping' ? <VolunteersMappingDetails /> : null}
            {formHeading === 'Manufacturers Mapping' ? <ManufacturersMappingDetails /> : null}
            {formHeading === 'Suppliers Mapping' ? <SuppliersMappingDetails /> : null}
            {formHeading === 'School Mapping' ? <SchoolMappingDetails /> : null}
            {formHeading === 'Inventory' ? <InventoryDetails /> : null}
            {formHeading === 'Compensation relief' ? <CompensationDetails /> : null}
            {formHeading === 'Contributors relief' ? <ContributorsReliefDetails /> : null}
            {formHeading === 'Human Casualties' ? <HumanCasualtieDetails /> : null}
            {formHeading === 'Infrastructure Damages' ? <InfrastructureDamageDetails /> : null}
            {formHeading === 'Food Damages' ? <FoodDamageDetails /> : null}
            {formHeading === 'Livestock Damages' ? <LivestockDamageDetails /> : null}
        </div>
    );
};

export default ItemDetails;