import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

interface Name {
    name: string; // Define the structure of your Name object
}

interface EntitiesState {
    [key: string]: any;
    divisionList: any[];
    districtList: any[];
    tehsilList: any[];
    unionCouncilList: any[];
    genderList: any[];
    eventList: any[];
    infrastructureTypeList: any[];
    agricultureTypeList: any[];
    externalWorkflowsList: any[];
    internalWorkflowsList: any[];
    allDistrictList: any[];
    alltehsilList: any[];
    allUnionCouncilList: any[];
    primaryAreasList: any[];
    secondaryAreasList: any[];
}

const initialState: EntitiesState = {
    divisionList: [],
    districtList: [],
    tehsilList: [],
    unionCouncilList: [],
    genderList: [],
    eventList: [],
    infrastructureTypeList: [],
    agricultureTypeList: [],
    externalWorkflowsList: [],
    internalWorkflowsList: [],
    allDistrictList: [],
    alltehsilList: [],
    allUnionCouncilList: [],
    primaryAreasList: [],
    secondaryAreasList: [],
};

export const fetchDivisionData = createAsyncThunk('entities/fetchDivisionData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiDivision, fetchPolicy: 'no-cache' });
        return response.data.divisions.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchDistrictData = createAsyncThunk('entities/fetchDistrictData', async (division_name: string, { rejectWithValue }) => {
    try {
        if (!division_name) {
            return [];
        }
        const response = await client.query({
            query: API.ApiDistrict,
            fetchPolicy: 'no-cache',
            variables: {
                "filter": {
                    "division": {
                        "division_name": {
                            "eq": division_name
                        }
                    }
                }
            }
        });
        return response.data.districts.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchTehsilsData = createAsyncThunk('entities/fetchTehsilsData', async (district_name: string, { rejectWithValue }) => {
    try {
        if (!district_name) {
            return [];
        }
        const response = await client.query({
            query: API.ApiTehsils,
            fetchPolicy: 'no-cache',
            variables: {
                "filter": {
                    "district": {
                        "district_name": {
                            "eq": district_name
                        }
                    }
                }
            }
        });
        return response.data.tehsils.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchUnionCouncilData = createAsyncThunk('entities/fetchUnionCouncilData', async (tehsil_name: string, { rejectWithValue }) => {
    try {
        if (!tehsil_name) {
            return [];
        }
        const response = await client.query({
            query: API.ApiUnionCouncils,
            fetchPolicy: 'no-cache',
            variables: {
                "filter": {
                    "tehsil": {
                        "tehsil_name": {
                            "eq": tehsil_name
                        }
                    }
                }
            }
        });
        return response.data.unionCouncils.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchGenderData = createAsyncThunk('entities/fetchGenderData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGenders, fetchPolicy: 'no-cache' });
        return response.data.genders.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllDistrictData = createAsyncThunk('entities/fetchAllDistrictData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllDistrict, fetchPolicy: 'no-cache' });
        return response.data.districts.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllTehsilData = createAsyncThunk('entities/fetchAllTehsilData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllTehsils });
        return response.data.tehsils.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllUnionCouncilData = createAsyncThunk('entities/fetchAllUnionCouncilData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllUnionCouncils });
        return response.data.unionCouncils.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllEvents = createAsyncThunk('entities/fetchAllEvents', async (user: any, { rejectWithValue }) => {
    try {
        const response = await client.query({
            query: API.ApiEvents,
            fetchPolicy: 'no-cache',
            variables: {
                "filter": {
                    "districts": {
                        "id": {
                            "eq": user?.district?.id
                        }
                    }
                }
            },
        });
        return response.data.events.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllInfrastructureTypes = createAsyncThunk('entities/fetchAllInfrastructureTypes', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiInfrastructureTypes, fetchPolicy: 'no-cache' });
        return response.data.infrastructureTypes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllAgricultureTypes = createAsyncThunk('entities/fetchAllAgricultureTypes', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAgricultureTypes, fetchPolicy: 'no-cache' });
        return response.data.agricultureTypes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchExternalWorkflows = createAsyncThunk('entities/fetchExternalWorkflows', async (role: string, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.query({
            query: API.ApiExternalWorkflows,
            // variables: {
            //     "filter": {
            //         "role": {
            //             "name": {
            //                 "eq": role
            //             }
            //         }
            //     }
            // },
            context: {
                headers,
            },
            fetchPolicy: 'no-cache'
        });
        const alignedExternalWorkflows = response.data.externalWorkflows.data?.map((item: any, index: any) => ({
            key: index + 1,
            id: item.id,
            role: item?.attributes?.role?.data?.attributes?.name,
            title: item?.attributes?.title,
            stage: item?.attributes?.stage,
        })) || [];
        return alignedExternalWorkflows;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchInternalWorkflows = createAsyncThunk('entities/fetchInternalWorkflows', async (role: string, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.query({
            query: API.ApiInternalWorkflows,
            // variables: {
            //     "filter": {
            //         "role": {
            //             "name": {
            //                 "eq": role
            //             }
            //         }
            //     }
            // },
            context: {
                headers,
            },
            fetchPolicy: 'no-cache'
        });
        const alignedInternalWorkflows = response.data.internalWorkflows.data?.map((item: any, index: any) => ({
            key: index + 1,
            id: item.id,
            role: item?.attributes?.role?.data?.attributes?.name,
            title: item?.attributes?.title,
            stage: item?.attributes?.stage,
        })) || [];
        return alignedInternalWorkflows;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllPrimaryAreas = createAsyncThunk('entities/fetchAllPrimaryAreas', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllPrimaryAreas, fetchPolicy: 'no-cache' });
        return response.data.primaryAreas.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllSecondaryAreas = createAsyncThunk('entities/fetchAllSecondaryAreas', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllSecondaryAreas, fetchPolicy: 'no-cache' });
        return response.data.secondaryAreas.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateSecondaryAreaRecordFromSocket = createAsyncThunk('entities/addAndUpdateSecondaryAreaRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllSecondaryAreaById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.secondaryArea.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdatePrimaryAreaRecordFromSocket = createAsyncThunk('entities/addAndUpdatePrimaryAreaRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllPrimaryAreaById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.primaryArea.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateDivisionRecordFromSocket = createAsyncThunk('entities/addAndUpdateDivisionRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetDivisionById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.division.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteEntitiesRecordFromSocket = createAsyncThunk('entities/deleteDivisionRecordFromSocket', async (data: any, { rejectWithValue }) => {
    try {
        return data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateDistrictRecordFromSocket = createAsyncThunk('entities/addAndUpdateDistrictRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetDistrictById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.district.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateTehsilRecordFromSocket = createAsyncThunk('entities/addAndUpdateTehsilRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetTehsilById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.tehsil.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateUnionCouncilRecordFromSocket = createAsyncThunk('entities/addAndUpdateUnionCouncilRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetUnionCouncilById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.unionCouncil.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateEventRecordFromSocket = createAsyncThunk('entities/addAndUpdateEventRecordFromSocket', async (data: any, { rejectWithValue }) => {
    try {
        const { recordId, userData } = data;
        const response = await client.query({ query: API.ApiGetEventById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.event.data, userData };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateInfrastructureTypeRecordFromSocket = createAsyncThunk('entities/addAndUpdateInfrastructureTypeRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetInfrastructureTypeById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.infrastructureType.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateAgricultureTypeRecordFromSocket = createAsyncThunk('entities/addAndUpdateAgricultureTypeRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetAgricultureTypeById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.agricultureType.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateGenderRecordFromSocket = createAsyncThunk('entities/addAndUpdateGenderRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetGenderById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.gender.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const entitiesSlice = createSlice({
    name: 'entities',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchDivisionData.fulfilled, (state, action) => {
                state.divisionList = action.payload; // Since action.payload is already an array of Division
            })
            .addCase(fetchDistrictData.fulfilled, (state, action) => {
                state.districtList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchGenderData.fulfilled, (state, action) => {
                state.genderList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchTehsilsData.fulfilled, (state, action) => {
                state.tehsilList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchUnionCouncilData.fulfilled, (state, action) => {
                state.unionCouncilList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchAllDistrictData.pending, (state, action) => {
                state.entitiesLoading = false;
            })
            .addCase(fetchAllDistrictData.fulfilled, (state, action) => {
                state.allDistrictList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchAllDistrictData.rejected, (state, action) => {
                state.entitiesLoading = false;
            })
            .addCase(fetchAllTehsilData.pending, (state, action) => {
                state.entitiesLoading = false;
            })
            .addCase(fetchAllTehsilData.fulfilled, (state, action) => {
                state.alltehsilList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchAllTehsilData.rejected, (state, action) => {
                state.entitiesLoading = false;
            })
            .addCase(fetchAllUnionCouncilData.pending, (state, action) => {
                state.entitiesLoading = false;
            })
            .addCase(fetchAllUnionCouncilData.fulfilled, (state, action) => {
                state.allUnionCouncilList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchAllUnionCouncilData.rejected, (state, action) => {
                state.entitiesLoading = false;
            })
            .addCase(fetchAllEvents.fulfilled, (state, action) => {
                state.eventList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchAllInfrastructureTypes.fulfilled, (state, action) => {
                state.infrastructureTypeList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchAllAgricultureTypes.fulfilled, (state, action) => {
                state.agricultureTypeList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchExternalWorkflows.fulfilled, (state, action) => {
                state.externalWorkflowsList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchInternalWorkflows.fulfilled, (state, action) => {
                state.internalWorkflowsList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchAllPrimaryAreas.fulfilled, (state, action) => {
                state.primaryAreasList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchAllSecondaryAreas.fulfilled, (state, action) => {
                state.secondaryAreasList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(deleteEntitiesRecordFromSocket.fulfilled, (state, action) => {
                action.payload.list.forEach((listItem: string) => {
                    if (state.hasOwnProperty(listItem)) {
                        state[listItem] = [...state[listItem].filter((item: any) => item.id != action.payload.recordId)];
                    }
                });
            })
            .addCase(addAndUpdateDivisionRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.divisionList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.divisionList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.divisionList = updatedArray;
            })
            .addCase(addAndUpdateDistrictRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.allDistrictList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.allDistrictList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.allDistrictList = updatedArray;
                const selectedDistrict = state.districtList.find((item: any) => item?.attributes?.division?.data?.id == attributes?.division?.data?.id);
                if (selectedDistrict) {
                    const selectedDistrictIndex = state.districtList.findIndex((item: any) => item.id == id);
                    selectedDistrictIndex !== -1 ? state.districtList[selectedDistrictIndex] = action.payload : state.districtList.push(action.payload);
                }
            })
            .addCase(addAndUpdateTehsilRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const selectedTehsil = state.tehsilList.find((item: any) => item?.attributes?.district?.data?.id == attributes?.district?.data?.id);
                if (selectedTehsil) {
                    const selectedTehsilIndex = state.tehsilList.findIndex((item: any) => item.id == id);
                    selectedTehsilIndex !== -1 ? state.tehsilList[selectedTehsilIndex] = action.payload : state.tehsilList.push(action.payload);
                }
            })
            .addCase(addAndUpdateUnionCouncilRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const selectedUnionCouncil = state.unionCouncilList.find((item: any) => item?.attributes?.tehsil?.data?.id == attributes?.tehsil?.data?.id);
                if (selectedUnionCouncil) {
                    const selectedUnionCouncilIndex = state.unionCouncilList.findIndex((item: any) => item.id == id);
                    selectedUnionCouncilIndex !== -1 ? state.unionCouncilList[selectedUnionCouncilIndex] = action.payload : state.unionCouncilList.push(action.payload);
                }
            })
            .addCase(addAndUpdateEventRecordFromSocket.fulfilled, (state, action) => {
                const userData = action.payload.userData;
                const { id, attributes } = action.payload.data;
                const selectedDistrict = attributes?.districts?.data.find((item: any) => item?.attributes?.district_name === userData?.district?.district_name);
                if (selectedDistrict) {
                    const index = state.eventList.findIndex((item: any) => item.id == id);
                    const updatedArray = [...state.eventList];
                    index !== -1 ? updatedArray[index] = action.payload.data : updatedArray.push(action.payload.data);
                    state.eventList = updatedArray;
                } else {
                    state.eventList = [...state.eventList.filter((item: any) => item.id != action.payload.data.id)];
                }
            })
            .addCase(addAndUpdateGenderRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.genderList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.genderList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.genderList = updatedArray;
            })
            .addCase(addAndUpdateInfrastructureTypeRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.infrastructureTypeList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.infrastructureTypeList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.infrastructureTypeList = updatedArray;
            })
            .addCase(addAndUpdateAgricultureTypeRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.agricultureTypeList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.agricultureTypeList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.agricultureTypeList = updatedArray;
            })
            .addCase(addAndUpdatePrimaryAreaRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.primaryAreasList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.primaryAreasList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.primaryAreasList = updatedArray;
            })
            .addCase(addAndUpdateSecondaryAreaRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.secondaryAreasList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.secondaryAreasList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.secondaryAreasList = updatedArray;
            });
    }
});


export default entitiesSlice.reducer;
