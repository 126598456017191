// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import entitiesSlice from './entitiesSlice';
import ngoSlice from './ngoSlice';
import inventorySlice from './inventorySlice';
import volunteerSlice from './volunteerSlice';
import manufacturerSlice from './manufacturerSlice';
import supplierSlice from './supplierSlice';
import humanCasualtieSlice from './humanCasualtieSlice';
import infrastructureDamageSlice from './infrastructureDamageSlice';
import foodDamageSlice from './foodDamageSlice';
import livestockDamageSlice from './livestockDamageSlice';
import financialReliefSlice from './financialReliefSlice';
import compensatonReliefSlice from './compensatonReliefSlice';
import schoolSlice from './schoolSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        entities: entitiesSlice,
        ngos: ngoSlice,
        inventory: inventorySlice,
        volunteer: volunteerSlice,
        manufacturer: manufacturerSlice,
        supplier: supplierSlice,
        humanCasualtie: humanCasualtieSlice,
        infrastructureDamage: infrastructureDamageSlice,
        foodDamage: foodDamageSlice,
        livestockDamage: livestockDamageSlice,
        financialRelief: financialReliefSlice,
        compensatonRelief: compensatonReliefSlice,
        school: schoolSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
