import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

interface InventoryState {
    [key: string]: any;
    inventoryList: any[];
    inventoryLoading: boolean;
    productTypeList: any[];
    productNameList: any[];
    productCategorieList: any[];
    filteredInventoriesList: any[];
    filteredInventoriesCount: number;
    foodList: any[];
    foodMeta: any;
    foodCount: 0,
    foodChartList: [],
    medicineList: any[];
    medicineMeta: any;
    medicineCount: 0,
    medicineChartList: [],
    householdList: any[];
    householdMeta: any;
    householdCount: 0,
    householdChartList: [],
    machineryList: any[];
    machineryMeta: any;
    machineryCount: 0,
    machineryChartList: [],
    classList: [],
    selectedInventory: any;
}

const initialState: InventoryState = {
    inventoryList: [],
    inventoryLoading: false,
    productTypeList: [],
    productNameList: [],
    productCategorieList: [],
    filteredInventoriesList: [],
    filteredInventoriesCount: 0,
    foodList: [],
    foodMeta: {},
    foodCount: 0,
    foodChartList: [],
    medicineList: [],
    medicineMeta: {},
    medicineCount: 0,
    medicineChartList: [],
    householdList: [],
    householdMeta: {},
    householdCount: 0,
    householdChartList: [],
    machineryList: [],
    machineryMeta: {},
    machineryCount: 0,
    machineryChartList: [],
    classList: [],
    selectedInventory: {}
};

export const fetchFoodCount = createAsyncThunk('inventory/fetchFoodCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                },
                "product_type": {
                    "name": {
                        "eq": "Food"
                    }
                }
            }
        };
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiInventoriesCount : API.ApiInventoriesCountWithOutChartData,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.inventories.meta.pagination.total, foodChartList: response.data.inventories.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFoodData = createAsyncThunk('inventory/fetchFoodData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil } = pageData;
        const filter: any = {
            stage: {
                null: true
            },
            "product_type": {
                "name": {
                    "eq": "Food"
                }
            }
        };
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllInventories,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.inventories.data, meta: response.data.inventories.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchMedicineCount = createAsyncThunk('inventory/fetchMedicineCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                },
                "product_type": {
                    "name": {
                        "eq": "Medicine"
                    }
                }
            }
        };
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiInventoriesCount : API.ApiInventoriesCountWithOutChartData,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.inventories.meta.pagination.total, medicineChartList: response.data.inventories.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchMedicineData = createAsyncThunk('inventory/fetchMedicineData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil } = pageData;
        const filter: any = {
            stage: {
                null: true
            },
            "product_type": {
                "name": {
                    "eq": "Medicine"
                }
            }
        };
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllInventories,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.inventories.data, meta: response.data.inventories.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchHouseholdCount = createAsyncThunk('inventory/fetchHouseholdCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                },
                "product_type": {
                    "name": {
                        "eq": "Household"
                    }
                }
            }
        };
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiInventoriesCount : API.ApiInventoriesCountWithOutChartData,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.inventories.meta.pagination.total, householdChartList: response.data.inventories.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchHouseholdData = createAsyncThunk('inventory/fetchHouseholdData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil } = pageData;
        const filter: any = {
            stage: {
                null: true
            },
            "product_type": {
                "name": {
                    "eq": "Household"
                }
            }
        };
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllInventories,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.inventories.data, meta: response.data.inventories.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchMachineryCount = createAsyncThunk('inventory/fetchMachineryCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                },
                "product_type": {
                    "name": {
                        "eq": "Machinery"
                    }
                }
            }
        };
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiInventoriesCount : API.ApiInventoriesCountWithOutChartData,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.inventories.meta.pagination.total, machineryChartList: response.data.inventories.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchMachineryData = createAsyncThunk('inventory/fetchMachineryData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil } = pageData;
        const filter: any = {
            stage: {
                null: true
            },
            "product_type": {
                "name": {
                    "eq": "Machinery"
                }
            }
        };
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllInventories,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.inventories.data, meta: response.data.inventories.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFilterdInventoriesData = createAsyncThunk('inventory/fetchFilterdInventoriesData', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await client.query({ query: API.ApiInventories, variables, context: { headers }, fetchPolicy: 'no-cache' });
        return { data: response.data.inventoryDrafts.data, filteredInventoriesCount: response.data.inventoryDrafts.meta.pagination.total };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchProductTypesData = createAsyncThunk('inventory/fetchProductTypesData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiProductTypes, fetchPolicy: 'no-cache' });
        return response.data.productTypes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchProductNamesData = createAsyncThunk('inventory/fetchProductNamesData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiProductNames, fetchPolicy: 'no-cache' });
        return response.data.productNames.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchProductCategoriesData = createAsyncThunk('inventory/fetchProductCategoriesData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiProductCategories, fetchPolicy: 'no-cache' });
        return response.data.productCategories.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchClassesData = createAsyncThunk('inventory/fetchClassesData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetAllClasses, fetchPolicy: 'no-cache' });
        return response.data.classes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchInventoryDataById = createAsyncThunk('inventory/fetchInventoryDataById', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetInventoriesById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.inventory.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createInventoryData = createAsyncThunk('inventory/createInventoryData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateInventory,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createInventoryDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateInventoryDraftData = createAsyncThunk('inventory/updateInventoryDraftData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingInventoryDraft,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateInventoryDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteInventoryData = createAsyncThunk('inventory/deleteInventoryData', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteInventory,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateInventoryData = createAsyncThunk('inventory/updateInventoryData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingInventory, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateInventory.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateInventoryStageData = createAsyncThunk('inventory/updateInventoryStageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { stage, id, record } = data;
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        if (stage) {
            await client.mutate({
                mutation: API.ApiUpdateInventoryStage,
                variables: {
                    id: id,
                    stage: stage
                },
                context: {
                    headers,
                },
            });
        } else {
            const obj = {
                "divisionId": record.divisionId,
                "districtId": record.districtId,
                "product_typeId": record.product_typeId,
                "product_nameId": record.product_nameId,
                "product_categoryId": record.product_categoryId,
                "product_section": record.product_section,
                "received_quantity": record.received_quantity ? Number(record.received_quantity) : null,
                "consumed_quantity": record.consumed_quantity ? Number(record.consumed_quantity) : null,
                "warehouse_address": record.warehouse_address,
                "description": record.description,
                "unit_of_measure": record.unit_of_measure,
                "product_code": record.product_code,
                "product_barcode": record.product_barcode,
                "product_qr_code": record.product_qr_code,
                "product_brand": record.product_brand,
                "product_company": record.product_company,
                "induction_date": record.induction_date,
                "warranty_year": record.warranty_year,
                "expiry_date": record.expiry_date,
                "classId": record.classId,
                "latitude": record.latitude ? Number(record.latitude) : null,
                "longitude": record.longitude ? Number(record.longitude) : null,
                "stage": null,
                "tehsilId": record.tehsilId,
                "union_councilId": record.union_councilId,
                "address": record.address,
                "inventoryId": record.inventory
            };
            await client.mutate({
                mutation: obj.inventoryId ? API.ApiUpdateExistingInventory : API.ApiCreatePublishedInventory,
                variables: obj,
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
            await client.mutate({
                mutation: API.ApiDeleteInventoryDraft,
                variables: { id },
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
        }
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateInventoryRecordFromSocket = createAsyncThunk('inventory/addAndUpdateInventoryRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, internalWorkflowsList } = data;
        const response = await client.query({ query: API.ApiGetInventoriesById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.inventory.data, internalWorkflowsList };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteInventoryRecordFromSocket = createAsyncThunk('inventory/deleteInventoryRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteInventoryEntitiesRecordFromSocket = createAsyncThunk('inventory/deleteInventoryEntitiesRecordFromSocket', async (data: any, { rejectWithValue }) => {
    try {
        return data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateProductTypeRecordFromSocket = createAsyncThunk('inventory/addAndUpdateProductTypeRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetProductTypeById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.productType.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateProductNameRecordFromSocket = createAsyncThunk('inventory/addAndUpdateProductNameRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetProductNameById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.productName.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateProductCategoryRecordFromSocket = createAsyncThunk('inventory/addAndUpdateProductCategoryRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetProductCategoryById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.productCategory.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const getInventoryDraftByUser = createAsyncThunk('inventory/getInventoryDraftByUser', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.APIGetInventoryDraftByUser, // Assuming you have an API constant for update mutation
            variables,
            context: {
                headers,
            },
        });
        if (response.data && response.data.inventoryDrafts.data.length > 0) {
            return true;
        }
        return false;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchFoodCount.fulfilled, (state, action) => {
                state.foodCount = action.payload.count;
                state.foodChartList = action.payload.foodChartList;
                state.inventoryLoading = false;
            })
            .addCase(fetchMedicineCount.fulfilled, (state, action) => {
                state.medicineCount = action.payload.count;
                state.medicineChartList = action.payload.medicineChartList;
                state.inventoryLoading = false;
            })
            .addCase(fetchHouseholdCount.fulfilled, (state, action) => {
                state.householdCount = action.payload.count;
                state.householdChartList = action.payload.householdChartList;
                state.inventoryLoading = false;
            })
            .addCase(fetchMachineryCount.fulfilled, (state, action) => {
                state.machineryCount = action.payload.count;
                state.machineryChartList = action.payload.machineryChartList;
                state.inventoryLoading = false;
            })
            .addCase(fetchFoodData.fulfilled, (state, action) => {
                state.foodList = action.payload.data;
                state.foodMeta = action.payload.meta;
                state.inventoryLoading = false;
            })
            .addCase(fetchMedicineData.fulfilled, (state, action) => {
                state.medicineList = action.payload.data;
                state.medicineMeta = action.payload.meta;
                state.inventoryLoading = false;
            })
            .addCase(fetchHouseholdData.fulfilled, (state, action) => {
                state.householdList = action.payload.data;
                state.householdMeta = action.payload.meta;
                state.inventoryLoading = false;
            })
            .addCase(fetchMachineryData.fulfilled, (state, action) => {
                state.machineryList = action.payload.data;
                state.machineryMeta = action.payload.meta;
                state.inventoryLoading = false;
            })
            .addCase(fetchFilterdInventoriesData.fulfilled, (state, action) => {
                state.filteredInventoriesList = action.payload.data;
                state.filteredInventoriesCount = action.payload.filteredInventoriesCount;
                state.inventoryLoading = false;
            })
            .addCase(fetchProductTypesData.fulfilled, (state, action) => {
                state.productTypeList = action.payload;
            })
            .addCase(fetchClassesData.fulfilled, (state, action) => {
                state.classList = action.payload;
            })
            .addCase(fetchProductNamesData.fulfilled, (state, action) => {
                state.productNameList = action.payload;
            })
            .addCase(fetchProductCategoriesData.fulfilled, (state, action) => {
                state.productCategorieList = action.payload;
            })
            .addCase(fetchInventoryDataById.fulfilled, (state, action) => {
                state.selectedInventory = action.payload;
                state.inventoryLoading = false;
            })
            .addCase(createInventoryData.fulfilled, (state, action) => {
                // state.inventoryList.push(action.payload);
                state.inventoryLoading = false;
            })
            .addCase(updateInventoryDraftData.fulfilled, (state, action) => {
                state.inventoryLoading = false;
            })
            .addCase(updateInventoryData.fulfilled, (state, action) => {
                state.inventoryLoading = false;
            })
            .addCase(getInventoryDraftByUser.fulfilled, (state, action) => {
                state.inventoryLoading = false;
            })
            .addCase(updateInventoryStageData.fulfilled, (state, action) => {
                const index = state.filteredInventoriesList.findIndex(item => item.id === action.payload);
                state.filteredInventoriesList.splice(index, 1);
                state.inventoryLoading = false;
            })
            .addCase(deleteInventoryData.fulfilled, (state, action) => {
                state.inventoryLoading = false;
            })
            .addCase(addAndUpdateInventoryRecordFromSocket.fulfilled, (state, action) => {
                const internalWorkflowsList = action.payload.internalWorkflowsList;
                const { id, attributes } = action.payload.data;
                if (attributes.stage) {
                    const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === attributes.stage);
                    const index = state.filteredInventoriesList.findIndex(item => item.id == id);
                    if (selectedWorkFlow) {
                        index !== -1 ? state.filteredInventoriesList[index] = action.payload.data : state.filteredInventoriesList.push(action.payload.data);
                    } else {
                        state.filteredInventoriesList.splice(index, 1);
                    }
                } else {
                    const index = state.inventoryList.findIndex(item => item.id == id);
                    index !== -1 ? state.inventoryList[index] = action.payload.data : state.inventoryList.push(action.payload.data);
                }
            })
            .addCase(deleteInventoryRecordFromSocket.fulfilled, (state, action) => {
                state.inventoryList = state.inventoryList.filter(item => item.id != action.payload);
                state.filteredInventoriesList = state.filteredInventoriesList.filter(item => item.id != action.payload);
            })
            .addCase(deleteInventoryEntitiesRecordFromSocket.fulfilled, (state, action) => {
                action.payload.list.forEach((listItem: string) => {
                    if (state.hasOwnProperty(listItem)) {
                        state[listItem] = [...state[listItem].filter((item: any) => item.id != action.payload.recordId)];
                    }
                });
            })
            .addCase(addAndUpdateProductTypeRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.productTypeList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.productTypeList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.productTypeList = updatedArray;
            })
            .addCase(addAndUpdateProductNameRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.productNameList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.productNameList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.productNameList = updatedArray;
            })
            .addCase(addAndUpdateProductCategoryRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.productCategorieList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.productCategorieList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.productCategorieList = updatedArray;
            });
        [
            fetchFoodCount,
            fetchMedicineCount,
            fetchHouseholdCount,
            fetchMachineryCount,
            fetchFoodData,
            fetchMedicineData,
            fetchHouseholdData,
            fetchMachineryData,
            deleteInventoryData,
            fetchFilterdInventoriesData,
            updateInventoryStageData,
            createInventoryData,
            updateInventoryData,
            fetchInventoryDataById,
            updateInventoryDraftData,
            getInventoryDraftByUser
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.inventoryLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.inventoryLoading = false;
                });
        });
    }
});


export default inventorySlice.reducer;
