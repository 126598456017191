import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lineIcon from '../../assets/images/lineIcno.png';
import DeathsIcon from '../../assets/images/Deaths.png';
import InjuredIcon from '../../assets/images/Injured.png';
import RecoveredIcon from '../../assets/images/Recovered.png';
import DisplacedIcon from '../../assets/images/Displaced.png';
import RescuedIcon from '../../assets/images/Rescued.png';
import HumanCasualtiesIcon from '../../assets/images/Human Casualties.png';
import InfrastructureFoodDamagesIcon from '../../assets/images/Infrastructure & Food Damages.png';
import PlusIcon from '../../assets/images/plusIcon.png';
import PieChart from '../../components/charts/pie_chart';
import PieChartWithRadius from '../../components/charts/pie_chart_with_radius';
import { RootState } from "../../reduxToolkit/store";
import ReusableTable from '../../components/table';
import ProgressBar from '../../components/progressBar';
import MapChartComponent from '../../components/Map';
import { deleteHumanCasualtieData, fetchHumanCasualtieCount, fetchHumanCasualtieData } from '../../reduxToolkit/humanCasualtieSlice';
import { deleteInfrastructureDamageData, fetchInfrastructureDamageCount, fetchInfrastructureDamageData } from '../../reduxToolkit/infrastructureDamageSlice';
import { deleteFoodDamageData, fetchFoodDamageData, fetchFoodDamagesCount } from '../../reduxToolkit/foodDamageSlice';
import { deleteLivestockDamageData, fetchLivestockDamageData, fetchLivestockDamagesCount } from '../../reduxToolkit/livestockDamageSlice';
import Filters from '../../components/top_filter';
import { useNavigate } from 'react-router-dom';
import SearchSelect from '../../components/selectDropdown';
import { Button, Dropdown, Input, Space, Tooltip } from 'antd';
import { foodDamageColumns, humanCasualtieColumns, infrastructureDamageColumns, livestockDamageColumns } from '../../helpers/tableColumns';
import { alignFoodDamageData, alignHumanCasualtieData, alignInfrastructureDamageData, alignLivestockDamageData } from '../../helpers/alignData';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import OverviewIcon from '../../assets/images/OverviewIcon.png';
import human_casualties_card from '../../assets/images/human_casualties_card.png';
import infrastructure_card from '../../assets/images/infrastructure_card.png';
import agriculture_card from '../../assets/images/agriculture_card.png';
import livestock_card from '../../assets/images/livestock_card.png';
import './index.css';
import { CardComponent } from '../../components';
import { MdVerified } from "react-icons/md";

const LossessDamages: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { userData } = useSelector((state: RootState) => state.auth);
  const { editorMode, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { humanCasualtieList, humanCasualtieMeta, humanCasualtieType, humanCasualtieChartList, humanCasualtieCount } = useSelector((state: RootState) => state.humanCasualtie);
  const { infrastructureDamageList, infrastructureDamageMeta, infrastructureDamageChartList, infrastructureDamageCount } = useSelector((state: RootState) => state.infrastructureDamage);
  const { foodDamageList, foodDamageMeta, foodDamagesChartList, foodDamagesCount } = useSelector((state: RootState) => state.foodDamage);
  const { livestockDamageList, livestockDamageMeta, livestockDamageChartList, livestockDamageCount } = useSelector((state: RootState) => state.livestockDamage);
  const [selectedDivision, setSelectedDivision] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedTehsil, setSelectedTehsil] = useState('');
  const [selectedUnionCouncil, setSelectedUnionCouncil] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedHumanCasualtiesParameter, setSelectedHumanCasualtiesParameter] = useState('');
  const [selectedHumanCasualtiesKeyword, setSelectedHumanCasualtiesKeyword] = useState('');
  const [humanCasualtiesRowsPerPage, setHumanCasualtiesRowsPerPage] = useState(10);
  const [selectedInfrastructureParameter, setSelectedInfrastructureParameter] = useState('');
  const [selectedInfrastructureKeyword, setSelectedInfrastructureKeyword] = useState('');
  const [infrastructureRowsPerPage, setInfrastructureRowsPerPage] = useState(10);
  const [selectedFoodParameter, setSelectedFoodParameter] = useState('');
  const [selectedFoodKeyword, setSelectedFoodKeyword] = useState('');
  const [foodRowsPerPage, setFoodRowsPerPage] = useState(10);
  const [selectedLivestockParameter, setSelectedLivestockParameter] = useState('');
  const [selectedLivestockKeyword, setSelectedLivestockKeyword] = useState('');
  const [livestockRowsPerPage, setLivestockRowsPerPage] = useState(10);
  const [selectedCard, setSelectedCard] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchHumanCasualtieCount({ withLocation: selectedCard === 1, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
    dispatch(fetchInfrastructureDamageCount({ withLocation: selectedCard === 2, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
    dispatch(fetchFoodDamagesCount({ withLocation: selectedCard === 3, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
    dispatch(fetchLivestockDamagesCount({ withLocation: selectedCard === 4, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
  }, [selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent]);

  useEffect(() => {
    (async () => {
      if (selectedCard === 1) {
        await dispatch(fetchHumanCasualtieCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
      } else if (selectedCard === 2) {
        await dispatch(fetchInfrastructureDamageCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
      } else if (selectedCard === 3) {
        await dispatch(fetchFoodDamagesCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
      } else if (selectedCard === 4) {
        await dispatch(fetchLivestockDamagesCount({ withLocation: true, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
      }
      if (selectedCard === 1 && isAuthenticated) {
        await dispatch(fetchHumanCasualtieData({ page: 1, pageSize: humanCasualtiesRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
      } else if (selectedCard === 2 && isAuthenticated) {
        await dispatch(fetchInfrastructureDamageData({ page: 1, pageSize: infrastructureRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
      } else if (selectedCard === 3 && isAuthenticated) {
        await dispatch(fetchFoodDamageData({ page: 1, pageSize: foodRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
      } else if (selectedCard === 4 && isAuthenticated) {
        await dispatch(fetchLivestockDamageData({ page: 1, pageSize: livestockRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
      }
    })();
  }, [selectedCard, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && selectedCard === 1) {
      dispatch(fetchHumanCasualtieData({ page: 1, pageSize: humanCasualtiesRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
    } else if (isAuthenticated && selectedCard === 2) {
      dispatch(fetchInfrastructureDamageData({ page: 1, pageSize: infrastructureRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
    } else if (isAuthenticated && selectedCard === 3) {
      dispatch(fetchFoodDamageData({ page: 1, pageSize: foodRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
    } else if (isAuthenticated && selectedCard === 4) {
      dispatch(fetchLivestockDamageData({ page: 1, pageSize: livestockRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }));
    }
  }, [humanCasualtiesRowsPerPage, infrastructureRowsPerPage, foodRowsPerPage, livestockRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent, isAuthenticated]);

  useEffect(() => {
    if (userData?.division?.division_name && userData?.district?.district_name) {
      setSelectedDivision(userData?.division?.division_name);
      setSelectedDistrict(userData?.district?.district_name);
    }
  }, [userData]);

  const humanCasualtieData = alignHumanCasualtieData(humanCasualtieList);
  const infrastructureDamageData = alignInfrastructureDamageData(infrastructureDamageList);
  const foodDamageData = alignFoodDamageData(foodDamageList);
  const livestockDamageData = alignLivestockDamageData(livestockDamageList);

  const humanCasualtieAlignChartData = alignHumanCasualtieData(humanCasualtieChartList);
  const infrastructureDamageAlignChartData = alignInfrastructureDamageData(infrastructureDamageChartList);
  const foodDamageAlignChartData = alignFoodDamageData(foodDamagesChartList);
  const livestockDamageAlignChartData = alignLivestockDamageData(livestockDamageChartList);

  const infrastructureDamageChartData = calculateCountChartData(infrastructureDamageAlignChartData, 'infrastructure_type');
  const foodDamageChartData = calculateQtyChartData(foodDamageAlignChartData, 'agriculture_type', 'acre');
  const livestockDamageChartData = calculateCountChartData(livestockDamageAlignChartData, 'livestock_type',);

  const actionButtons = (formHeading: String) => {
    return {
      title: 'Actions',
      width: 150,
      align: 'center' as 'center',
      hidden: !editorMode,
      render: (text: any, record: any) => {
        const ableToAction = userData?.division?.division_name === record.division && userData?.district?.district_name === record.district;
        return (ableToAction) && <span onClick={(e) => e.stopPropagation()}>
          <Button
            type="primary"
            title='Edit'
            style={{ background: '#357A38' }}
            className='m-v-5'
            icon={<EditOutlined />}
            onClick={() => naviagteToEdit(formHeading, record)}
            shape='circle' />
          <Button
            type="primary"
            title='Delete'
            style={{ background: '#357A38' }}
            className='m-v-5'
            icon={<DeleteOutlined />}
            onClick={() => deleteData(formHeading, record.id)}
            shape='circle' />
        </span>;
      },
    };
  };

  const deleteData = (formHeading: String, id: String) => {
    if (formHeading === "Human Casualties") {
      dispatch(deleteHumanCasualtieData(id));
    } else if (formHeading === "Infrastructure Damages") {
      dispatch(deleteInfrastructureDamageData(id));
    } else if (formHeading === "Food Damages") {
      dispatch(deleteFoodDamageData(id));
    } else if (formHeading === "Livestock Damages") {
      dispatch(deleteLivestockDamageData(id));
    }
  };

  const naviagteToEdit = (formHeading: String, data: any) => {
    navigate("/form", {
      state: {
        formHeading,
        recordId: data.id,
        data: {
          ...data,
          human_casualtie: data.id,
          infrastructure_damage: data.id,
          food_damage: data.id,
          livestock_damage: data.id,
        },
        isForUpdate: true
      }
    });
  };

  const onChangeCard = (num: number) => {
    setSelectedCard(num);
    setSelectedHumanCasualtiesParameter('');
    setSelectedHumanCasualtiesKeyword('');
    setSelectedInfrastructureParameter('');
    setSelectedInfrastructureKeyword('');
    setSelectedFoodParameter('');
    setSelectedFoodKeyword('');
    setSelectedLivestockParameter('');
    setSelectedLivestockKeyword('');

  };

  const mapData = [
    ...selectedCard === 1 ? humanCasualtieAlignChartData : [],
    ...selectedCard === 2 ? infrastructureDamageAlignChartData : [],
    ...selectedCard === 3 ? foodDamageAlignChartData : [],
    ...selectedCard === 4 ? livestockDamageAlignChartData : [],
  ];

  return (
    <div className='LossesDamages-mainCont'>
      <Filters
        setSelectedDivision={setSelectedDivision}
        selectedDivision={selectedDivision}
        setSelectedDistrict={setSelectedDistrict}
        selectedDistrict={selectedDistrict}
        setSelectedTehsil={setSelectedTehsil}
        selectedTehsil={selectedTehsil}
        setSelectedUnionCouncil={setSelectedUnionCouncil}
        selectedUnionCouncil={selectedUnionCouncil}
        setSelectedEvent={setSelectedEvent}
        selectedEvent={selectedEvent}
      />
      <div className='top-lable-cont'>
        <div>
          <img src={OverviewIcon} alt="Overview_icon" style={{ width: "21px" }} />
          <span>Overview</span>
        </div>
        <img src={lineIcon} alt="line_icon" style={{ width: "32px" }} />
      </div>
      <div className='card-cont'>
        <CardComponent
          title="Human Casualties"
          icon={human_casualties_card}
          count={humanCasualtieCount}
          selectedCard={selectedCard}
          cardNumber={1}
          onChangeCard={() => onChangeCard(1)}
        />
        <CardComponent
          title="Infrastructures"
          icon={infrastructure_card}
          count={infrastructureDamageCount}
          selectedCard={selectedCard}
          cardNumber={2}
          onChangeCard={() => onChangeCard(2)}
        />
        <CardComponent
          title="Agriculture Foods"
          icon={agriculture_card}
          count={foodDamagesCount}
          selectedCard={selectedCard}
          cardNumber={3}
          onChangeCard={() => onChangeCard(3)}
        />
        <CardComponent
          title="Livestock / Fisheries"
          icon={livestock_card}
          count={livestockDamageCount}
          selectedCard={selectedCard}
          cardNumber={4}
          onChangeCard={() => onChangeCard(4)}
        />
      </div>
      {!editorMode && <>
        {selectedCard === 1 ? <>
          <div className='LossesDamages-card-cont'>
            {humanCasualtieType?.map((item, index) => {
              const icon = humanCasualtieTypeIcons[item?.attributes?.name];
              return <LossesDamagesCard
                key={index}
                title={item?.attributes?.name}
                icon={icon}
                progressBarColor={item?.attributes?.chart_color}
                data={humanCasualtieAlignChartData} />;
            })}
          </div>
        </> : null}
        {selectedCard === 2 ? <>
          <div className='LossesDamages_charts'>
            <div className='LossesDamages_charts_headingCont'>
              <div className='LossesDamages_charts_heading'>
                <img src={infrastructure_card} alt="" style={{ height: '22px' }} />
                <span>Infrastructure Overview:</span>
              </div>
              <img src={lineIcon} alt="" style={{ height: '22px' }} />
            </div>
            <div className='LossesDamageschart'>
              <div className='LchartLabal'>
                <span>Damages</span>
                <span style={{ color: "#073042", paddingLeft: "5px" }}>( Count )</span>
              </div>
              <div className='Lchart'>
                <PieChart data={infrastructureDamageChartData} colorField="Item" angleField="Count" />
              </div>
            </div>
          </div>
        </> : null}
        {selectedCard === 3 ? <>
          <div className='LossesDamages_charts'>
            <div className='LossesDamages_charts_headingCont'>
              <div className='LossesDamages_charts_heading'>
                <img src={agriculture_card} alt="" style={{ height: '22px' }} />
                <span>Agriculture Food Overview:</span>
              </div>
              <img src={lineIcon} alt="" style={{ height: '22px' }} />
            </div>
            <div className='LossesDamageschart'>
              <div className='LchartLabal'>
                <span>Damages</span>
                <span style={{ color: "#073042", paddingLeft: "5px" }}>( Acres )</span>
              </div>
              <div className='Lchart'>
                <PieChartWithRadius data={foodDamageChartData} colorField="Item" angleField="acre" innerRadius={0.5} />
              </div>
            </div>
          </div>
        </> : null}
        {selectedCard === 4 ? <>
          <div className='LossesDamages_charts'>
            <div className='LossesDamages_charts_headingCont'>
              <div className='LossesDamages_charts_heading'>
                <img src={livestock_card} alt="" style={{ height: '22px' }} />
                <span>Livestock / Fisheries Overview:</span>
              </div>
              <img src={lineIcon} alt="" style={{ height: '22px' }} />
            </div>
            <div className='LossesDamageschart'>
              <div className='LchartLabal'>
                <span>Damages</span>
                <span style={{ color: "#073042", paddingLeft: "5px" }}>( Count )</span>
              </div>
              <div className='Lchart'>
                <PieChartWithRadius data={livestockDamageChartData} colorField="Item" angleField="Count" innerRadius={0.5} />
              </div>
            </div>
          </div>
        </> : null}
        <div className='LossesDamages-Mapcharts-cont' style={{ marginTop: "20px", height: "auto" }}>
          <div className='LossesDamages_charts_headingCont'>
            <span style={{ padding: '0' }}>Sindh Map Overview</span>
            <div className='LossesDamages_charts_heading'>
            </div>
            <img src={lineIcon} alt="" style={{ height: '22px' }} />
          </div>
          <div className='LossesDamageschartOne' style={{ height: "auto" }}>
            <div className='LchartLabalInner'>
              <span>Affected Areas</span>
              <span style={{ color: "#073042", paddingLeft: "5px" }}>( Acres )</span>
            </div>
            <div className='LchartInner'>
              <MapChartComponent data={mapData} />
            </div>
          </div>
        </div>
      </>}
      {isAuthenticated ? <>
        {selectedCard === 1 ? <>
          <div className='LossesDamages-table-cont'>
            <div className='LossesDamages-table-filter-btn-cont'>
              {!!editorMode ?
                <div className='admin-add-data-btn' onClick={() => navigate("/form", { state: { formHeading: 'Human Casualties' } })}>
                  <img src={PlusIcon} alt="" />
                  <span>Add New Data</span>
                </div> : <div />}
              <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap', width: "500px" }}>
                <SearchSelect
                  onChange={env => { setSelectedHumanCasualtiesParameter(env); setSelectedHumanCasualtiesKeyword(''); }}
                  placeholder="Select the Parameter"
                  className="filter-input select-param-input"
                  value={selectedHumanCasualtiesParameter || undefined}
                  options={humanCasualtieColumns
                    .filter(item => item.title !== 'Google Map Location')
                    .map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))
                  }
                  allowClear={true}
                />
                <Input
                  placeholder="Keyword Search"
                  className=' filter-input select-param-input'
                  disabled={!selectedHumanCasualtiesParameter}
                  allowClear={true}
                  onChange={({ target }) => setSelectedHumanCasualtiesKeyword(target.value)}
                  value={selectedHumanCasualtiesKeyword}
                />
                <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                  <span >Show:</span>
                  <SearchSelect
                    onChange={(value: String) => setHumanCasualtiesRowsPerPage(+value)}
                    placeholder="Select the Entries count"
                    className="w-100px"
                    value={humanCasualtiesRowsPerPage}
                    options={[
                      { value: "2", label: "2 Entries" },
                      { value: "5", label: "5 Entries" },
                      { value: "8", label: "8 Entries" },
                      { value: "10", label: "10 Entries" },
                    ]}
                  />
                </div>
              </div>
            </div>
            <ReusableTable
              data={(selectedHumanCasualtiesParameter && selectedHumanCasualtiesKeyword) ? humanCasualtieData.filter((item: any) => {
                const value = typeof item[selectedHumanCasualtiesParameter] === 'string' ? item[selectedHumanCasualtiesParameter].toLowerCase() : item[selectedHumanCasualtiesParameter]?.toString();
                return value && (Array.isArray(item[selectedHumanCasualtiesParameter]) ? item[selectedHumanCasualtiesParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedHumanCasualtiesKeyword.toLowerCase())) : value.includes(selectedHumanCasualtiesKeyword.toLowerCase()));
              }) : humanCasualtieData}
              columns={[...humanCasualtieColumns, actionButtons("Human Casualties")]}
              rowsPerPage={humanCasualtiesRowsPerPage}
              total={humanCasualtieMeta?.pagination?.total}
              onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Human Casualties', recordId: r.id } })}
              onChangePagination={(page: number) => dispatch(fetchHumanCasualtieData({ page, pageSize: humanCasualtiesRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }))}
            />
          </div>
        </> : null}
        {selectedCard === 2 ? <>
          <div className='LossesDamages-table-cont'>
            <div className='LossesDamages-table-filter-btn-cont'>
              {!!editorMode ?
                <div className='admin-add-data-btn' onClick={() => navigate("/form", { state: { formHeading: 'Infrastructure Damages' } })}>
                  <img src={PlusIcon} alt="" />
                  <span>Add New Data</span>
                </div> : <div />}
              <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap', width: "500px" }}>
                <SearchSelect
                  onChange={env => { setSelectedInfrastructureParameter(env); setSelectedInfrastructureKeyword(''); }}
                  placeholder="Select the Parameter"
                  className="filter-input select-param-input"
                  value={selectedInfrastructureParameter || undefined}
                  options={infrastructureDamageColumns
                    .filter(item => item.title !== 'Google Map Location')
                    .map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))
                  }
                  allowClear={true}
                />
                <Input
                  placeholder="Keyword Search"
                  className=' filter-input select-param-input'
                  disabled={!selectedInfrastructureParameter}
                  allowClear={true}
                  onChange={({ target }) => setSelectedInfrastructureKeyword(target.value)}
                  value={selectedInfrastructureKeyword}
                />
                <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                  <span>Show:</span>
                  <SearchSelect
                    onChange={(value: String) => setInfrastructureRowsPerPage(+value)}
                    placeholder="Select the Entries count"
                    className="w-100px"
                    value={infrastructureRowsPerPage}
                    options={[
                      { value: "2", label: "2 Entries" },
                      { value: "5", label: "5 Entries" },
                      { value: "8", label: "8 Entries" },
                      { value: "10", label: "10 Entries" },
                    ]}
                  />
                </div>
              </div>
            </div>
            <ReusableTable
              data={(selectedInfrastructureParameter && selectedInfrastructureKeyword) ? infrastructureDamageData.filter((item: any) => {
                const value = typeof item[selectedInfrastructureParameter] === 'string' ? item[selectedInfrastructureParameter].toLowerCase() : item[selectedInfrastructureParameter]?.toString();
                return value && (Array.isArray(item[selectedInfrastructureParameter]) ? item[selectedInfrastructureParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedInfrastructureKeyword.toLowerCase())) : value.includes(selectedInfrastructureKeyword.toLowerCase()));
              }) : infrastructureDamageData}
              columns={[...infrastructureDamageColumns, actionButtons("Infrastructure Damages")]}
              rowsPerPage={infrastructureRowsPerPage}
              total={infrastructureDamageMeta?.pagination?.total}
              onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Infrastructure Damages', recordId: r.id } })}
              onChangePagination={(page: number) => dispatch(fetchInfrastructureDamageData({ page, pageSize: infrastructureRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }))}
            />
          </div>
        </> : null}
        {selectedCard === 3 ? <>
          <div className='LossesDamages-table-cont'>
            <div className='LossesDamages-table-filter-btn-cont'>
              {!!editorMode ?
                <div className='admin-add-data-btn' onClick={() => navigate("/form", { state: { formHeading: 'Food Damages' } })}>
                  <img src={PlusIcon} alt="" />
                  <span>Add New Data</span>
                </div> : <div />}
              <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap', width: "500px" }}>
                <SearchSelect
                  onChange={env => { setSelectedFoodParameter(env); setSelectedFoodKeyword(''); }}
                  placeholder="Select the Parameter"
                  className="filter-input select-param-input"
                  value={selectedFoodParameter || undefined}
                  options={foodDamageColumns
                    .filter(item => item.title !== 'Google Map Location')
                    .map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))
                  }
                  allowClear={true}
                />
                <Input
                  placeholder="Keyword Search"
                  className=' filter-input select-param-input'
                  disabled={!selectedFoodParameter}
                  allowClear={true}
                  onChange={({ target }) => setSelectedFoodKeyword(target.value)}
                  value={selectedFoodKeyword}
                />
                <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                  <span>Show:</span>
                  <SearchSelect
                    onChange={(value: String) => setFoodRowsPerPage(+value)}
                    placeholder="Select the Entries count"
                    className="w-100px"
                    value={foodRowsPerPage}
                    options={[
                      { value: "2", label: "2 Entries" },
                      { value: "5", label: "5 Entries" },
                      { value: "8", label: "8 Entries" },
                      { value: "10", label: "10 Entries" },
                    ]}
                  />
                </div>
              </div>
            </div>
            <ReusableTable
              data={(selectedFoodParameter && selectedFoodKeyword) ? foodDamageData.filter((item: any) => {
                const value = typeof item[selectedFoodParameter] === 'string' ? item[selectedFoodParameter].toLowerCase() : item[selectedFoodParameter]?.toString();
                return value && (Array.isArray(item[selectedFoodParameter]) ? item[selectedFoodParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedFoodKeyword.toLowerCase())) : value.includes(selectedFoodKeyword.toLowerCase()));
              }) : foodDamageData}
              columns={[...foodDamageColumns, actionButtons("Food Damages")]}
              rowsPerPage={foodRowsPerPage}
              total={foodDamageMeta?.pagination?.total}
              onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Food Damages', recordId: r.id } })}
              onChangePagination={(page: number) => dispatch(fetchFoodDamageData({ page, pageSize: foodRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }))}
            />
          </div>
        </> : null}
        {selectedCard === 4 ? <>
          <div className='LossesDamages-table-cont'>
            <div className='LossesDamages-table-filter-btn-cont'>
              {!!editorMode ?
                <div className='admin-add-data-btn' onClick={() => navigate("/form", { state: { formHeading: 'Livestock Damages' } })}>
                  <img src={PlusIcon} alt="" />
                  <span>Add New Data</span>
                </div> : <div />}
              <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap', width: "500px" }}>
                <SearchSelect
                  onChange={env => { setSelectedLivestockParameter(env); setSelectedLivestockKeyword(''); }}
                  placeholder="Select the Parameter"
                  className="filter-input select-param-input"
                  value={selectedLivestockParameter || undefined}
                  options={livestockDamageColumns
                    .filter(item => item.title !== 'Google Map Location')
                    .map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))
                  }
                  allowClear={true}
                />
                <Input
                  placeholder="Keyword Search"
                  className=' filter-input select-param-input'
                  disabled={!selectedLivestockParameter}
                  allowClear={true}
                  onChange={({ target }) => setSelectedLivestockKeyword(target.value)}
                  value={selectedLivestockKeyword}
                />
                <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                  <span>Show:</span>
                  <SearchSelect
                    onChange={(value: String) => setLivestockRowsPerPage(+value)}
                    placeholder="Select the Entries count"
                    className="w-100px"
                    value={livestockRowsPerPage}
                    options={[
                      { value: "2", label: "2 Entries" },
                      { value: "5", label: "5 Entries" },
                      { value: "8", label: "8 Entries" },
                      { value: "10", label: "10 Entries" },
                    ]}
                  />
                </div>
              </div>
            </div>
            <ReusableTable
              data={(selectedLivestockParameter && selectedLivestockKeyword) ? livestockDamageData.filter((item: any) => {
                const value = typeof item[selectedLivestockParameter] === 'string' ? item[selectedLivestockParameter].toLowerCase() : item[selectedLivestockParameter]?.toString();
                return value && (Array.isArray(item[selectedLivestockParameter]) ? item[selectedLivestockParameter].some((arrayValue: any) => typeof arrayValue === 'string' && arrayValue.toLowerCase().includes(selectedLivestockKeyword.toLowerCase())) : value.includes(selectedLivestockKeyword.toLowerCase()));
              }) : livestockDamageData}
              columns={[...livestockDamageColumns, actionButtons("Livestock Damages")]}
              rowsPerPage={livestockRowsPerPage}
              total={livestockDamageMeta?.pagination?.total}
              onSelectRow={(r: any) => navigate("/details", { state: { formHeading: 'Livestock Damages', recordId: r.id } })}
              onChangePagination={(page: number) => dispatch(fetchLivestockDamageData({ page, pageSize: livestockRowsPerPage, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent }))}
            />
          </div>
        </> : null}
      </> : null}
    </div>
  );
};

export default LossessDamages;

const humanCasualtieTypeIcons: any = {
  "Death": DeathsIcon,
  "Injured": InjuredIcon,
  "Recovered": RecoveredIcon,
  "Displaced": DisplacedIcon,
  "Rescued": RescuedIcon,
};

const LossesDamagesCard = ({ title, icon, progressBarColor, data }: any) => {
  const { genderList } = useSelector((state: RootState) => state.entities);
  const selectedData = data.filter((item: any) => item.casualty_type === title);

  // Calculate gender counts and percentages
  const genderCounts = selectedData.reduce((acc: any, item: any) => {
    item.casualties_details.forEach((detail: any) => {
      const gender = detail.gender;
      acc[gender] = (acc[gender] || 0) + 1; // Initialize count to 0 if not present
    });
    return acc;
  }, {});
  const quantitySum = selectedData.reduce((sum: any, item: any) => sum + item.quantity, 0);
  const totalCasualties: any = Object.values(genderCounts).reduce((sum: any, val: any) => sum + val, 0);
  const genderPercentages = Object.entries(genderCounts).map(([gender, count]: any) => ({
    gender,
    count,
    percentage: Math.round((count / totalCasualties) * 100), // Round to nearest integer
  }));

  // Render the LossesDamagesCard with dynamic progress bars
  return (
    <div className="LossesDamages-card">
      <div className="barline_Headingcont">
        <div>
          <span>{title}</span>
          <span style={{ fontSize: "20px" }}>{quantitySum}</span>
        </div>
        <img src={icon} alt="" />
      </div>
      <div className="barline_cont">
        <div className="barline_labalCont">
          {genderList?.map((item: any, index) => {
            const genderData = genderPercentages.find((g) => g.gender === item.attributes?.name);
            const percentage = genderData?.percentage || 0; // Handle missing genders gracefully
            const count = genderData?.count || 0; // Handle missing genders gracefully
            return (
              <div key={index} className="d-flex justify-content-between align-items-center">
                <Tooltip title={`Verified ${item?.attributes?.name}`}>
                  <span style={{ display: 'flex', alignItems: 'center' }}><MdVerified className='verified-icon' /> {item?.attributes?.name}</span>
                </Tooltip>
                <div className="progressBarCont">
                  <div style={{ width: "100%" }}> {/* Dynamic width based on percentage */}
                    <ProgressBar percentage={percentage} color={progressBarColor} />
                  </div>
                  <span>{count}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const calculateQtyChartData = (StockData: any, name: any, value: any) => {
  const cumulativeQuantities: any = {};

  StockData.forEach((item: any) => {
    const itemName = item[name];
    const itemValue = item[value]; // Dynamically get the value using the value parameter
    cumulativeQuantities[itemName] = (cumulativeQuantities[itemName] || 0) + +itemValue;
  });

  return Object.entries(cumulativeQuantities).map(([Item, Qty]) => ({ Item, [value]: Qty }));
};

const calculateCountChartData = (StockData: any, name: any) => {
  const itemCounts: any = {};
  StockData?.forEach((item: any) => {
    const itemName = item[name];
    itemCounts[itemName] = (itemCounts[itemName] || 0) + 1;
  });
  return Object.entries(itemCounts).map(([Item, Count]) => ({ Item, Count }));
};