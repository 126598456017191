import React, { useState } from "react";
import './index.css';
import ICON from '../../assets/images/output-onlinegiftools.gif';
import { useSelector } from "react-redux";
import { RootState } from "../../reduxToolkit/store";

const Loading = ({ isLoading }: any) => {
    const { compensatonReliefLoading } = useSelector((state: RootState) => state.compensatonRelief);
    const { ngosLoading } = useSelector((state: RootState) => state.ngos);
    const { volunteersLoading } = useSelector((state: RootState) => state.volunteer);
    const { manufacturersLoading } = useSelector((state: RootState) => state.manufacturer);
    const { suppliersLoading } = useSelector((state: RootState) => state.supplier);
    const { inventoryLoading } = useSelector((state: RootState) => state.inventory);
    const { humanCasualtieLoading } = useSelector((state: RootState) => state.humanCasualtie);
    const { infrastructureDamagesLoading } = useSelector((state: RootState) => state.infrastructureDamage);
    const { foodDamagesLoading } = useSelector((state: RootState) => state.foodDamage);
    const { financialReliefLoading } = useSelector((state: RootState) => state.financialRelief);
    const { livestockDamagesLoading } = useSelector((state: RootState) => state.livestockDamage);
    const { schoolsLoading } = useSelector((state: RootState) => state.school);

    const load = isLoading || compensatonReliefLoading || ngosLoading || volunteersLoading || manufacturersLoading || suppliersLoading || inventoryLoading || humanCasualtieLoading || infrastructureDamagesLoading || foodDamagesLoading || financialReliefLoading || livestockDamagesLoading || schoolsLoading;
    return (
        load ? <div className="spinner">
            <img src={ICON} alt="" />
        </div> : null
    );
};

export default Loading;