// Tabs.tsx

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.css'; // Import CSS for styling
import { useSelector } from 'react-redux';
import ReliefDistributionIcon from "../../assets/images/non-activeTabIcon (1).png";
import LossessDamagesIcon from "../../assets/images/non-activeTabIcon (2).png";
import InventoryIcon from "../../assets/images/non-activeTabIcon (3).png";
import ResourceMappingIcon from "../../assets/images/non-activeTabIcon (4).png";

import InventoryActiveIcon from "../../assets/images/activeTabIcon (1).png";
import ResourceMappingActiveIcon from "../../assets/images/activeTabIcon (2).png";
import ReliefDistributionActiveIcon from "../../assets/images/activeTabIcon (3).png";
import LossessDamagesActiveIcon from "../../assets/images/activeTabIcon (4).png";
import { RootState } from "../../reduxToolkit/store";
interface TabProps {
    title: string;
    icon: string;
    isActiveIcon: string;
    path: string;
}

const Tab: React.FC<TabProps & { isActive: boolean; onClick: () => void; }> = ({ title, icon, isActive, isActiveIcon, onClick }) => {
    return (
        <button className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}>
            {isActive ?
                <img src={isActiveIcon} alt={title} className="icon" />
                :
                <img src={icon} alt={title} className="icon" />
            }
            <span className="title">{title}</span>
        </button>
    );
};

const Tabs: React.FC = () => {
    const { isAuthenticated } = useSelector((state: RootState) => state.auth);

    const location = useLocation();
    const navigate = useNavigate();

    const handleTabClick = (path: string) => {
        if (location.pathname !== path) {
            navigate(path);
        }
    };
    const substrings = ["/resourcemapping", "/inventory", "/reliefdistribution", "/lossessdamages"];

    const containsSubstring = substrings.some(substring => location.pathname.includes(substring));

    if (!containsSubstring) {
        return null;
    }

    return (
        <div className="tabs">
            <Tab title="Resource Mapping" icon={ResourceMappingIcon} isActiveIcon={ResourceMappingActiveIcon} path="/resourcemapping" isActive={location.pathname === '/resourcemapping'} onClick={() => handleTabClick('/resourcemapping')} />
            <Tab title="Inventory Mapping" icon={InventoryIcon} isActiveIcon={InventoryActiveIcon} path="/inventory" isActive={location.pathname === '/inventory'} onClick={() => handleTabClick('/inventory')} />
            {!!isAuthenticated && <Tab title="Relief Distribution" icon={ReliefDistributionIcon} isActiveIcon={ReliefDistributionActiveIcon} path="/reliefdistribution" isActive={location.pathname === '/reliefdistribution'} onClick={() => handleTabClick('/reliefdistribution')} />}
            <Tab title="Losses & Damages" icon={LossessDamagesIcon} isActiveIcon={LossessDamagesActiveIcon} path="/lossessdamages" isActive={location.pathname === '/lossessdamages'} onClick={() => handleTabClick('/lossessdamages')} />
        </div>
    );
};

export default Tabs;
