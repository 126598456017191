import React, { useEffect, useState } from 'react';
import './index.css'; // Import CSS for styling
import { FaArrowLeft } from "react-icons/fa6";
import liveStockChartIcon from '../../assets/images/livestock_card.png';
import formLossesDamagesLogo from '../../assets/images/formLosses&DamagesLogo.png';
import { Button, Input } from 'antd';
import { Select } from 'antd';
import type { SelectProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { toast } from '../../components/Toast';
import { fetchTehsilsData, fetchUnionCouncilData } from '../../reduxToolkit/entitiesSlice';
import { validateObject } from '../../helpers/common';
import { createLivestockDamageData, createLivestockOwnerDetail, deleteLivestockOwnerDetail, fetchAllLivestockOwnerDetails, fetchAllLivestockTypes, getLivestockDraftByUser, updateLivestockDamageData, updateLivestockDamageStageData, updateLivestockDraftData, updateLivestockOwnerDetail } from '../../reduxToolkit/livestockDamageSlice';
import DetailIcon from '../../assets/images/Detail.png';
import addIcon from '../../assets/images/Add.png';
import ReusableTable from '../../components/table';
import { DeleteOutlined } from '@ant-design/icons';
import { alignLivestockOwnerDetailsData } from '../../helpers/alignData';

const LivestockDamages = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { allDistrictList, divisionList, tehsilList, unionCouncilList, eventList, internalWorkflowsList } = useSelector((state: RootState) => state.entities);
    const { userData } = useSelector((state: RootState) => state.auth);
    const { humanCasualtieType } = useSelector((state: RootState) => state.humanCasualtie);
    const { livestockTypeList, livestockOwnerDetails } = useSelector((state: RootState) => state.livestockDamage);
    const [livestockDamageDetails, setLivestockDamageDetails] = useState<{ [key: string]: any; }>({});
    const [actionButtonText, setActionButtonText] = useState("");
    const { data, isForAction, isViewOnly, isForUpdate, is_draft } = location.state;
    const [isCreating, setIsCreating] = useState(false);
    const [isCheckRequired, setIsCheckRequired] = useState(false);
    const [ownerData, setOwnerDetails] = useState<any>([{}]);
    const [hasDraft, setHasDraft] = useState(false);

    useEffect(() => {
        (async () => {
            if (userData && userData.id) {
                const userDraft = (await dispatch(getLivestockDraftByUser({
                    id: livestockDamageDetails.draft_id,
                    created_by_user: userData.id
                }))).payload;
                setHasDraft(userDraft);
            }
        })();
    }, [userData, livestockDamageDetails.draft_id]);

    useEffect(() => {
        if ((isForAction || isViewOnly) && internalWorkflowsList.length) {
            setLivestockDamageDetails(data);
            const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === data.stage);
            setActionButtonText(selectedWorkFlow?.title);
        } else if (isForUpdate) {
            setLivestockDamageDetails(data);
            setActionButtonText("Update Information");
        } else {
            const obj = {
                division: userData?.division?.division_name,
                divisionId: userData?.division?.id,
                district: userData?.district?.district_name,
                districtId: userData?.district?.id,
            };
            setLivestockDamageDetails(prevState => ({ ...prevState, ...obj }));
        }
        dispatch(fetchAllLivestockTypes());
        if (data?.livestock_damage) {
            dispatch(fetchAllLivestockOwnerDetails({
                page: 1,
                pageSize: 1000000,
                "filter": {
                    "livestock_damage": {
                        "id": {
                            "eq": data.livestock_damage
                        }
                    }
                }
            }));
        }
    }, [userData, internalWorkflowsList]);

    useEffect(() => {
        const growerData = alignLivestockOwnerDetailsData(livestockOwnerDetails);
        console.log("livestockOwnerDetails", livestockOwnerDetails);
        setOwnerDetails(growerData);
    }, [livestockOwnerDetails]);

    useEffect(() => {
        dispatch(fetchTehsilsData(livestockDamageDetails?.district));
    }, [dispatch, livestockDamageDetails?.district]);

    useEffect(() => {
        dispatch(fetchUnionCouncilData(livestockDamageDetails?.tehsil));
    }, [dispatch, livestockDamageDetails?.tehsil]);

    const divisionData = divisionList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.division_name,
        label: item.attributes.division_name,
    })) || [];

    const districtData = allDistrictList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.district_name,
        label: item.attributes.district_name,
    })) || [];

    const tehsilData = tehsilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.tehsil_name,
        label: item.attributes.tehsil_name,
    })) || [];

    const unionCouncilListData = unionCouncilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.union_council_name,
        label: item.attributes.union_council_name,
    })) || [];

    const eventListData = eventList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const livestockTypeListData = livestockTypeList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const humanCasualtieTypeData = humanCasualtieType.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const onSave = async () => {
        try {
            if (hasDraft) {
                throw new Error("You already have a draft in progress or pending approval.");
            } else {
                const obj: any = {
                    "divisionId": livestockDamageDetails.divisionId,
                    "districtId": livestockDamageDetails.districtId,
                    "livestock_typeId": livestockDamageDetails.livestock_typeId,
                    "eventId": livestockDamageDetails.eventId,
                    "total_quantity": livestockDamageDetails.total_quantity ? Number(livestockDamageDetails.total_quantity) : null,
                    "cost": livestockDamageDetails.cost ? Number(livestockDamageDetails.cost) : null,
                    "latitude": livestockDamageDetails.latitude ? Number(livestockDamageDetails.latitude) : null,
                    "longitude": livestockDamageDetails.longitude ? Number(livestockDamageDetails.longitude) : null,
                    "casualty_typeId": livestockDamageDetails.casualty_typeId,
                    "stage": 1,
                    "is_draft": false,
                    "created_by_user": userData.id,
                };
                if (!validateObject(obj)) {
                    setIsCheckRequired(true);
                    throw new Error('Please fill the all highlighted fields');
                }
                obj.tehsilId = livestockDamageDetails.tehsilId;
                obj.union_councilId = livestockDamageDetails.union_councilId;
                obj.address = livestockDamageDetails.address;
                obj.livestock_damage = livestockDamageDetails.livestock_damage;
                if (livestockDamageDetails.draft_id) {
                    obj.livestockDamageId = livestockDamageDetails.id;
                    await updateChild();
                    const res = await dispatch(updateLivestockDraftData(obj));
                    if (res.type !== "livestockDamage/updateLivestockDraftData/rejected") {
                        navigate(-1);
                        toast('success', "livestock Damage draft update successfully");
                    } else {
                        setIsCreating(false);
                    }
                } else {
                    if (isForUpdate) {
                        await updateChild();
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createLivestockDamageData(obj));
                        if (res.type !== "livestockDamage/createLivestockDamageData/rejected") {
                            navigate(-1);
                            toast('success', "Livestock Damage update successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                        // const res = await dispatch(updateLivestockDamageData(obj));
                        // if (res.type !== "livestockDamage/updateLivestockDamageData/rejected") {
                        //     navigate(-1);
                        //     toast('success', "Livestock Damage update successfully");
                        // }
                    } else {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createLivestockDamageData(obj));
                        if (res.type !== "livestockDamage/createLivestockDamageData/rejected") {
                            navigate(-1);
                            toast('success', "Livestock Damage created successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                    }
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    const updateChild = async () => {
        for (let i = 0; i < ownerData.length; i++) {
            const element = ownerData[i];
            const growerObj = {
                livestock_damageId: livestockDamageDetails.id,
                name: element.name,
                address: element.address,
                "cnic": element.cnic ? Number(element.cnic) : null,
                "mobile_number": element.mobile_number ? Number(element.mobile_number) : null,
                "quantity": element.quantity ? Number(element.quantity) : null,
            };
            if (Object.values(growerObj).some(item => item)) {
                if (element.id) {
                    await dispatch(updateLivestockOwnerDetail({ ...growerObj, livestock_owner_detail_id: element.id }));
                } else {
                    await dispatch(createLivestockOwnerDetail(growerObj));
                }
            }
        }
    };

    const onAction = async () => {
        const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === livestockDamageDetails.stage);
        let smallestStageNumber = internalWorkflowsList.reduce((min: any, record: any) => record.stage < min && record.stage > livestockDamageDetails.stage ? record.stage : min, Infinity);
        const res = await dispatch(updateLivestockDamageStageData({
            id: livestockDamageDetails.id,
            stage: (!smallestStageNumber || smallestStageNumber == Infinity) ? null : smallestStageNumber,
            record: livestockDamageDetails
        }));
        if (res.type !== "livestockDamage/updateLivestockDamageStageData/rejected") {
            navigate(-1);
            toast("success", `${(!smallestStageNumber || smallestStageNumber == Infinity) ? "Publish" : selectedWorkFlow.title} successfully`);
        }
    };

    const deleteData = (index: number, id: String) => {
        console.log("index, id", index, id);

        if (id) {
            dispatch(deleteLivestockOwnerDetail(id));
        } else {
            setOwnerDetails((prevState: any) => {
                const newState = [...prevState];
                newState.splice(index, 1);
                return newState;
            });
        }
    };

    const growerDetailsColumn = [
        {
            title: 'Owner Name',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Owner Name'
                disabled={isViewOnly}
                onChange={({ target }) => setOwnerDetails((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].name = target.value;
                    return newState;
                })}
                value={record.name}
                className='input'
            />,
        },
        {
            title: 'Owner CNIC',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter CNIC No.'
                disabled={isViewOnly}
                onChange={({ target }) => setOwnerDetails((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].cnic = target.value;
                    return newState;
                })}
                value={record.cnic}
                className='input'
            />,
        },
        {
            title: 'Owner Address',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Address Here'
                disabled={isViewOnly}
                onChange={({ target }) => setOwnerDetails((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].address = target.value;
                    return newState;
                })}
                value={record.address}
                className='input'
            />,
        },
        {
            title: 'Owner Mobile Number',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Mobile no.'
                disabled={isViewOnly}
                onChange={({ target }) => setOwnerDetails((prevState: any) => {
                    const newState = [...prevState];
                    newState[index].mobile_number = target.value;
                    return newState;
                })}
                value={record.mobile_number}
                className='input'
            />,
        },
        {
            title: 'Quantity',
            render: (record: any, text: any, index: number) => <Input
                placeholder='Enter Quantity'
                disabled={isViewOnly}
                onChange={({ target }) => setOwnerDetails((prevState: any) => {
                    const newState = [...prevState];
                    const newTotalAcre = prevState.reduce((sum: any, item: any, idx: any) =>
                        sum + (idx === index ? parseFloat(target.value) : parseFloat(item.quantity) || 0)
                        , 0);

                    if (newTotalAcre > Number(livestockDamageDetails.total_quantity)) {
                        toast("error", `Cannot exceed the total quantity limit of ${Number(livestockDamageDetails.total_quantity)}.`);
                        return prevState;
                    }

                    newState[index].quantity = target.value;
                    return newState;
                })}
                value={record.quantity}
                className='input'
            />,
        },
        {
            title: "Delete",
            render: (a: any, b: any, index: number) => (
                <Button
                    type="primary"
                    title='Delete'
                    style={{ background: '#357A38' }}
                    disabled={isViewOnly}
                    className='m-v-5'
                    icon={<DeleteOutlined />}
                    onClick={() => deleteData(index, a.id)}
                    shape='circle'
                />
            ),
        }
    ];

    const onSaveAsDraft = async () => {
        try {
            if (isCreating) return;
            setIsCreating(true);
            await updateChild();
            const obj: any = {
                "divisionId": livestockDamageDetails.divisionId,
                "districtId": livestockDamageDetails.districtId,
                "livestock_typeId": livestockDamageDetails.livestock_typeId,
                "eventId": livestockDamageDetails.eventId,
                "total_quantity": livestockDamageDetails.total_quantity ? Number(livestockDamageDetails.total_quantity) : null,
                "cost": livestockDamageDetails.cost ? Number(livestockDamageDetails.cost) : null,
                "latitude": livestockDamageDetails.latitude ? Number(livestockDamageDetails.latitude) : null,
                "longitude": livestockDamageDetails.longitude ? Number(livestockDamageDetails.longitude) : null,
                "casualty_typeId": livestockDamageDetails.casualty_typeId,
                "tehsilId": livestockDamageDetails.tehsilId,
                "union_councilId": livestockDamageDetails.union_councilId,
                "address": livestockDamageDetails.address,
                "stage": null,
                "is_draft": true,
                "created_by_user": userData.id,
            };
            if (livestockDamageDetails.draft_id) {
                obj.livestock_damage = livestockDamageDetails.livestock_damage;
                obj.livestockDamageId = data.draft_id;
                const res = await dispatch(updateLivestockDraftData(obj));
                if (res.type !== "livestockDamage/updateLivestockDraftData/rejected") {
                    navigate(-1);
                    toast('success', "livestock damage draft update successfully");
                } else {
                    setIsCreating(false);
                }
            } else {
                obj.livestock_damage = livestockDamageDetails.livestock_damage;
                const res = await dispatch(createLivestockDamageData(obj));
                if (res.type !== "livestockDamage/createLivestockDamageData/rejected") {
                    navigate(-1);
                    toast('success', "livestock damage draft successfully");
                } else {
                    setIsCreating(false);
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    const handleAddMoreData = () => {
        // Calculate total restored and damaged values
        const totalQuantity = ownerData.reduce((sum: any, item: any) => sum + (parseFloat(item.quantity) || 0), 0);

        // Check if adding another record would exceed the limit
        if (totalQuantity >= Number(livestockDamageDetails.total_quantity)) {
            toast('error', `Cannot add more records. The total Quantity value cannot exceed ${Number(livestockDamageDetails.total_quantity)}.`);
            return;
        }

        setOwnerDetails((prevState: any) => ([...prevState, {}]));
    };

    console.log("is_draft", is_draft);
    console.log("data", data);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formLossesDamagesLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Losses & Damages</span>
                        </div>
                    </div>
                    {isForAction ? <div className='form-send-btn' onClick={onAction}>
                        <span className='sand-btn-text'>{actionButtonText}</span>
                    </div>
                        : <div>
                            {!hasDraft ? <div className='form-send-btn' onClick={onSaveAsDraft}>
                                <span className='sand-btn-text'>Save as draft</span>
                            </div> : null}
                            <div className='form-send-btn' onClick={onSave}>
                                <span className='sand-btn-text'>Submit</span>
                            </div>
                        </div>}
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <img src={liveStockChartIcon} alt="" />
                    <span>Livestock Damages</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Total Quantity</span>
                        <Input
                            onChange={({ target }) => setLivestockDamageDetails(prevState => ({ ...prevState, total_quantity: target.value }))}
                            placeholder="Enter Total Quantity"
                            status={(isCheckRequired && !livestockDamageDetails.total_quantity) ? 'error' : ""}
                            value={livestockDamageDetails.total_quantity}
                            disabled={isViewOnly}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Cost</span>
                        <Input
                            onChange={({ target }) => setLivestockDamageDetails(prevState => ({ ...prevState, cost: target.value }))}
                            placeholder="Enter Cost of Damage"
                            status={(isCheckRequired && !livestockDamageDetails.cost) ? 'error' : ""}
                            value={livestockDamageDetails.cost}
                            disabled={isViewOnly}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Casualty Type</span>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setLivestockDamageDetails(prevState => ({ ...prevState, casualty_typeId: item.id, casualty_type: item.value }))}
                            disabled={isViewOnly}
                            options={humanCasualtieTypeData}
                            placeholder="Select Casualty Type"
                            status={(isCheckRequired && !livestockDamageDetails.casualty_typeId) ? 'error' : ""}
                            className='input'
                            value={livestockDamageDetails.casualty_type}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Livestock Type</span>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setLivestockDamageDetails(prevState => ({ ...prevState, livestock_typeId: item.id, livestock_type: item.value }))}
                            options={livestockTypeListData}
                            disabled={isViewOnly}
                            status={(isCheckRequired && !livestockDamageDetails.livestock_typeId) ? 'error' : ""}
                            placeholder="Select livestock Type"
                            className='input'
                            value={livestockDamageDetails.livestock_type}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Event</span>
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setLivestockDamageDetails(prevState => ({ ...prevState, eventId: item.id, event: item.value }))}
                            options={eventListData}
                            status={(isCheckRequired && !livestockDamageDetails.eventId) ? 'error' : ""}
                            disabled={isViewOnly}
                            placeholder="Select Event"
                            className='input'
                            value={livestockDamageDetails.event}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Select
                            value={livestockDamageDetails.division || undefined}
                            placeholder="Select the Division"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setLivestockDamageDetails(prevState => ({ ...prevState, divisionId: item?.id, division: item?.value }))}
                            status={(isCheckRequired && !livestockDamageDetails.divisionId) ? 'error' : ""}
                            disabled
                            options={divisionData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the District"
                            value={livestockDamageDetails.district || undefined}
                            disabled
                            style={{ width: '100%' }}
                            status={(isCheckRequired && !livestockDamageDetails.districtId) ? 'error' : ""}
                            onChange={(value: any, item: any) => setLivestockDamageDetails(prevState => ({ ...prevState, districtId: item?.id, district: item?.value }))}
                            options={districtData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Tehsil"
                            style={{ width: '100%' }}
                            disabled={isViewOnly}
                            value={livestockDamageDetails.tehsil || undefined}
                            onChange={(value: any, item: any) => setLivestockDamageDetails(prevState => ({ ...prevState, tehsilId: item?.id, tehsil: item?.value }))}
                            options={tehsilData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Union Council"
                            disabled={isViewOnly}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setLivestockDamageDetails(prevState => ({ ...prevState, union_councilId: item?.id, union_council: item?.value }))}
                            options={unionCouncilListData}
                            className='input'
                            value={livestockDamageDetails.union_council || undefined}
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            onChange={({ target }) => setLivestockDamageDetails(prevState => ({ ...prevState, address: target.value }))}
                            placeholder="Enter Address"
                            value={livestockDamageDetails.address || undefined}
                            status={(isCheckRequired && !livestockDamageDetails.address) ? 'error' : ""}
                            disabled={isViewOnly}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            onChange={({ target }) => setLivestockDamageDetails(prevState => ({ ...prevState, latitude: target.value }))}
                            placeholder="Enter Latitude"
                            status={(isCheckRequired && !livestockDamageDetails.latitude) ? 'error' : ""}
                            value={livestockDamageDetails.latitude}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            disabled={isViewOnly}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            onChange={({ target }) => setLivestockDamageDetails(prevState => ({ ...prevState, longitude: target.value }))}
                            placeholder="Enter Longitude"
                            value={livestockDamageDetails.longitude}
                            status={(isCheckRequired && !livestockDamageDetails.longitude) ? 'error' : ""}
                            disabled={isViewOnly}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                </div>
            </div>
            {(isForUpdate || isViewOnly) ? <div className='form-body '>
                <div className='form-bady-heading grower-heading'>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <img src={DetailIcon} alt="" style={{ height: "35px" }} />
                        <span>Owner Details</span>
                    </div>
                    {isForUpdate ? <div className='add-more-button' onClick={handleAddMoreData}>
                        <img style={{ height: "20px" }} src={addIcon} />
                        <p style={{ fontSize: "14px", color: 'white' }}>Add more Data</p>
                    </div> : null}
                </div>
                <div className='form-cont'>
                    <ReusableTable
                        data={ownerData}
                        columns={growerDetailsColumn}
                        rowsPerPage={10}
                        total={ownerData.length}
                    />
                </div>
            </div> : null}
        </div >
    );
};

export default LivestockDamages;
