// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
  display: flex;
  padding: 10px;
  background-color: White;
}

.legend {
  width: 20%;
}

@media screen and (max-width: 945px) {
  .map-container {
    flex-direction: column;
  }

  .legend {
    width: 100vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Map/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".map-container {\n  display: flex;\n  padding: 10px;\n  background-color: White;\n}\n\n.legend {\n  width: 20%;\n}\n\n@media screen and (max-width: 945px) {\n  .map-container {\n    flex-direction: column;\n  }\n\n  .legend {\n    width: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
