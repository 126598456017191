import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

interface VolunteerState {
    volunteerList: any[];
    volunteersLoading: boolean;
    filteredVolunteerList: any[];
    volunteersMeta: any;
    volunteerCount: number;
    volunteerChartList: any[];
    filteredVolunteersCount: number;
    selectedVolunteer: any;
}

const initialState: VolunteerState = {
    volunteerList: [],
    volunteersLoading: false,
    filteredVolunteerList: [],
    volunteersMeta: {},
    volunteerCount: 0,
    volunteerChartList: [],
    filteredVolunteersCount: 0,
    selectedVolunteer: {}
};

export const fetchVolunteerCount = createAsyncThunk('volunteer/fetchVolunteerCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                }
            }
        };
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiGetVolunteersCount : API.ApiGetVolunteersCountWithOutLocation,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.volunteers.meta.pagination.total, volunteerChartList: response.data.volunteers.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchVolunteerData = createAsyncThunk('volunteer/fetchVolunteerData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil } = pageData;
        const filter: any = {
            stage: {
                null: true
            }
        };
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllVolunteers,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.volunteers.data, meta: response.data.volunteers.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFilterdVolunteersData = createAsyncThunk('volunteer/fetchFilterdVolunteersData', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await client.query({ query: API.ApiVolunteers, variables, context: { headers }, fetchPolicy: 'no-cache' });
        return { data: response.data.volunteerDrafts.data, filteredVolunteersCount: response.data.volunteerDrafts.meta.pagination.total };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchVolunteerDataById = createAsyncThunk('volunteer/fetchVolunteerDataById', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetVolunteerById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.volunteer.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createVolunteerData = createAsyncThunk('volunteer/createVolunteerData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateVolunteer,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createVolunteerDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateVolunteerDraftData = createAsyncThunk('volunteer/updateVolunteerDraftData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingVolunteerDraft,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateVolunteerDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateVolunteerStageData = createAsyncThunk('volunteer/updateVolunteerStageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { stage, id, record } = data;
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        if (stage) {
            await client.mutate({
                mutation: API.ApiUpdateVolunteer,
                variables: {
                    id: id,
                    stage: stage
                },
                context: {
                    headers,
                },
            });
        } else {
            const obj = {
                "name": record.name,
                "divisionId": record.divisionId,
                "districtId": record.districtId,
                "cnic_number": record.cnic_number ? Number(record.cnic_number) : null,
                "postal_address": record.postal_address,
                "website": record.website,
                "contact_person": record.contact_person,
                "contact_person_mobile": record.contact_person_mobile ? Number(record.contact_person_mobile) : null,
                "latitude": record.latitude ? Number(record.latitude) : null,
                "longitude": record.longitude ? Number(record.longitude) : null,
                "stage": null,
                "tehsilId": record.tehsilId,
                "union_councilId": record.union_councilId,
                "address": record.address,
                "volunteerId": record.volunteer
            };
            await client.mutate({
                mutation: obj.volunteerId ? API.ApiUpdateExistingVolunteer : API.ApiCreatePublishedVolunteer,
                variables: obj,
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
            await client.mutate({
                mutation: API.ApiDeleteVolunteerDraft,
                variables: { id },
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
        }
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteVolunteerData = createAsyncThunk('volunteer/deleteVolunteerData', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteVolunteer,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateVolunteerData = createAsyncThunk('volunteer/updateVolunteerData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingVolunteer, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateVolunteer.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateVolunteerRecordFromSocket = createAsyncThunk('volunteer/addAndUpdateVolunteerRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, externalWorkflowsList } = data;
        const response = await client.query({ query: API.ApiGetVolunteerById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.volunteer.data, externalWorkflowsList };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteVolunteerRecordFromSocket = createAsyncThunk('volunteer/deleteVolunteerRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const getVolunteerDraftByUser = createAsyncThunk('volunteer/getVolunteerDraftByUser', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.APIGetVolunteerDraftByUser, // Assuming you have an API constant for update mutation
            variables,
            context: {
                headers,
            },
        });
        if (response.data && response.data.volunteerDrafts.data.length > 0) {
            return true;
        }
        return false;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const checkVolunteerCnicExists = createAsyncThunk('volunteer/checkVolunteerCnicExists', async ({ cnic_number, excludeVolunteerDraftId, excludeVolunteerId }: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.query({
            query: API.APICheckVolunteerCnic,
            variables: { cnic_number, excludeVolunteerDraftId, excludeVolunteerId },
            context: {
                headers,
            },
        });
        return {
            volunteers: response.data.volunteers.data,
            volunteerDrafts: response.data.volunteerDrafts.data,
        };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const volunteerSlice = createSlice({
    name: 'volunteer',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchVolunteerCount.fulfilled, (state, action) => {
                state.volunteerCount = action.payload.count;
                state.volunteerChartList = action.payload.volunteerChartList;
                state.volunteersLoading = false;
            })
            .addCase(fetchVolunteerData.fulfilled, (state, action) => {
                state.volunteerList = action.payload.data;
                state.volunteersMeta = action.payload.meta;
                state.volunteersLoading = false;
            })
            .addCase(fetchFilterdVolunteersData.fulfilled, (state, action) => {
                state.filteredVolunteerList = action.payload.data;
                state.filteredVolunteersCount = action.payload.filteredVolunteersCount;
                state.volunteersLoading = false;
            })
            .addCase(fetchVolunteerDataById.fulfilled, (state, action) => {
                state.selectedVolunteer = action.payload;
                state.volunteersLoading = false;
            })
            .addCase(createVolunteerData.fulfilled, (state, action) => {
                // state.volunteerList.push(action.payload);
                state.volunteersLoading = false;
            })
            .addCase(updateVolunteerDraftData.fulfilled, (state, action) => {
                // state.volunteerList.push(action.payload);
                state.volunteersLoading = false;
            })
            .addCase(updateVolunteerData.fulfilled, (state, action) => {
                state.volunteersLoading = false;
            })
            .addCase(getVolunteerDraftByUser.fulfilled, (state, action) => {
                state.volunteersLoading = false;
            })
            .addCase(updateVolunteerStageData.fulfilled, (state, action) => {
                const index = state.filteredVolunteerList.findIndex(item => item.id === action.payload);
                state.filteredVolunteerList.splice(index, 1);
                state.volunteersLoading = false;
            })
            .addCase(deleteVolunteerData.fulfilled, (state, action) => {
                state.volunteersLoading = false;
            })
            .addCase(addAndUpdateVolunteerRecordFromSocket.fulfilled, (state, action) => {
                const externalWorkflowsList = action.payload.externalWorkflowsList;
                const { id, attributes } = action.payload.data;
                if (attributes.stage) {
                    const selectedWorkFlow: any = externalWorkflowsList.find((item: any) => item.stage === attributes.stage);
                    const index = state.filteredVolunteerList.findIndex(item => item.id == id);
                    if (selectedWorkFlow) {
                        index !== -1 ? state.filteredVolunteerList[index] = action.payload.data : state.filteredVolunteerList.push(action.payload.data);
                    } else {
                        state.filteredVolunteerList.splice(index, 1);
                    }
                } else {
                    const index = state.volunteerList.findIndex(item => item.id == id);
                    index !== -1 ? state.volunteerList[index] = action.payload.data : state.volunteerList.push(action.payload.data);
                }
            })
            .addCase(deleteVolunteerRecordFromSocket.fulfilled, (state, action) => {
                state.volunteerList = state.volunteerList.filter(item => item.id != action.payload);
                state.filteredVolunteerList = state.filteredVolunteerList.filter(item => item.id != action.payload);
            });
        [
            fetchVolunteerCount,
            fetchVolunteerData,
            fetchFilterdVolunteersData,
            updateVolunteerStageData,
            deleteVolunteerData,
            createVolunteerData,
            updateVolunteerData,
            fetchVolunteerDataById,
            updateVolunteerDraftData,
            getVolunteerDraftByUser
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.volunteersLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.volunteersLoading = false;
                });
        });
    }
});


export default volunteerSlice.reducer;
