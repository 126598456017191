import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa6";
import formLossesDamagesLogo from '../../assets/images/formLosses&DamagesLogo.png';
import FoodDamagesIcon from '../../assets/images/agriculture_card.png';
import DetailIcon from '../../assets/images/Detail.png';
import { Flex, Input, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { alignEventData, alignFoodDamageData, alignGrowerDetailData } from '../../helpers/alignData';
import { numberFormater } from '../../helpers/common';
import { fetchAllGrowerDetails, fetchFoodDamageDataById } from '../../reduxToolkit/foodDamageSlice';
import "./style.css";
import ReusableTable from '../../components/table';
import { growerDetailsColumn } from '../../helpers/tableColumns';
import EventsIcon from '../../assets/images/Events.png';

const FoodDamageDetails = () => {
    const { TextArea } = Input;
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const navigate = useNavigate();
    const { recordId } = location.state || {};
    const { selectedfoodDamage, growerDetails, growerDetailsMeta } = useSelector((state: RootState) => state.foodDamage);
    const [foodDamageDetails, setFoodDamageDetails] = useState<{ [key: string]: any; }>({});
    const [eventData, setEventData] = useState<{ [key: string]: any; }>({});

    useEffect(() => {
        dispatch(fetchFoodDamageDataById(recordId));
    }, []);

    useEffect(() => {
        const foodDamageData = alignFoodDamageData([selectedfoodDamage]);
        const selectedfoodDamageData = foodDamageData[0] ? foodDamageData[0] : {};
        const id = selectedfoodDamageData ? selectedfoodDamageData.id : null;
        if (id) {
            dispatch(fetchAllGrowerDetails({
                page: 1,
                pageSize: 10,
                "filter": {
                    "food_damage": {
                        "id": {
                            "eq": id
                        }
                    }
                }
            }));
        }
        const eventData = alignEventData(selectedfoodDamageData.eventData);
        setEventData(eventData);
        setFoodDamageDetails(selectedfoodDamageData);
    }, [selectedfoodDamage]);

    const growerData = alignGrowerDetailData(growerDetails);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formLossesDamagesLogo} alt="" style={{ height: "40px" }} />
                            <span>Losses & Damages</span>
                        </div>
                    </div>
                    <div />
                </div>
            </div>
            <div className='event-form'>
                <div className='form-bady-heading'>
                    <img src={EventsIcon} alt="" style={{ height: "35px" }} />
                    <span>Event</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Name</span>
                        <Input
                            value={eventData.name}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>From date</span>
                        <Input
                            value={eventData.from_date}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>To date</span>
                        <Input
                            value={eventData.to_date}
                            disabled
                            className='input' />
                    </div>
                    <div className='textArea-cont'>
                        <span>Description</span>
                        <TextArea
                            rows={4}
                            value={eventData.description}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Divisions</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.divisions?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Districts</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.districts?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Tehsils</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.tehsils?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Union Councils</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.union_councils?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <img src={FoodDamagesIcon} alt="" style={{ height: "35px" }} />
                    <span>Food Damage</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Acre</span>
                        <Input
                            value={numberFormater(foodDamageDetails.acre)}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Cost</span>
                        <Input
                            value={foodDamageDetails.cost}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Agriculture Type</span>
                        <Input
                            value={foodDamageDetails.agriculture_type}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Event</span>
                        <Input
                            value={foodDamageDetails.event}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Input
                            value={foodDamageDetails.division}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Input
                            value={foodDamageDetails.district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Input
                            value={foodDamageDetails.tehsil}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Input
                            value={foodDamageDetails.union_council}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            value={foodDamageDetails.address}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            value={foodDamageDetails.latitude}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            value={foodDamageDetails.longitude}
                            disabled
                            className='input'
                        />
                    </div>
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading' style={{ marginTop: 0 }}>
                    <img src={DetailIcon} alt="" style={{ height: "35px" }} />
                    <span>Grower Details</span>
                </div>
                <div className='form-cont'>
                    <ReusableTable
                        data={growerData}
                        columns={growerDetailsColumn}
                        rowsPerPage={10}
                        total={growerDetailsMeta?.pagination?.total}
                        onChangePagination={(page: number) => dispatch(fetchAllGrowerDetails({
                            page,
                            pageSize: 10,
                            "filter": {
                                "food_damage": {
                                    "id": {
                                        "eq": foodDamageDetails.id
                                    }
                                }
                            }
                        }))}
                    />
                </div>
            </div>
        </div>
    );
};

export default FoodDamageDetails;