import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import formLossesDamagesLogo from '../../assets/images/formLosses&DamagesLogo.png';
import EventsIcon from '../../assets/images/Events.png';
import { FaArrowLeft } from "react-icons/fa6";
import personeIcon from '../../assets/images/personeIcon.png';
import { Flex, Input, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { fetchAllCasualtiesDetails, fetchHumanCasualtieDataById } from '../../reduxToolkit/humanCasualtieSlice';
import { alignCasualtiesDetailsData, alignEventData, alignHumanCasualtieData } from '../../helpers/alignData';
import DetailIcon from '../../assets/images/Detail.png';
import ReusableTable from '../../components/table';
import { casualtiesDetailsColumn } from '../../helpers/tableColumns';

const HumanCasualtieDetails = () => {
    const { TextArea } = Input;
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const navigate = useNavigate();
    const { recordId } = location.state || {};
    const { selectedHumanCasualtie, casualtiesDetails, casualtiesDetailsMeta } = useSelector((state: RootState) => state.humanCasualtie);
    const [humanCasualtiesData, setHumanCasualtiesData] = useState<{ [key: string]: any; }>({});
    const [eventData, setEventData] = useState<{ [key: string]: any; }>({});

    useEffect(() => {
        dispatch(fetchHumanCasualtieDataById(recordId));
    }, []);

    useEffect(() => {
        if (selectedHumanCasualtie) {
            const humanCasualtieData = alignHumanCasualtieData([selectedHumanCasualtie]);
            const selectedHumanCasualtieData = humanCasualtieData[0] ? humanCasualtieData[0] : {};
            const eventData = alignEventData(selectedHumanCasualtieData.eventData);
            const id = selectedHumanCasualtieData ? selectedHumanCasualtieData.id : null;
            if (id) {
                dispatch(fetchAllCasualtiesDetails({
                    page: 1,
                    pageSize: 10,
                    "filter": {
                        "human_casualtie": {
                            "id": {
                                "eq": id
                            }
                        }
                    }
                }));
            }
            setEventData(eventData);
            setHumanCasualtiesData(selectedHumanCasualtieData);
        }
    }, [selectedHumanCasualtie]);

    const casualtiesDetailsData = alignCasualtiesDetailsData(casualtiesDetails);

    return (
        <div className="form-main-cont">
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <img src={formLossesDamagesLogo} alt="" style={{ height: "40px" }} />
                        <span>Losses & Damages</span>
                    </div>
                    <div />
                </div>
            </div>
            <div className='event-form'>
                <div className='form-bady-heading'>
                    <img src={EventsIcon} alt="" style={{ height: "35px" }} />
                    <span>Event</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Name</span>
                        <Input
                            value={eventData.name}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>From date</span>
                        <Input
                            value={eventData.from_date}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>To date</span>
                        <Input
                            value={eventData.to_date}
                            disabled
                            className='input' />
                    </div>
                    <div className='textArea-cont'>
                        <span>Description</span>
                        <TextArea
                            rows={4}
                            value={eventData.description}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Divisions</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.divisions?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Districts</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.districts?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Tehsils</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.tehsils?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Union Councils</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.union_councils?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <img src={personeIcon} alt="" style={{ height: "35px" }} />
                    <span>Human Casualties</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Event</span>
                        <Input
                            value={humanCasualtiesData.event}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Casualty Type</span>
                        <Input
                            disabled
                            value={humanCasualtiesData.casualty_type}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Quantity</span>
                        <Input
                            value={humanCasualtiesData.quantity}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Input
                            value={humanCasualtiesData.division}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Input
                            value={humanCasualtiesData.district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Input
                            value={humanCasualtiesData.tehsil}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Input
                            value={humanCasualtiesData.union_council}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            value={humanCasualtiesData.latitude}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            value={humanCasualtiesData.longitude}
                            disabled
                            className='input'
                        />
                    </div>
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading' style={{ marginTop: 0 }}>
                    <img src={DetailIcon} alt="" style={{ height: "35px" }} />
                    <span>Casualties Details</span>
                </div>
                <div className='form-cont'>
                    <ReusableTable
                        data={casualtiesDetailsData}
                        columns={casualtiesDetailsColumn}
                        rowsPerPage={10}
                        total={casualtiesDetailsMeta?.pagination?.total}
                        onChangePagination={(page: number) => dispatch(fetchAllCasualtiesDetails({
                            page,
                            pageSize: 10,
                            "filter": {
                                "human_casualtie": {
                                    "id": {
                                        "eq": humanCasualtiesData.id
                                    }
                                }
                            }
                        }))}
                    />
                </div>
            </div>
        </div>
    );
};

export default HumanCasualtieDetails;