// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  background-color: #edf7ee;
  padding-left: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 6px;
  cursor: pointer;
}

.selected-card {
  background-color: #c6e5c9;
  border: 2px solid #009900;
}

.card img {
  height: 40px;
}

.card h2 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  color: #073042;
}

.card span {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 28px;
  color: #073042;
}

.selected-card h2 {
  color: #009900;
}

.selected-card span {
  color: #009900;
}
`, "",{"version":3,"sources":["webpack://./src/components/Card/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".card {\n  background-color: #edf7ee;\n  padding-left: 25px;\n  padding-top: 20px;\n  padding-bottom: 20px;\n  border-radius: 6px;\n  cursor: pointer;\n}\n\n.selected-card {\n  background-color: #c6e5c9;\n  border: 2px solid #009900;\n}\n\n.card img {\n  height: 40px;\n}\n\n.card h2 {\n  font-family: Montserrat;\n  font-weight: 600;\n  font-size: 18px;\n  color: #073042;\n}\n\n.card span {\n  font-family: Montserrat;\n  font-weight: 600;\n  font-size: 28px;\n  color: #073042;\n}\n\n.selected-card h2 {\n  color: #009900;\n}\n\n.selected-card span {\n  color: #009900;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
