import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

interface ManufacturerState {
    manufacturerList: any[];
    manufacturersLoading: boolean;
    filteredManufacturerList: any[];
    manufacturersMeta: any;
    manufacturerCount: number;
    manufacturerChartList: any[];
    filteredManufacturerCount: number;
    selectedManufacturer: any;
}

const initialState: ManufacturerState = {
    manufacturerList: [],
    manufacturersLoading: false,
    filteredManufacturerList: [],
    manufacturersMeta: {},
    manufacturerCount: 0,
    manufacturerChartList: [],
    filteredManufacturerCount: 0,
    selectedManufacturer: {}
};

export const fetchManufacturerCount = createAsyncThunk('manufacturer/fetchManufacturerCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                }
            }
        };
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiGetManufacturersCount : API.ApiGetManufacturersCountWithOutLocation,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.manufacturers.meta.pagination.total, manufacturerChartList: response.data.manufacturers.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchManufacturerData = createAsyncThunk('manufacturer/fetchManufacturerData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil } = pageData;
        const filter: any = {
            stage: {
                null: true
            }
        };
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllManufacturer,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.manufacturers.data, meta: response.data.manufacturers.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFilterdManufacturerData = createAsyncThunk('manufacturer/fetchFilterdManufacturerData', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await client.query({ query: API.ApiManufacturer, variables, context: { headers }, fetchPolicy: 'no-cache' });
        return { data: response.data.manufacturerDrafts.data, filteredManufacturerCount: response.data.manufacturerDrafts.meta.pagination.total };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchManufacturerDataById = createAsyncThunk('manufacturer/fetchManufacturerDataById', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetManufacturerById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.manufacturer.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createManufacturerData = createAsyncThunk('manufacturer/createManufacturerData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateManufacturer,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createManufacturerDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateManufacturerDraftData = createAsyncThunk('manufacturer/updateManufacturerDraftData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingManufacturerDraft,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateManufacturerDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateManufacturerStageData = createAsyncThunk('manufacturer/updateManufacturerStageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { stage, id, record } = data;
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        if (stage) {
            await client.mutate({
                mutation: API.ApiUpdateManufacturer,
                variables: {
                    id: id,
                    stage: stage
                },
                context: {
                    headers,
                },
            });
        } else {
            const obj = {
                "name": record.name,
                "divisionId": record.divisionId,
                "districtId": record.districtId,
                "postal_address": record.postal_address,
                "primary_areasId": record.primary_areasId,
                "secondary_areasId": record.secondary_areasId,
                "website": record.website,
                "registration_number": record.registration_number,
                "year_established": record.year_established,
                "organizational_head": record.organizational_head,
                "contact_person_in_district": record.contact_person_in_district,
                "contact_person_mobile_number_in_district": record.contact_person_mobile_number_in_district ? Number(record.contact_person_mobile_number_in_district) : null,
                "contact_number": record.contact_number ? Number(record.contact_number) : null,
                "latitude": record.latitude ? Number(record.latitude) : null,
                "longitude": record.longitude ? Number(record.longitude) : null,
                "stage": null,
                "tehsilId": record.tehsilId,
                "union_councilId": record.union_councilId,
                "address": record.address,
                "manufacturerId": record.manufacturer
            };
            await client.mutate({
                mutation: obj.manufacturerId ? API.ApiUpdateExistingManufacturer : API.ApiCreatePublishedManufacturer,
                variables: obj,
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
            await client.mutate({
                mutation: API.ApiDeleteManufacturerDraft,
                variables: { id },
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
        }
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteManufacturerData = createAsyncThunk('manufacturer/deleteManufacturerData', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteManufacturer,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateManufacturerData = createAsyncThunk('manufacturer/updateManufacturerData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingManufacturer, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateManufacturer.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateManufacturerRecordFromSocket = createAsyncThunk('manufacturer/addAndUpdateManufacturerRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, externalWorkflowsList } = data;
        const response = await client.query({ query: API.ApiGetManufacturerById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.manufacturer.data, externalWorkflowsList };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteManufacturerRecordFromSocket = createAsyncThunk('manufacturer/deleteManufacturerRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const getManufacturerDraftByUser = createAsyncThunk('manufacturer/getManufacturerDraftByUser', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.APIGetManufacturerDraftByUser, // Assuming you have an API constant for update mutation
            variables,
            context: {
                headers,
            },
        });
        if (response.data && response.data.manufacturerDrafts.data.length > 0) {
            return true;
        }
        return false;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const manufacturerSlice = createSlice({
    name: 'manufacturer',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchManufacturerCount.fulfilled, (state, action) => {
                state.manufacturerCount = action.payload.count;
                state.manufacturerChartList = action.payload.manufacturerChartList;
                state.manufacturersLoading = false;
            })
            .addCase(fetchManufacturerData.fulfilled, (state, action) => {
                state.manufacturerList = action.payload.data;
                state.manufacturersMeta = action.payload.meta;
                state.manufacturersLoading = false;
            })
            .addCase(fetchFilterdManufacturerData.fulfilled, (state, action) => {
                state.filteredManufacturerList = action.payload.data;
                state.filteredManufacturerCount = action.payload.filteredManufacturerCount;
                state.manufacturersLoading = false;
            })
            .addCase(fetchManufacturerDataById.fulfilled, (state, action) => {
                state.selectedManufacturer = action.payload;
                state.manufacturersLoading = false;
            })
            .addCase(createManufacturerData.fulfilled, (state, action) => {
                // state.manufacturerList.push(action.payload);
                state.manufacturersLoading = false;
            })
            .addCase(updateManufacturerDraftData.fulfilled, (state, action) => {
                // state.manufacturerList.push(action.payload);
                state.manufacturersLoading = false;
            })
            .addCase(updateManufacturerData.fulfilled, (state, action) => {
                state.manufacturersLoading = false;
            })
            .addCase(getManufacturerDraftByUser.fulfilled, (state, action) => {
                state.manufacturersLoading = false;
            })
            .addCase(updateManufacturerStageData.fulfilled, (state, action) => {
                const index = state.filteredManufacturerList.findIndex(item => item.id === action.payload);
                state.filteredManufacturerList.splice(index, 1);
                state.manufacturersLoading = false;
            })
            .addCase(deleteManufacturerData.fulfilled, (state, action) => {
                state.manufacturersLoading = false;
            })
            .addCase(addAndUpdateManufacturerRecordFromSocket.fulfilled, (state, action) => {
                const externalWorkflowsList = action.payload.externalWorkflowsList;
                const { id, attributes } = action.payload.data;
                if (attributes.stage) {
                    const selectedWorkFlow: any = externalWorkflowsList.find((item: any) => item.stage === attributes.stage);
                    const index = state.filteredManufacturerList.findIndex(item => item.id == id);
                    if (selectedWorkFlow) {
                        index !== -1 ? state.filteredManufacturerList[index] = action.payload.data : state.filteredManufacturerList.push(action.payload.data);
                    } else {
                        state.filteredManufacturerList.splice(index, 1);
                    }
                } else {
                    const index = state.manufacturerList.findIndex(item => item.id == id);
                    index !== -1 ? state.manufacturerList[index] = action.payload.data : state.manufacturerList.push(action.payload.data);
                }
            })
            .addCase(deleteManufacturerRecordFromSocket.fulfilled, (state, action) => {
                state.manufacturerList = state.manufacturerList.filter(item => item.id != action.payload);
                state.filteredManufacturerList = state.filteredManufacturerList.filter(item => item.id != action.payload);
            });
        [
            fetchManufacturerCount,
            fetchManufacturerData,
            fetchFilterdManufacturerData,
            updateManufacturerStageData,
            deleteManufacturerData,
            createManufacturerData,
            updateManufacturerData,
            fetchManufacturerDataById,
            updateManufacturerDraftData,
            getManufacturerDraftByUser
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.manufacturersLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.manufacturersLoading = false;
                });
        });
    }
});


export default manufacturerSlice.reducer;
