import { createVolunteer, createNgo, createManufacturer, createSupplier, createInventory, createFinancialRelief, createCompensatonRelief, createHumanCasualtie, createInfrastructureDamage, createFoodDamage, deleteNgo, deleteVolunteer, deleteManufacturer, deleteSupplier, deleteInventory, createLivestockDamage, deleteFinancialRelief, deleteCompensatonRelief, deleteHumanCasualtie, deleteInfrastructureDamage, deleteFoodDamage, deleteLivestockDamage, createSchool, createNewFoodDamage, createGrowerDetail, deleteGrowerDetail, createLivestockOwnerDetail, createInfrastructureOwnerDetail, deleteLivestockOwnerDetail, deleteInfrastructureOwnerDetail, createCasualtiesDetail, deleteCasualtiesDetail, createPublisedNgo, deleteNgoDraft, createPublishedVolunteer, deleteVolunteerDraft, createPublishedManufacturer, deleteManufacturerDraft, createPublishedSupplier, deleteSupplierDraft, createPublishedInventory, deleteInventoryDraft, createPublishedFinancialRelief, deleteFinancialReliefDraft, createPublisedCompensatonRelief, deleteCompensatonReliefDraft, createPublishedHumanCasualtie, deleteHumanCasualtieDraft, createPublisedInfrastructureDamage, deleteInfrastructureDamageDraft, createPublishedFoodDamage, deleteFoodDamageDraft, createPublisedLivestockDamage, deleteLivestockDamageDraft } from "./mutation";
import {
  getAgricultureTypes,
  getAllDistrict,
  getAllManufacturer,
  getAllNGOS,
  getAllSuppliers,
  getAllTehsils,
  getAllUnionCouncils,
  getAllVolunteers,
  getCompensationStatuses,
  getCompensatonReliefs,
  getDistrict,
  getDivision,
  getEvents,
  getExternalWorkflows,
  getFinancialReliefs,
  getFoodDamages,
  getGenders,
  getHumanCasualties,
  getCasualtyTypes,
  getInfrastructureDamages,
  getInfrastructureTypes,
  getInternalWorkflows,
  getAllInventories,
  getLivestockDamages,
  getManufacturer,
  getNGOS,
  getProductCategories,
  getProductNames,
  getProductTypes,
  getSuppliers,
  getTehsils,
  getUnionCouncils,
  getVolunteers,
  updateExistingInventory,
  updateExistingManufacturer,
  updateExistingNgo,
  updateExistingSupplier,
  updateExistingVolunteer,
  updateManufacturer,
  updateNgoStage,
  updateSupplier,
  updateVolunteer,
  getInventories,
  updateInventoryStage,
  updateFinancialReliefStage,
  getAllFinancialReliefs,
  updateCompensatonReliefStage,
  updateHumanCasualtieStage,
  updateInfrastructureDamageStage,
  updateLivestockDamageStage,
  updateFoodDamageStage,
  getAllCompensatonReliefs,
  getAllHumanCasualties,
  getAllInfrastructureDamages,
  getAllfoodDamages,
  getAllLivestockDamages,
  getLivestockTypes,
  updateExistingFinancialRelief,
  updateExistingCompensatonRelief,
  updateExistingHumanCasualtie,
  updateExistingInfrastructureDamage,
  updateExistingFoodDamage,
  updateExistingLivestockDamage,
  getNGOById,
  getInventoriesById,
  getFinancialReliefById,
  getCompensatonReliefById,
  getHumanCasualtieById,
  getInfrastructureDamageById,
  getFoodDamageById,
  getLivestockDamageById,
  getVolunteerById,
  getManufacturerById,
  getSupplierById,
  getDivisionById,
  getGenderById,
  getUnionCouncilById,
  getTehsilById,
  getDistrictById,
  getEventById,
  getInfrastructureTypeById,
  getAgricultureTypeById,
  getCompensationStatusById,
  getCasualtyTypeById,
  getProductTypeById,
  getProductNameById,
  getProductCategoryById,
  getLivestockTypeById,
  getAllSchool,
  getAllSchoolById,
  getAllPrimaryAreas,
  getAllSecondaryAreas,
  getAllPrimaryAreaById,
  getAllSecondaryAreaById,
  getNgoCount,
  getVolunteersCount,
  getManufacturersCount,
  getSuppliersCount,
  getSchoolsCount,
  getInventoriesCount,
  getHumanCasualtiesCount,
  getInfrastructureDamagesCount,
  getFoodDamagesCount,
  getLivestockDamagesCount,
  getFinancialReliefsCount,
  getCompensatonReliefsCount,
  getNgoCountWithOutLocation,
  getVolunteersCountWithOutLocation,
  getManufacturersCountWithOutLocation,
  getSuppliersCountWithOutLocation,
  getSchoolsCountWithOutLocation,
  ApiInventoriesCountWithOutChartData,
  getFinancialReliefsCountWithOutChartData,
  getCompensatonReliefsCountWithOutChartData,
  getHumanCasualtiesCountWithOutChartData,
  getInfrastructureDamagesCountWithOutChartData,
  getFoodDamagesCountWithOutChartData,
  getLivestockDamagesCountWithOutChartData,
  getAllClasses,
  getAllGrowerDetails,
  updateExistingGrowerDetail,
  updateLivestockOwnerDetail,
  updateInfrastructureOwnerDetail,
  getAllLivestockOwnerDetails,
  getAllInfrastructureOwnerDetails,
  getAllCasualtiesDetails,
  updateCasualtiesDetail,
  updateExistingNgoDraft,
  updateExistingVolunteerDraft,
  updateExistingManufacturerDraft,
  updateExistingSupplierDraft,
  updateExistingInventoryDraft,
  updateExistingFinancialReliefDraft,
  updateExistingCompensatonReliefDraft,
  updateExistingHumanCasualtieDraft,
  updateExistingInfrastructureDamageDraft,
  updateExistingFoodDamageDraft,
  updateExistingLivestockDamageDraft,
  getNgoDraftByUser,
  getVolunteerDraftByUser,
  getManufacturerDraftByUser,
  getSupplierDraftByUser,
  getInventoryDraftByUser,
  getFinancialReliefDraftByUser,
  getCompensatonReliefDraftByUser,
  getHumanCasualtieDraftByUser,
  getInfrastructureDamageDraftByUser,
  getFoodDamageDraftByUser,
  getLivestockDamageDraftByUser,
  checkLicenseRegistrationNumber,
  checkVolunteerCnic,
  checkCompensatonReliefCnic
} from "./queries";

const API = {
  ApiDivision: getDivision,
  ApiGetDivisionById: getDivisionById,

  ApiAllDistrict: getAllDistrict,
  ApiDistrict: getDistrict,
  ApiGetDistrictById: getDistrictById,

  ApiAllTehsils: getAllTehsils,
  ApiTehsils: getTehsils,
  ApiGetTehsilById: getTehsilById,

  ApiAllUnionCouncils: getAllUnionCouncils,
  ApiUnionCouncils: getUnionCouncils,
  ApiGetUnionCouncilById: getUnionCouncilById,

  ApiGenders: getGenders,
  ApiGetGenderById: getGenderById,

  ApiGetNgoCount: getNgoCount,
  ApiGetNgoCountWithOutLocation: getNgoCountWithOutLocation,
  ApiNGOS: getNGOS,
  ApiAllNGOS: getAllNGOS,
  ApiGetNGOById: getNGOById,

  ApiGetVolunteersCount: getVolunteersCount,
  ApiGetVolunteersCountWithOutLocation: getVolunteersCountWithOutLocation,
  ApiVolunteers: getVolunteers,
  ApiAllVolunteers: getAllVolunteers,
  ApiGetVolunteerById: getVolunteerById,

  ApiGetManufacturersCount: getManufacturersCount,
  ApiGetManufacturersCountWithOutLocation: getManufacturersCountWithOutLocation,
  ApiManufacturer: getManufacturer,
  ApiAllManufacturer: getAllManufacturer,
  ApiGetManufacturerById: getManufacturerById,

  ApiGetSuppliersCount: getSuppliersCount,
  ApiGetSuppliersCountWithOutLocation: getSuppliersCountWithOutLocation,
  ApiSuppliers: getSuppliers,
  ApiAllSuppliers: getAllSuppliers,
  ApiGetSupplierById: getSupplierById,

  ApiGetSchoolsCount: getSchoolsCount,
  ApiGetSchoolsCountWithOutLocation: getSchoolsCountWithOutLocation,
  ApiGetAllSchool: getAllSchool,
  ApiGetAllSchoolById: getAllSchoolById,

  ApiInventoriesCount: getInventoriesCount,
  ApiInventoriesCountWithOutChartData: ApiInventoriesCountWithOutChartData,
  ApiInventories: getInventories,
  ApiAllInventories: getAllInventories,
  ApiGetInventoriesById: getInventoriesById,
  ApiProductTypes: getProductTypes,
  ApiGetProductTypeById: getProductTypeById,
  ApiProductNames: getProductNames,
  ApiGetProductNameById: getProductNameById,
  ApiProductCategories: getProductCategories,
  ApiGetProductCategoryById: getProductCategoryById,
  ApiGetAllClasses: getAllClasses,

  ApiFinancialReliefsCount: getFinancialReliefsCount,
  ApiFinancialReliefsCountWithOutChartData: getFinancialReliefsCountWithOutChartData,
  ApiFinancialReliefs: getFinancialReliefs,
  ApiAllFinancialReliefs: getAllFinancialReliefs,
  ApiGetFinancialReliefById: getFinancialReliefById,

  ApiCompensatonReliefsCount: getCompensatonReliefsCount,
  ApiCompensatonReliefsCountWithOutChartData: getCompensatonReliefsCountWithOutChartData,
  ApiCompensatonReliefs: getCompensatonReliefs,
  ApiAllCompensatonReliefs: getAllCompensatonReliefs,
  ApiGetCompensatonReliefById: getCompensatonReliefById,
  ApiCompensationStatuses: getCompensationStatuses,
  ApiGetCompensationStatusById: getCompensationStatusById,

  ApiHumanCasualtiesCount: getHumanCasualtiesCount,
  ApiHumanCasualtiesCountWithOutChartData: getHumanCasualtiesCountWithOutChartData,
  ApiHumanCasualties: getHumanCasualties,
  ApiAllHumanCasualties: getAllHumanCasualties,
  ApiGetHumanCasualtieById: getHumanCasualtieById,
  ApiCasualtyTypes: getCasualtyTypes,
  ApiGetCasualtyTypeById: getCasualtyTypeById,
  ApiGetAllCasualtiesDetails: getAllCasualtiesDetails,

  ApiInfrastructureDamagesCount: getInfrastructureDamagesCount,
  ApiInfrastructureDamagesCountWithOutChartData: getInfrastructureDamagesCountWithOutChartData,
  ApiInfrastructureDamages: getInfrastructureDamages,
  ApiAllInfrastructureDamages: getAllInfrastructureDamages,
  ApiGetInfrastructureDamageById: getInfrastructureDamageById,

  ApiFoodDamagesCount: getFoodDamagesCount,
  ApiFoodDamagesCountWithOutChartData: getFoodDamagesCountWithOutChartData,
  ApiFoodDamages: getFoodDamages,
  ApiAllFoodDamages: getAllfoodDamages,
  ApiGetFoodDamageById: getFoodDamageById,

  ApiLivestockDamagesCount: getLivestockDamagesCount,
  ApiLivestockDamagesCountWithOutChartData: getLivestockDamagesCountWithOutChartData,
  ApiLivestockDamages: getLivestockDamages,
  ApiAllLivestockDamages: getAllLivestockDamages,
  ApiGetLivestockDamageById: getLivestockDamageById,
  ApiGetLivestockTypes: getLivestockTypes,
  ApiGetLivestockTypeById: getLivestockTypeById,
  ApiGetAllLivestockOwnerDetails: getAllLivestockOwnerDetails,

  ApiEvents: getEvents,
  ApiGetEventById: getEventById,

  ApiInfrastructureTypes: getInfrastructureTypes,
  ApiGetInfrastructureTypeById: getInfrastructureTypeById,
  ApiGetAllInfrastructureOwnerDetails: getAllInfrastructureOwnerDetails,

  ApiGetAllGrowerDetails: getAllGrowerDetails,

  ApiAgricultureTypes: getAgricultureTypes,
  ApiGetAgricultureTypeById: getAgricultureTypeById,

  ApiExternalWorkflows: getExternalWorkflows,
  ApiInternalWorkflows: getInternalWorkflows,

  ApiAllPrimaryAreas: getAllPrimaryAreas,
  ApiAllPrimaryAreaById: getAllPrimaryAreaById,
  ApiAllSecondaryAreas: getAllSecondaryAreas,
  ApiAllSecondaryAreaById: getAllSecondaryAreaById,

  // Update  Stage

  ApiUpdateNgoStage: updateNgoStage,

  ApiUpdateVolunteer: updateVolunteer,

  ApiUpdateManufacturer: updateManufacturer,

  ApiUpdateSupplier: updateSupplier,

  ApiUpdateInventoryStage: updateInventoryStage,

  ApiUpdateFinancialReliefStage: updateFinancialReliefStage,

  ApiUpdateCompensatonReliefStage: updateCompensatonReliefStage,

  ApiUpdateHumanCasualtieStage: updateHumanCasualtieStage,

  ApiUpdateInfrastructureDamageStage: updateInfrastructureDamageStage,

  ApiUpdateFoodDamageStage: updateFoodDamageStage,

  ApiUpdateLivestockDamageStage: updateLivestockDamageStage,

  // Delete 

  ApiDeleteNgo: deleteNgo,
  ApiDeleteNgoDraft: deleteNgoDraft,

  ApiDeleteVolunteer: deleteVolunteer,
  ApiDeleteVolunteerDraft: deleteVolunteerDraft,

  ApiDeleteManufacturer: deleteManufacturer,
  ApiDeleteManufacturerDraft: deleteManufacturerDraft,

  ApiDeleteSupplier: deleteSupplier,
  ApiDeleteSupplierDraft: deleteSupplierDraft,

  ApiDeleteInventory: deleteInventory,
  ApiDeleteInventoryDraft: deleteInventoryDraft,

  ApiDeleteFinancialRelief: deleteFinancialRelief,
  ApiDeleteFinancialReliefDraft: deleteFinancialReliefDraft,

  ApiDeleteCompensatonRelief: deleteCompensatonRelief,
  ApiDeleteCompensatonReliefDraft: deleteCompensatonReliefDraft,

  ApiDeleteHumanCasualtie: deleteHumanCasualtie,
  ApiDeleteHumanCasualtieDraft: deleteHumanCasualtieDraft,

  ApiDeleteInfrastructureDamage: deleteInfrastructureDamage,
  ApiDeleteInfrastructureDamageDraft: deleteInfrastructureDamageDraft,

  ApiDeleteFoodDamage: deleteFoodDamage,
  ApiDeleteFoodDamageDraft: deleteFoodDamageDraft,

  ApiDeleteGrowerDetail: deleteGrowerDetail,

  ApiDeleteLivestockDamage: deleteLivestockDamage,
  ApiDeleteLivestockDamageDraft: deleteLivestockDamageDraft,

  ApiCreateSchool: createSchool,

  ApiDeleteLivestockOwnerDetail: deleteLivestockOwnerDetail,

  ApiDeleteInfrastructureOwnerDetail: deleteInfrastructureOwnerDetail,

  ApiDeleteCasualtiesDetail: deleteCasualtiesDetail,

  // Update Existing record
  ApiUpdateExistingNgo: updateExistingNgo,
  ApiUpdateExistingNgoDraft: updateExistingNgoDraft,
  ApiUpdateExistingVolunteer: updateExistingVolunteer,
  ApiUpdateExistingVolunteerDraft: updateExistingVolunteerDraft,
  ApiUpdateExistingManufacturer: updateExistingManufacturer,
  ApiUpdateExistingManufacturerDraft: updateExistingManufacturerDraft,
  ApiUpdateExistingSupplier: updateExistingSupplier,
  ApiUpdateExistingSupplierDraft: updateExistingSupplierDraft,
  ApiUpdateExistingInventory: updateExistingInventory,
  ApiUpdateExistingInventoryDraft: updateExistingInventoryDraft,
  ApiUpdateExistingFinancialRelief: updateExistingFinancialRelief,
  ApiUpdateExistingFinancialReliefDraft: updateExistingFinancialReliefDraft,
  ApiUpdateExistingCompensatonRelief: updateExistingCompensatonRelief,
  ApiUpdateExistingCompensatonReliefDraft: updateExistingCompensatonReliefDraft,
  ApiUpdateExistingHumanCasualtie: updateExistingHumanCasualtie,
  ApiUpdateExistingHumanCasualtieDraft: updateExistingHumanCasualtieDraft,
  ApiUpdateExistingInfrastructureDamage: updateExistingInfrastructureDamage,
  ApiUpdateExistingInfrastructureDamageDraft: updateExistingInfrastructureDamageDraft,
  ApiUpdateExistingFoodDamage: updateExistingFoodDamage,
  ApiUpdateExistingFoodDamageDraft: updateExistingFoodDamageDraft,
  ApiUpdateExistingLivestockDamage: updateExistingLivestockDamage,
  ApiUpdateExistingLivestockDamageDraft: updateExistingLivestockDamageDraft,
  ApiUpdateExistingGrowerDetail: updateExistingGrowerDetail,
  ApiUpdateExistingLivestockOwnerDetail: updateLivestockOwnerDetail,
  ApiUpdateExistingInfrastructureOwnerDetail: updateInfrastructureOwnerDetail,
  ApiUpdateExistingCasualtiesDetail: updateCasualtiesDetail,

  // mutations create

  ApiCreateNGO: createNgo,
  ApiCreatePublisedNgo: createPublisedNgo,

  ApiCreateVolunteer: createVolunteer,
  ApiCreatePublishedVolunteer: createPublishedVolunteer,

  ApiCreateManufacturer: createManufacturer,
  ApiCreatePublishedManufacturer: createPublishedManufacturer,

  ApiCreateSupplier: createSupplier,
  ApiCreatePublishedSupplier: createPublishedSupplier,

  ApiCreateInventory: createInventory,
  ApiCreatePublishedInventory: createPublishedInventory,

  ApiCreateFinancialRelief: createFinancialRelief,
  ApiCreatePublishedFinancialRelief: createPublishedFinancialRelief,

  ApiCreateCompensatonRelief: createCompensatonRelief,
  ApiCreatePublisedCompensatonRelief: createPublisedCompensatonRelief,

  ApiCreateHumanCasualtie: createHumanCasualtie,
  ApiCreatePublishedHumanCasualtie: createPublishedHumanCasualtie,

  ApiCreateInfrastructureDamage: createInfrastructureDamage,
  ApiCreatePublisedInfrastructureDamage: createPublisedInfrastructureDamage,

  ApiCreateFoodDamage: createFoodDamage,
  ApiCreatePublishedFoodDamage: createPublishedFoodDamage,

  ApiCreateLivestockDamage: createLivestockDamage,
  ApiCreatePublisedLivestockDamage: createPublisedLivestockDamage,

  ApiCreateGrowerDetail: createGrowerDetail,

  ApiCreateLivestockOwnerDetail: createLivestockOwnerDetail,

  ApiCreateInfrastructureOwnerDetail: createInfrastructureOwnerDetail,

  ApiCreateCasualtiesDetail: createCasualtiesDetail,

  APIGetNgoDraftByUser: getNgoDraftByUser,
  APIGetVolunteerDraftByUser: getVolunteerDraftByUser,
  APIGetManufacturerDraftByUser: getManufacturerDraftByUser,
  APIGetSupplierDraftByUser: getSupplierDraftByUser,
  APIGetInventoryDraftByUser: getInventoryDraftByUser,
  APIGetFinancialReliefDraftByUser: getFinancialReliefDraftByUser,
  APIGetCompensatonReliefDraftByUser: getCompensatonReliefDraftByUser,
  APIGetHumanCasualtieDraftByUser: getHumanCasualtieDraftByUser,
  APIGetInfrastructureDamageDraftByUser: getInfrastructureDamageDraftByUser,
  APIGetFoodDamageDraftByUser: getFoodDamageDraftByUser,
  APIGetLivestockDamageDraftByUser: getLivestockDamageDraftByUser,

  APICheckLicenseRegistrationNumber: checkLicenseRegistrationNumber,
  APICheckVolunteerCnic: checkVolunteerCnic,
  APICheckCompensatonReliefCnic: checkCompensatonReliefCnic,
};

export default API;