// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-container {
    width: 100%;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    /* border: 1px solid; */
}

.progress-bar {
    height: 100%;
    background-color: #007bff;
    transition: width 0.5s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/progressBar/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,kCAAkC;AACtC","sourcesContent":[".progress-bar-container {\n    width: 100%;\n    height: 20px;\n    background-color: #e0e0e0;\n    border-radius: 5px;\n    overflow: hidden;\n    /* border: 1px solid; */\n}\n\n.progress-bar {\n    height: 100%;\n    background-color: #007bff;\n    transition: width 0.5s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
