import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

interface FinancialReliefState {
    financialReliefList: any[];
    financialReliefLoading: boolean;
    filteredFinancialReliefList: any[];
    financialReliefMeta: any;
    filteredFinancialCount: number;
    financialReliefCount: number,
    financialReliefChartList: any[],
    selectedFinancialRelief: any,
}

const initialState: FinancialReliefState = {
    financialReliefList: [],
    financialReliefLoading: false,
    filteredFinancialReliefList: [],
    financialReliefMeta: {},
    filteredFinancialCount: 0,
    financialReliefCount: 0,
    financialReliefChartList: [],
    selectedFinancialRelief: {},
};

export const fetchFinancialReliefCount = createAsyncThunk('financialRelief/fetchFinancialReliefCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedEvent, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                }
            }
        };
        if (selectedEvent) {
            variables.filter.event = {
                name: {
                    "eq": selectedEvent
                }
            };
        }
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiFinancialReliefsCount : API.ApiFinancialReliefsCountWithOutChartData,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.financialReliefs.meta.pagination.total, financialReliefChartList: response.data.financialReliefs.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFinancialReliefData = createAsyncThunk('financialRelief/fetchFinancialReliefData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent } = pageData;
        const filter: any = {
            stage: {
                null: true
            }
        };
        if (selectedEvent) {
            filter.event = {
                name: {
                    "eq": selectedEvent
                }
            };
        }
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllFinancialReliefs,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.financialReliefs.data, meta: response.data.financialReliefs.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFilterdFinancialReliefData = createAsyncThunk('financialRelief/fetchFilterdFinancialReliefData', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await client.query({ query: API.ApiFinancialReliefs, variables, context: { headers }, fetchPolicy: 'no-cache' });
        return { data: response.data.financialReliefDrafts.data, filteredFinancialCount: response.data.financialReliefDrafts.meta.pagination.total };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFinancialReliefDataById = createAsyncThunk('financialRelief/fetchFinancialReliefDataById', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetFinancialReliefById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.financialRelief.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createFinancialReliefData = createAsyncThunk('financialRelief/createFinancialReliefData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateFinancialRelief,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createFinancialReliefDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateFinancialReliefDraftData = createAsyncThunk('financialRelief/updateFinancialReliefDraftData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingFinancialReliefDraft,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateFinancialReliefDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateFinancialReliefStageData = createAsyncThunk('financialRelief/updateFinancialReliefStageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { stage, id, record } = data;
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        if (stage) {
            await client.mutate({
                mutation: API.ApiUpdateFinancialReliefStage,
                variables: {
                    id: id,
                    stage: stage
                },
                context: {
                    headers,
                },
            });
        } else {
            const obj = {
                "divisionId": record.divisionId,
                "districtId": record.districtId,
                "eventId": record.eventId,
                "contributor": record.contributor,
                "amount_received_in_pkr": record.amount_received_in_pkr ? Number(record.amount_received_in_pkr) : null,
                "amount_consumed_in_pkr": record.amount_consumed_in_pkr ? Number(record.amount_consumed_in_pkr) : null,
                "amount_on_hand_in_pkr": record.amount_on_hand_in_pkr ? Number(record.amount_on_hand_in_pkr) : null,
                "stage": null,
                "tehsilId": record.tehsilId,
                "union_councilId": record.union_councilId,
                "address": record.address,
                "financialReliefId": record.financial_relief
            };
            await client.mutate({
                mutation: obj.financialReliefId ? API.ApiUpdateExistingFinancialRelief : API.ApiCreatePublishedFinancialRelief,
                variables: obj,
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
            await client.mutate({
                mutation: API.ApiDeleteFinancialReliefDraft,
                variables: { id },
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
        }
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteFinancialReliefData = createAsyncThunk('financialRelief/deleteFinancialReliefData', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteFinancialRelief,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateFinancialReliefData = createAsyncThunk('inventory/updateFinancialReliefData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingFinancialRelief, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateFinancialRelief.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateFinancialReliefRecordFromSocket = createAsyncThunk('financialRelief/addAndUpdateFinancialReliefRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, internalWorkflowsList } = data;
        const response = await client.query({ query: API.ApiGetFinancialReliefById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.financialRelief.data, internalWorkflowsList };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteFinancialReliefRecordFromSocket = createAsyncThunk('financialRelief/deleteFinancialReliefRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const getFinancialReliefDraftByUser = createAsyncThunk('financialRelief/getFinancialReliefDraftByUser', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.APIGetFinancialReliefDraftByUser, // Assuming you have an API constant for update mutation
            variables,
            context: {
                headers,
            },
        });
        if (response.data && response.data.financialReliefDrafts.data.length > 0) {
            return true;
        }
        return false;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const financialReliefSlice = createSlice({
    name: 'financialRelief',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchFinancialReliefCount.fulfilled, (state, action) => {
                state.financialReliefCount = action.payload.count;
                state.financialReliefChartList = action.payload.financialReliefChartList;
                state.financialReliefLoading = false;
            })
            .addCase(fetchFinancialReliefData.fulfilled, (state, action) => {
                state.financialReliefList = action.payload.data;
                state.financialReliefMeta = action.payload.meta;
                state.financialReliefLoading = false;
            })
            .addCase(fetchFilterdFinancialReliefData.fulfilled, (state, action) => {
                state.filteredFinancialReliefList = action.payload.data;
                state.filteredFinancialCount = action.payload.filteredFinancialCount;
                state.financialReliefLoading = false;
            })
            .addCase(fetchFinancialReliefDataById.fulfilled, (state, action) => {
                state.selectedFinancialRelief = action.payload;
                state.financialReliefLoading = false;
            })
            .addCase(createFinancialReliefData.fulfilled, (state, action) => {
                // state.financialReliefList.push(action.payload);
                state.financialReliefLoading = false;
            })
            .addCase(updateFinancialReliefDraftData.fulfilled, (state, action) => {
                state.financialReliefLoading = false;
            })
            .addCase(getFinancialReliefDraftByUser.fulfilled, (state, action) => {
                state.financialReliefLoading = false;
            })
            .addCase(updateFinancialReliefStageData.fulfilled, (state, action) => {
                const index = state.filteredFinancialReliefList.findIndex(item => item.id === action.payload);
                state.filteredFinancialReliefList.splice(index, 1);
                state.financialReliefLoading = false;
            })
            .addCase(deleteFinancialReliefData.fulfilled, (state, action) => {
                state.financialReliefLoading = false;
            })
            .addCase(updateFinancialReliefData.fulfilled, (state, action) => {
                const index = state.filteredFinancialReliefList.findIndex(item => item.id === action.payload);
                state.filteredFinancialReliefList.splice(index, 1);
                state.financialReliefLoading = false;
            })
            .addCase(addAndUpdateFinancialReliefRecordFromSocket.fulfilled, (state, action) => {
                const internalWorkflowsList = action.payload.internalWorkflowsList;
                const { id, attributes } = action.payload.data;
                if (attributes.stage) {
                    const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === attributes.stage);
                    const index = state.filteredFinancialReliefList.findIndex(item => item.id == id);
                    if (selectedWorkFlow) {
                        index !== -1 ? state.filteredFinancialReliefList[index] = action.payload.data : state.filteredFinancialReliefList.push(action.payload.data);
                    } else {
                        state.filteredFinancialReliefList.splice(index, 1);
                    }
                } else {
                    const index = state.financialReliefList.findIndex(item => item.id == id);
                    index !== -1 ? state.financialReliefList[index] = action.payload.data : state.financialReliefList.push(action.payload.data);
                }
            })
            .addCase(deleteFinancialReliefRecordFromSocket.fulfilled, (state, action) => {
                state.financialReliefList = state.financialReliefList.filter(item => item.id != action.payload);
                state.filteredFinancialReliefList = state.filteredFinancialReliefList.filter(item => item.id != action.payload);
            });
        [
            fetchFinancialReliefCount,
            fetchFinancialReliefData,
            fetchFilterdFinancialReliefData,
            updateFinancialReliefStageData,
            createFinancialReliefData,
            deleteFinancialReliefData,
            updateFinancialReliefData,
            fetchFinancialReliefDataById,
            updateFinancialReliefDraftData,
            getFinancialReliefDraftByUser
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.financialReliefLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.financialReliefLoading = false;
                });
        });
    }
});


export default financialReliefSlice.reducer;
