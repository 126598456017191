import React, { useEffect, useState } from 'react';
import './index.css'; // Import CSS for styling
import { FaArrowLeft } from "react-icons/fa6";
import formInventoryLogo from '../../assets/images/formInventoryLogo.png';
import { DatePicker, Input } from 'antd';
import type { DatePickerProps } from 'antd';
import { Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { toast } from '../../components/Toast';
import { createInventoryData, fetchClassesData, fetchProductCategoriesData, fetchProductNamesData, fetchProductTypesData, getInventoryDraftByUser, updateInventoryData, updateInventoryDraftData, updateInventoryStageData } from '../../reduxToolkit/inventorySlice';
import dayjs from 'dayjs';
import { fetchTehsilsData, fetchUnionCouncilData } from '../../reduxToolkit/entitiesSlice';
import { validateObject } from '../../helpers/common';

const InventoryForms = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { allDistrictList, divisionList, tehsilList, unionCouncilList, internalWorkflowsList } = useSelector((state: RootState) => state.entities);
    const { userData } = useSelector((state: RootState) => state.auth);
    const { productTypeList, productNameList, productCategorieList, classList } = useSelector((state: RootState) => state.inventory);
    const [inventory, setInventory] = useState<{ [key: string]: any; }>({});
    const [actionButtonText, setActionButtonText] = useState("");
    const { data, isForAction, isViewOnly, isForUpdate, is_draft } = location.state;
    const [isCreating, setIsCreating] = useState(false);
    const [isCheckRequired, setIsCheckRequired] = useState(false);
    const [hasDraft, setHasDraft] = useState(false);

    useEffect(() => {
        (async () => {
            if (userData && userData.id) {
                const userDraft = (await dispatch(getInventoryDraftByUser({
                    id: inventory.draft_id,
                    created_by_user: userData.id
                }))).payload;
                setHasDraft(userDraft);
            }
        })();
    }, [userData, inventory.draft_id]);

    useEffect(() => {
        if ((isForAction || isViewOnly) && internalWorkflowsList.length) {
            setInventory(data);
            const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === data.stage);
            setActionButtonText(selectedWorkFlow?.title);
        } else if (isForUpdate) {
            setInventory(data);
            setActionButtonText("Update Information");
        } else {
            const obj = {
                division: userData?.division?.division_name,
                divisionId: userData?.division?.id,
                district: userData?.district?.district_name,
                districtId: userData?.district?.id,
            };
            setInventory(prevState => ({ ...prevState, ...obj }));
        }
        dispatch(fetchProductCategoriesData());
        dispatch(fetchProductNamesData());
        dispatch(fetchProductTypesData());
        dispatch(fetchClassesData());
    }, [userData, internalWorkflowsList]);


    useEffect(() => {
        dispatch(fetchTehsilsData(inventory?.district));
    }, [dispatch, inventory?.district]);

    useEffect(() => {
        dispatch(fetchUnionCouncilData(inventory?.tehsil));
    }, [dispatch, inventory?.tehsil]);

    const divisionData = divisionList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.division_name,
        label: item.attributes.division_name,
    })) || [];

    const districtData = allDistrictList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.district_name,
        label: item.attributes.district_name,
    })) || [];

    const tehsilData = tehsilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.tehsil_name,
        label: item.attributes.tehsil_name,
    })) || [];

    const unionCouncilListData = unionCouncilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.union_council_name,
        label: item.attributes.union_council_name,
    })) || [];

    const productTypeListData = productTypeList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const productNameListData = productNameList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const productCategorieListData = productCategorieList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const classListData = classList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const onSave = async () => {
        try {
            if (hasDraft) {
                throw new Error("You already have a draft in progress or pending approval.");
            } else {
                const obj: any = {
                    "divisionId": inventory.divisionId,
                    "districtId": inventory.districtId,
                    "product_typeId": inventory.product_typeId,
                    "product_nameId": inventory.product_nameId,
                    "product_categoryId": inventory.product_categoryId,
                    "product_section": inventory.product_section,
                    "received_quantity": inventory.received_quantity ? Number(inventory.received_quantity) : null,
                    "consumed_quantity": inventory.consumed_quantity ? Number(inventory.consumed_quantity) : null,
                    "warehouse_address": inventory.warehouse_address,
                    "description": inventory.description,
                    "unit_of_measure": inventory.unit_of_measure,
                    "product_code": inventory.product_code,
                    "product_barcode": inventory.product_barcode,
                    "product_qr_code": inventory.product_qr_code,
                    "product_brand": inventory.product_brand,
                    "product_company": inventory.product_company,
                    "induction_date": inventory.induction_date,
                    "warranty_year": inventory.warranty_year,
                    "expiry_date": inventory.expiry_date,
                    "latitude": inventory.latitude ? Number(inventory.latitude) : null,
                    "longitude": inventory.longitude ? Number(inventory.longitude) : null,
                    "stage": 1,
                    "is_draft": false,
                    "created_by_user": userData.id,
                };
                if (inventory.product_type === "Food" || inventory.product_type === "Medicine") {
                    obj.classId = inventory.classId;
                }
                console.log('obj obj obj', obj);

                if (!validateObject(obj)) {
                    setIsCheckRequired(true);
                    throw new Error('Please fill the all highlighted fields');
                }
                obj.tehsilId = inventory.tehsilId;
                obj.union_councilId = inventory.union_councilId;
                obj.inventory = inventory.inventory;
                if (inventory.draft_id) {
                    obj.inventoryId = inventory.id;
                    const res = await dispatch(updateInventoryDraftData(obj));
                    if (res.type !== "inventory/updateInventoryDraftData/rejected") {
                        navigate(-1);
                        toast('success', "inventory draft update successfully");
                    } else {
                        setIsCreating(false);
                    }
                } else {
                    if (isForUpdate) {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createInventoryData(obj));
                        if (res.type !== "inventory/createInventoryData/rejected") {
                            navigate(-1);
                            toast('success', "Inventory update successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                        // const res = await dispatch(updateInventoryData(obj));
                        // if (res.type !== "inventory/updateInventoryData/rejected") {
                        //     navigate(-1);
                        //     toast('success', "Inventory update successfully");
                        // }
                    } else {
                        if (isCreating) return;
                        setIsCreating(true);
                        const res = await dispatch(createInventoryData(obj));
                        if (res.type !== "inventory/createInventoryData/rejected") {
                            navigate(-1);
                            toast('success', "Inventory created successfully submitted for approval. It will be published after approval.");
                        } else {
                            setIsCreating(false);
                        }
                    }
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    const onAction = async () => {
        const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === inventory.stage);
        let smallestStageNumber = internalWorkflowsList.reduce((min: any, record: any) => record.stage < min && record.stage > inventory.stage ? record.stage : min, Infinity);
        const res = await dispatch(updateInventoryStageData({
            id: inventory.id,
            stage: (!smallestStageNumber || smallestStageNumber == Infinity) ? null : smallestStageNumber,
            record: inventory
        }));
        if (res.type !== "inventory/updateInventoryStageData/rejected") {
            navigate(-1);
            toast("success", `${(!smallestStageNumber || smallestStageNumber == Infinity) ? "Publish" : selectedWorkFlow.title} successfully`);
        }
    };

    const handleDateChange = (key: any, date: any, dateString: any) => {
        const newInventory: any = { ...inventory, [key]: dateString };
        const inductionDate = newInventory.induction_date ? dayjs(newInventory.induction_date) : null;
        const warrantyYear = newInventory.warranty_year ? dayjs(newInventory.warranty_year, 'YYYY') : null;
        const expiryDate = newInventory.expiry_date ? dayjs(newInventory.expiry_date) : null;

        if (inductionDate && (dayjs(inductionDate).isAfter(warrantyYear) || dayjs(inductionDate).isAfter(expiryDate))) {
            toast('error', 'Induction date cannot be after warranty year or expiry date.');
            return;
        }
        if (warrantyYear && (dayjs(warrantyYear).isBefore(inductionDate) || dayjs(warrantyYear).isAfter(expiryDate))) {
            toast('error', 'Warranty year cannot be before induction date or after expiry date.');
            return;
        }
        if (expiryDate && (dayjs(expiryDate).isBefore(inductionDate) || dayjs(expiryDate).isBefore(warrantyYear))) {
            toast('error', 'Expiry date cannot be before induction date or warranty year.');
            return;
        }
        setInventory(newInventory);
    };

    const filterOption = (input: string, option?: { label: string; value: string; }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onSaveAsDraft = async () => {
        try {
            if (isCreating) return;
            setIsCreating(true);
            const obj: any = {
                "divisionId": inventory.divisionId,
                "districtId": inventory.districtId,
                "product_typeId": inventory.product_typeId,
                "product_nameId": inventory.product_nameId,
                "product_categoryId": inventory.product_categoryId,
                "product_section": inventory.product_section,
                "received_quantity": inventory.received_quantity ? Number(inventory.received_quantity) : null,
                "consumed_quantity": inventory.consumed_quantity ? Number(inventory.consumed_quantity) : null,
                "warehouse_address": inventory.warehouse_address,
                "description": inventory.description,
                "unit_of_measure": inventory.unit_of_measure,
                "product_code": inventory.product_code,
                "product_barcode": inventory.product_barcode,
                "product_qr_code": inventory.product_qr_code,
                "product_brand": inventory.product_brand,
                "product_company": inventory.product_company,
                "induction_date": inventory.induction_date,
                "warranty_year": inventory.warranty_year,
                "expiry_date": inventory.expiry_date,
                "latitude": inventory.latitude ? Number(inventory.latitude) : null,
                "longitude": inventory.longitude ? Number(inventory.longitude) : null,
                "tehsilId": inventory.tehsilId,
                "union_councilId": inventory.union_councilId,
                "address": inventory.address,
                "stage": null,
                "is_draft": true,
                "created_by_user": userData.id,
            };
            if (inventory.draft_id) {
                obj.inventory = inventory.inventory;
                obj.inventoryId = data.draft_id;
                const res = await dispatch(updateInventoryDraftData(obj));
                if (res.type !== "inventory/updateInventoryDraftData/rejected") {
                    navigate(-1);
                    toast('success', "inventory draft update successfully");
                } else {
                    setIsCreating(false);
                }
            } else {
                obj.inventory = inventory.inventory;
                const res = await dispatch(createInventoryData(obj));
                if (res.type !== "inventory/createInventoryData/rejected") {
                    navigate(-1);
                    toast('success', "inventory draft successfully");
                } else {
                    setIsCreating(false);
                }
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    console.log("is_draft", is_draft);
    console.log("data", data);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formInventoryLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Inventory</span>
                        </div>
                    </div>
                    {isForAction ? <div className='form-send-btn' onClick={onAction}>
                        <span className='sand-btn-text'>{actionButtonText}</span>
                    </div>
                        : <div>
                            {!hasDraft ? <div className='form-send-btn' onClick={onSaveAsDraft}>
                                <span className='sand-btn-text'>Save as draft</span>
                            </div> : null}
                            <div className='form-send-btn' onClick={onSave}>
                                <span className='sand-btn-text'>Submit</span>
                            </div>
                        </div>}
                </div>
            </div>
            <div className='form-body'>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Product type</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select Product type"
                            style={{ width: '100%' }}
                            showSearch
                            filterOption={filterOption}
                            allowClear
                            disabled={isViewOnly}
                            status={(isCheckRequired && !inventory.product_typeId) ? 'error' : ""}
                            onChange={(value: any, item: any) => setInventory(prevState => ({ ...prevState, product_typeId: item?.id, product_type: item?.value }))}
                            options={productTypeListData}
                            className='input'
                            value={inventory.product_type}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Product Name</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select Product Name"
                            style={{ width: '100%' }}
                            showSearch
                            filterOption={filterOption}
                            allowClear
                            disabled={isViewOnly}
                            status={(isCheckRequired && !inventory.product_nameId) ? 'error' : ""}
                            onChange={(value: any, item: any) => setInventory(prevState => ({ ...prevState, product_nameId: item?.id, product_name: item?.value }))}
                            options={productNameListData}
                            className='input'
                            value={inventory.product_name}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Product Category</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select Product Name"
                            style={{ width: '100%' }}
                            disabled={isViewOnly}
                            showSearch
                            filterOption={filterOption}
                            allowClear
                            status={(isCheckRequired && !inventory.product_categoryId) ? 'error' : ""}
                            onChange={(value: any, item: any) => setInventory(prevState => ({ ...prevState, product_categoryId: item?.id, product_category: item?.value }))}
                            options={productCategorieListData}
                            className='input'
                            value={inventory.product_category}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Product Section</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, product_section: target.value }))}
                            placeholder="Enter Product Section"
                            status={(isCheckRequired && !inventory.product_section) ? 'error' : ""}
                            disabled={isViewOnly}
                            value={inventory.product_section}
                            className='input' />
                    </div>
                    {(inventory.product_type === "Food" || inventory.product_type === "Medicine") ? <div className='input-cont'>
                        <span>Class</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select Class"
                            style={{ width: '100%' }}
                            disabled={isViewOnly}
                            status={(isCheckRequired && !inventory.classId) ? 'error' : ""}
                            onChange={(value: any, item: any) => setInventory(prevState => ({ ...prevState, classId: item.id, class: item.value }))}
                            options={classListData}
                            className='input'
                            value={inventory.class}
                        />
                    </div> : null}
                    <div className='input-cont'>
                        <span>Received Quantity</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, received_quantity: target.value }))}
                            placeholder="Enter Received Quantity"
                            disabled={isViewOnly}
                            status={(isCheckRequired && !inventory.received_quantity) ? 'error' : ""}
                            value={inventory.received_quantity}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Consumed Quantity</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, consumed_quantity: target.value }))}
                            placeholder="Enter Consumed Quantity"
                            disabled={isViewOnly}
                            status={(isCheckRequired && !inventory.consumed_quantity) ? 'error' : ""}
                            value={inventory.consumed_quantity}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Warehouse Address</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, warehouse_address: target.value }))}
                            status={(isCheckRequired && !inventory.warehouse_address) ? 'error' : ""}
                            placeholder="Enter Warehouse Address"
                            disabled={isViewOnly}
                            value={inventory.warehouse_address}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Description</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, description: target.value }))}
                            status={(isCheckRequired && !inventory.description) ? 'error' : ""}
                            disabled={isViewOnly}
                            placeholder="Enter Description"
                            value={inventory.description}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Unit of Measure</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, unit_of_measure: target.value }))}
                            placeholder="Enter Unit"
                            status={(isCheckRequired && !inventory.unit_of_measure) ? 'error' : ""}
                            disabled={isViewOnly}
                            value={inventory.unit_of_measure}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Product Code</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, product_code: target.value }))}
                            disabled={isViewOnly}
                            status={(isCheckRequired && !inventory.product_code) ? 'error' : ""}
                            placeholder="Enter Product Code"
                            value={inventory.product_code}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Product Barcode</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, product_barcode: target.value }))}
                            disabled={isViewOnly}
                            placeholder="Enter Product Barcode"
                            status={(isCheckRequired && !inventory.product_barcode) ? 'error' : ""}
                            value={inventory.product_barcode}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Product QR Code</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, product_qr_code: target.value }))}
                            status={(isCheckRequired && !inventory.product_qr_code) ? 'error' : ""}
                            placeholder="Enter QR Code"
                            disabled={isViewOnly}
                            value={inventory.product_qr_code}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Product Brand</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, product_brand: target.value }))}
                            placeholder="Enter Product Brand"
                            status={(isCheckRequired && !inventory.product_brand) ? 'error' : ""}
                            disabled={isViewOnly}
                            value={inventory.product_brand}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Product Company</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, product_company: target.value }))}
                            placeholder="Enter Product Company"
                            status={(isCheckRequired && !inventory.product_company) ? 'error' : ""}
                            disabled={isViewOnly}
                            value={inventory.product_company}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Induction Date</span>
                        <DatePicker
                            onChange={(date, dateString) => handleDateChange('induction_date', date, dateString)}
                            value={inventory.induction_date ? dayjs(inventory.induction_date) : ""}
                            className="input"
                            status={(isCheckRequired && !inventory.induction_date) ? 'error' : ""}
                            disabled={isViewOnly}
                            placeholder="Enter Date"
                            format={"YYYY-MM-DD"}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Warranty Year</span>
                        <DatePicker
                            onChange={(date, dateString) => handleDateChange('warranty_year', date, dateString)}
                            value={inventory.warranty_year ? dayjs(inventory.warranty_year) : ""}
                            status={(isCheckRequired && !inventory.warranty_year) ? 'error' : ""}
                            className="input"
                            disabled={isViewOnly}
                            placeholder="Enter Warranty Year"
                            format={"YYYY"}
                            picker="year"
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Expiry Date</span>
                        <DatePicker
                            disabled={isViewOnly}
                            onChange={(date, dateString) => handleDateChange('expiry_date', date, dateString)}
                            value={inventory.expiry_date ? dayjs(inventory.expiry_date) : ""}
                            status={(isCheckRequired && !inventory.expiry_date) ? 'error' : ""}
                            className="input"
                            placeholder="Enter Expiry Date"
                            format={"YYYY-MM-DD"}
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Select
                            value={inventory.division || undefined}
                            placeholder="Select the Division"
                            disabled
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setInventory(prevState => ({ ...prevState, divisionId: item?.id, division: item?.value }))}
                            status={(isCheckRequired && !inventory.divisionId) ? 'error' : ""}
                            options={divisionData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the District"
                            disabled
                            value={inventory.district || undefined}
                            status={(isCheckRequired && !inventory.districtId) ? 'error' : ""}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setInventory(prevState => ({ ...prevState, districtId: item?.id, district: item?.value }))}
                            options={districtData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Tehsil"
                            style={{ width: '100%' }}
                            disabled={isViewOnly}
                            value={inventory.tehsil || undefined}
                            onChange={(value: any, item: any) => setInventory(prevState => ({ ...prevState, tehsilId: item?.id, tehsil: item?.value }))}
                            // status={(isCheckRequired && !inventory.tehsilId) ? 'error' : ""}
                            options={tehsilData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Union Council"
                            disabled={isViewOnly}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setInventory(prevState => ({ ...prevState, union_councilId: item?.id, union_council: item?.value }))}
                            // status={(isCheckRequired && !inventory.union_councilId) ? 'error' : ""}
                            options={unionCouncilListData}
                            className='input'
                            value={inventory.union_council || undefined}
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, latitude: target.value }))}
                            placeholder="Enter Latitude"
                            value={inventory.latitude}
                            status={(isCheckRequired && !inventory.latitude) ? 'error' : ""}
                            disabled={isViewOnly}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            onChange={({ target }) => setInventory(prevState => ({ ...prevState, longitude: target.value }))}
                            placeholder="Enter Longitude"
                            status={(isCheckRequired && !inventory.longitude) ? 'error' : ""}
                            value={inventory.longitude}
                            disabled={isViewOnly}
                            className='input'
                            onKeyDown={(event) => {
                                // Prevent the input of "e" key
                                if (event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                            type='number'
                            inputMode='numeric' />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default InventoryForms;
