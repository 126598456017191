import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

interface LivestockDamageState {
    livestockDamageList: any[];
    livestockDamagesLoading: boolean;
    filteredLivestockDamagesList: any[];
    livestockTypeList: any[];
    livestockDamageMeta: any;
    filteredLivestockDamagesCount: number;
    livestockDamageChartList: any[];
    livestockDamageCount: number;
    selectedLivestockDamage: any;
    livestockOwnerDetails: any[];
    livestockOwnerDetailsMeta: any;
}

const initialState: LivestockDamageState = {
    livestockDamageList: [],
    livestockDamagesLoading: false,
    filteredLivestockDamagesList: [],
    livestockTypeList: [],
    livestockDamageMeta: {},
    filteredLivestockDamagesCount: 0,
    livestockDamageChartList: [],
    livestockDamageCount: 0,
    selectedLivestockDamage: {},
    livestockOwnerDetails: [],
    livestockOwnerDetailsMeta: {},
};

export const fetchLivestockDamagesCount = createAsyncThunk('livestockDamage/fetchLivestockDamagesCount', async (data: any, { rejectWithValue }) => {
    try {
        const { selectedEvent, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, withLocation } = data;
        const variables: any = {
            filter: {
                stage: {
                    null: true
                }
            }
        };
        if (selectedEvent) {
            variables.filter.event = {
                name: {
                    "eq": selectedEvent
                }
            };
        }
        if (selectedDivision) {
            variables.filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            variables.filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            variables.filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            variables.filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: withLocation ? API.ApiLivestockDamagesCount : API.ApiLivestockDamagesCountWithOutChartData,
            fetchPolicy: 'no-cache',
            variables
        });
        return { count: response.data.livestockDamages.meta.pagination.total, livestockDamageChartList: response.data.livestockDamages.data };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchLivestockDamageData = createAsyncThunk('livestockDamage/fetchLivestockDamageData', async (pageData: any, { rejectWithValue }) => {
    try {
        const { page, pageSize, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil, selectedEvent } = pageData;
        const filter: any = {
            stage: {
                null: true
            }
        };
        if (selectedEvent) {
            filter.event = {
                name: {
                    "eq": selectedEvent
                }
            };
        }
        if (selectedDivision) {
            filter.division = {
                division_name: {
                    "eq": selectedDivision
                }
            };
        }
        if (selectedDistrict) {
            filter.district = {
                district_name: {
                    "eq": selectedDistrict
                }
            };
        }
        if (selectedTehsil) {
            filter.tehsil = {
                tehsil_name: {
                    "eq": selectedTehsil
                }
            };
        }
        if (selectedUnionCouncil) {
            filter.union_council = {
                union_council_name: {
                    "eq": selectedUnionCouncil
                }
            };
        }
        const response = await client.query({
            query: API.ApiAllLivestockDamages,
            fetchPolicy: 'no-cache',
            variables: { page, pageSize, filter }
        });
        return { data: response.data.livestockDamages.data, meta: response.data.livestockDamages.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchFilterdLivestockDamageData = createAsyncThunk('livestockDamage/fetchFilterdLivestockDamageData', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await client.query({ query: API.ApiLivestockDamages, variables, context: { headers }, fetchPolicy: 'no-cache' });
        return { data: response.data.livestockDamageDrafts.data, filteredLivestockDamagesCount: response.data.livestockDamageDrafts.meta.pagination.total };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchLivestockDamageDataById = createAsyncThunk('livestockDamage/fetchLivestockDamageDataById', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        const response = await client.query({ query: API.ApiGetLivestockDamageById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.livestockDamage.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllLivestockOwnerDetails = createAsyncThunk('livestockDamage/fetchAllLivestockOwnerDetails', async (variables: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetAllLivestockOwnerDetails, variables, fetchPolicy: 'no-cache' });
        return { data: response.data.livestockOwnerDetails.data, meta: response.data.livestockOwnerDetails.meta };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateLivestockDamageStageData = createAsyncThunk('livestockDamage/updateLivestockDamageStageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { stage, id, record } = data;
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        if (stage) {
            await client.mutate({
                mutation: API.ApiUpdateLivestockDamageStage,
                variables: {
                    id: id,
                    stage: stage
                },
                context: {
                    headers,
                },
            });
        } else {
            const obj = {
                "divisionId": record.divisionId,
                "districtId": record.districtId,
                "livestock_typeId": record.livestock_typeId,
                "eventId": record.eventId,
                "total_quantity": record.total_quantity ? Number(record.total_quantity) : null,
                "cost": record.cost ? Number(record.cost) : null,
                "latitude": record.latitude ? Number(record.latitude) : null,
                "longitude": record.longitude ? Number(record.longitude) : null,
                "casualty_typeId": record.casualty_typeId,
                "stage": null,
                "tehsilId": record.tehsilId,
                "union_councilId": record.union_councilId,
                "address": record.address,
                "livestockDamageId": record.livestock_damage
            };
            await client.mutate({
                mutation: obj.livestockDamageId ? API.ApiUpdateExistingLivestockDamage : API.ApiCreatePublisedLivestockDamage,
                variables: obj,
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
            await client.mutate({
                mutation: API.ApiDeleteLivestockDamageDraft,
                variables: { id },
                context: {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                },
            });
        }
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllLivestockTypes = createAsyncThunk('livestockDamage/fetchAllLivestockTypes', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetLivestockTypes, fetchPolicy: 'no-cache' });
        return response.data.livestockTypes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createLivestockDamageData = createAsyncThunk('livestockDamage/createLivestockDamageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateLivestockDamage,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createLivestockDamageDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createLivestockOwnerDetail = createAsyncThunk('livestockDamage/createLivestockOwnerDetail', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateLivestockOwnerDetail,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createLivestockOwnerDetail.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateLivestockDraftData = createAsyncThunk('livestockDamage/updateLivestockDraftData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingLivestockDamageDraft,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateLivestockDamageDraft.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteLivestockDamageData = createAsyncThunk('livestockDamage/deleteLivestockDamageData', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteLivestockDamage,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateLivestockDamageData = createAsyncThunk('livestockDamage/updateLivestockDamageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingLivestockDamage, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateLivestockDamage.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const updateLivestockOwnerDetail = createAsyncThunk('livestockDamage/updateLivestockOwnerDetail', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.ApiUpdateExistingLivestockOwnerDetail, // Assuming you have an API constant for update mutation
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.updateLivestockOwnerDetail.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateLivestockDamageRecordFromSocket = createAsyncThunk('livestockDamage/addAndUpdateLivestockDamageRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, internalWorkflowsList } = data;
        const response = await client.query({ query: API.ApiGetLivestockDamageById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.livestockDamage.data, internalWorkflowsList };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteLivestockDamageRecordFromSocket = createAsyncThunk('livestockDamage/deleteLivestockDamageRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteLivestockTypeRecordFromSocket = createAsyncThunk('livestockDamage/deleteLivestockTypeRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteLivestockOwnerDetail = createAsyncThunk('livestockDamage/deleteLivestockOwnerDetail', async (id: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        };
        await client.mutate({
            mutation: API.ApiDeleteLivestockOwnerDetail,
            variables: { id },
            context: {
                headers,
            },
        });
        return id;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateLivestockTypeRecordFromSocket = createAsyncThunk('livestockDamage/addAndUpdateLivestockTypeRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetLivestockTypeById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.livestockType.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const getLivestockDraftByUser = createAsyncThunk('livestockDamage/getLivestockDraftByUser', async (variables: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const headers = {
            Authorization: `Bearer ${authToken}`,
        };
        const response = await client.mutate({
            mutation: API.APIGetLivestockDamageDraftByUser, // Assuming you have an API constant for update mutation
            variables,
            context: {
                headers,
            },
        });
        if (response.data && response.data.livestockDamageDrafts.data.length > 0) {
            return true;
        }
        return false;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const livestockDamageSlice = createSlice({
    name: 'livestockDamage',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchLivestockDamagesCount.fulfilled, (state, action) => {
                state.livestockDamageCount = action.payload.count;
                state.livestockDamageChartList = action.payload.livestockDamageChartList;
                state.livestockDamagesLoading = false;
            })
            .addCase(fetchLivestockDamageData.fulfilled, (state, action) => {
                state.livestockDamageList = action.payload.data;
                state.livestockDamageMeta = action.payload.meta;
                state.livestockDamagesLoading = false;
            })
            .addCase(fetchFilterdLivestockDamageData.fulfilled, (state, action) => {
                state.filteredLivestockDamagesList = action.payload.data;
                state.filteredLivestockDamagesCount = action.payload.filteredLivestockDamagesCount;
                state.livestockDamagesLoading = false;
            })
            .addCase(fetchLivestockDamageDataById.fulfilled, (state, action) => {
                state.selectedLivestockDamage = action.payload;
                state.livestockDamagesLoading = false;
            })
            .addCase(fetchAllLivestockOwnerDetails.fulfilled, (state, action) => {
                state.livestockOwnerDetails = action.payload.data; // Since action.payload is already an array of District
                state.livestockOwnerDetailsMeta = action.payload.meta; // Since action.payload is already an array of District
                state.livestockDamagesLoading = false;
            })
            .addCase(createLivestockDamageData.fulfilled, (state, action) => {
                // state.livestockDamageList.push(action.payload);
                state.livestockDamagesLoading = false;
            })
            .addCase(updateLivestockDraftData.fulfilled, (state, action) => {
                state.livestockDamagesLoading = false;
            })
            .addCase(getLivestockDraftByUser.fulfilled, (state, action) => {
                state.livestockDamagesLoading = false;
            })
            .addCase(createLivestockOwnerDetail.fulfilled, (state, action) => {
                state.livestockOwnerDetails.push(action.payload);
                state.livestockDamagesLoading = false;
            })
            .addCase(updateLivestockDamageStageData.fulfilled, (state, action) => {
                const index = state.filteredLivestockDamagesList.findIndex(item => item.id === action.payload);
                state.filteredLivestockDamagesList.splice(index, 1);
                state.livestockDamagesLoading = false;
            })
            .addCase(fetchAllLivestockTypes.fulfilled, (state, action) => {
                state.livestockTypeList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(deleteLivestockDamageData.fulfilled, (state, action) => {
                state.livestockDamagesLoading = false;
            })
            .addCase(deleteLivestockOwnerDetail.fulfilled, (state, action) => {
                const index = state.livestockOwnerDetails.findIndex(item => item.id === action.payload);
                state.livestockOwnerDetails.splice(index, 1);
                state.livestockDamagesLoading = false;
            })
            .addCase(updateLivestockDamageData.fulfilled, (state, action) => {
                const index = state.filteredLivestockDamagesList.findIndex(item => item.id === action.payload);
                state.filteredLivestockDamagesList.splice(index, 1);
                state.livestockDamagesLoading = false;
            })
            .addCase(updateLivestockOwnerDetail.fulfilled, (state, action) => {
                const index = state.livestockOwnerDetails.findIndex(item => item.id === action.payload.id);
                index !== -1 ? state.livestockOwnerDetails[index] = action.payload : state.livestockOwnerDetails.push(action.payload);
                state.livestockDamagesLoading = false;
            })
            .addCase(addAndUpdateLivestockDamageRecordFromSocket.fulfilled, (state, action) => {
                const internalWorkflowsList = action.payload.internalWorkflowsList;
                const { id, attributes } = action.payload.data;
                if (attributes.stage) {
                    const selectedWorkFlow: any = internalWorkflowsList.find((item: any) => item.stage === attributes.stage);
                    const index = state.filteredLivestockDamagesList.findIndex(item => item.id == id);
                    if (selectedWorkFlow) {
                        index !== -1 ? state.filteredLivestockDamagesList[index] = action.payload.data : state.filteredLivestockDamagesList.push(action.payload.data);
                    } else {
                        state.filteredLivestockDamagesList.splice(index, 1);
                    }
                } else {
                    const index = state.livestockDamageList.findIndex(item => item.id == id);
                    index !== -1 ? state.livestockDamageList[index] = action.payload.data : state.livestockDamageList.push(action.payload.data);
                }
                if (state.selectedLivestockDamage.id === id) {
                    state.selectedLivestockDamage = action.payload.data;
                }
            })
            .addCase(deleteLivestockDamageRecordFromSocket.fulfilled, (state, action) => {
                state.livestockDamageList = state.livestockDamageList.filter(item => item.id != action.payload);
                state.filteredLivestockDamagesList = state.filteredLivestockDamagesList.filter(item => item.id != action.payload);
            })
            .addCase(deleteLivestockTypeRecordFromSocket.fulfilled, (state, action) => {
                state.livestockTypeList = state.livestockTypeList.filter(item => item.id != action.payload);
            })
            .addCase(addAndUpdateLivestockTypeRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.livestockTypeList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.livestockTypeList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.livestockTypeList = updatedArray;
            });
        [
            fetchLivestockDamagesCount,
            fetchLivestockDamageData,
            fetchFilterdLivestockDamageData,
            fetchLivestockDamageDataById,
            updateLivestockDamageStageData,
            createLivestockDamageData,
            deleteLivestockDamageData,
            updateLivestockDamageData,
            fetchAllLivestockOwnerDetails,
            createLivestockOwnerDetail,
            deleteLivestockOwnerDetail,
            updateLivestockOwnerDetail,
            updateLivestockDraftData,
            getLivestockDraftByUser
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.livestockDamagesLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.livestockDamagesLoading = false;
                });
        });
    }
});


export default livestockDamageSlice.reducer;
