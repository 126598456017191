import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import formReliefDistributionLogo from '../../assets/images/formReliefDistributionLogo.png';
import { Flex, Input, Tag } from 'antd';
import { RootState } from '../../reduxToolkit/store';
import EventsIcon from '../../assets/images/Events.png';
import FinancialIcon from '../../assets/images/FinancialIcon.png';
import { fetchFinancialReliefDataById } from '../../reduxToolkit/financialReliefSlice';
import { alignEventData, alignFinancialReliefData } from '../../helpers/alignData';

const ContributorsReliefDetails = () => {
    const { TextArea } = Input;
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { recordId } = location.state || {};
    const [contributorsDetails, setContributorsDetails] = useState<{ [key: string]: any; }>({});
    const [eventData, setEventData] = useState<{ [key: string]: any; }>({});
    const { selectedFinancialRelief } = useSelector((state: RootState) => state.financialRelief);

    useEffect(() => {
        dispatch(fetchFinancialReliefDataById(recordId));
    }, []);

    useEffect(() => {
        const financialReliefData = alignFinancialReliefData([selectedFinancialRelief]);
        const selectedFinancialReliefData = financialReliefData[0] ? financialReliefData[0] : {};
        const eventData = alignEventData(selectedFinancialReliefData.eventData);
        setEventData(eventData);
        setContributorsDetails(selectedFinancialReliefData);
    }, [selectedFinancialRelief]);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formReliefDistributionLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Relief Distribution</span>
                        </div>
                    </div>
                    <div />
                </div>
            </div>
            <div className='event-form'>
                <div className='form-bady-heading'>
                    <img src={EventsIcon} alt="" style={{ height: "35px" }} />
                    <span>Event</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Name</span>
                        <Input
                            value={eventData.name}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>From date</span>
                        <Input
                            value={eventData.from_date}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>To date</span>
                        <Input
                            value={eventData.to_date}
                            disabled
                            className='input' />
                    </div>
                    <div className='textArea-cont'>
                        <span>Description</span>
                        <TextArea
                            rows={4}
                            value={eventData.description}
                            disabled
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Divisions</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.divisions?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Districts</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.districts?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Tehsils</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.tehsils?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                    <div className='input-cont'>
                        <span>Union Councils</span>
                        <Flex gap="4px 0" wrap='wrap' style={{ alignItems: 'center' }}>
                            {
                                eventData?.union_councils?.map((item: string) => <Tag style={{ height: 'fit-content' }}>{item}</Tag>)
                            }
                        </Flex>
                    </div>
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <img src={FinancialIcon} alt="" />
                    <span>Contributors relief</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Contributor</span>
                        <Input
                            value={contributorsDetails.contributor}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Amount Received in PKR</span>
                        <Input
                            value={contributorsDetails.amount_received_in_pkr}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Amount Consumed in PKR</span>
                        <Input
                            value={contributorsDetails.amount_consumed_in_pkr}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Amount in Hand in PKR</span>
                        <Input
                            value={contributorsDetails.amount_on_hand_in_pkr}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Event</span>
                        <Input
                            value={contributorsDetails.event}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Input
                            value={contributorsDetails.division}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Input
                            value={contributorsDetails.district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Input
                            value={contributorsDetails.tehsil}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Input
                            value={contributorsDetails.union_council}
                            disabled
                            className='input'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContributorsReliefDetails;