import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import formResourceMappingLogo from '../../assets/images/formResourceMappingLogo.png';
import { Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { fetchVolunteerDataById } from '../../reduxToolkit/volunteerSlice';
import { alignVolunteersData } from '../../helpers/alignData';

const VolunteersMappingDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { recordId } = location.state || {};
    const [volunteersMapping, setVolunteersMapping] = useState<{ [key: string]: any; }>({});
    const { selectedVolunteer } = useSelector((state: RootState) => state.volunteer);

    useEffect(() => {
        dispatch(fetchVolunteerDataById(recordId));
    }, []);

    useEffect(() => {
        const volunteerData = alignVolunteersData([selectedVolunteer]);
        const selectedVolunteerData = volunteerData[0] ? volunteerData[0] : {};
        setVolunteersMapping(selectedVolunteerData);
    }, [selectedVolunteer]);

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={formResourceMappingLogo} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Resource Mapping</span>
                        </div>
                    </div>
                    <div />
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <span>Volunteers Mapping</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Name</span>
                        <Input
                            value={volunteersMapping.name}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>CNIC Number</span>
                        <Input
                            value={volunteersMapping.cnic_number}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Input
                            value={volunteersMapping.division}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Input
                            value={volunteersMapping.district}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Input
                            value={volunteersMapping.tehsil}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Input
                            value={volunteersMapping.union_council}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            value={volunteersMapping.address}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Postal Address</span>
                        <Input
                            value={volunteersMapping.postal_address}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Website</span>
                        <Input
                            value={volunteersMapping.website}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Contact Person</span>
                        <Input
                            value={volunteersMapping.contact_person}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Contact Person Mobile</span>
                        <Input
                            value={volunteersMapping.contact_person_mobile}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Latitude</span>
                        <Input
                            value={volunteersMapping.latitude}
                            disabled
                            className='input'
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Longitude</span>
                        <Input
                            value={volunteersMapping.longitude}
                            disabled
                            className='input'
                        />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default VolunteersMappingDetails;
